




























































































































































































































































































































import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";
import {
  initGeDetailByListItem,
  deleteCurrentItemInfo,
  getCollectFolder,
  addCollectFolder,
  moveInfoToFolder,
  deleteInfoCollectFolder,
  downloadFile,
} from "./script";

interface Vdata {
  name: string; // 组件名称
  img: string;
  list: Array<any>;
  detailModal: boolean;
}

interface FieldAttr {
  page: number;
}

interface CollectInfoType {
  dialogCollectVisible: boolean;
  title: string;
  type: string;
  selectMoveCollectValue: string;
  selectOptions: Array<any>;
  addCollectContent: string;
}

@Component({
  components: {},
})
export default class ContainerList extends Vue {
  // data
  private data: Vdata = {
    name: "container-list",
    img: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1112%2F061519115S2%2F1Z615115S2-3-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653553107&t=d5f51282a495b0757f4e34e404aac8cc",
    list: [1, 1, 1, 1, 1, 1, 1],
    detailModal: false, // 详情弹窗
  };
  // 收藏弹窗信息
  private collectInfo: CollectInfoType = {
    dialogCollectVisible: false, // 收藏弹窗
    title: "将文件收藏到", // 弹窗标题 将文件收藏到 添加文件夹
    type: "move", // move add
    selectMoveCollectValue: "", // 移动文件夹
    selectOptions: [
      { name: "文件夹1", id: 1 },
      { name: "文件夹2", id: 2 },
      { name: "文件夹3", id: 3 },
    ],
    addCollectContent: "", // 新增收藏文件夹
  };
  // 信息详情
  private infoItemDetailData: any = {
    publishTime: "0000-00-00 00:00:00",
    typeId: ["VIDEO"],
    frameImageIds: [],
    playVideo: false, // 默认不展示视频播放器
  }; // 点击每一项 获取到对应的信息详情

  private reg: RegExp = new RegExp("(<font color='#F85C5B'>)|(</font>)", "g");

  // props
  @Prop({ default: "事件推送" }) readonly pageType: string | undefined; // 事件推送 视图搜索
  @Prop({ default: "video" }) readonly detailType: string | undefined; // 图片 image 、视频 video
  @Prop({ default: 0, type: Number }) readonly totalNum: number | undefined; // 图片 image 、视频 video
  @Prop({ default: () => [], type: Array }) readonly list:
    | Array<any>
    | undefined; // 图片 image 、视频 video
  @Prop({ default: 0, type: Number }) readonly currentPage: number | undefined; // 图片 image 、视频 video
  // emit 翻页
  @Emit("getField")
  getField(params: FieldAttr) {
    return params;
  }

  // methods 点击列表项 弹出详情
  private getDetail(it: any): void {
    this.data.detailModal = true;
    initGeDetailByListItem.bind(this)(it); // 获取详情页数据 REQUEST
  }

  // methods 翻页
  private handleCurrentChange(page: number): void {
    this.getField({ page });
  }

  // methods 删除
  private deleteItem(): void {
    this.$confirm("是否确认删除该信息?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      // DELETE REQUEST OK
      deleteCurrentItemInfo.bind(this)();
    });
  }

  // methods 下载
  private downloadItem(): void {
    this.$confirm("是否确认下载该信息内容?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      // DELETE REQUEST OK
      downloadFile.bind(this)();
    });
  }

  // methods 收藏
  private collectItem(): void {
    // 1. 判断是否已经收藏
    if (this.infoItemDetailData.collection) {
      // 取消收藏
      deleteInfoCollectFolder.bind(this)(
        this.infoItemDetailData.esPropInfo.docId
      );
      return;
    }

    // 打开 收藏文件夹
    this.collectInfo.dialogCollectVisible = true;
    getCollectFolder.bind(this)(); // 获取
  }

  // methods 添加文件夹点击
  private addCollectFolderClick(): void {
    // check is null
    if (this.collectInfo.addCollectContent === "") {
      this.$message({ type: "error", message: "请输入内容" });
      return;
    }
    addCollectFolder.bind(this)(
      this.infoItemDetailData.esPropInfo.docId,
      this.collectInfo.addCollectContent
    ); // 新增 REQUEST
  }

  // methods 移动文件信息到文件夹
  private moveInfoToFolderClick(): void {
    // check is null
    if (!this.collectInfo.selectMoveCollectValue) {
      this.$message({ type: "error", message: "请选择收藏文件夹" });
      return;
    }
    moveInfoToFolder.bind(this)(
      this.infoItemDetailData.esPropInfo.docId,
      this.collectInfo.selectMoveCollectValue
    ); // 移动 REQUEST
  }

  // methods 去到源视频地址 图片地址
  private goToSourceLinkPage(): void {
    window.open(this.infoItemDetailData.url, "_blank");
  }

  // methods 关闭收藏弹窗
  private closeCollectModal(done: any): void {
    this.collectInfo.type = "move";
    done();
  }

  private closeDetailModal(done: any): void {
    // 初始化
    this.infoItemDetailData = {
      publishTime: "0000-00-00 00:00:00",
      typeId: ["VIDEO"],
      frameImageIds: [],
      playVideo: false, // 默认不展示视频播放器
    };
    done();
  }

  // methods 点击播放视频
  private playVideo(): void {
    this.infoItemDetailData.playVideo = true;
    console.log(this.infoItemDetailData.playVideo);
  }

  // mounted
  private mounted(): void {
    getCollectFolder.bind(this)(); // 获取
  }

  // showBigImage
  private showBigImage(): void {
    console.log("showBigImage");
  }
}
