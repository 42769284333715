export const region = [
  {
    "id": 0,
    "name": "全国",
    "level": 1,
    "regionCode": "",
    "childrens": []
  },
  {
    "id": 1,
    "name": "北京市",
    "level": 1,
    "regionCode": "110000",
    "childrens": []
  },
  {
    "id": 25,
    "name": "天津市",
    "level": 1,
    "regionCode": "120000",
    "childrens": []
  },
  {
    "id": 50,
    "name": "河北省",
    "level": 1,
    "regionCode": "130000",
    "childrens": [
      {
        "id": 51,
        "name": "石家庄市",
        "level": 2,
        "regionCode": "130100",
        "childrens": [
          {
            "id": 52,
            "name": "长安区",
            "level": 3,
            "regionCode": "130102",
            "childrens": []
          },
          {
            "id": 54,
            "name": "桥西区",
            "level": 3,
            "regionCode": "130104",
            "childrens": []
          },
          {
            "id": 55,
            "name": "新华区",
            "level": 3,
            "regionCode": "130105",
            "childrens": []
          },
          {
            "id": 56,
            "name": "井陉矿区",
            "level": 3,
            "regionCode": "130107",
            "childrens": []
          },
          {
            "id": 57,
            "name": "裕华区",
            "level": 3,
            "regionCode": "130108",
            "childrens": []
          },
          {
            "id": 58,
            "name": "井陉县",
            "level": 3,
            "regionCode": "130121",
            "childrens": []
          },
          {
            "id": 59,
            "name": "正定县",
            "level": 3,
            "regionCode": "130123",
            "childrens": []
          },
          {
            "id": 60,
            "name": "栾城区",
            "level": 3,
            "regionCode": "130111",
            "childrens": []
          },
          {
            "id": 61,
            "name": "行唐县",
            "level": 3,
            "regionCode": "130125",
            "childrens": []
          },
          {
            "id": 62,
            "name": "灵寿县",
            "level": 3,
            "regionCode": "130126",
            "childrens": []
          },
          {
            "id": 63,
            "name": "高邑县",
            "level": 3,
            "regionCode": "130127",
            "childrens": []
          },
          {
            "id": 64,
            "name": "深泽县",
            "level": 3,
            "regionCode": "130128",
            "childrens": []
          },
          {
            "id": 65,
            "name": "赞皇县",
            "level": 3,
            "regionCode": "130129",
            "childrens": []
          },
          {
            "id": 66,
            "name": "无极县",
            "level": 3,
            "regionCode": "130130",
            "childrens": []
          },
          {
            "id": 67,
            "name": "平山县",
            "level": 3,
            "regionCode": "130131",
            "childrens": []
          },
          {
            "id": 68,
            "name": "元氏县",
            "level": 3,
            "regionCode": "130132",
            "childrens": []
          },
          {
            "id": 69,
            "name": "赵县",
            "level": 3,
            "regionCode": "130133",
            "childrens": []
          },
          {
            "id": 70,
            "name": "辛集市",
            "level": 3,
            "regionCode": "130181",
            "childrens": []
          },
          {
            "id": 71,
            "name": "藁城区",
            "level": 3,
            "regionCode": "130109",
            "childrens": []
          },
          {
            "id": 72,
            "name": "晋州市",
            "level": 3,
            "regionCode": "130183",
            "childrens": []
          },
          {
            "id": 73,
            "name": "新乐市",
            "level": 3,
            "regionCode": "130184",
            "childrens": []
          },
          {
            "id": 74,
            "name": "鹿泉区",
            "level": 3,
            "regionCode": "130110",
            "childrens": []
          }
        ]
      },
      {
        "id": 80,
        "name": "唐山市",
        "level": 2,
        "regionCode": "130200",
        "childrens": [
          {
            "id": 81,
            "name": "路南区",
            "level": 3,
            "regionCode": "130202",
            "childrens": []
          },
          {
            "id": 82,
            "name": "路北区",
            "level": 3,
            "regionCode": "130203",
            "childrens": []
          },
          {
            "id": 83,
            "name": "古冶区",
            "level": 3,
            "regionCode": "130204",
            "childrens": []
          },
          {
            "id": 84,
            "name": "开平区",
            "level": 3,
            "regionCode": "130205",
            "childrens": []
          },
          {
            "id": 85,
            "name": "丰南区",
            "level": 3,
            "regionCode": "130207",
            "childrens": []
          },
          {
            "id": 86,
            "name": "丰润区",
            "level": 3,
            "regionCode": "130208",
            "childrens": []
          },
          {
            "id": 88,
            "name": "滦南县",
            "level": 3,
            "regionCode": "130224",
            "childrens": []
          },
          {
            "id": 89,
            "name": "乐亭县",
            "level": 3,
            "regionCode": "130225",
            "childrens": []
          },
          {
            "id": 90,
            "name": "迁西县",
            "level": 3,
            "regionCode": "130227",
            "childrens": []
          },
          {
            "id": 91,
            "name": "玉田县",
            "level": 3,
            "regionCode": "130229",
            "childrens": []
          },
          {
            "id": 93,
            "name": "遵化市",
            "level": 3,
            "regionCode": "130281",
            "childrens": []
          },
          {
            "id": 94,
            "name": "迁安市",
            "level": 3,
            "regionCode": "130283",
            "childrens": []
          },
          {
            "id": 5134,
            "name": "曹妃甸区",
            "level": 3,
            "regionCode": "130209",
            "childrens": []
          },
          {
            "id": 5135,
            "name": "滦州市",
            "level": 3,
            "regionCode": "130284",
            "childrens": []
          }
        ]
      },
      {
        "id": 100,
        "name": "秦皇岛市",
        "level": 2,
        "regionCode": "130300",
        "childrens": [
          {
            "id": 101,
            "name": "海港区",
            "level": 3,
            "regionCode": "130302",
            "childrens": []
          },
          {
            "id": 102,
            "name": "山海关区",
            "level": 3,
            "regionCode": "130303",
            "childrens": []
          },
          {
            "id": 103,
            "name": "北戴河区",
            "level": 3,
            "regionCode": "130304",
            "childrens": []
          },
          {
            "id": 104,
            "name": "青龙满族自治县",
            "level": 3,
            "regionCode": "130321",
            "childrens": []
          },
          {
            "id": 105,
            "name": "昌黎县",
            "level": 3,
            "regionCode": "130322",
            "childrens": []
          },
          {
            "id": 106,
            "name": "抚宁区",
            "level": 3,
            "regionCode": "130306",
            "childrens": []
          },
          {
            "id": 107,
            "name": "卢龙县",
            "level": 3,
            "regionCode": "130324",
            "childrens": []
          }
        ]
      },
      {
        "id": 113,
        "name": "邯郸市",
        "level": 2,
        "regionCode": "130400",
        "childrens": [
          {
            "id": 114,
            "name": "邯山区",
            "level": 3,
            "regionCode": "130402",
            "childrens": []
          },
          {
            "id": 115,
            "name": "丛台区",
            "level": 3,
            "regionCode": "130403",
            "childrens": []
          },
          {
            "id": 116,
            "name": "复兴区",
            "level": 3,
            "regionCode": "130404",
            "childrens": []
          },
          {
            "id": 117,
            "name": "峰峰矿区",
            "level": 3,
            "regionCode": "130406",
            "childrens": []
          },
          {
            "id": 119,
            "name": "临漳县",
            "level": 3,
            "regionCode": "130423",
            "childrens": []
          },
          {
            "id": 120,
            "name": "成安县",
            "level": 3,
            "regionCode": "130424",
            "childrens": []
          },
          {
            "id": 121,
            "name": "大名县",
            "level": 3,
            "regionCode": "130425",
            "childrens": []
          },
          {
            "id": 122,
            "name": "涉县",
            "level": 3,
            "regionCode": "130426",
            "childrens": []
          },
          {
            "id": 123,
            "name": "磁县",
            "level": 3,
            "regionCode": "130427",
            "childrens": []
          },
          {
            "id": 124,
            "name": "肥乡区",
            "level": 3,
            "regionCode": "130407",
            "childrens": []
          },
          {
            "id": 125,
            "name": "永年区",
            "level": 3,
            "regionCode": "130408",
            "childrens": []
          },
          {
            "id": 126,
            "name": "邱县",
            "level": 3,
            "regionCode": "130430",
            "childrens": []
          },
          {
            "id": 127,
            "name": "鸡泽县",
            "level": 3,
            "regionCode": "130431",
            "childrens": []
          },
          {
            "id": 128,
            "name": "广平县",
            "level": 3,
            "regionCode": "130432",
            "childrens": []
          },
          {
            "id": 129,
            "name": "馆陶县",
            "level": 3,
            "regionCode": "130433",
            "childrens": []
          },
          {
            "id": 130,
            "name": "魏县",
            "level": 3,
            "regionCode": "130434",
            "childrens": []
          },
          {
            "id": 131,
            "name": "曲周县",
            "level": 3,
            "regionCode": "130435",
            "childrens": []
          },
          {
            "id": 132,
            "name": "武安市",
            "level": 3,
            "regionCode": "130481",
            "childrens": []
          }
        ]
      },
      {
        "id": 138,
        "name": "邢台市",
        "level": 2,
        "regionCode": "130500",
        "childrens": [
          {
            "id": 142,
            "name": "临城县",
            "level": 3,
            "regionCode": "130522",
            "childrens": []
          },
          {
            "id": 144,
            "name": "柏乡县",
            "level": 3,
            "regionCode": "130524",
            "childrens": []
          },
          {
            "id": 145,
            "name": "隆尧县",
            "level": 3,
            "regionCode": "130525",
            "childrens": []
          },
          {
            "id": 147,
            "name": "南和区",
            "level": 3,
            "regionCode": "130506",
            "childrens": []
          },
          {
            "id": 148,
            "name": "宁晋县",
            "level": 3,
            "regionCode": "130528",
            "childrens": []
          },
          {
            "id": 149,
            "name": "巨鹿县",
            "level": 3,
            "regionCode": "130529",
            "childrens": []
          },
          {
            "id": 150,
            "name": "新河县",
            "level": 3,
            "regionCode": "130530",
            "childrens": []
          },
          {
            "id": 151,
            "name": "广宗县",
            "level": 3,
            "regionCode": "130531",
            "childrens": []
          },
          {
            "id": 152,
            "name": "平乡县",
            "level": 3,
            "regionCode": "130532",
            "childrens": []
          },
          {
            "id": 153,
            "name": "威县",
            "level": 3,
            "regionCode": "130533",
            "childrens": []
          },
          {
            "id": 154,
            "name": "清河县",
            "level": 3,
            "regionCode": "130534",
            "childrens": []
          },
          {
            "id": 155,
            "name": "临西县",
            "level": 3,
            "regionCode": "130535",
            "childrens": []
          },
          {
            "id": 156,
            "name": "南宫市",
            "level": 3,
            "regionCode": "130581",
            "childrens": []
          },
          {
            "id": 157,
            "name": "沙河市",
            "level": 3,
            "regionCode": "130582",
            "childrens": []
          },
          {
            "id": 5136,
            "name": "襄都区",
            "level": 3,
            "regionCode": "130502",
            "childrens": []
          },
          {
            "id": 5137,
            "name": "信都区",
            "level": 3,
            "regionCode": "130503",
            "childrens": []
          },
          {
            "id": 5138,
            "name": "任泽区",
            "level": 3,
            "regionCode": "130505",
            "childrens": []
          },
          {
            "id": 5139,
            "name": "内丘县",
            "level": 3,
            "regionCode": "130523",
            "childrens": []
          }
        ]
      },
      {
        "id": 163,
        "name": "保定市",
        "level": 2,
        "regionCode": "130600",
        "childrens": [
          {
            "id": 167,
            "name": "满城区",
            "level": 3,
            "regionCode": "130607",
            "childrens": []
          },
          {
            "id": 168,
            "name": "清苑区",
            "level": 3,
            "regionCode": "130608",
            "childrens": []
          },
          {
            "id": 169,
            "name": "涞水县",
            "level": 3,
            "regionCode": "130623",
            "childrens": []
          },
          {
            "id": 170,
            "name": "阜平县",
            "level": 3,
            "regionCode": "130624",
            "childrens": []
          },
          {
            "id": 171,
            "name": "徐水区",
            "level": 3,
            "regionCode": "130609",
            "childrens": []
          },
          {
            "id": 172,
            "name": "定兴县",
            "level": 3,
            "regionCode": "130626",
            "childrens": []
          },
          {
            "id": 173,
            "name": "唐县",
            "level": 3,
            "regionCode": "130627",
            "childrens": []
          },
          {
            "id": 174,
            "name": "高阳县",
            "level": 3,
            "regionCode": "130628",
            "childrens": []
          },
          {
            "id": 175,
            "name": "容城县",
            "level": 3,
            "regionCode": "130629",
            "childrens": []
          },
          {
            "id": 176,
            "name": "涞源县",
            "level": 3,
            "regionCode": "130630",
            "childrens": []
          },
          {
            "id": 177,
            "name": "望都县",
            "level": 3,
            "regionCode": "130631",
            "childrens": []
          },
          {
            "id": 178,
            "name": "安新县",
            "level": 3,
            "regionCode": "130632",
            "childrens": []
          },
          {
            "id": 179,
            "name": "易县",
            "level": 3,
            "regionCode": "130633",
            "childrens": []
          },
          {
            "id": 180,
            "name": "曲阳县",
            "level": 3,
            "regionCode": "130634",
            "childrens": []
          },
          {
            "id": 181,
            "name": "蠡县",
            "level": 3,
            "regionCode": "130635",
            "childrens": []
          },
          {
            "id": 182,
            "name": "顺平县",
            "level": 3,
            "regionCode": "130636",
            "childrens": []
          },
          {
            "id": 183,
            "name": "博野县",
            "level": 3,
            "regionCode": "130637",
            "childrens": []
          },
          {
            "id": 184,
            "name": "雄县",
            "level": 3,
            "regionCode": "130638",
            "childrens": []
          },
          {
            "id": 185,
            "name": "涿州市",
            "level": 3,
            "regionCode": "130681",
            "childrens": []
          },
          {
            "id": 186,
            "name": "定州市",
            "level": 3,
            "regionCode": "130682",
            "childrens": []
          },
          {
            "id": 187,
            "name": "安国市",
            "level": 3,
            "regionCode": "130683",
            "childrens": []
          },
          {
            "id": 188,
            "name": "高碑店市",
            "level": 3,
            "regionCode": "130684",
            "childrens": []
          },
          {
            "id": 5140,
            "name": "竞秀区",
            "level": 3,
            "regionCode": "130602",
            "childrens": []
          },
          {
            "id": 5141,
            "name": "莲池区",
            "level": 3,
            "regionCode": "130606",
            "childrens": []
          }
        ]
      },
      {
        "id": 194,
        "name": "张家口市",
        "level": 2,
        "regionCode": "130700",
        "childrens": [
          {
            "id": 197,
            "name": "宣化区",
            "level": 3,
            "regionCode": "130705",
            "childrens": []
          },
          {
            "id": 198,
            "name": "下花园区",
            "level": 3,
            "regionCode": "130706",
            "childrens": []
          },
          {
            "id": 200,
            "name": "张北县",
            "level": 3,
            "regionCode": "130722",
            "childrens": []
          },
          {
            "id": 201,
            "name": "康保县",
            "level": 3,
            "regionCode": "130723",
            "childrens": []
          },
          {
            "id": 202,
            "name": "沽源县",
            "level": 3,
            "regionCode": "130724",
            "childrens": []
          },
          {
            "id": 203,
            "name": "尚义县",
            "level": 3,
            "regionCode": "130725",
            "childrens": []
          },
          {
            "id": 204,
            "name": "蔚县",
            "level": 3,
            "regionCode": "130726",
            "childrens": []
          },
          {
            "id": 205,
            "name": "阳原县",
            "level": 3,
            "regionCode": "130727",
            "childrens": []
          },
          {
            "id": 206,
            "name": "怀安县",
            "level": 3,
            "regionCode": "130728",
            "childrens": []
          },
          {
            "id": 207,
            "name": "万全区",
            "level": 3,
            "regionCode": "130708",
            "childrens": []
          },
          {
            "id": 208,
            "name": "怀来县",
            "level": 3,
            "regionCode": "130730",
            "childrens": []
          },
          {
            "id": 209,
            "name": "涿鹿县",
            "level": 3,
            "regionCode": "130731",
            "childrens": []
          },
          {
            "id": 210,
            "name": "赤城县",
            "level": 3,
            "regionCode": "130732",
            "childrens": []
          },
          {
            "id": 211,
            "name": "崇礼区",
            "level": 3,
            "regionCode": "130709",
            "childrens": []
          },
          {
            "id": 5142,
            "name": "桥东区",
            "level": 3,
            "regionCode": "130702",
            "childrens": []
          },
          {
            "id": 5143,
            "name": "桥西区",
            "level": 3,
            "regionCode": "130703",
            "childrens": []
          }
        ]
      },
      {
        "id": 217,
        "name": "承德市",
        "level": 2,
        "regionCode": "130800",
        "childrens": [
          {
            "id": 219,
            "name": "双滦区",
            "level": 3,
            "regionCode": "130803",
            "childrens": []
          },
          {
            "id": 220,
            "name": "鹰手营子矿区",
            "level": 3,
            "regionCode": "130804",
            "childrens": []
          },
          {
            "id": 221,
            "name": "承德县",
            "level": 3,
            "regionCode": "130821",
            "childrens": []
          },
          {
            "id": 222,
            "name": "兴隆县",
            "level": 3,
            "regionCode": "130822",
            "childrens": []
          },
          {
            "id": 223,
            "name": "平泉市",
            "level": 3,
            "regionCode": "130881",
            "childrens": []
          },
          {
            "id": 224,
            "name": "滦平县",
            "level": 3,
            "regionCode": "130824",
            "childrens": []
          },
          {
            "id": 225,
            "name": "隆化县",
            "level": 3,
            "regionCode": "130825",
            "childrens": []
          },
          {
            "id": 226,
            "name": "丰宁满族自治县",
            "level": 3,
            "regionCode": "130826",
            "childrens": []
          },
          {
            "id": 227,
            "name": "宽城满族自治县",
            "level": 3,
            "regionCode": "130827",
            "childrens": []
          },
          {
            "id": 228,
            "name": "围场满族蒙古族自治县",
            "level": 3,
            "regionCode": "130828",
            "childrens": []
          },
          {
            "id": 5144,
            "name": "双桥区",
            "level": 3,
            "regionCode": "130802",
            "childrens": []
          }
        ]
      },
      {
        "id": 234,
        "name": "沧州市",
        "level": 2,
        "regionCode": "130900",
        "childrens": [
          {
            "id": 236,
            "name": "运河区",
            "level": 3,
            "regionCode": "130903",
            "childrens": []
          },
          {
            "id": 237,
            "name": "沧县",
            "level": 3,
            "regionCode": "130921",
            "childrens": []
          },
          {
            "id": 238,
            "name": "青县",
            "level": 3,
            "regionCode": "130922",
            "childrens": []
          },
          {
            "id": 239,
            "name": "东光县",
            "level": 3,
            "regionCode": "130923",
            "childrens": []
          },
          {
            "id": 240,
            "name": "海兴县",
            "level": 3,
            "regionCode": "130924",
            "childrens": []
          },
          {
            "id": 241,
            "name": "盐山县",
            "level": 3,
            "regionCode": "130925",
            "childrens": []
          },
          {
            "id": 242,
            "name": "肃宁县",
            "level": 3,
            "regionCode": "130926",
            "childrens": []
          },
          {
            "id": 243,
            "name": "南皮县",
            "level": 3,
            "regionCode": "130927",
            "childrens": []
          },
          {
            "id": 244,
            "name": "吴桥县",
            "level": 3,
            "regionCode": "130928",
            "childrens": []
          },
          {
            "id": 245,
            "name": "献县",
            "level": 3,
            "regionCode": "130929",
            "childrens": []
          },
          {
            "id": 246,
            "name": "孟村回族自治县",
            "level": 3,
            "regionCode": "130930",
            "childrens": []
          },
          {
            "id": 247,
            "name": "泊头市",
            "level": 3,
            "regionCode": "130981",
            "childrens": []
          },
          {
            "id": 249,
            "name": "黄骅市",
            "level": 3,
            "regionCode": "130983",
            "childrens": []
          },
          {
            "id": 250,
            "name": "河间市",
            "level": 3,
            "regionCode": "130984",
            "childrens": []
          },
          {
            "id": 5145,
            "name": "新华区",
            "level": 3,
            "regionCode": "130902",
            "childrens": []
          },
          {
            "id": 5146,
            "name": "任丘市",
            "level": 3,
            "regionCode": "130982",
            "childrens": []
          }
        ]
      },
      {
        "id": 256,
        "name": "廊坊市",
        "level": 2,
        "regionCode": "131000",
        "childrens": [
          {
            "id": 257,
            "name": "安次区",
            "level": 3,
            "regionCode": "131002",
            "childrens": []
          },
          {
            "id": 258,
            "name": "广阳区",
            "level": 3,
            "regionCode": "131003",
            "childrens": []
          },
          {
            "id": 259,
            "name": "固安县",
            "level": 3,
            "regionCode": "131022",
            "childrens": []
          },
          {
            "id": 260,
            "name": "永清县",
            "level": 3,
            "regionCode": "131023",
            "childrens": []
          },
          {
            "id": 261,
            "name": "香河县",
            "level": 3,
            "regionCode": "131024",
            "childrens": []
          },
          {
            "id": 262,
            "name": "大城县",
            "level": 3,
            "regionCode": "131025",
            "childrens": []
          },
          {
            "id": 263,
            "name": "文安县",
            "level": 3,
            "regionCode": "131026",
            "childrens": []
          },
          {
            "id": 265,
            "name": "霸州市",
            "level": 3,
            "regionCode": "131081",
            "childrens": []
          },
          {
            "id": 266,
            "name": "三河市",
            "level": 3,
            "regionCode": "131082",
            "childrens": []
          },
          {
            "id": 5147,
            "name": "大厂回族自治县",
            "level": 3,
            "regionCode": "131028",
            "childrens": []
          }
        ]
      },
      {
        "id": 272,
        "name": "衡水市",
        "level": 2,
        "regionCode": "131100",
        "childrens": [
          {
            "id": 273,
            "name": "桃城区",
            "level": 3,
            "regionCode": "131102",
            "childrens": []
          },
          {
            "id": 274,
            "name": "枣强县",
            "level": 3,
            "regionCode": "131121",
            "childrens": []
          },
          {
            "id": 275,
            "name": "武邑县",
            "level": 3,
            "regionCode": "131122",
            "childrens": []
          },
          {
            "id": 276,
            "name": "武强县",
            "level": 3,
            "regionCode": "131123",
            "childrens": []
          },
          {
            "id": 277,
            "name": "饶阳县",
            "level": 3,
            "regionCode": "131124",
            "childrens": []
          },
          {
            "id": 278,
            "name": "安平县",
            "level": 3,
            "regionCode": "131125",
            "childrens": []
          },
          {
            "id": 279,
            "name": "故城县",
            "level": 3,
            "regionCode": "131126",
            "childrens": []
          },
          {
            "id": 280,
            "name": "景县",
            "level": 3,
            "regionCode": "131127",
            "childrens": []
          },
          {
            "id": 281,
            "name": "阜城县",
            "level": 3,
            "regionCode": "131128",
            "childrens": []
          },
          {
            "id": 282,
            "name": "冀州区",
            "level": 3,
            "regionCode": "131103",
            "childrens": []
          },
          {
            "id": 283,
            "name": "深州市",
            "level": 3,
            "regionCode": "131182",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 289,
    "name": "山西省",
    "level": 1,
    "regionCode": "140000",
    "childrens": [
      {
        "id": 290,
        "name": "太原市",
        "level": 2,
        "regionCode": "140100",
        "childrens": [
          {
            "id": 291,
            "name": "小店区",
            "level": 3,
            "regionCode": "140105",
            "childrens": []
          },
          {
            "id": 292,
            "name": "迎泽区",
            "level": 3,
            "regionCode": "140106",
            "childrens": []
          },
          {
            "id": 293,
            "name": "杏花岭区",
            "level": 3,
            "regionCode": "140107",
            "childrens": []
          },
          {
            "id": 294,
            "name": "尖草坪区",
            "level": 3,
            "regionCode": "140108",
            "childrens": []
          },
          {
            "id": 295,
            "name": "万柏林区",
            "level": 3,
            "regionCode": "140109",
            "childrens": []
          },
          {
            "id": 296,
            "name": "晋源区",
            "level": 3,
            "regionCode": "140110",
            "childrens": []
          },
          {
            "id": 297,
            "name": "清徐县",
            "level": 3,
            "regionCode": "140121",
            "childrens": []
          },
          {
            "id": 298,
            "name": "阳曲县",
            "level": 3,
            "regionCode": "140122",
            "childrens": []
          },
          {
            "id": 299,
            "name": "娄烦县",
            "level": 3,
            "regionCode": "140123",
            "childrens": []
          },
          {
            "id": 300,
            "name": "古交市",
            "level": 3,
            "regionCode": "140181",
            "childrens": []
          }
        ]
      },
      {
        "id": 306,
        "name": "大同市",
        "level": 2,
        "regionCode": "140200",
        "childrens": [
          {
            "id": 310,
            "name": "新荣区",
            "level": 3,
            "regionCode": "140212",
            "childrens": []
          },
          {
            "id": 311,
            "name": "阳高县",
            "level": 3,
            "regionCode": "140221",
            "childrens": []
          },
          {
            "id": 312,
            "name": "天镇县",
            "level": 3,
            "regionCode": "140222",
            "childrens": []
          },
          {
            "id": 313,
            "name": "广灵县",
            "level": 3,
            "regionCode": "140223",
            "childrens": []
          },
          {
            "id": 314,
            "name": "灵丘县",
            "level": 3,
            "regionCode": "140224",
            "childrens": []
          },
          {
            "id": 315,
            "name": "浑源县",
            "level": 3,
            "regionCode": "140225",
            "childrens": []
          },
          {
            "id": 316,
            "name": "左云县",
            "level": 3,
            "regionCode": "140226",
            "childrens": []
          },
          {
            "id": 5148,
            "name": "平城区",
            "level": 3,
            "regionCode": "140213",
            "childrens": []
          },
          {
            "id": 5149,
            "name": "云冈区",
            "level": 3,
            "regionCode": "140214",
            "childrens": []
          },
          {
            "id": 5150,
            "name": "云州区",
            "level": 3,
            "regionCode": "140215",
            "childrens": []
          }
        ]
      },
      {
        "id": 323,
        "name": "阳泉市",
        "level": 2,
        "regionCode": "140300",
        "childrens": [
          {
            "id": 326,
            "name": "郊区",
            "level": 3,
            "regionCode": "140311",
            "childrens": []
          },
          {
            "id": 327,
            "name": "平定县",
            "level": 3,
            "regionCode": "140321",
            "childrens": []
          },
          {
            "id": 328,
            "name": "盂县",
            "level": 3,
            "regionCode": "140322",
            "childrens": []
          },
          {
            "id": 5151,
            "name": "城区",
            "level": 3,
            "regionCode": "140302",
            "childrens": []
          },
          {
            "id": 5152,
            "name": "矿区",
            "level": 3,
            "regionCode": "140303",
            "childrens": []
          }
        ]
      },
      {
        "id": 334,
        "name": "长治市",
        "level": 2,
        "regionCode": "140400",
        "childrens": [
          {
            "id": 338,
            "name": "襄垣县",
            "level": 3,
            "regionCode": "140423",
            "childrens": []
          },
          {
            "id": 339,
            "name": "屯留区",
            "level": 3,
            "regionCode": "140405",
            "childrens": []
          },
          {
            "id": 340,
            "name": "平顺县",
            "level": 3,
            "regionCode": "140425",
            "childrens": []
          },
          {
            "id": 341,
            "name": "黎城县",
            "level": 3,
            "regionCode": "140426",
            "childrens": []
          },
          {
            "id": 342,
            "name": "壶关县",
            "level": 3,
            "regionCode": "140427",
            "childrens": []
          },
          {
            "id": 343,
            "name": "长子县",
            "level": 3,
            "regionCode": "140428",
            "childrens": []
          },
          {
            "id": 344,
            "name": "武乡县",
            "level": 3,
            "regionCode": "140429",
            "childrens": []
          },
          {
            "id": 345,
            "name": "沁县",
            "level": 3,
            "regionCode": "140430",
            "childrens": []
          },
          {
            "id": 346,
            "name": "沁源县",
            "level": 3,
            "regionCode": "140431",
            "childrens": []
          },
          {
            "id": 347,
            "name": "潞城区",
            "level": 3,
            "regionCode": "140406",
            "childrens": []
          },
          {
            "id": 5153,
            "name": "潞州区",
            "level": 3,
            "regionCode": "140403",
            "childrens": []
          },
          {
            "id": 5154,
            "name": "上党区",
            "level": 3,
            "regionCode": "140404",
            "childrens": []
          }
        ]
      },
      {
        "id": 353,
        "name": "晋城市",
        "level": 2,
        "regionCode": "140500",
        "childrens": [
          {
            "id": 355,
            "name": "沁水县",
            "level": 3,
            "regionCode": "140521",
            "childrens": []
          },
          {
            "id": 356,
            "name": "阳城县",
            "level": 3,
            "regionCode": "140522",
            "childrens": []
          },
          {
            "id": 357,
            "name": "陵川县",
            "level": 3,
            "regionCode": "140524",
            "childrens": []
          },
          {
            "id": 358,
            "name": "泽州县",
            "level": 3,
            "regionCode": "140525",
            "childrens": []
          },
          {
            "id": 359,
            "name": "高平市",
            "level": 3,
            "regionCode": "140581",
            "childrens": []
          },
          {
            "id": 5155,
            "name": "城区",
            "level": 3,
            "regionCode": "140502",
            "childrens": []
          }
        ]
      },
      {
        "id": 365,
        "name": "朔州市",
        "level": 2,
        "regionCode": "140600",
        "childrens": [
          {
            "id": 366,
            "name": "朔城区",
            "level": 3,
            "regionCode": "140602",
            "childrens": []
          },
          {
            "id": 367,
            "name": "平鲁区",
            "level": 3,
            "regionCode": "140603",
            "childrens": []
          },
          {
            "id": 368,
            "name": "山阴县",
            "level": 3,
            "regionCode": "140621",
            "childrens": []
          },
          {
            "id": 369,
            "name": "应县",
            "level": 3,
            "regionCode": "140622",
            "childrens": []
          },
          {
            "id": 370,
            "name": "右玉县",
            "level": 3,
            "regionCode": "140623",
            "childrens": []
          },
          {
            "id": 371,
            "name": "怀仁市",
            "level": 3,
            "regionCode": "140681",
            "childrens": []
          }
        ]
      },
      {
        "id": 377,
        "name": "晋中市",
        "level": 2,
        "regionCode": "140700",
        "childrens": [
          {
            "id": 378,
            "name": "榆次区",
            "level": 3,
            "regionCode": "140702",
            "childrens": []
          },
          {
            "id": 379,
            "name": "榆社县",
            "level": 3,
            "regionCode": "140721",
            "childrens": []
          },
          {
            "id": 380,
            "name": "左权县",
            "level": 3,
            "regionCode": "140722",
            "childrens": []
          },
          {
            "id": 381,
            "name": "和顺县",
            "level": 3,
            "regionCode": "140723",
            "childrens": []
          },
          {
            "id": 382,
            "name": "昔阳县",
            "level": 3,
            "regionCode": "140724",
            "childrens": []
          },
          {
            "id": 383,
            "name": "寿阳县",
            "level": 3,
            "regionCode": "140725",
            "childrens": []
          },
          {
            "id": 384,
            "name": "太谷区",
            "level": 3,
            "regionCode": "140703",
            "childrens": []
          },
          {
            "id": 385,
            "name": "祁县",
            "level": 3,
            "regionCode": "140727",
            "childrens": []
          },
          {
            "id": 386,
            "name": "平遥县",
            "level": 3,
            "regionCode": "140728",
            "childrens": []
          },
          {
            "id": 387,
            "name": "灵石县",
            "level": 3,
            "regionCode": "140729",
            "childrens": []
          },
          {
            "id": 388,
            "name": "介休市",
            "level": 3,
            "regionCode": "140781",
            "childrens": []
          }
        ]
      },
      {
        "id": 394,
        "name": "运城市",
        "level": 2,
        "regionCode": "140800",
        "childrens": [
          {
            "id": 395,
            "name": "盐湖区",
            "level": 3,
            "regionCode": "140802",
            "childrens": []
          },
          {
            "id": 396,
            "name": "临猗县",
            "level": 3,
            "regionCode": "140821",
            "childrens": []
          },
          {
            "id": 397,
            "name": "万荣县",
            "level": 3,
            "regionCode": "140822",
            "childrens": []
          },
          {
            "id": 398,
            "name": "闻喜县",
            "level": 3,
            "regionCode": "140823",
            "childrens": []
          },
          {
            "id": 399,
            "name": "稷山县",
            "level": 3,
            "regionCode": "140824",
            "childrens": []
          },
          {
            "id": 400,
            "name": "新绛县",
            "level": 3,
            "regionCode": "140825",
            "childrens": []
          },
          {
            "id": 401,
            "name": "绛县",
            "level": 3,
            "regionCode": "140826",
            "childrens": []
          },
          {
            "id": 402,
            "name": "垣曲县",
            "level": 3,
            "regionCode": "140827",
            "childrens": []
          },
          {
            "id": 403,
            "name": "夏县",
            "level": 3,
            "regionCode": "140828",
            "childrens": []
          },
          {
            "id": 404,
            "name": "平陆县",
            "level": 3,
            "regionCode": "140829",
            "childrens": []
          },
          {
            "id": 405,
            "name": "芮城县",
            "level": 3,
            "regionCode": "140830",
            "childrens": []
          },
          {
            "id": 406,
            "name": "永济市",
            "level": 3,
            "regionCode": "140881",
            "childrens": []
          },
          {
            "id": 407,
            "name": "河津市",
            "level": 3,
            "regionCode": "140882",
            "childrens": []
          }
        ]
      },
      {
        "id": 413,
        "name": "忻州市",
        "level": 2,
        "regionCode": "140900",
        "childrens": [
          {
            "id": 414,
            "name": "忻府区",
            "level": 3,
            "regionCode": "140902",
            "childrens": []
          },
          {
            "id": 415,
            "name": "定襄县",
            "level": 3,
            "regionCode": "140921",
            "childrens": []
          },
          {
            "id": 416,
            "name": "五台县",
            "level": 3,
            "regionCode": "140922",
            "childrens": []
          },
          {
            "id": 417,
            "name": "代县",
            "level": 3,
            "regionCode": "140923",
            "childrens": []
          },
          {
            "id": 418,
            "name": "繁峙县",
            "level": 3,
            "regionCode": "140924",
            "childrens": []
          },
          {
            "id": 419,
            "name": "宁武县",
            "level": 3,
            "regionCode": "140925",
            "childrens": []
          },
          {
            "id": 420,
            "name": "静乐县",
            "level": 3,
            "regionCode": "140926",
            "childrens": []
          },
          {
            "id": 421,
            "name": "神池县",
            "level": 3,
            "regionCode": "140927",
            "childrens": []
          },
          {
            "id": 422,
            "name": "五寨县",
            "level": 3,
            "regionCode": "140928",
            "childrens": []
          },
          {
            "id": 423,
            "name": "岢岚县",
            "level": 3,
            "regionCode": "140929",
            "childrens": []
          },
          {
            "id": 424,
            "name": "河曲县",
            "level": 3,
            "regionCode": "140930",
            "childrens": []
          },
          {
            "id": 425,
            "name": "保德县",
            "level": 3,
            "regionCode": "140931",
            "childrens": []
          },
          {
            "id": 426,
            "name": "偏关县",
            "level": 3,
            "regionCode": "140932",
            "childrens": []
          },
          {
            "id": 427,
            "name": "原平市",
            "level": 3,
            "regionCode": "140981",
            "childrens": []
          }
        ]
      },
      {
        "id": 433,
        "name": "临汾市",
        "level": 2,
        "regionCode": "141000",
        "childrens": [
          {
            "id": 434,
            "name": "尧都区",
            "level": 3,
            "regionCode": "141002",
            "childrens": []
          },
          {
            "id": 435,
            "name": "曲沃县",
            "level": 3,
            "regionCode": "141021",
            "childrens": []
          },
          {
            "id": 436,
            "name": "翼城县",
            "level": 3,
            "regionCode": "141022",
            "childrens": []
          },
          {
            "id": 437,
            "name": "襄汾县",
            "level": 3,
            "regionCode": "141023",
            "childrens": []
          },
          {
            "id": 438,
            "name": "洪洞县",
            "level": 3,
            "regionCode": "141024",
            "childrens": []
          },
          {
            "id": 439,
            "name": "古县",
            "level": 3,
            "regionCode": "141025",
            "childrens": []
          },
          {
            "id": 440,
            "name": "安泽县",
            "level": 3,
            "regionCode": "141026",
            "childrens": []
          },
          {
            "id": 441,
            "name": "浮山县",
            "level": 3,
            "regionCode": "141027",
            "childrens": []
          },
          {
            "id": 442,
            "name": "吉县",
            "level": 3,
            "regionCode": "141028",
            "childrens": []
          },
          {
            "id": 443,
            "name": "乡宁县",
            "level": 3,
            "regionCode": "141029",
            "childrens": []
          },
          {
            "id": 444,
            "name": "大宁县",
            "level": 3,
            "regionCode": "141030",
            "childrens": []
          },
          {
            "id": 445,
            "name": "隰县",
            "level": 3,
            "regionCode": "141031",
            "childrens": []
          },
          {
            "id": 446,
            "name": "永和县",
            "level": 3,
            "regionCode": "141032",
            "childrens": []
          },
          {
            "id": 447,
            "name": "蒲县",
            "level": 3,
            "regionCode": "141033",
            "childrens": []
          },
          {
            "id": 448,
            "name": "汾西县",
            "level": 3,
            "regionCode": "141034",
            "childrens": []
          },
          {
            "id": 449,
            "name": "侯马市",
            "level": 3,
            "regionCode": "141081",
            "childrens": []
          },
          {
            "id": 450,
            "name": "霍州市",
            "level": 3,
            "regionCode": "141082",
            "childrens": []
          }
        ]
      },
      {
        "id": 456,
        "name": "吕梁市",
        "level": 2,
        "regionCode": "141100",
        "childrens": [
          {
            "id": 457,
            "name": "离石区",
            "level": 3,
            "regionCode": "141102",
            "childrens": []
          },
          {
            "id": 458,
            "name": "文水县",
            "level": 3,
            "regionCode": "141121",
            "childrens": []
          },
          {
            "id": 459,
            "name": "交城县",
            "level": 3,
            "regionCode": "141122",
            "childrens": []
          },
          {
            "id": 460,
            "name": "兴县",
            "level": 3,
            "regionCode": "141123",
            "childrens": []
          },
          {
            "id": 461,
            "name": "临县",
            "level": 3,
            "regionCode": "141124",
            "childrens": []
          },
          {
            "id": 462,
            "name": "柳林县",
            "level": 3,
            "regionCode": "141125",
            "childrens": []
          },
          {
            "id": 463,
            "name": "石楼县",
            "level": 3,
            "regionCode": "141126",
            "childrens": []
          },
          {
            "id": 464,
            "name": "岚县",
            "level": 3,
            "regionCode": "141127",
            "childrens": []
          },
          {
            "id": 465,
            "name": "方山县",
            "level": 3,
            "regionCode": "141128",
            "childrens": []
          },
          {
            "id": 466,
            "name": "中阳县",
            "level": 3,
            "regionCode": "141129",
            "childrens": []
          },
          {
            "id": 467,
            "name": "交口县",
            "level": 3,
            "regionCode": "141130",
            "childrens": []
          },
          {
            "id": 468,
            "name": "孝义市",
            "level": 3,
            "regionCode": "141181",
            "childrens": []
          },
          {
            "id": 469,
            "name": "汾阳市",
            "level": 3,
            "regionCode": "141182",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 475,
    "name": "内蒙古自治区",
    "level": 1,
    "regionCode": "150000",
    "childrens": [
      {
        "id": 476,
        "name": "呼和浩特市",
        "level": 2,
        "regionCode": "150100",
        "childrens": [
          {
            "id": 477,
            "name": "新城区",
            "level": 3,
            "regionCode": "150102",
            "childrens": []
          },
          {
            "id": 478,
            "name": "回民区",
            "level": 3,
            "regionCode": "150103",
            "childrens": []
          },
          {
            "id": 479,
            "name": "玉泉区",
            "level": 3,
            "regionCode": "150104",
            "childrens": []
          },
          {
            "id": 480,
            "name": "赛罕区",
            "level": 3,
            "regionCode": "150105",
            "childrens": []
          },
          {
            "id": 482,
            "name": "托克托县",
            "level": 3,
            "regionCode": "150122",
            "childrens": []
          },
          {
            "id": 483,
            "name": "和林格尔县",
            "level": 3,
            "regionCode": "150123",
            "childrens": []
          },
          {
            "id": 484,
            "name": "清水河县",
            "level": 3,
            "regionCode": "150124",
            "childrens": []
          },
          {
            "id": 485,
            "name": "武川县",
            "level": 3,
            "regionCode": "150125",
            "childrens": []
          },
          {
            "id": 5156,
            "name": "土默特左旗",
            "level": 3,
            "regionCode": "150121",
            "childrens": []
          }
        ]
      },
      {
        "id": 491,
        "name": "包头市",
        "level": 2,
        "regionCode": "150200",
        "childrens": [
          {
            "id": 492,
            "name": "东河区",
            "level": 3,
            "regionCode": "150202",
            "childrens": []
          },
          {
            "id": 493,
            "name": "昆都仑区",
            "level": 3,
            "regionCode": "150203",
            "childrens": []
          },
          {
            "id": 495,
            "name": "石拐区",
            "level": 3,
            "regionCode": "150205",
            "childrens": []
          },
          {
            "id": 496,
            "name": "白云鄂博矿区",
            "level": 3,
            "regionCode": "150206",
            "childrens": []
          },
          {
            "id": 497,
            "name": "九原区",
            "level": 3,
            "regionCode": "150207",
            "childrens": []
          },
          {
            "id": 498,
            "name": "土默特右旗",
            "level": 3,
            "regionCode": "150221",
            "childrens": []
          },
          {
            "id": 499,
            "name": "固阳县",
            "level": 3,
            "regionCode": "150222",
            "childrens": []
          },
          {
            "id": 5157,
            "name": "青山区",
            "level": 3,
            "regionCode": "150204",
            "childrens": []
          },
          {
            "id": 5158,
            "name": "达尔罕茂明安联合旗",
            "level": 3,
            "regionCode": "150223",
            "childrens": []
          }
        ]
      },
      {
        "id": 506,
        "name": "乌海市",
        "level": 2,
        "regionCode": "150300",
        "childrens": [
          {
            "id": 508,
            "name": "海勃湾区",
            "level": 3,
            "regionCode": "150302",
            "childrens": []
          },
          {
            "id": 509,
            "name": "海南区",
            "level": 3,
            "regionCode": "150303",
            "childrens": []
          },
          {
            "id": 510,
            "name": "乌达区",
            "level": 3,
            "regionCode": "150304",
            "childrens": []
          }
        ]
      },
      {
        "id": 516,
        "name": "赤峰市",
        "level": 2,
        "regionCode": "150400",
        "childrens": [
          {
            "id": 517,
            "name": "红山区",
            "level": 3,
            "regionCode": "150402",
            "childrens": []
          },
          {
            "id": 518,
            "name": "元宝山区",
            "level": 3,
            "regionCode": "150403",
            "childrens": []
          },
          {
            "id": 519,
            "name": "松山区",
            "level": 3,
            "regionCode": "150404",
            "childrens": []
          },
          {
            "id": 520,
            "name": "阿鲁科尔沁旗",
            "level": 3,
            "regionCode": "150421",
            "childrens": []
          },
          {
            "id": 521,
            "name": "巴林左旗",
            "level": 3,
            "regionCode": "150422",
            "childrens": []
          },
          {
            "id": 522,
            "name": "巴林右旗",
            "level": 3,
            "regionCode": "150423",
            "childrens": []
          },
          {
            "id": 523,
            "name": "林西县",
            "level": 3,
            "regionCode": "150424",
            "childrens": []
          },
          {
            "id": 524,
            "name": "克什克腾旗",
            "level": 3,
            "regionCode": "150425",
            "childrens": []
          },
          {
            "id": 525,
            "name": "翁牛特旗",
            "level": 3,
            "regionCode": "150426",
            "childrens": []
          },
          {
            "id": 526,
            "name": "喀喇沁旗",
            "level": 3,
            "regionCode": "150428",
            "childrens": []
          },
          {
            "id": 527,
            "name": "宁城县",
            "level": 3,
            "regionCode": "150429",
            "childrens": []
          },
          {
            "id": 528,
            "name": "敖汉旗",
            "level": 3,
            "regionCode": "150430",
            "childrens": []
          }
        ]
      },
      {
        "id": 534,
        "name": "通辽市",
        "level": 2,
        "regionCode": "150500",
        "childrens": [
          {
            "id": 535,
            "name": "科尔沁区",
            "level": 3,
            "regionCode": "150502",
            "childrens": []
          },
          {
            "id": 536,
            "name": "科尔沁左翼中旗",
            "level": 3,
            "regionCode": "150521",
            "childrens": []
          },
          {
            "id": 538,
            "name": "开鲁县",
            "level": 3,
            "regionCode": "150523",
            "childrens": []
          },
          {
            "id": 539,
            "name": "库伦旗",
            "level": 3,
            "regionCode": "150524",
            "childrens": []
          },
          {
            "id": 540,
            "name": "奈曼旗",
            "level": 3,
            "regionCode": "150525",
            "childrens": []
          },
          {
            "id": 541,
            "name": "扎鲁特旗",
            "level": 3,
            "regionCode": "150526",
            "childrens": []
          },
          {
            "id": 542,
            "name": "霍林郭勒市",
            "level": 3,
            "regionCode": "150581",
            "childrens": []
          },
          {
            "id": 5159,
            "name": "科尔沁左翼后旗",
            "level": 3,
            "regionCode": "150522",
            "childrens": []
          }
        ]
      },
      {
        "id": 548,
        "name": "鄂尔多斯市",
        "level": 2,
        "regionCode": "150600",
        "childrens": [
          {
            "id": 549,
            "name": "东胜区",
            "level": 3,
            "regionCode": "150602",
            "childrens": []
          },
          {
            "id": 550,
            "name": "达拉特旗",
            "level": 3,
            "regionCode": "150621",
            "childrens": []
          },
          {
            "id": 551,
            "name": "准格尔旗",
            "level": 3,
            "regionCode": "150622",
            "childrens": []
          },
          {
            "id": 552,
            "name": "鄂托克前旗",
            "level": 3,
            "regionCode": "150623",
            "childrens": []
          },
          {
            "id": 553,
            "name": "鄂托克旗",
            "level": 3,
            "regionCode": "150624",
            "childrens": []
          },
          {
            "id": 554,
            "name": "杭锦旗",
            "level": 3,
            "regionCode": "150625",
            "childrens": []
          },
          {
            "id": 555,
            "name": "乌审旗",
            "level": 3,
            "regionCode": "150626",
            "childrens": []
          },
          {
            "id": 556,
            "name": "伊金霍洛旗",
            "level": 3,
            "regionCode": "150627",
            "childrens": []
          },
          {
            "id": 5160,
            "name": "康巴什区",
            "level": 3,
            "regionCode": "150603",
            "childrens": []
          }
        ]
      },
      {
        "id": 562,
        "name": "呼伦贝尔市",
        "level": 2,
        "regionCode": "150700",
        "childrens": [
          {
            "id": 563,
            "name": "海拉尔区",
            "level": 3,
            "regionCode": "150702",
            "childrens": []
          },
          {
            "id": 564,
            "name": "阿荣旗",
            "level": 3,
            "regionCode": "150721",
            "childrens": []
          },
          {
            "id": 565,
            "name": "莫力达瓦达斡尔族自治旗",
            "level": 3,
            "regionCode": "150722",
            "childrens": []
          },
          {
            "id": 566,
            "name": "鄂伦春自治旗",
            "level": 3,
            "regionCode": "150723",
            "childrens": []
          },
          {
            "id": 567,
            "name": "鄂温克族自治旗",
            "level": 3,
            "regionCode": "150724",
            "childrens": []
          },
          {
            "id": 568,
            "name": "陈巴尔虎旗",
            "level": 3,
            "regionCode": "150725",
            "childrens": []
          },
          {
            "id": 569,
            "name": "新巴尔虎左旗",
            "level": 3,
            "regionCode": "150726",
            "childrens": []
          },
          {
            "id": 570,
            "name": "新巴尔虎右旗",
            "level": 3,
            "regionCode": "150727",
            "childrens": []
          },
          {
            "id": 571,
            "name": "满洲里市",
            "level": 3,
            "regionCode": "150781",
            "childrens": []
          },
          {
            "id": 572,
            "name": "牙克石市",
            "level": 3,
            "regionCode": "150782",
            "childrens": []
          },
          {
            "id": 573,
            "name": "扎兰屯市",
            "level": 3,
            "regionCode": "150783",
            "childrens": []
          },
          {
            "id": 574,
            "name": "额尔古纳市",
            "level": 3,
            "regionCode": "150784",
            "childrens": []
          },
          {
            "id": 575,
            "name": "根河市",
            "level": 3,
            "regionCode": "150785",
            "childrens": []
          },
          {
            "id": 5161,
            "name": "扎赉诺尔区",
            "level": 3,
            "regionCode": "150703",
            "childrens": []
          }
        ]
      },
      {
        "id": 581,
        "name": "巴彦淖尔市",
        "level": 2,
        "regionCode": "150800",
        "childrens": [
          {
            "id": 582,
            "name": "临河区",
            "level": 3,
            "regionCode": "150802",
            "childrens": []
          },
          {
            "id": 583,
            "name": "五原县",
            "level": 3,
            "regionCode": "150821",
            "childrens": []
          },
          {
            "id": 584,
            "name": "磴口县",
            "level": 3,
            "regionCode": "150822",
            "childrens": []
          },
          {
            "id": 585,
            "name": "乌拉特前旗",
            "level": 3,
            "regionCode": "150823",
            "childrens": []
          },
          {
            "id": 586,
            "name": "乌拉特中旗",
            "level": 3,
            "regionCode": "150824",
            "childrens": []
          },
          {
            "id": 587,
            "name": "乌拉特后旗",
            "level": 3,
            "regionCode": "150825",
            "childrens": []
          },
          {
            "id": 588,
            "name": "杭锦后旗",
            "level": 3,
            "regionCode": "150826",
            "childrens": []
          }
        ]
      },
      {
        "id": 594,
        "name": "乌兰察布市",
        "level": 2,
        "regionCode": "150900",
        "childrens": [
          {
            "id": 595,
            "name": "集宁区",
            "level": 3,
            "regionCode": "150902",
            "childrens": []
          },
          {
            "id": 596,
            "name": "卓资县",
            "level": 3,
            "regionCode": "150921",
            "childrens": []
          },
          {
            "id": 597,
            "name": "化德县",
            "level": 3,
            "regionCode": "150922",
            "childrens": []
          },
          {
            "id": 598,
            "name": "商都县",
            "level": 3,
            "regionCode": "150923",
            "childrens": []
          },
          {
            "id": 599,
            "name": "兴和县",
            "level": 3,
            "regionCode": "150924",
            "childrens": []
          },
          {
            "id": 600,
            "name": "凉城县",
            "level": 3,
            "regionCode": "150925",
            "childrens": []
          },
          {
            "id": 601,
            "name": "察哈尔右翼前旗",
            "level": 3,
            "regionCode": "150926",
            "childrens": []
          },
          {
            "id": 603,
            "name": "察哈尔右翼后旗",
            "level": 3,
            "regionCode": "150928",
            "childrens": []
          },
          {
            "id": 604,
            "name": "四子王旗",
            "level": 3,
            "regionCode": "150929",
            "childrens": []
          },
          {
            "id": 605,
            "name": "丰镇市",
            "level": 3,
            "regionCode": "150981",
            "childrens": []
          },
          {
            "id": 5162,
            "name": "察哈尔右翼中旗",
            "level": 3,
            "regionCode": "150927",
            "childrens": []
          }
        ]
      },
      {
        "id": 611,
        "name": "兴安盟",
        "level": 2,
        "regionCode": "152200",
        "childrens": [
          {
            "id": 612,
            "name": "乌兰浩特市",
            "level": 3,
            "regionCode": "152201",
            "childrens": []
          },
          {
            "id": 613,
            "name": "阿尔山市",
            "level": 3,
            "regionCode": "152202",
            "childrens": []
          },
          {
            "id": 616,
            "name": "扎赉特旗",
            "level": 3,
            "regionCode": "152223",
            "childrens": []
          },
          {
            "id": 617,
            "name": "突泉县",
            "level": 3,
            "regionCode": "152224",
            "childrens": []
          },
          {
            "id": 5163,
            "name": "科尔沁右翼前旗",
            "level": 3,
            "regionCode": "152221",
            "childrens": []
          },
          {
            "id": 5164,
            "name": "科尔沁右翼中旗",
            "level": 3,
            "regionCode": "152222",
            "childrens": []
          }
        ]
      },
      {
        "id": 623,
        "name": "锡林郭勒盟",
        "level": 2,
        "regionCode": "152500",
        "childrens": [
          {
            "id": 624,
            "name": "二连浩特市",
            "level": 3,
            "regionCode": "152501",
            "childrens": []
          },
          {
            "id": 625,
            "name": "锡林浩特市",
            "level": 3,
            "regionCode": "152502",
            "childrens": []
          },
          {
            "id": 626,
            "name": "阿巴嘎旗",
            "level": 3,
            "regionCode": "152522",
            "childrens": []
          },
          {
            "id": 627,
            "name": "苏尼特左旗",
            "level": 3,
            "regionCode": "152523",
            "childrens": []
          },
          {
            "id": 628,
            "name": "苏尼特右旗",
            "level": 3,
            "regionCode": "152524",
            "childrens": []
          },
          {
            "id": 629,
            "name": "东乌珠穆沁旗",
            "level": 3,
            "regionCode": "152525",
            "childrens": []
          },
          {
            "id": 630,
            "name": "西乌珠穆沁旗",
            "level": 3,
            "regionCode": "152526",
            "childrens": []
          },
          {
            "id": 631,
            "name": "太仆寺旗",
            "level": 3,
            "regionCode": "152527",
            "childrens": []
          },
          {
            "id": 632,
            "name": "镶黄旗",
            "level": 3,
            "regionCode": "152528",
            "childrens": []
          },
          {
            "id": 633,
            "name": "正镶白旗",
            "level": 3,
            "regionCode": "152529",
            "childrens": []
          },
          {
            "id": 634,
            "name": "正蓝旗",
            "level": 3,
            "regionCode": "152530",
            "childrens": []
          },
          {
            "id": 635,
            "name": "多伦县",
            "level": 3,
            "regionCode": "152531",
            "childrens": []
          }
        ]
      },
      {
        "id": 641,
        "name": "阿拉善盟",
        "level": 2,
        "regionCode": "152900",
        "childrens": [
          {
            "id": 642,
            "name": "阿拉善左旗",
            "level": 3,
            "regionCode": "152921",
            "childrens": []
          },
          {
            "id": 643,
            "name": "阿拉善右旗",
            "level": 3,
            "regionCode": "152922",
            "childrens": []
          },
          {
            "id": 644,
            "name": "额济纳旗",
            "level": 3,
            "regionCode": "152923",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 650,
    "name": "辽宁省",
    "level": 1,
    "regionCode": "210000",
    "childrens": [
      {
        "id": 651,
        "name": "沈阳市",
        "level": 2,
        "regionCode": "210100",
        "childrens": [
          {
            "id": 653,
            "name": "沈河区",
            "level": 3,
            "regionCode": "210103",
            "childrens": []
          },
          {
            "id": 654,
            "name": "大东区",
            "level": 3,
            "regionCode": "210104",
            "childrens": []
          },
          {
            "id": 655,
            "name": "皇姑区",
            "level": 3,
            "regionCode": "210105",
            "childrens": []
          },
          {
            "id": 656,
            "name": "铁西区",
            "level": 3,
            "regionCode": "210106",
            "childrens": []
          },
          {
            "id": 657,
            "name": "苏家屯区",
            "level": 3,
            "regionCode": "210111",
            "childrens": []
          },
          {
            "id": 658,
            "name": "浑南区",
            "level": 3,
            "regionCode": "210112",
            "childrens": []
          },
          {
            "id": 660,
            "name": "于洪区",
            "level": 3,
            "regionCode": "210114",
            "childrens": []
          },
          {
            "id": 661,
            "name": "辽中区",
            "level": 3,
            "regionCode": "210115",
            "childrens": []
          },
          {
            "id": 662,
            "name": "康平县",
            "level": 3,
            "regionCode": "210123",
            "childrens": []
          },
          {
            "id": 663,
            "name": "法库县",
            "level": 3,
            "regionCode": "210124",
            "childrens": []
          },
          {
            "id": 664,
            "name": "新民市",
            "level": 3,
            "regionCode": "210181",
            "childrens": []
          },
          {
            "id": 5165,
            "name": "和平区",
            "level": 3,
            "regionCode": "210102",
            "childrens": []
          },
          {
            "id": 5166,
            "name": "沈北新区",
            "level": 3,
            "regionCode": "210113",
            "childrens": []
          }
        ]
      },
      {
        "id": 670,
        "name": "大连市",
        "level": 2,
        "regionCode": "210200",
        "childrens": [
          {
            "id": 671,
            "name": "中山区",
            "level": 3,
            "regionCode": "210202",
            "childrens": []
          },
          {
            "id": 672,
            "name": "西岗区",
            "level": 3,
            "regionCode": "210203",
            "childrens": []
          },
          {
            "id": 673,
            "name": "沙河口区",
            "level": 3,
            "regionCode": "210204",
            "childrens": []
          },
          {
            "id": 674,
            "name": "甘井子区",
            "level": 3,
            "regionCode": "210211",
            "childrens": []
          },
          {
            "id": 675,
            "name": "旅顺口区",
            "level": 3,
            "regionCode": "210212",
            "childrens": []
          },
          {
            "id": 676,
            "name": "金州区",
            "level": 3,
            "regionCode": "210213",
            "childrens": []
          },
          {
            "id": 677,
            "name": "长海县",
            "level": 3,
            "regionCode": "210224",
            "childrens": []
          },
          {
            "id": 678,
            "name": "瓦房店市",
            "level": 3,
            "regionCode": "210281",
            "childrens": []
          },
          {
            "id": 679,
            "name": "普兰店区",
            "level": 3,
            "regionCode": "210214",
            "childrens": []
          },
          {
            "id": 680,
            "name": "庄河市",
            "level": 3,
            "regionCode": "210283",
            "childrens": []
          }
        ]
      },
      {
        "id": 686,
        "name": "鞍山市",
        "level": 2,
        "regionCode": "210300",
        "childrens": [
          {
            "id": 687,
            "name": "铁东区",
            "level": 3,
            "regionCode": "210302",
            "childrens": []
          },
          {
            "id": 689,
            "name": "立山区",
            "level": 3,
            "regionCode": "210304",
            "childrens": []
          },
          {
            "id": 690,
            "name": "千山区",
            "level": 3,
            "regionCode": "210311",
            "childrens": []
          },
          {
            "id": 691,
            "name": "台安县",
            "level": 3,
            "regionCode": "210321",
            "childrens": []
          },
          {
            "id": 692,
            "name": "岫岩满族自治县",
            "level": 3,
            "regionCode": "210323",
            "childrens": []
          },
          {
            "id": 693,
            "name": "海城市",
            "level": 3,
            "regionCode": "210381",
            "childrens": []
          },
          {
            "id": 5167,
            "name": "铁西区",
            "level": 3,
            "regionCode": "210303",
            "childrens": []
          }
        ]
      },
      {
        "id": 699,
        "name": "抚顺市",
        "level": 2,
        "regionCode": "210400",
        "childrens": [
          {
            "id": 700,
            "name": "新抚区",
            "level": 3,
            "regionCode": "210402",
            "childrens": []
          },
          {
            "id": 701,
            "name": "东洲区",
            "level": 3,
            "regionCode": "210403",
            "childrens": []
          },
          {
            "id": 702,
            "name": "望花区",
            "level": 3,
            "regionCode": "210404",
            "childrens": []
          },
          {
            "id": 703,
            "name": "顺城区",
            "level": 3,
            "regionCode": "210411",
            "childrens": []
          },
          {
            "id": 704,
            "name": "抚顺县",
            "level": 3,
            "regionCode": "210421",
            "childrens": []
          },
          {
            "id": 705,
            "name": "新宾满族自治县",
            "level": 3,
            "regionCode": "210422",
            "childrens": []
          },
          {
            "id": 706,
            "name": "清原满族自治县",
            "level": 3,
            "regionCode": "210423",
            "childrens": []
          }
        ]
      },
      {
        "id": 712,
        "name": "本溪市",
        "level": 2,
        "regionCode": "210500",
        "childrens": [
          {
            "id": 713,
            "name": "平山区",
            "level": 3,
            "regionCode": "210502",
            "childrens": []
          },
          {
            "id": 714,
            "name": "溪湖区",
            "level": 3,
            "regionCode": "210503",
            "childrens": []
          },
          {
            "id": 715,
            "name": "明山区",
            "level": 3,
            "regionCode": "210504",
            "childrens": []
          },
          {
            "id": 716,
            "name": "南芬区",
            "level": 3,
            "regionCode": "210505",
            "childrens": []
          },
          {
            "id": 717,
            "name": "本溪满族自治县",
            "level": 3,
            "regionCode": "210521",
            "childrens": []
          },
          {
            "id": 718,
            "name": "桓仁满族自治县",
            "level": 3,
            "regionCode": "210522",
            "childrens": []
          }
        ]
      },
      {
        "id": 724,
        "name": "丹东市",
        "level": 2,
        "regionCode": "210600",
        "childrens": [
          {
            "id": 725,
            "name": "元宝区",
            "level": 3,
            "regionCode": "210602",
            "childrens": []
          },
          {
            "id": 726,
            "name": "振兴区",
            "level": 3,
            "regionCode": "210603",
            "childrens": []
          },
          {
            "id": 727,
            "name": "振安区",
            "level": 3,
            "regionCode": "210604",
            "childrens": []
          },
          {
            "id": 728,
            "name": "宽甸满族自治县",
            "level": 3,
            "regionCode": "210624",
            "childrens": []
          },
          {
            "id": 729,
            "name": "东港市",
            "level": 3,
            "regionCode": "210681",
            "childrens": []
          },
          {
            "id": 730,
            "name": "凤城市",
            "level": 3,
            "regionCode": "210682",
            "childrens": []
          }
        ]
      },
      {
        "id": 736,
        "name": "锦州市",
        "level": 2,
        "regionCode": "210700",
        "childrens": [
          {
            "id": 737,
            "name": "古塔区",
            "level": 3,
            "regionCode": "210702",
            "childrens": []
          },
          {
            "id": 738,
            "name": "凌河区",
            "level": 3,
            "regionCode": "210703",
            "childrens": []
          },
          {
            "id": 739,
            "name": "太和区",
            "level": 3,
            "regionCode": "210711",
            "childrens": []
          },
          {
            "id": 740,
            "name": "黑山县",
            "level": 3,
            "regionCode": "210726",
            "childrens": []
          },
          {
            "id": 741,
            "name": "义县",
            "level": 3,
            "regionCode": "210727",
            "childrens": []
          },
          {
            "id": 742,
            "name": "凌海市",
            "level": 3,
            "regionCode": "210781",
            "childrens": []
          },
          {
            "id": 743,
            "name": "北镇市",
            "level": 3,
            "regionCode": "210782",
            "childrens": []
          }
        ]
      },
      {
        "id": 749,
        "name": "营口市",
        "level": 2,
        "regionCode": "210800",
        "childrens": [
          {
            "id": 750,
            "name": "站前区",
            "level": 3,
            "regionCode": "210802",
            "childrens": []
          },
          {
            "id": 751,
            "name": "西市区",
            "level": 3,
            "regionCode": "210803",
            "childrens": []
          },
          {
            "id": 752,
            "name": "鲅鱼圈区",
            "level": 3,
            "regionCode": "210804",
            "childrens": []
          },
          {
            "id": 753,
            "name": "老边区",
            "level": 3,
            "regionCode": "210811",
            "childrens": []
          },
          {
            "id": 754,
            "name": "盖州市",
            "level": 3,
            "regionCode": "210881",
            "childrens": []
          },
          {
            "id": 755,
            "name": "大石桥市",
            "level": 3,
            "regionCode": "210882",
            "childrens": []
          }
        ]
      },
      {
        "id": 761,
        "name": "阜新市",
        "level": 2,
        "regionCode": "210900",
        "childrens": [
          {
            "id": 763,
            "name": "新邱区",
            "level": 3,
            "regionCode": "210903",
            "childrens": []
          },
          {
            "id": 764,
            "name": "太平区",
            "level": 3,
            "regionCode": "210904",
            "childrens": []
          },
          {
            "id": 765,
            "name": "清河门区",
            "level": 3,
            "regionCode": "210905",
            "childrens": []
          },
          {
            "id": 766,
            "name": "细河区",
            "level": 3,
            "regionCode": "210911",
            "childrens": []
          },
          {
            "id": 767,
            "name": "阜新蒙古族自治县",
            "level": 3,
            "regionCode": "210921",
            "childrens": []
          },
          {
            "id": 768,
            "name": "彰武县",
            "level": 3,
            "regionCode": "210922",
            "childrens": []
          },
          {
            "id": 5168,
            "name": "海州区",
            "level": 3,
            "regionCode": "210902",
            "childrens": []
          }
        ]
      },
      {
        "id": 774,
        "name": "辽阳市",
        "level": 2,
        "regionCode": "211000",
        "childrens": [
          {
            "id": 775,
            "name": "白塔区",
            "level": 3,
            "regionCode": "211002",
            "childrens": []
          },
          {
            "id": 776,
            "name": "文圣区",
            "level": 3,
            "regionCode": "211003",
            "childrens": []
          },
          {
            "id": 777,
            "name": "宏伟区",
            "level": 3,
            "regionCode": "211004",
            "childrens": []
          },
          {
            "id": 778,
            "name": "弓长岭区",
            "level": 3,
            "regionCode": "211005",
            "childrens": []
          },
          {
            "id": 779,
            "name": "太子河区",
            "level": 3,
            "regionCode": "211011",
            "childrens": []
          },
          {
            "id": 780,
            "name": "辽阳县",
            "level": 3,
            "regionCode": "211021",
            "childrens": []
          },
          {
            "id": 781,
            "name": "灯塔市",
            "level": 3,
            "regionCode": "211081",
            "childrens": []
          }
        ]
      },
      {
        "id": 787,
        "name": "盘锦市",
        "level": 2,
        "regionCode": "211100",
        "childrens": [
          {
            "id": 788,
            "name": "双台子区",
            "level": 3,
            "regionCode": "211102",
            "childrens": []
          },
          {
            "id": 789,
            "name": "兴隆台区",
            "level": 3,
            "regionCode": "211103",
            "childrens": []
          },
          {
            "id": 790,
            "name": "大洼区",
            "level": 3,
            "regionCode": "211104",
            "childrens": []
          },
          {
            "id": 791,
            "name": "盘山县",
            "level": 3,
            "regionCode": "211122",
            "childrens": []
          }
        ]
      },
      {
        "id": 797,
        "name": "铁岭市",
        "level": 2,
        "regionCode": "211200",
        "childrens": [
          {
            "id": 798,
            "name": "银州区",
            "level": 3,
            "regionCode": "211202",
            "childrens": []
          },
          {
            "id": 800,
            "name": "铁岭县",
            "level": 3,
            "regionCode": "211221",
            "childrens": []
          },
          {
            "id": 801,
            "name": "西丰县",
            "level": 3,
            "regionCode": "211223",
            "childrens": []
          },
          {
            "id": 802,
            "name": "昌图县",
            "level": 3,
            "regionCode": "211224",
            "childrens": []
          },
          {
            "id": 803,
            "name": "调兵山市",
            "level": 3,
            "regionCode": "211281",
            "childrens": []
          },
          {
            "id": 804,
            "name": "开原市",
            "level": 3,
            "regionCode": "211282",
            "childrens": []
          },
          {
            "id": 5169,
            "name": "清河区",
            "level": 3,
            "regionCode": "211204",
            "childrens": []
          }
        ]
      },
      {
        "id": 810,
        "name": "朝阳市",
        "level": 2,
        "regionCode": "211300",
        "childrens": [
          {
            "id": 811,
            "name": "双塔区",
            "level": 3,
            "regionCode": "211302",
            "childrens": []
          },
          {
            "id": 812,
            "name": "龙城区",
            "level": 3,
            "regionCode": "211303",
            "childrens": []
          },
          {
            "id": 813,
            "name": "朝阳县",
            "level": 3,
            "regionCode": "211321",
            "childrens": []
          },
          {
            "id": 814,
            "name": "建平县",
            "level": 3,
            "regionCode": "211322",
            "childrens": []
          },
          {
            "id": 816,
            "name": "北票市",
            "level": 3,
            "regionCode": "211381",
            "childrens": []
          },
          {
            "id": 817,
            "name": "凌源市",
            "level": 3,
            "regionCode": "211382",
            "childrens": []
          },
          {
            "id": 5170,
            "name": "喀喇沁左翼蒙古族自治县",
            "level": 3,
            "regionCode": "211324",
            "childrens": []
          }
        ]
      },
      {
        "id": 823,
        "name": "葫芦岛市",
        "level": 2,
        "regionCode": "211400",
        "childrens": [
          {
            "id": 824,
            "name": "连山区",
            "level": 3,
            "regionCode": "211402",
            "childrens": []
          },
          {
            "id": 825,
            "name": "龙港区",
            "level": 3,
            "regionCode": "211403",
            "childrens": []
          },
          {
            "id": 826,
            "name": "南票区",
            "level": 3,
            "regionCode": "211404",
            "childrens": []
          },
          {
            "id": 827,
            "name": "绥中县",
            "level": 3,
            "regionCode": "211421",
            "childrens": []
          },
          {
            "id": 828,
            "name": "建昌县",
            "level": 3,
            "regionCode": "211422",
            "childrens": []
          },
          {
            "id": 829,
            "name": "兴城市",
            "level": 3,
            "regionCode": "211481",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 835,
    "name": "吉林省",
    "level": 1,
    "regionCode": "220000",
    "childrens": [
      {
        "id": 836,
        "name": "长春市",
        "level": 2,
        "regionCode": "220100",
        "childrens": [
          {
            "id": 838,
            "name": "南关区",
            "level": 3,
            "regionCode": "220102",
            "childrens": []
          },
          {
            "id": 839,
            "name": "宽城区",
            "level": 3,
            "regionCode": "220103",
            "childrens": []
          },
          {
            "id": 841,
            "name": "二道区",
            "level": 3,
            "regionCode": "220105",
            "childrens": []
          },
          {
            "id": 842,
            "name": "绿园区",
            "level": 3,
            "regionCode": "220106",
            "childrens": []
          },
          {
            "id": 843,
            "name": "双阳区",
            "level": 3,
            "regionCode": "220112",
            "childrens": []
          },
          {
            "id": 844,
            "name": "农安县",
            "level": 3,
            "regionCode": "220122",
            "childrens": []
          },
          {
            "id": 845,
            "name": "九台区",
            "level": 3,
            "regionCode": "220113",
            "childrens": []
          },
          {
            "id": 846,
            "name": "榆树市",
            "level": 3,
            "regionCode": "220182",
            "childrens": []
          },
          {
            "id": 847,
            "name": "德惠市",
            "level": 3,
            "regionCode": "220183",
            "childrens": []
          },
          {
            "id": 5171,
            "name": "朝阳区",
            "level": 3,
            "regionCode": "220104",
            "childrens": []
          },
          {
            "id": 5172,
            "name": "公主岭市",
            "level": 3,
            "regionCode": "220184",
            "childrens": []
          }
        ]
      },
      {
        "id": 853,
        "name": "吉林市",
        "level": 2,
        "regionCode": "220200",
        "childrens": [
          {
            "id": 855,
            "name": "昌邑区",
            "level": 3,
            "regionCode": "220202",
            "childrens": []
          },
          {
            "id": 856,
            "name": "龙潭区",
            "level": 3,
            "regionCode": "220203",
            "childrens": []
          },
          {
            "id": 857,
            "name": "船营区",
            "level": 3,
            "regionCode": "220204",
            "childrens": []
          },
          {
            "id": 858,
            "name": "丰满区",
            "level": 3,
            "regionCode": "220211",
            "childrens": []
          },
          {
            "id": 859,
            "name": "永吉县",
            "level": 3,
            "regionCode": "220221",
            "childrens": []
          },
          {
            "id": 860,
            "name": "蛟河市",
            "level": 3,
            "regionCode": "220281",
            "childrens": []
          },
          {
            "id": 861,
            "name": "桦甸市",
            "level": 3,
            "regionCode": "220282",
            "childrens": []
          },
          {
            "id": 862,
            "name": "舒兰市",
            "level": 3,
            "regionCode": "220283",
            "childrens": []
          },
          {
            "id": 863,
            "name": "磐石市",
            "level": 3,
            "regionCode": "220284",
            "childrens": []
          }
        ]
      },
      {
        "id": 869,
        "name": "四平市",
        "level": 2,
        "regionCode": "220300",
        "childrens": [
          {
            "id": 873,
            "name": "梨树县",
            "level": 3,
            "regionCode": "220322",
            "childrens": []
          },
          {
            "id": 874,
            "name": "伊通满族自治县",
            "level": 3,
            "regionCode": "220323",
            "childrens": []
          },
          {
            "id": 876,
            "name": "双辽市",
            "level": 3,
            "regionCode": "220382",
            "childrens": []
          },
          {
            "id": 5173,
            "name": "铁西区",
            "level": 3,
            "regionCode": "220302",
            "childrens": []
          },
          {
            "id": 5174,
            "name": "铁东区",
            "level": 3,
            "regionCode": "220303",
            "childrens": []
          }
        ]
      },
      {
        "id": 882,
        "name": "辽源市",
        "level": 2,
        "regionCode": "220400",
        "childrens": [
          {
            "id": 884,
            "name": "龙山区",
            "level": 3,
            "regionCode": "220402",
            "childrens": []
          },
          {
            "id": 885,
            "name": "西安区",
            "level": 3,
            "regionCode": "220403",
            "childrens": []
          },
          {
            "id": 886,
            "name": "东丰县",
            "level": 3,
            "regionCode": "220421",
            "childrens": []
          },
          {
            "id": 887,
            "name": "东辽县",
            "level": 3,
            "regionCode": "220422",
            "childrens": []
          }
        ]
      },
      {
        "id": 893,
        "name": "通化市",
        "level": 2,
        "regionCode": "220500",
        "childrens": [
          {
            "id": 895,
            "name": "东昌区",
            "level": 3,
            "regionCode": "220502",
            "childrens": []
          },
          {
            "id": 896,
            "name": "二道江区",
            "level": 3,
            "regionCode": "220503",
            "childrens": []
          },
          {
            "id": 897,
            "name": "通化县",
            "level": 3,
            "regionCode": "220521",
            "childrens": []
          },
          {
            "id": 898,
            "name": "辉南县",
            "level": 3,
            "regionCode": "220523",
            "childrens": []
          },
          {
            "id": 899,
            "name": "柳河县",
            "level": 3,
            "regionCode": "220524",
            "childrens": []
          },
          {
            "id": 900,
            "name": "梅河口市",
            "level": 3,
            "regionCode": "220581",
            "childrens": []
          },
          {
            "id": 901,
            "name": "集安市",
            "level": 3,
            "regionCode": "220582",
            "childrens": []
          }
        ]
      },
      {
        "id": 907,
        "name": "白山市",
        "level": 2,
        "regionCode": "220600",
        "childrens": [
          {
            "id": 910,
            "name": "江源区",
            "level": 3,
            "regionCode": "220605",
            "childrens": []
          },
          {
            "id": 911,
            "name": "抚松县",
            "level": 3,
            "regionCode": "220621",
            "childrens": []
          },
          {
            "id": 912,
            "name": "靖宇县",
            "level": 3,
            "regionCode": "220622",
            "childrens": []
          },
          {
            "id": 913,
            "name": "长白朝鲜族自治县",
            "level": 3,
            "regionCode": "220623",
            "childrens": []
          },
          {
            "id": 914,
            "name": "临江市",
            "level": 3,
            "regionCode": "220681",
            "childrens": []
          },
          {
            "id": 5175,
            "name": "浑江区",
            "level": 3,
            "regionCode": "220602",
            "childrens": []
          }
        ]
      },
      {
        "id": 920,
        "name": "松原市",
        "level": 2,
        "regionCode": "220700",
        "childrens": [
          {
            "id": 922,
            "name": "宁江区",
            "level": 3,
            "regionCode": "220702",
            "childrens": []
          },
          {
            "id": 923,
            "name": "前郭尔罗斯蒙古族自治县",
            "level": 3,
            "regionCode": "220721",
            "childrens": []
          },
          {
            "id": 924,
            "name": "长岭县",
            "level": 3,
            "regionCode": "220722",
            "childrens": []
          },
          {
            "id": 925,
            "name": "乾安县",
            "level": 3,
            "regionCode": "220723",
            "childrens": []
          },
          {
            "id": 926,
            "name": "扶余市",
            "level": 3,
            "regionCode": "220781",
            "childrens": []
          }
        ]
      },
      {
        "id": 932,
        "name": "白城市",
        "level": 2,
        "regionCode": "220800",
        "childrens": [
          {
            "id": 934,
            "name": "洮北区",
            "level": 3,
            "regionCode": "220802",
            "childrens": []
          },
          {
            "id": 935,
            "name": "镇赉县",
            "level": 3,
            "regionCode": "220821",
            "childrens": []
          },
          {
            "id": 936,
            "name": "通榆县",
            "level": 3,
            "regionCode": "220822",
            "childrens": []
          },
          {
            "id": 937,
            "name": "洮南市",
            "level": 3,
            "regionCode": "220881",
            "childrens": []
          },
          {
            "id": 938,
            "name": "大安市",
            "level": 3,
            "regionCode": "220882",
            "childrens": []
          }
        ]
      },
      {
        "id": 944,
        "name": "延边朝鲜族自治州",
        "level": 2,
        "regionCode": "222400",
        "childrens": [
          {
            "id": 945,
            "name": "延吉市",
            "level": 3,
            "regionCode": "222401",
            "childrens": []
          },
          {
            "id": 946,
            "name": "图们市",
            "level": 3,
            "regionCode": "222402",
            "childrens": []
          },
          {
            "id": 947,
            "name": "敦化市",
            "level": 3,
            "regionCode": "222403",
            "childrens": []
          },
          {
            "id": 948,
            "name": "珲春市",
            "level": 3,
            "regionCode": "222404",
            "childrens": []
          },
          {
            "id": 949,
            "name": "龙井市",
            "level": 3,
            "regionCode": "222405",
            "childrens": []
          },
          {
            "id": 950,
            "name": "和龙市",
            "level": 3,
            "regionCode": "222406",
            "childrens": []
          },
          {
            "id": 951,
            "name": "汪清县",
            "level": 3,
            "regionCode": "222424",
            "childrens": []
          },
          {
            "id": 952,
            "name": "安图县",
            "level": 3,
            "regionCode": "222426",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 958,
    "name": "黑龙江省",
    "level": 1,
    "regionCode": "230000",
    "childrens": [
      {
        "id": 959,
        "name": "哈尔滨市",
        "level": 2,
        "regionCode": "230100",
        "childrens": [
          {
            "id": 960,
            "name": "道里区",
            "level": 3,
            "regionCode": "230102",
            "childrens": []
          },
          {
            "id": 961,
            "name": "南岗区",
            "level": 3,
            "regionCode": "230103",
            "childrens": []
          },
          {
            "id": 962,
            "name": "道外区",
            "level": 3,
            "regionCode": "230104",
            "childrens": []
          },
          {
            "id": 963,
            "name": "平房区",
            "level": 3,
            "regionCode": "230108",
            "childrens": []
          },
          {
            "id": 964,
            "name": "松北区",
            "level": 3,
            "regionCode": "230109",
            "childrens": []
          },
          {
            "id": 965,
            "name": "香坊区",
            "level": 3,
            "regionCode": "230110",
            "childrens": []
          },
          {
            "id": 966,
            "name": "呼兰区",
            "level": 3,
            "regionCode": "230111",
            "childrens": []
          },
          {
            "id": 967,
            "name": "阿城区",
            "level": 3,
            "regionCode": "230112",
            "childrens": []
          },
          {
            "id": 968,
            "name": "依兰县",
            "level": 3,
            "regionCode": "230123",
            "childrens": []
          },
          {
            "id": 969,
            "name": "方正县",
            "level": 3,
            "regionCode": "230124",
            "childrens": []
          },
          {
            "id": 970,
            "name": "宾县",
            "level": 3,
            "regionCode": "230125",
            "childrens": []
          },
          {
            "id": 971,
            "name": "巴彦县",
            "level": 3,
            "regionCode": "230126",
            "childrens": []
          },
          {
            "id": 972,
            "name": "木兰县",
            "level": 3,
            "regionCode": "230127",
            "childrens": []
          },
          {
            "id": 973,
            "name": "通河县",
            "level": 3,
            "regionCode": "230128",
            "childrens": []
          },
          {
            "id": 974,
            "name": "延寿县",
            "level": 3,
            "regionCode": "230129",
            "childrens": []
          },
          {
            "id": 975,
            "name": "双城区",
            "level": 3,
            "regionCode": "230113",
            "childrens": []
          },
          {
            "id": 976,
            "name": "尚志市",
            "level": 3,
            "regionCode": "230183",
            "childrens": []
          },
          {
            "id": 977,
            "name": "五常市",
            "level": 3,
            "regionCode": "230184",
            "childrens": []
          }
        ]
      },
      {
        "id": 983,
        "name": "齐齐哈尔市",
        "level": 2,
        "regionCode": "230200",
        "childrens": [
          {
            "id": 984,
            "name": "龙沙区",
            "level": 3,
            "regionCode": "230202",
            "childrens": []
          },
          {
            "id": 985,
            "name": "建华区",
            "level": 3,
            "regionCode": "230203",
            "childrens": []
          },
          {
            "id": 986,
            "name": "铁锋区",
            "level": 3,
            "regionCode": "230204",
            "childrens": []
          },
          {
            "id": 987,
            "name": "昂昂溪区",
            "level": 3,
            "regionCode": "230205",
            "childrens": []
          },
          {
            "id": 988,
            "name": "富拉尔基区",
            "level": 3,
            "regionCode": "230206",
            "childrens": []
          },
          {
            "id": 989,
            "name": "碾子山区",
            "level": 3,
            "regionCode": "230207",
            "childrens": []
          },
          {
            "id": 990,
            "name": "梅里斯达斡尔族区",
            "level": 3,
            "regionCode": "230208",
            "childrens": []
          },
          {
            "id": 991,
            "name": "龙江县",
            "level": 3,
            "regionCode": "230221",
            "childrens": []
          },
          {
            "id": 992,
            "name": "依安县",
            "level": 3,
            "regionCode": "230223",
            "childrens": []
          },
          {
            "id": 993,
            "name": "泰来县",
            "level": 3,
            "regionCode": "230224",
            "childrens": []
          },
          {
            "id": 994,
            "name": "甘南县",
            "level": 3,
            "regionCode": "230225",
            "childrens": []
          },
          {
            "id": 995,
            "name": "富裕县",
            "level": 3,
            "regionCode": "230227",
            "childrens": []
          },
          {
            "id": 996,
            "name": "克山县",
            "level": 3,
            "regionCode": "230229",
            "childrens": []
          },
          {
            "id": 997,
            "name": "克东县",
            "level": 3,
            "regionCode": "230230",
            "childrens": []
          },
          {
            "id": 998,
            "name": "拜泉县",
            "level": 3,
            "regionCode": "230231",
            "childrens": []
          },
          {
            "id": 999,
            "name": "讷河市",
            "level": 3,
            "regionCode": "230281",
            "childrens": []
          }
        ]
      },
      {
        "id": 1005,
        "name": "鸡西市",
        "level": 2,
        "regionCode": "230300",
        "childrens": [
          {
            "id": 1006,
            "name": "鸡冠区",
            "level": 3,
            "regionCode": "230302",
            "childrens": []
          },
          {
            "id": 1007,
            "name": "恒山区",
            "level": 3,
            "regionCode": "230303",
            "childrens": []
          },
          {
            "id": 1008,
            "name": "滴道区",
            "level": 3,
            "regionCode": "230304",
            "childrens": []
          },
          {
            "id": 1009,
            "name": "梨树区",
            "level": 3,
            "regionCode": "230305",
            "childrens": []
          },
          {
            "id": 1010,
            "name": "城子河区",
            "level": 3,
            "regionCode": "230306",
            "childrens": []
          },
          {
            "id": 1011,
            "name": "麻山区",
            "level": 3,
            "regionCode": "230307",
            "childrens": []
          },
          {
            "id": 1012,
            "name": "鸡东县",
            "level": 3,
            "regionCode": "230321",
            "childrens": []
          },
          {
            "id": 1013,
            "name": "虎林市",
            "level": 3,
            "regionCode": "230381",
            "childrens": []
          },
          {
            "id": 1014,
            "name": "密山市",
            "level": 3,
            "regionCode": "230382",
            "childrens": []
          }
        ]
      },
      {
        "id": 1020,
        "name": "鹤岗市",
        "level": 2,
        "regionCode": "230400",
        "childrens": [
          {
            "id": 1021,
            "name": "向阳区",
            "level": 3,
            "regionCode": "230402",
            "childrens": []
          },
          {
            "id": 1022,
            "name": "工农区",
            "level": 3,
            "regionCode": "230403",
            "childrens": []
          },
          {
            "id": 1023,
            "name": "南山区",
            "level": 3,
            "regionCode": "230404",
            "childrens": []
          },
          {
            "id": 1024,
            "name": "兴安区",
            "level": 3,
            "regionCode": "230405",
            "childrens": []
          },
          {
            "id": 1025,
            "name": "东山区",
            "level": 3,
            "regionCode": "230406",
            "childrens": []
          },
          {
            "id": 1026,
            "name": "兴山区",
            "level": 3,
            "regionCode": "230407",
            "childrens": []
          },
          {
            "id": 1027,
            "name": "萝北县",
            "level": 3,
            "regionCode": "230421",
            "childrens": []
          },
          {
            "id": 1028,
            "name": "绥滨县",
            "level": 3,
            "regionCode": "230422",
            "childrens": []
          }
        ]
      },
      {
        "id": 1034,
        "name": "双鸭山市",
        "level": 2,
        "regionCode": "230500",
        "childrens": [
          {
            "id": 1035,
            "name": "尖山区",
            "level": 3,
            "regionCode": "230502",
            "childrens": []
          },
          {
            "id": 1036,
            "name": "岭东区",
            "level": 3,
            "regionCode": "230503",
            "childrens": []
          },
          {
            "id": 1037,
            "name": "四方台区",
            "level": 3,
            "regionCode": "230505",
            "childrens": []
          },
          {
            "id": 1038,
            "name": "宝山区",
            "level": 3,
            "regionCode": "230506",
            "childrens": []
          },
          {
            "id": 1039,
            "name": "集贤县",
            "level": 3,
            "regionCode": "230521",
            "childrens": []
          },
          {
            "id": 1040,
            "name": "友谊县",
            "level": 3,
            "regionCode": "230522",
            "childrens": []
          },
          {
            "id": 1041,
            "name": "宝清县",
            "level": 3,
            "regionCode": "230523",
            "childrens": []
          },
          {
            "id": 1042,
            "name": "饶河县",
            "level": 3,
            "regionCode": "230524",
            "childrens": []
          }
        ]
      },
      {
        "id": 1048,
        "name": "大庆市",
        "level": 2,
        "regionCode": "230600",
        "childrens": [
          {
            "id": 1049,
            "name": "萨尔图区",
            "level": 3,
            "regionCode": "230602",
            "childrens": []
          },
          {
            "id": 1050,
            "name": "龙凤区",
            "level": 3,
            "regionCode": "230603",
            "childrens": []
          },
          {
            "id": 1051,
            "name": "让胡路区",
            "level": 3,
            "regionCode": "230604",
            "childrens": []
          },
          {
            "id": 1052,
            "name": "红岗区",
            "level": 3,
            "regionCode": "230605",
            "childrens": []
          },
          {
            "id": 1053,
            "name": "大同区",
            "level": 3,
            "regionCode": "230606",
            "childrens": []
          },
          {
            "id": 1054,
            "name": "肇州县",
            "level": 3,
            "regionCode": "230621",
            "childrens": []
          },
          {
            "id": 1055,
            "name": "肇源县",
            "level": 3,
            "regionCode": "230622",
            "childrens": []
          },
          {
            "id": 1056,
            "name": "林甸县",
            "level": 3,
            "regionCode": "230623",
            "childrens": []
          },
          {
            "id": 1057,
            "name": "杜尔伯特蒙古族自治县",
            "level": 3,
            "regionCode": "230624",
            "childrens": []
          }
        ]
      },
      {
        "id": 1063,
        "name": "伊春市",
        "level": 2,
        "regionCode": "230700",
        "childrens": [
          {
            "id": 1065,
            "name": "南岔县",
            "level": 3,
            "regionCode": "230726",
            "childrens": []
          },
          {
            "id": 1066,
            "name": "友好区",
            "level": 3,
            "regionCode": "230719",
            "childrens": []
          },
          {
            "id": 1079,
            "name": "嘉荫县",
            "level": 3,
            "regionCode": "230722",
            "childrens": []
          },
          {
            "id": 1080,
            "name": "铁力市",
            "level": 3,
            "regionCode": "230781",
            "childrens": []
          },
          {
            "id": 5176,
            "name": "伊美区",
            "level": 3,
            "regionCode": "230717",
            "childrens": []
          },
          {
            "id": 5177,
            "name": "乌翠区",
            "level": 3,
            "regionCode": "230718",
            "childrens": []
          },
          {
            "id": 5178,
            "name": "汤旺县",
            "level": 3,
            "regionCode": "230723",
            "childrens": []
          },
          {
            "id": 5179,
            "name": "丰林县",
            "level": 3,
            "regionCode": "230724",
            "childrens": []
          },
          {
            "id": 5180,
            "name": "大箐山县",
            "level": 3,
            "regionCode": "230725",
            "childrens": []
          },
          {
            "id": 5181,
            "name": "金林区",
            "level": 3,
            "regionCode": "230751",
            "childrens": []
          }
        ]
      },
      {
        "id": 1086,
        "name": "佳木斯市",
        "level": 2,
        "regionCode": "230800",
        "childrens": [
          {
            "id": 1088,
            "name": "前进区",
            "level": 3,
            "regionCode": "230804",
            "childrens": []
          },
          {
            "id": 1089,
            "name": "东风区",
            "level": 3,
            "regionCode": "230805",
            "childrens": []
          },
          {
            "id": 1091,
            "name": "桦南县",
            "level": 3,
            "regionCode": "230822",
            "childrens": []
          },
          {
            "id": 1092,
            "name": "桦川县",
            "level": 3,
            "regionCode": "230826",
            "childrens": []
          },
          {
            "id": 1093,
            "name": "汤原县",
            "level": 3,
            "regionCode": "230828",
            "childrens": []
          },
          {
            "id": 1094,
            "name": "抚远市",
            "level": 3,
            "regionCode": "230883",
            "childrens": []
          },
          {
            "id": 1095,
            "name": "同江市",
            "level": 3,
            "regionCode": "230881",
            "childrens": []
          },
          {
            "id": 1096,
            "name": "富锦市",
            "level": 3,
            "regionCode": "230882",
            "childrens": []
          },
          {
            "id": 5182,
            "name": "向阳区",
            "level": 3,
            "regionCode": "230803",
            "childrens": []
          },
          {
            "id": 5183,
            "name": "郊区",
            "level": 3,
            "regionCode": "230811",
            "childrens": []
          }
        ]
      },
      {
        "id": 1102,
        "name": "七台河市",
        "level": 2,
        "regionCode": "230900",
        "childrens": [
          {
            "id": 1103,
            "name": "新兴区",
            "level": 3,
            "regionCode": "230902",
            "childrens": []
          },
          {
            "id": 1104,
            "name": "桃山区",
            "level": 3,
            "regionCode": "230903",
            "childrens": []
          },
          {
            "id": 1105,
            "name": "茄子河区",
            "level": 3,
            "regionCode": "230904",
            "childrens": []
          },
          {
            "id": 1106,
            "name": "勃利县",
            "level": 3,
            "regionCode": "230921",
            "childrens": []
          }
        ]
      },
      {
        "id": 1112,
        "name": "牡丹江市",
        "level": 2,
        "regionCode": "231000",
        "childrens": [
          {
            "id": 1113,
            "name": "东安区",
            "level": 3,
            "regionCode": "231002",
            "childrens": []
          },
          {
            "id": 1114,
            "name": "阳明区",
            "level": 3,
            "regionCode": "231003",
            "childrens": []
          },
          {
            "id": 1115,
            "name": "爱民区",
            "level": 3,
            "regionCode": "231004",
            "childrens": []
          },
          {
            "id": 1117,
            "name": "东宁市",
            "level": 3,
            "regionCode": "231086",
            "childrens": []
          },
          {
            "id": 1118,
            "name": "林口县",
            "level": 3,
            "regionCode": "231025",
            "childrens": []
          },
          {
            "id": 1119,
            "name": "绥芬河市",
            "level": 3,
            "regionCode": "231081",
            "childrens": []
          },
          {
            "id": 1120,
            "name": "海林市",
            "level": 3,
            "regionCode": "231083",
            "childrens": []
          },
          {
            "id": 1121,
            "name": "宁安市",
            "level": 3,
            "regionCode": "231084",
            "childrens": []
          },
          {
            "id": 1122,
            "name": "穆棱市",
            "level": 3,
            "regionCode": "231085",
            "childrens": []
          },
          {
            "id": 5184,
            "name": "西安区",
            "level": 3,
            "regionCode": "231005",
            "childrens": []
          }
        ]
      },
      {
        "id": 1128,
        "name": "黑河市",
        "level": 2,
        "regionCode": "231100",
        "childrens": [
          {
            "id": 1129,
            "name": "爱辉区",
            "level": 3,
            "regionCode": "231102",
            "childrens": []
          },
          {
            "id": 1130,
            "name": "嫩江市",
            "level": 3,
            "regionCode": "231183",
            "childrens": []
          },
          {
            "id": 1131,
            "name": "逊克县",
            "level": 3,
            "regionCode": "231123",
            "childrens": []
          },
          {
            "id": 1132,
            "name": "孙吴县",
            "level": 3,
            "regionCode": "231124",
            "childrens": []
          },
          {
            "id": 1133,
            "name": "北安市",
            "level": 3,
            "regionCode": "231181",
            "childrens": []
          },
          {
            "id": 1134,
            "name": "五大连池市",
            "level": 3,
            "regionCode": "231182",
            "childrens": []
          }
        ]
      },
      {
        "id": 1140,
        "name": "绥化市",
        "level": 2,
        "regionCode": "231200",
        "childrens": [
          {
            "id": 1141,
            "name": "北林区",
            "level": 3,
            "regionCode": "231202",
            "childrens": []
          },
          {
            "id": 1142,
            "name": "望奎县",
            "level": 3,
            "regionCode": "231221",
            "childrens": []
          },
          {
            "id": 1143,
            "name": "兰西县",
            "level": 3,
            "regionCode": "231222",
            "childrens": []
          },
          {
            "id": 1144,
            "name": "青冈县",
            "level": 3,
            "regionCode": "231223",
            "childrens": []
          },
          {
            "id": 1145,
            "name": "庆安县",
            "level": 3,
            "regionCode": "231224",
            "childrens": []
          },
          {
            "id": 1146,
            "name": "明水县",
            "level": 3,
            "regionCode": "231225",
            "childrens": []
          },
          {
            "id": 1147,
            "name": "绥棱县",
            "level": 3,
            "regionCode": "231226",
            "childrens": []
          },
          {
            "id": 1148,
            "name": "安达市",
            "level": 3,
            "regionCode": "231281",
            "childrens": []
          },
          {
            "id": 1149,
            "name": "肇东市",
            "level": 3,
            "regionCode": "231282",
            "childrens": []
          },
          {
            "id": 1150,
            "name": "海伦市",
            "level": 3,
            "regionCode": "231283",
            "childrens": []
          }
        ]
      },
      {
        "id": 1156,
        "name": "大兴安岭地区",
        "level": 2,
        "regionCode": "232700",
        "childrens": [
          {
            "id": 1161,
            "name": "呼玛县",
            "level": 3,
            "regionCode": "232721",
            "childrens": []
          },
          {
            "id": 1162,
            "name": "塔河县",
            "level": 3,
            "regionCode": "232722",
            "childrens": []
          },
          {
            "id": 1163,
            "name": "漠河市",
            "level": 3,
            "regionCode": "232701",
            "childrens": []
          },
          {
            "id": 5185,
            "name": "加格达奇区",
            "level": 3,
            "regionCode": "232718",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 1169,
    "name": "上海市",
    "level": 1,
    "regionCode": "310000",
    "childrens": []
  },
  {
    "id": 1194,
    "name": "江苏省",
    "level": 1,
    "regionCode": "320000",
    "childrens": [
      {
        "id": 1195,
        "name": "南京市",
        "level": 2,
        "regionCode": "320100",
        "childrens": [
          {
            "id": 1196,
            "name": "玄武区",
            "level": 3,
            "regionCode": "320102",
            "childrens": []
          },
          {
            "id": 1198,
            "name": "秦淮区",
            "level": 3,
            "regionCode": "320104",
            "childrens": []
          },
          {
            "id": 1199,
            "name": "建邺区",
            "level": 3,
            "regionCode": "320105",
            "childrens": []
          },
          {
            "id": 1202,
            "name": "浦口区",
            "level": 3,
            "regionCode": "320111",
            "childrens": []
          },
          {
            "id": 1203,
            "name": "栖霞区",
            "level": 3,
            "regionCode": "320113",
            "childrens": []
          },
          {
            "id": 1204,
            "name": "雨花台区",
            "level": 3,
            "regionCode": "320114",
            "childrens": []
          },
          {
            "id": 1205,
            "name": "江宁区",
            "level": 3,
            "regionCode": "320115",
            "childrens": []
          },
          {
            "id": 1206,
            "name": "六合区",
            "level": 3,
            "regionCode": "320116",
            "childrens": []
          },
          {
            "id": 1207,
            "name": "溧水区",
            "level": 3,
            "regionCode": "320117",
            "childrens": []
          },
          {
            "id": 1208,
            "name": "高淳区",
            "level": 3,
            "regionCode": "320118",
            "childrens": []
          },
          {
            "id": 5186,
            "name": "鼓楼区",
            "level": 3,
            "regionCode": "320106",
            "childrens": []
          }
        ]
      },
      {
        "id": 1214,
        "name": "无锡市",
        "level": 2,
        "regionCode": "320200",
        "childrens": [
          {
            "id": 1218,
            "name": "锡山区",
            "level": 3,
            "regionCode": "320205",
            "childrens": []
          },
          {
            "id": 1219,
            "name": "惠山区",
            "level": 3,
            "regionCode": "320206",
            "childrens": []
          },
          {
            "id": 1220,
            "name": "滨湖区",
            "level": 3,
            "regionCode": "320211",
            "childrens": []
          },
          {
            "id": 1221,
            "name": "江阴市",
            "level": 3,
            "regionCode": "320281",
            "childrens": []
          },
          {
            "id": 1222,
            "name": "宜兴市",
            "level": 3,
            "regionCode": "320282",
            "childrens": []
          },
          {
            "id": 5187,
            "name": "梁溪区",
            "level": 3,
            "regionCode": "320213",
            "childrens": []
          },
          {
            "id": 5188,
            "name": "新吴区",
            "level": 3,
            "regionCode": "320214",
            "childrens": []
          }
        ]
      },
      {
        "id": 1228,
        "name": "徐州市",
        "level": 2,
        "regionCode": "320300",
        "childrens": [
          {
            "id": 1230,
            "name": "云龙区",
            "level": 3,
            "regionCode": "320303",
            "childrens": []
          },
          {
            "id": 1232,
            "name": "贾汪区",
            "level": 3,
            "regionCode": "320305",
            "childrens": []
          },
          {
            "id": 1233,
            "name": "泉山区",
            "level": 3,
            "regionCode": "320311",
            "childrens": []
          },
          {
            "id": 1234,
            "name": "丰县",
            "level": 3,
            "regionCode": "320321",
            "childrens": []
          },
          {
            "id": 1235,
            "name": "沛县",
            "level": 3,
            "regionCode": "320322",
            "childrens": []
          },
          {
            "id": 1236,
            "name": "铜山区",
            "level": 3,
            "regionCode": "320312",
            "childrens": []
          },
          {
            "id": 1237,
            "name": "睢宁县",
            "level": 3,
            "regionCode": "320324",
            "childrens": []
          },
          {
            "id": 1238,
            "name": "新沂市",
            "level": 3,
            "regionCode": "320381",
            "childrens": []
          },
          {
            "id": 1239,
            "name": "邳州市",
            "level": 3,
            "regionCode": "320382",
            "childrens": []
          },
          {
            "id": 5189,
            "name": "鼓楼区",
            "level": 3,
            "regionCode": "320302",
            "childrens": []
          }
        ]
      },
      {
        "id": 1245,
        "name": "常州市",
        "level": 2,
        "regionCode": "320400",
        "childrens": [
          {
            "id": 1247,
            "name": "天宁区",
            "level": 3,
            "regionCode": "320402",
            "childrens": []
          },
          {
            "id": 1248,
            "name": "钟楼区",
            "level": 3,
            "regionCode": "320404",
            "childrens": []
          },
          {
            "id": 1250,
            "name": "新北区",
            "level": 3,
            "regionCode": "320411",
            "childrens": []
          },
          {
            "id": 1251,
            "name": "武进区",
            "level": 3,
            "regionCode": "320412",
            "childrens": []
          },
          {
            "id": 1252,
            "name": "溧阳市",
            "level": 3,
            "regionCode": "320481",
            "childrens": []
          },
          {
            "id": 1253,
            "name": "金坛区",
            "level": 3,
            "regionCode": "320413",
            "childrens": []
          }
        ]
      },
      {
        "id": 1259,
        "name": "苏州市",
        "level": 2,
        "regionCode": "320500",
        "childrens": [
          {
            "id": 1264,
            "name": "吴中区",
            "level": 3,
            "regionCode": "320506",
            "childrens": []
          },
          {
            "id": 1265,
            "name": "相城区",
            "level": 3,
            "regionCode": "320507",
            "childrens": []
          },
          {
            "id": 1266,
            "name": "常熟市",
            "level": 3,
            "regionCode": "320581",
            "childrens": []
          },
          {
            "id": 1268,
            "name": "昆山市",
            "level": 3,
            "regionCode": "320583",
            "childrens": []
          },
          {
            "id": 1269,
            "name": "吴江区",
            "level": 3,
            "regionCode": "320509",
            "childrens": []
          },
          {
            "id": 1270,
            "name": "太仓市",
            "level": 3,
            "regionCode": "320585",
            "childrens": []
          },
          {
            "id": 5190,
            "name": "虎丘区",
            "level": 3,
            "regionCode": "320505",
            "childrens": []
          },
          {
            "id": 5191,
            "name": "姑苏区",
            "level": 3,
            "regionCode": "320508",
            "childrens": []
          },
          {
            "id": 5192,
            "name": "张家港市",
            "level": 3,
            "regionCode": "320582",
            "childrens": []
          }
        ]
      },
      {
        "id": 1276,
        "name": "南通市",
        "level": 2,
        "regionCode": "320600",
        "childrens": [
          {
            "id": 1277,
            "name": "崇川区",
            "level": 3,
            "regionCode": "320602",
            "childrens": []
          },
          {
            "id": 1279,
            "name": "海安市",
            "level": 3,
            "regionCode": "320685",
            "childrens": []
          },
          {
            "id": 1280,
            "name": "如东县",
            "level": 3,
            "regionCode": "320623",
            "childrens": []
          },
          {
            "id": 1281,
            "name": "启东市",
            "level": 3,
            "regionCode": "320681",
            "childrens": []
          },
          {
            "id": 1282,
            "name": "通州区",
            "level": 3,
            "regionCode": "320612",
            "childrens": []
          },
          {
            "id": 1283,
            "name": "海门区",
            "level": 3,
            "regionCode": "320684",
            "childrens": []
          },
          {
            "id": 5193,
            "name": "如皋市",
            "level": 3,
            "regionCode": "320682",
            "childrens": []
          }
        ]
      },
      {
        "id": 1289,
        "name": "连云港市",
        "level": 2,
        "regionCode": "320700",
        "childrens": [
          {
            "id": 1290,
            "name": "连云区",
            "level": 3,
            "regionCode": "320703",
            "childrens": []
          },
          {
            "id": 1292,
            "name": "海州区",
            "level": 3,
            "regionCode": "320706",
            "childrens": []
          },
          {
            "id": 1293,
            "name": "赣榆区",
            "level": 3,
            "regionCode": "320707",
            "childrens": []
          },
          {
            "id": 1294,
            "name": "东海县",
            "level": 3,
            "regionCode": "320722",
            "childrens": []
          },
          {
            "id": 1295,
            "name": "灌云县",
            "level": 3,
            "regionCode": "320723",
            "childrens": []
          },
          {
            "id": 1296,
            "name": "灌南县",
            "level": 3,
            "regionCode": "320724",
            "childrens": []
          }
        ]
      },
      {
        "id": 1302,
        "name": "淮安市",
        "level": 2,
        "regionCode": "320800",
        "childrens": [
          {
            "id": 1305,
            "name": "淮阴区",
            "level": 3,
            "regionCode": "320804",
            "childrens": []
          },
          {
            "id": 1307,
            "name": "涟水县",
            "level": 3,
            "regionCode": "320826",
            "childrens": []
          },
          {
            "id": 1308,
            "name": "洪泽区",
            "level": 3,
            "regionCode": "320813",
            "childrens": []
          },
          {
            "id": 1309,
            "name": "盱眙县",
            "level": 3,
            "regionCode": "320830",
            "childrens": []
          },
          {
            "id": 1310,
            "name": "金湖县",
            "level": 3,
            "regionCode": "320831",
            "childrens": []
          },
          {
            "id": 5194,
            "name": "淮安区",
            "level": 3,
            "regionCode": "320803",
            "childrens": []
          },
          {
            "id": 5195,
            "name": "清江浦区",
            "level": 3,
            "regionCode": "320812",
            "childrens": []
          }
        ]
      },
      {
        "id": 1316,
        "name": "盐城市",
        "level": 2,
        "regionCode": "320900",
        "childrens": [
          {
            "id": 1317,
            "name": "亭湖区",
            "level": 3,
            "regionCode": "320902",
            "childrens": []
          },
          {
            "id": 1318,
            "name": "盐都区",
            "level": 3,
            "regionCode": "320903",
            "childrens": []
          },
          {
            "id": 1319,
            "name": "响水县",
            "level": 3,
            "regionCode": "320921",
            "childrens": []
          },
          {
            "id": 1320,
            "name": "滨海县",
            "level": 3,
            "regionCode": "320922",
            "childrens": []
          },
          {
            "id": 1321,
            "name": "阜宁县",
            "level": 3,
            "regionCode": "320923",
            "childrens": []
          },
          {
            "id": 1322,
            "name": "射阳县",
            "level": 3,
            "regionCode": "320924",
            "childrens": []
          },
          {
            "id": 1323,
            "name": "建湖县",
            "level": 3,
            "regionCode": "320925",
            "childrens": []
          },
          {
            "id": 1324,
            "name": "东台市",
            "level": 3,
            "regionCode": "320981",
            "childrens": []
          },
          {
            "id": 1325,
            "name": "大丰区",
            "level": 3,
            "regionCode": "320904",
            "childrens": []
          }
        ]
      },
      {
        "id": 1331,
        "name": "扬州市",
        "level": 2,
        "regionCode": "321000",
        "childrens": [
          {
            "id": 1332,
            "name": "广陵区",
            "level": 3,
            "regionCode": "321002",
            "childrens": []
          },
          {
            "id": 1333,
            "name": "邗江区",
            "level": 3,
            "regionCode": "321003",
            "childrens": []
          },
          {
            "id": 1335,
            "name": "宝应县",
            "level": 3,
            "regionCode": "321023",
            "childrens": []
          },
          {
            "id": 1336,
            "name": "仪征市",
            "level": 3,
            "regionCode": "321081",
            "childrens": []
          },
          {
            "id": 1337,
            "name": "高邮市",
            "level": 3,
            "regionCode": "321084",
            "childrens": []
          },
          {
            "id": 1338,
            "name": "江都区",
            "level": 3,
            "regionCode": "321012",
            "childrens": []
          }
        ]
      },
      {
        "id": 1344,
        "name": "镇江市",
        "level": 2,
        "regionCode": "321100",
        "childrens": [
          {
            "id": 1346,
            "name": "京口区",
            "level": 3,
            "regionCode": "321102",
            "childrens": []
          },
          {
            "id": 1347,
            "name": "润州区",
            "level": 3,
            "regionCode": "321111",
            "childrens": []
          },
          {
            "id": 1348,
            "name": "丹徒区",
            "level": 3,
            "regionCode": "321112",
            "childrens": []
          },
          {
            "id": 1349,
            "name": "丹阳市",
            "level": 3,
            "regionCode": "321181",
            "childrens": []
          },
          {
            "id": 1350,
            "name": "扬中市",
            "level": 3,
            "regionCode": "321182",
            "childrens": []
          },
          {
            "id": 1351,
            "name": "句容市",
            "level": 3,
            "regionCode": "321183",
            "childrens": []
          }
        ]
      },
      {
        "id": 1357,
        "name": "泰州市",
        "level": 2,
        "regionCode": "321200",
        "childrens": [
          {
            "id": 1359,
            "name": "高港区",
            "level": 3,
            "regionCode": "321203",
            "childrens": []
          },
          {
            "id": 1360,
            "name": "兴化市",
            "level": 3,
            "regionCode": "321281",
            "childrens": []
          },
          {
            "id": 1361,
            "name": "靖江市",
            "level": 3,
            "regionCode": "321282",
            "childrens": []
          },
          {
            "id": 1362,
            "name": "泰兴市",
            "level": 3,
            "regionCode": "321283",
            "childrens": []
          },
          {
            "id": 1363,
            "name": "姜堰区",
            "level": 3,
            "regionCode": "321204",
            "childrens": []
          },
          {
            "id": 5196,
            "name": "海陵区",
            "level": 3,
            "regionCode": "321202",
            "childrens": []
          }
        ]
      },
      {
        "id": 1369,
        "name": "宿迁市",
        "level": 2,
        "regionCode": "321300",
        "childrens": [
          {
            "id": 1370,
            "name": "宿城区",
            "level": 3,
            "regionCode": "321302",
            "childrens": []
          },
          {
            "id": 1371,
            "name": "宿豫区",
            "level": 3,
            "regionCode": "321311",
            "childrens": []
          },
          {
            "id": 1372,
            "name": "沭阳县",
            "level": 3,
            "regionCode": "321322",
            "childrens": []
          },
          {
            "id": 1373,
            "name": "泗阳县",
            "level": 3,
            "regionCode": "321323",
            "childrens": []
          },
          {
            "id": 1374,
            "name": "泗洪县",
            "level": 3,
            "regionCode": "321324",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 1380,
    "name": "浙江省",
    "level": 1,
    "regionCode": "330000",
    "childrens": [
      {
        "id": 1381,
        "name": "杭州市",
        "level": 2,
        "regionCode": "330100",
        "childrens": [
          {
            "id": 1382,
            "name": "上城区",
            "level": 3,
            "regionCode": "330102",
            "childrens": []
          },
          {
            "id": 1385,
            "name": "拱墅区",
            "level": 3,
            "regionCode": "330105",
            "childrens": []
          },
          {
            "id": 1386,
            "name": "西湖区",
            "level": 3,
            "regionCode": "330106",
            "childrens": []
          },
          {
            "id": 1387,
            "name": "滨江区",
            "level": 3,
            "regionCode": "330108",
            "childrens": []
          },
          {
            "id": 1388,
            "name": "萧山区",
            "level": 3,
            "regionCode": "330109",
            "childrens": []
          },
          {
            "id": 1389,
            "name": "余杭区",
            "level": 3,
            "regionCode": "330110",
            "childrens": []
          },
          {
            "id": 1390,
            "name": "桐庐县",
            "level": 3,
            "regionCode": "330122",
            "childrens": []
          },
          {
            "id": 1391,
            "name": "淳安县",
            "level": 3,
            "regionCode": "330127",
            "childrens": []
          },
          {
            "id": 1392,
            "name": "建德市",
            "level": 3,
            "regionCode": "330182",
            "childrens": []
          },
          {
            "id": 1393,
            "name": "富阳区",
            "level": 3,
            "regionCode": "330111",
            "childrens": []
          },
          {
            "id": 1394,
            "name": "临安区",
            "level": 3,
            "regionCode": "330112",
            "childrens": []
          },
          {
            "id": 1467,
            "name": "临平区",
            "level": 3,
            "regionCode": "330113",
            "childrens": []
          },
          {
            "id": 1468,
            "name": "钱塘区",
            "level": 3,
            "regionCode": "330114",
            "childrens": []
          }
        ]
      },
      {
        "id": 1400,
        "name": "宁波市",
        "level": 2,
        "regionCode": "330200",
        "childrens": [
          {
            "id": 1401,
            "name": "海曙区",
            "level": 3,
            "regionCode": "330203",
            "childrens": []
          },
          {
            "id": 1404,
            "name": "北仑区",
            "level": 3,
            "regionCode": "330206",
            "childrens": []
          },
          {
            "id": 1405,
            "name": "镇海区",
            "level": 3,
            "regionCode": "330211",
            "childrens": []
          },
          {
            "id": 1406,
            "name": "鄞州区",
            "level": 3,
            "regionCode": "330212",
            "childrens": []
          },
          {
            "id": 1407,
            "name": "象山县",
            "level": 3,
            "regionCode": "330225",
            "childrens": []
          },
          {
            "id": 1408,
            "name": "宁海县",
            "level": 3,
            "regionCode": "330226",
            "childrens": []
          },
          {
            "id": 1409,
            "name": "余姚市",
            "level": 3,
            "regionCode": "330281",
            "childrens": []
          },
          {
            "id": 1410,
            "name": "慈溪市",
            "level": 3,
            "regionCode": "330282",
            "childrens": []
          },
          {
            "id": 1411,
            "name": "奉化区",
            "level": 3,
            "regionCode": "330213",
            "childrens": []
          },
          {
            "id": 5197,
            "name": "江北区",
            "level": 3,
            "regionCode": "330205",
            "childrens": []
          }
        ]
      },
      {
        "id": 1417,
        "name": "温州市",
        "level": 2,
        "regionCode": "330300",
        "childrens": [
          {
            "id": 1418,
            "name": "鹿城区",
            "level": 3,
            "regionCode": "330302",
            "childrens": []
          },
          {
            "id": 1419,
            "name": "龙湾区",
            "level": 3,
            "regionCode": "330303",
            "childrens": []
          },
          {
            "id": 1420,
            "name": "瓯海区",
            "level": 3,
            "regionCode": "330304",
            "childrens": []
          },
          {
            "id": 1421,
            "name": "洞头区",
            "level": 3,
            "regionCode": "330305",
            "childrens": []
          },
          {
            "id": 1422,
            "name": "永嘉县",
            "level": 3,
            "regionCode": "330324",
            "childrens": []
          },
          {
            "id": 1423,
            "name": "平阳县",
            "level": 3,
            "regionCode": "330326",
            "childrens": []
          },
          {
            "id": 1425,
            "name": "文成县",
            "level": 3,
            "regionCode": "330328",
            "childrens": []
          },
          {
            "id": 1426,
            "name": "泰顺县",
            "level": 3,
            "regionCode": "330329",
            "childrens": []
          },
          {
            "id": 1427,
            "name": "瑞安市",
            "level": 3,
            "regionCode": "330381",
            "childrens": []
          },
          {
            "id": 1428,
            "name": "乐清市",
            "level": 3,
            "regionCode": "330382",
            "childrens": []
          },
          {
            "id": 5198,
            "name": "苍南县",
            "level": 3,
            "regionCode": "330327",
            "childrens": []
          },
          {
            "id": 5199,
            "name": "龙港市",
            "level": 3,
            "regionCode": "330383",
            "childrens": []
          }
        ]
      },
      {
        "id": 1434,
        "name": "嘉兴市",
        "level": 2,
        "regionCode": "330400",
        "childrens": [
          {
            "id": 1435,
            "name": "南湖区",
            "level": 3,
            "regionCode": "330402",
            "childrens": []
          },
          {
            "id": 1436,
            "name": "秀洲区",
            "level": 3,
            "regionCode": "330411",
            "childrens": []
          },
          {
            "id": 1437,
            "name": "嘉善县",
            "level": 3,
            "regionCode": "330421",
            "childrens": []
          },
          {
            "id": 1438,
            "name": "海盐县",
            "level": 3,
            "regionCode": "330424",
            "childrens": []
          },
          {
            "id": 1439,
            "name": "海宁市",
            "level": 3,
            "regionCode": "330481",
            "childrens": []
          },
          {
            "id": 1440,
            "name": "平湖市",
            "level": 3,
            "regionCode": "330482",
            "childrens": []
          },
          {
            "id": 1441,
            "name": "桐乡市",
            "level": 3,
            "regionCode": "330483",
            "childrens": []
          }
        ]
      },
      {
        "id": 1447,
        "name": "湖州市",
        "level": 2,
        "regionCode": "330500",
        "childrens": [
          {
            "id": 1448,
            "name": "吴兴区",
            "level": 3,
            "regionCode": "330502",
            "childrens": []
          },
          {
            "id": 1449,
            "name": "南浔区",
            "level": 3,
            "regionCode": "330503",
            "childrens": []
          },
          {
            "id": 1450,
            "name": "德清县",
            "level": 3,
            "regionCode": "330521",
            "childrens": []
          },
          {
            "id": 1451,
            "name": "长兴县",
            "level": 3,
            "regionCode": "330522",
            "childrens": []
          },
          {
            "id": 1452,
            "name": "安吉县",
            "level": 3,
            "regionCode": "330523",
            "childrens": []
          }
        ]
      },
      {
        "id": 1458,
        "name": "绍兴市",
        "level": 2,
        "regionCode": "330600",
        "childrens": [
          {
            "id": 1459,
            "name": "越城区",
            "level": 3,
            "regionCode": "330602",
            "childrens": []
          },
          {
            "id": 1461,
            "name": "新昌县",
            "level": 3,
            "regionCode": "330624",
            "childrens": []
          },
          {
            "id": 1462,
            "name": "诸暨市",
            "level": 3,
            "regionCode": "330681",
            "childrens": []
          },
          {
            "id": 1463,
            "name": "上虞区",
            "level": 3,
            "regionCode": "330604",
            "childrens": []
          },
          {
            "id": 1464,
            "name": "嵊州市",
            "level": 3,
            "regionCode": "330683",
            "childrens": []
          },
          {
            "id": 5200,
            "name": "柯桥区",
            "level": 3,
            "regionCode": "330603",
            "childrens": []
          }
        ]
      },
      {
        "id": 1470,
        "name": "金华市",
        "level": 2,
        "regionCode": "330700",
        "childrens": [
          {
            "id": 1471,
            "name": "婺城区",
            "level": 3,
            "regionCode": "330702",
            "childrens": []
          },
          {
            "id": 1472,
            "name": "金东区",
            "level": 3,
            "regionCode": "330703",
            "childrens": []
          },
          {
            "id": 1473,
            "name": "武义县",
            "level": 3,
            "regionCode": "330723",
            "childrens": []
          },
          {
            "id": 1474,
            "name": "浦江县",
            "level": 3,
            "regionCode": "330726",
            "childrens": []
          },
          {
            "id": 1475,
            "name": "磐安县",
            "level": 3,
            "regionCode": "330727",
            "childrens": []
          },
          {
            "id": 1476,
            "name": "兰溪市",
            "level": 3,
            "regionCode": "330781",
            "childrens": []
          },
          {
            "id": 1477,
            "name": "义乌市",
            "level": 3,
            "regionCode": "330782",
            "childrens": []
          },
          {
            "id": 1478,
            "name": "东阳市",
            "level": 3,
            "regionCode": "330783",
            "childrens": []
          },
          {
            "id": 1479,
            "name": "永康市",
            "level": 3,
            "regionCode": "330784",
            "childrens": []
          }
        ]
      },
      {
        "id": 1485,
        "name": "衢州市",
        "level": 2,
        "regionCode": "330800",
        "childrens": [
          {
            "id": 1486,
            "name": "柯城区",
            "level": 3,
            "regionCode": "330802",
            "childrens": []
          },
          {
            "id": 1487,
            "name": "衢江区",
            "level": 3,
            "regionCode": "330803",
            "childrens": []
          },
          {
            "id": 1488,
            "name": "常山县",
            "level": 3,
            "regionCode": "330822",
            "childrens": []
          },
          {
            "id": 1489,
            "name": "开化县",
            "level": 3,
            "regionCode": "330824",
            "childrens": []
          },
          {
            "id": 1490,
            "name": "龙游县",
            "level": 3,
            "regionCode": "330825",
            "childrens": []
          },
          {
            "id": 1491,
            "name": "江山市",
            "level": 3,
            "regionCode": "330881",
            "childrens": []
          }
        ]
      },
      {
        "id": 1497,
        "name": "舟山市",
        "level": 2,
        "regionCode": "330900",
        "childrens": [
          {
            "id": 1498,
            "name": "定海区",
            "level": 3,
            "regionCode": "330902",
            "childrens": []
          },
          {
            "id": 1500,
            "name": "岱山县",
            "level": 3,
            "regionCode": "330921",
            "childrens": []
          },
          {
            "id": 1501,
            "name": "嵊泗县",
            "level": 3,
            "regionCode": "330922",
            "childrens": []
          },
          {
            "id": 5201,
            "name": "普陀区",
            "level": 3,
            "regionCode": "330903",
            "childrens": []
          }
        ]
      },
      {
        "id": 1507,
        "name": "台州市",
        "level": 2,
        "regionCode": "331000",
        "childrens": [
          {
            "id": 1508,
            "name": "椒江区",
            "level": 3,
            "regionCode": "331002",
            "childrens": []
          },
          {
            "id": 1509,
            "name": "黄岩区",
            "level": 3,
            "regionCode": "331003",
            "childrens": []
          },
          {
            "id": 1510,
            "name": "路桥区",
            "level": 3,
            "regionCode": "331004",
            "childrens": []
          },
          {
            "id": 1511,
            "name": "玉环市",
            "level": 3,
            "regionCode": "331083",
            "childrens": []
          },
          {
            "id": 1512,
            "name": "三门县",
            "level": 3,
            "regionCode": "331022",
            "childrens": []
          },
          {
            "id": 1513,
            "name": "天台县",
            "level": 3,
            "regionCode": "331023",
            "childrens": []
          },
          {
            "id": 1514,
            "name": "仙居县",
            "level": 3,
            "regionCode": "331024",
            "childrens": []
          },
          {
            "id": 1515,
            "name": "温岭市",
            "level": 3,
            "regionCode": "331081",
            "childrens": []
          },
          {
            "id": 1516,
            "name": "临海市",
            "level": 3,
            "regionCode": "331082",
            "childrens": []
          }
        ]
      },
      {
        "id": 1522,
        "name": "丽水市",
        "level": 2,
        "regionCode": "331100",
        "childrens": [
          {
            "id": 1523,
            "name": "莲都区",
            "level": 3,
            "regionCode": "331102",
            "childrens": []
          },
          {
            "id": 1524,
            "name": "青田县",
            "level": 3,
            "regionCode": "331121",
            "childrens": []
          },
          {
            "id": 1525,
            "name": "缙云县",
            "level": 3,
            "regionCode": "331122",
            "childrens": []
          },
          {
            "id": 1526,
            "name": "遂昌县",
            "level": 3,
            "regionCode": "331123",
            "childrens": []
          },
          {
            "id": 1527,
            "name": "松阳县",
            "level": 3,
            "regionCode": "331124",
            "childrens": []
          },
          {
            "id": 1528,
            "name": "云和县",
            "level": 3,
            "regionCode": "331125",
            "childrens": []
          },
          {
            "id": 1529,
            "name": "庆元县",
            "level": 3,
            "regionCode": "331126",
            "childrens": []
          },
          {
            "id": 1530,
            "name": "景宁畲族自治县",
            "level": 3,
            "regionCode": "331127",
            "childrens": []
          },
          {
            "id": 1531,
            "name": "龙泉市",
            "level": 3,
            "regionCode": "331181",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 1537,
    "name": "安徽省",
    "level": 1,
    "regionCode": "340000",
    "childrens": [
      {
        "id": 1538,
        "name": "合肥市",
        "level": 2,
        "regionCode": "340100",
        "childrens": [
          {
            "id": 1539,
            "name": "瑶海区",
            "level": 3,
            "regionCode": "340102",
            "childrens": []
          },
          {
            "id": 1540,
            "name": "庐阳区",
            "level": 3,
            "regionCode": "340103",
            "childrens": []
          },
          {
            "id": 1541,
            "name": "蜀山区",
            "level": 3,
            "regionCode": "340104",
            "childrens": []
          },
          {
            "id": 1542,
            "name": "包河区",
            "level": 3,
            "regionCode": "340111",
            "childrens": []
          },
          {
            "id": 1543,
            "name": "长丰县",
            "level": 3,
            "regionCode": "340121",
            "childrens": []
          },
          {
            "id": 1544,
            "name": "肥东县",
            "level": 3,
            "regionCode": "340122",
            "childrens": []
          },
          {
            "id": 1545,
            "name": "肥西县",
            "level": 3,
            "regionCode": "340123",
            "childrens": []
          },
          {
            "id": 5202,
            "name": "庐江县",
            "level": 3,
            "regionCode": "340124",
            "childrens": []
          },
          {
            "id": 5203,
            "name": "巢湖市",
            "level": 3,
            "regionCode": "340181",
            "childrens": []
          }
        ]
      },
      {
        "id": 1551,
        "name": "芜湖市",
        "level": 2,
        "regionCode": "340200",
        "childrens": [
          {
            "id": 1552,
            "name": "镜湖区",
            "level": 3,
            "regionCode": "340202",
            "childrens": []
          },
          {
            "id": 1553,
            "name": "弋江区",
            "level": 3,
            "regionCode": "340209",
            "childrens": []
          },
          {
            "id": 1554,
            "name": "鸠江区",
            "level": 3,
            "regionCode": "340207",
            "childrens": []
          },
          {
            "id": 1557,
            "name": "繁昌区",
            "level": 3,
            "regionCode": "340211",
            "childrens": []
          },
          {
            "id": 1558,
            "name": "南陵县",
            "level": 3,
            "regionCode": "340223",
            "childrens": []
          },
          {
            "id": 5204,
            "name": "湾沚区",
            "level": 3,
            "regionCode": "340210",
            "childrens": []
          },
          {
            "id": 5205,
            "name": "无为市",
            "level": 3,
            "regionCode": "340281",
            "childrens": []
          }
        ]
      },
      {
        "id": 1564,
        "name": "蚌埠市",
        "level": 2,
        "regionCode": "340300",
        "childrens": [
          {
            "id": 1565,
            "name": "龙子湖区",
            "level": 3,
            "regionCode": "340302",
            "childrens": []
          },
          {
            "id": 1566,
            "name": "蚌山区",
            "level": 3,
            "regionCode": "340303",
            "childrens": []
          },
          {
            "id": 1567,
            "name": "禹会区",
            "level": 3,
            "regionCode": "340304",
            "childrens": []
          },
          {
            "id": 1568,
            "name": "淮上区",
            "level": 3,
            "regionCode": "340311",
            "childrens": []
          },
          {
            "id": 1569,
            "name": "怀远县",
            "level": 3,
            "regionCode": "340321",
            "childrens": []
          },
          {
            "id": 1570,
            "name": "五河县",
            "level": 3,
            "regionCode": "340322",
            "childrens": []
          },
          {
            "id": 1571,
            "name": "固镇县",
            "level": 3,
            "regionCode": "340323",
            "childrens": []
          }
        ]
      },
      {
        "id": 1577,
        "name": "淮南市",
        "level": 2,
        "regionCode": "340400",
        "childrens": [
          {
            "id": 1579,
            "name": "田家庵区",
            "level": 3,
            "regionCode": "340403",
            "childrens": []
          },
          {
            "id": 1580,
            "name": "谢家集区",
            "level": 3,
            "regionCode": "340404",
            "childrens": []
          },
          {
            "id": 1581,
            "name": "八公山区",
            "level": 3,
            "regionCode": "340405",
            "childrens": []
          },
          {
            "id": 1582,
            "name": "潘集区",
            "level": 3,
            "regionCode": "340406",
            "childrens": []
          },
          {
            "id": 1583,
            "name": "凤台县",
            "level": 3,
            "regionCode": "340421",
            "childrens": []
          },
          {
            "id": 5206,
            "name": "大通区",
            "level": 3,
            "regionCode": "340402",
            "childrens": []
          },
          {
            "id": 5207,
            "name": "寿县",
            "level": 3,
            "regionCode": "340422",
            "childrens": []
          }
        ]
      },
      {
        "id": 1589,
        "name": "马鞍山市",
        "level": 2,
        "regionCode": "340500",
        "childrens": [
          {
            "id": 1591,
            "name": "花山区",
            "level": 3,
            "regionCode": "340503",
            "childrens": []
          },
          {
            "id": 1592,
            "name": "雨山区",
            "level": 3,
            "regionCode": "340504",
            "childrens": []
          },
          {
            "id": 1593,
            "name": "当涂县",
            "level": 3,
            "regionCode": "340521",
            "childrens": []
          },
          {
            "id": 5208,
            "name": "博望区",
            "level": 3,
            "regionCode": "340506",
            "childrens": []
          },
          {
            "id": 5209,
            "name": "含山县",
            "level": 3,
            "regionCode": "340522",
            "childrens": []
          },
          {
            "id": 5210,
            "name": "和县",
            "level": 3,
            "regionCode": "340523",
            "childrens": []
          }
        ]
      },
      {
        "id": 1599,
        "name": "淮北市",
        "level": 2,
        "regionCode": "340600",
        "childrens": [
          {
            "id": 1600,
            "name": "杜集区",
            "level": 3,
            "regionCode": "340602",
            "childrens": []
          },
          {
            "id": 1601,
            "name": "相山区",
            "level": 3,
            "regionCode": "340603",
            "childrens": []
          },
          {
            "id": 1602,
            "name": "烈山区",
            "level": 3,
            "regionCode": "340604",
            "childrens": []
          },
          {
            "id": 1603,
            "name": "濉溪县",
            "level": 3,
            "regionCode": "340621",
            "childrens": []
          }
        ]
      },
      {
        "id": 1609,
        "name": "铜陵市",
        "level": 2,
        "regionCode": "340700",
        "childrens": [
          {
            "id": 5211,
            "name": "铜官区",
            "level": 3,
            "regionCode": "340705",
            "childrens": []
          },
          {
            "id": 5212,
            "name": "义安区",
            "level": 3,
            "regionCode": "340706",
            "childrens": []
          },
          {
            "id": 5213,
            "name": "郊区",
            "level": 3,
            "regionCode": "340711",
            "childrens": []
          },
          {
            "id": 5214,
            "name": "枞阳县",
            "level": 3,
            "regionCode": "340722",
            "childrens": []
          }
        ]
      },
      {
        "id": 1619,
        "name": "安庆市",
        "level": 2,
        "regionCode": "340800",
        "childrens": [
          {
            "id": 1620,
            "name": "迎江区",
            "level": 3,
            "regionCode": "340802",
            "childrens": []
          },
          {
            "id": 1621,
            "name": "大观区",
            "level": 3,
            "regionCode": "340803",
            "childrens": []
          },
          {
            "id": 1622,
            "name": "宜秀区",
            "level": 3,
            "regionCode": "340811",
            "childrens": []
          },
          {
            "id": 1623,
            "name": "怀宁县",
            "level": 3,
            "regionCode": "340822",
            "childrens": []
          },
          {
            "id": 1625,
            "name": "潜山市",
            "level": 3,
            "regionCode": "340882",
            "childrens": []
          },
          {
            "id": 1626,
            "name": "太湖县",
            "level": 3,
            "regionCode": "340825",
            "childrens": []
          },
          {
            "id": 1627,
            "name": "宿松县",
            "level": 3,
            "regionCode": "340826",
            "childrens": []
          },
          {
            "id": 1628,
            "name": "望江县",
            "level": 3,
            "regionCode": "340827",
            "childrens": []
          },
          {
            "id": 1629,
            "name": "岳西县",
            "level": 3,
            "regionCode": "340828",
            "childrens": []
          },
          {
            "id": 1630,
            "name": "桐城市",
            "level": 3,
            "regionCode": "340881",
            "childrens": []
          }
        ]
      },
      {
        "id": 1636,
        "name": "黄山市",
        "level": 2,
        "regionCode": "341000",
        "childrens": [
          {
            "id": 1637,
            "name": "屯溪区",
            "level": 3,
            "regionCode": "341002",
            "childrens": []
          },
          {
            "id": 1638,
            "name": "黄山区",
            "level": 3,
            "regionCode": "341003",
            "childrens": []
          },
          {
            "id": 1639,
            "name": "徽州区",
            "level": 3,
            "regionCode": "341004",
            "childrens": []
          },
          {
            "id": 1640,
            "name": "歙县",
            "level": 3,
            "regionCode": "341021",
            "childrens": []
          },
          {
            "id": 1641,
            "name": "休宁县",
            "level": 3,
            "regionCode": "341022",
            "childrens": []
          },
          {
            "id": 1642,
            "name": "黟县",
            "level": 3,
            "regionCode": "341023",
            "childrens": []
          },
          {
            "id": 1643,
            "name": "祁门县",
            "level": 3,
            "regionCode": "341024",
            "childrens": []
          }
        ]
      },
      {
        "id": 1649,
        "name": "滁州市",
        "level": 2,
        "regionCode": "341100",
        "childrens": [
          {
            "id": 1650,
            "name": "琅琊区",
            "level": 3,
            "regionCode": "341102",
            "childrens": []
          },
          {
            "id": 1651,
            "name": "南谯区",
            "level": 3,
            "regionCode": "341103",
            "childrens": []
          },
          {
            "id": 1652,
            "name": "来安县",
            "level": 3,
            "regionCode": "341122",
            "childrens": []
          },
          {
            "id": 1653,
            "name": "全椒县",
            "level": 3,
            "regionCode": "341124",
            "childrens": []
          },
          {
            "id": 1654,
            "name": "定远县",
            "level": 3,
            "regionCode": "341125",
            "childrens": []
          },
          {
            "id": 1655,
            "name": "凤阳县",
            "level": 3,
            "regionCode": "341126",
            "childrens": []
          },
          {
            "id": 1656,
            "name": "天长市",
            "level": 3,
            "regionCode": "341181",
            "childrens": []
          },
          {
            "id": 1657,
            "name": "明光市",
            "level": 3,
            "regionCode": "341182",
            "childrens": []
          }
        ]
      },
      {
        "id": 1663,
        "name": "阜阳市",
        "level": 2,
        "regionCode": "341200",
        "childrens": [
          {
            "id": 1664,
            "name": "颍州区",
            "level": 3,
            "regionCode": "341202",
            "childrens": []
          },
          {
            "id": 1665,
            "name": "颍东区",
            "level": 3,
            "regionCode": "341203",
            "childrens": []
          },
          {
            "id": 1666,
            "name": "颍泉区",
            "level": 3,
            "regionCode": "341204",
            "childrens": []
          },
          {
            "id": 1667,
            "name": "临泉县",
            "level": 3,
            "regionCode": "341221",
            "childrens": []
          },
          {
            "id": 1668,
            "name": "太和县",
            "level": 3,
            "regionCode": "341222",
            "childrens": []
          },
          {
            "id": 1669,
            "name": "阜南县",
            "level": 3,
            "regionCode": "341225",
            "childrens": []
          },
          {
            "id": 1670,
            "name": "颍上县",
            "level": 3,
            "regionCode": "341226",
            "childrens": []
          },
          {
            "id": 1671,
            "name": "界首市",
            "level": 3,
            "regionCode": "341282",
            "childrens": []
          }
        ]
      },
      {
        "id": 1677,
        "name": "宿州市",
        "level": 2,
        "regionCode": "341300",
        "childrens": [
          {
            "id": 1679,
            "name": "砀山县",
            "level": 3,
            "regionCode": "341321",
            "childrens": []
          },
          {
            "id": 1680,
            "name": "萧县",
            "level": 3,
            "regionCode": "341322",
            "childrens": []
          },
          {
            "id": 1681,
            "name": "灵璧县",
            "level": 3,
            "regionCode": "341323",
            "childrens": []
          },
          {
            "id": 1682,
            "name": "泗县",
            "level": 3,
            "regionCode": "341324",
            "childrens": []
          },
          {
            "id": 5215,
            "name": "埇桥区",
            "level": 3,
            "regionCode": "341302",
            "childrens": []
          }
        ]
      },
      {
        "id": 1699,
        "name": "六安市",
        "level": 2,
        "regionCode": "341500",
        "childrens": [
          {
            "id": 1700,
            "name": "金安区",
            "level": 3,
            "regionCode": "341502",
            "childrens": []
          },
          {
            "id": 1701,
            "name": "裕安区",
            "level": 3,
            "regionCode": "341503",
            "childrens": []
          },
          {
            "id": 1703,
            "name": "霍邱县",
            "level": 3,
            "regionCode": "341522",
            "childrens": []
          },
          {
            "id": 1704,
            "name": "舒城县",
            "level": 3,
            "regionCode": "341523",
            "childrens": []
          },
          {
            "id": 1705,
            "name": "金寨县",
            "level": 3,
            "regionCode": "341524",
            "childrens": []
          },
          {
            "id": 1706,
            "name": "霍山县",
            "level": 3,
            "regionCode": "341525",
            "childrens": []
          },
          {
            "id": 5216,
            "name": "叶集区",
            "level": 3,
            "regionCode": "341504",
            "childrens": []
          }
        ]
      },
      {
        "id": 1712,
        "name": "亳州市",
        "level": 2,
        "regionCode": "341600",
        "childrens": [
          {
            "id": 1713,
            "name": "谯城区",
            "level": 3,
            "regionCode": "341602",
            "childrens": []
          },
          {
            "id": 1714,
            "name": "涡阳县",
            "level": 3,
            "regionCode": "341621",
            "childrens": []
          },
          {
            "id": 1715,
            "name": "蒙城县",
            "level": 3,
            "regionCode": "341622",
            "childrens": []
          },
          {
            "id": 1716,
            "name": "利辛县",
            "level": 3,
            "regionCode": "341623",
            "childrens": []
          }
        ]
      },
      {
        "id": 1722,
        "name": "池州市",
        "level": 2,
        "regionCode": "341700",
        "childrens": [
          {
            "id": 1723,
            "name": "贵池区",
            "level": 3,
            "regionCode": "341702",
            "childrens": []
          },
          {
            "id": 1724,
            "name": "东至县",
            "level": 3,
            "regionCode": "341721",
            "childrens": []
          },
          {
            "id": 1725,
            "name": "石台县",
            "level": 3,
            "regionCode": "341722",
            "childrens": []
          },
          {
            "id": 1726,
            "name": "青阳县",
            "level": 3,
            "regionCode": "341723",
            "childrens": []
          }
        ]
      },
      {
        "id": 1732,
        "name": "宣城市",
        "level": 2,
        "regionCode": "341800",
        "childrens": [
          {
            "id": 1733,
            "name": "宣州区",
            "level": 3,
            "regionCode": "341802",
            "childrens": []
          },
          {
            "id": 1734,
            "name": "郎溪县",
            "level": 3,
            "regionCode": "341821",
            "childrens": []
          },
          {
            "id": 1735,
            "name": "广德市",
            "level": 3,
            "regionCode": "341882",
            "childrens": []
          },
          {
            "id": 1736,
            "name": "泾县",
            "level": 3,
            "regionCode": "341823",
            "childrens": []
          },
          {
            "id": 1737,
            "name": "绩溪县",
            "level": 3,
            "regionCode": "341824",
            "childrens": []
          },
          {
            "id": 1738,
            "name": "旌德县",
            "level": 3,
            "regionCode": "341825",
            "childrens": []
          },
          {
            "id": 1739,
            "name": "宁国市",
            "level": 3,
            "regionCode": "341881",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 1745,
    "name": "福建省",
    "level": 1,
    "regionCode": "350000",
    "childrens": [
      {
        "id": 1746,
        "name": "福州市",
        "level": 2,
        "regionCode": "350100",
        "childrens": [
          {
            "id": 1748,
            "name": "台江区",
            "level": 3,
            "regionCode": "350103",
            "childrens": []
          },
          {
            "id": 1749,
            "name": "仓山区",
            "level": 3,
            "regionCode": "350104",
            "childrens": []
          },
          {
            "id": 1750,
            "name": "马尾区",
            "level": 3,
            "regionCode": "350105",
            "childrens": []
          },
          {
            "id": 1751,
            "name": "晋安区",
            "level": 3,
            "regionCode": "350111",
            "childrens": []
          },
          {
            "id": 1752,
            "name": "闽侯县",
            "level": 3,
            "regionCode": "350121",
            "childrens": []
          },
          {
            "id": 1753,
            "name": "连江县",
            "level": 3,
            "regionCode": "350122",
            "childrens": []
          },
          {
            "id": 1754,
            "name": "罗源县",
            "level": 3,
            "regionCode": "350123",
            "childrens": []
          },
          {
            "id": 1755,
            "name": "闽清县",
            "level": 3,
            "regionCode": "350124",
            "childrens": []
          },
          {
            "id": 1756,
            "name": "永泰县",
            "level": 3,
            "regionCode": "350125",
            "childrens": []
          },
          {
            "id": 1757,
            "name": "平潭县",
            "level": 3,
            "regionCode": "350128",
            "childrens": []
          },
          {
            "id": 1758,
            "name": "福清市",
            "level": 3,
            "regionCode": "350181",
            "childrens": []
          },
          {
            "id": 1759,
            "name": "长乐区",
            "level": 3,
            "regionCode": "350112",
            "childrens": []
          },
          {
            "id": 5217,
            "name": "鼓楼区",
            "level": 3,
            "regionCode": "350102",
            "childrens": []
          }
        ]
      },
      {
        "id": 1765,
        "name": "厦门市",
        "level": 2,
        "regionCode": "350200",
        "childrens": [
          {
            "id": 1766,
            "name": "思明区",
            "level": 3,
            "regionCode": "350203",
            "childrens": []
          },
          {
            "id": 1767,
            "name": "海沧区",
            "level": 3,
            "regionCode": "350205",
            "childrens": []
          },
          {
            "id": 1768,
            "name": "湖里区",
            "level": 3,
            "regionCode": "350206",
            "childrens": []
          },
          {
            "id": 1769,
            "name": "集美区",
            "level": 3,
            "regionCode": "350211",
            "childrens": []
          },
          {
            "id": 1770,
            "name": "同安区",
            "level": 3,
            "regionCode": "350212",
            "childrens": []
          },
          {
            "id": 1771,
            "name": "翔安区",
            "level": 3,
            "regionCode": "350213",
            "childrens": []
          }
        ]
      },
      {
        "id": 1777,
        "name": "莆田市",
        "level": 2,
        "regionCode": "350300",
        "childrens": [
          {
            "id": 1778,
            "name": "城厢区",
            "level": 3,
            "regionCode": "350302",
            "childrens": []
          },
          {
            "id": 1779,
            "name": "涵江区",
            "level": 3,
            "regionCode": "350303",
            "childrens": []
          },
          {
            "id": 1780,
            "name": "荔城区",
            "level": 3,
            "regionCode": "350304",
            "childrens": []
          },
          {
            "id": 1781,
            "name": "秀屿区",
            "level": 3,
            "regionCode": "350305",
            "childrens": []
          },
          {
            "id": 1782,
            "name": "仙游县",
            "level": 3,
            "regionCode": "350322",
            "childrens": []
          }
        ]
      },
      {
        "id": 1788,
        "name": "三明市",
        "level": 2,
        "regionCode": "350400",
        "childrens": [
          {
            "id": 1790,
            "name": "三元区",
            "level": 3,
            "regionCode": "350403",
            "childrens": []
          },
          {
            "id": 1791,
            "name": "明溪县",
            "level": 3,
            "regionCode": "350421",
            "childrens": []
          },
          {
            "id": 1792,
            "name": "清流县",
            "level": 3,
            "regionCode": "350423",
            "childrens": []
          },
          {
            "id": 1793,
            "name": "宁化县",
            "level": 3,
            "regionCode": "350424",
            "childrens": []
          },
          {
            "id": 1794,
            "name": "大田县",
            "level": 3,
            "regionCode": "350425",
            "childrens": []
          },
          {
            "id": 1795,
            "name": "尤溪县",
            "level": 3,
            "regionCode": "350426",
            "childrens": []
          },
          {
            "id": 1796,
            "name": "沙县区",
            "level": 3,
            "regionCode": "350427",
            "childrens": []
          },
          {
            "id": 1797,
            "name": "将乐县",
            "level": 3,
            "regionCode": "350428",
            "childrens": []
          },
          {
            "id": 1798,
            "name": "泰宁县",
            "level": 3,
            "regionCode": "350429",
            "childrens": []
          },
          {
            "id": 1799,
            "name": "建宁县",
            "level": 3,
            "regionCode": "350430",
            "childrens": []
          },
          {
            "id": 1800,
            "name": "永安市",
            "level": 3,
            "regionCode": "350481",
            "childrens": []
          }
        ]
      },
      {
        "id": 1806,
        "name": "泉州市",
        "level": 2,
        "regionCode": "350500",
        "childrens": [
          {
            "id": 1807,
            "name": "鲤城区",
            "level": 3,
            "regionCode": "350502",
            "childrens": []
          },
          {
            "id": 1808,
            "name": "丰泽区",
            "level": 3,
            "regionCode": "350503",
            "childrens": []
          },
          {
            "id": 1809,
            "name": "洛江区",
            "level": 3,
            "regionCode": "350504",
            "childrens": []
          },
          {
            "id": 1810,
            "name": "泉港区",
            "level": 3,
            "regionCode": "350505",
            "childrens": []
          },
          {
            "id": 1811,
            "name": "惠安县",
            "level": 3,
            "regionCode": "350521",
            "childrens": []
          },
          {
            "id": 1812,
            "name": "安溪县",
            "level": 3,
            "regionCode": "350524",
            "childrens": []
          },
          {
            "id": 1813,
            "name": "永春县",
            "level": 3,
            "regionCode": "350525",
            "childrens": []
          },
          {
            "id": 1814,
            "name": "德化县",
            "level": 3,
            "regionCode": "350526",
            "childrens": []
          },
          {
            "id": 1816,
            "name": "石狮市",
            "level": 3,
            "regionCode": "350581",
            "childrens": []
          },
          {
            "id": 1817,
            "name": "晋江市",
            "level": 3,
            "regionCode": "350582",
            "childrens": []
          },
          {
            "id": 1818,
            "name": "南安市",
            "level": 3,
            "regionCode": "350583",
            "childrens": []
          },
          {
            "id": 5218,
            "name": "金门县",
            "level": 3,
            "regionCode": "350527",
            "childrens": []
          }
        ]
      },
      {
        "id": 1824,
        "name": "漳州市",
        "level": 2,
        "regionCode": "350600",
        "childrens": [
          {
            "id": 1825,
            "name": "芗城区",
            "level": 3,
            "regionCode": "350602",
            "childrens": []
          },
          {
            "id": 1826,
            "name": "龙文区",
            "level": 3,
            "regionCode": "350603",
            "childrens": []
          },
          {
            "id": 1827,
            "name": "云霄县",
            "level": 3,
            "regionCode": "350622",
            "childrens": []
          },
          {
            "id": 1828,
            "name": "漳浦县",
            "level": 3,
            "regionCode": "350623",
            "childrens": []
          },
          {
            "id": 1829,
            "name": "诏安县",
            "level": 3,
            "regionCode": "350624",
            "childrens": []
          },
          {
            "id": 1830,
            "name": "长泰区",
            "level": 3,
            "regionCode": "350625",
            "childrens": []
          },
          {
            "id": 1831,
            "name": "东山县",
            "level": 3,
            "regionCode": "350626",
            "childrens": []
          },
          {
            "id": 1832,
            "name": "南靖县",
            "level": 3,
            "regionCode": "350627",
            "childrens": []
          },
          {
            "id": 1833,
            "name": "平和县",
            "level": 3,
            "regionCode": "350628",
            "childrens": []
          },
          {
            "id": 1834,
            "name": "华安县",
            "level": 3,
            "regionCode": "350629",
            "childrens": []
          },
          {
            "id": 1835,
            "name": "龙海区",
            "level": 3,
            "regionCode": "350681",
            "childrens": []
          }
        ]
      },
      {
        "id": 1841,
        "name": "南平市",
        "level": 2,
        "regionCode": "350700",
        "childrens": [
          {
            "id": 1842,
            "name": "延平区",
            "level": 3,
            "regionCode": "350702",
            "childrens": []
          },
          {
            "id": 1843,
            "name": "顺昌县",
            "level": 3,
            "regionCode": "350721",
            "childrens": []
          },
          {
            "id": 1844,
            "name": "浦城县",
            "level": 3,
            "regionCode": "350722",
            "childrens": []
          },
          {
            "id": 1845,
            "name": "光泽县",
            "level": 3,
            "regionCode": "350723",
            "childrens": []
          },
          {
            "id": 1846,
            "name": "松溪县",
            "level": 3,
            "regionCode": "350724",
            "childrens": []
          },
          {
            "id": 1847,
            "name": "政和县",
            "level": 3,
            "regionCode": "350725",
            "childrens": []
          },
          {
            "id": 1848,
            "name": "邵武市",
            "level": 3,
            "regionCode": "350781",
            "childrens": []
          },
          {
            "id": 1849,
            "name": "武夷山市",
            "level": 3,
            "regionCode": "350782",
            "childrens": []
          },
          {
            "id": 1850,
            "name": "建瓯市",
            "level": 3,
            "regionCode": "350783",
            "childrens": []
          },
          {
            "id": 1851,
            "name": "建阳区",
            "level": 3,
            "regionCode": "350703",
            "childrens": []
          }
        ]
      },
      {
        "id": 1857,
        "name": "龙岩市",
        "level": 2,
        "regionCode": "350800",
        "childrens": [
          {
            "id": 1858,
            "name": "新罗区",
            "level": 3,
            "regionCode": "350802",
            "childrens": []
          },
          {
            "id": 1859,
            "name": "长汀县",
            "level": 3,
            "regionCode": "350821",
            "childrens": []
          },
          {
            "id": 1860,
            "name": "永定区",
            "level": 3,
            "regionCode": "350803",
            "childrens": []
          },
          {
            "id": 1861,
            "name": "上杭县",
            "level": 3,
            "regionCode": "350823",
            "childrens": []
          },
          {
            "id": 1862,
            "name": "武平县",
            "level": 3,
            "regionCode": "350824",
            "childrens": []
          },
          {
            "id": 1863,
            "name": "连城县",
            "level": 3,
            "regionCode": "350825",
            "childrens": []
          },
          {
            "id": 1864,
            "name": "漳平市",
            "level": 3,
            "regionCode": "350881",
            "childrens": []
          }
        ]
      },
      {
        "id": 1870,
        "name": "宁德市",
        "level": 2,
        "regionCode": "350900",
        "childrens": [
          {
            "id": 1871,
            "name": "蕉城区",
            "level": 3,
            "regionCode": "350902",
            "childrens": []
          },
          {
            "id": 1872,
            "name": "霞浦县",
            "level": 3,
            "regionCode": "350921",
            "childrens": []
          },
          {
            "id": 1873,
            "name": "古田县",
            "level": 3,
            "regionCode": "350922",
            "childrens": []
          },
          {
            "id": 1874,
            "name": "屏南县",
            "level": 3,
            "regionCode": "350923",
            "childrens": []
          },
          {
            "id": 1875,
            "name": "寿宁县",
            "level": 3,
            "regionCode": "350924",
            "childrens": []
          },
          {
            "id": 1876,
            "name": "周宁县",
            "level": 3,
            "regionCode": "350925",
            "childrens": []
          },
          {
            "id": 1877,
            "name": "柘荣县",
            "level": 3,
            "regionCode": "350926",
            "childrens": []
          },
          {
            "id": 1878,
            "name": "福安市",
            "level": 3,
            "regionCode": "350981",
            "childrens": []
          },
          {
            "id": 1879,
            "name": "福鼎市",
            "level": 3,
            "regionCode": "350982",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 1885,
    "name": "江西省",
    "level": 1,
    "regionCode": "360000",
    "childrens": [
      {
        "id": 1886,
        "name": "南昌市",
        "level": 2,
        "regionCode": "360100",
        "childrens": [
          {
            "id": 1887,
            "name": "东湖区",
            "level": 3,
            "regionCode": "360102",
            "childrens": []
          },
          {
            "id": 1888,
            "name": "西湖区",
            "level": 3,
            "regionCode": "360103",
            "childrens": []
          },
          {
            "id": 1889,
            "name": "青云谱区",
            "level": 3,
            "regionCode": "360104",
            "childrens": []
          },
          {
            "id": 1891,
            "name": "青山湖区",
            "level": 3,
            "regionCode": "360111",
            "childrens": []
          },
          {
            "id": 1892,
            "name": "南昌县",
            "level": 3,
            "regionCode": "360121",
            "childrens": []
          },
          {
            "id": 1893,
            "name": "新建区",
            "level": 3,
            "regionCode": "360112",
            "childrens": []
          },
          {
            "id": 1894,
            "name": "安义县",
            "level": 3,
            "regionCode": "360123",
            "childrens": []
          },
          {
            "id": 1895,
            "name": "进贤县",
            "level": 3,
            "regionCode": "360124",
            "childrens": []
          },
          {
            "id": 5219,
            "name": "红谷滩区",
            "level": 3,
            "regionCode": "360113",
            "childrens": []
          }
        ]
      },
      {
        "id": 1901,
        "name": "景德镇市",
        "level": 2,
        "regionCode": "360200",
        "childrens": [
          {
            "id": 1903,
            "name": "珠山区",
            "level": 3,
            "regionCode": "360203",
            "childrens": []
          },
          {
            "id": 1904,
            "name": "浮梁县",
            "level": 3,
            "regionCode": "360222",
            "childrens": []
          },
          {
            "id": 1905,
            "name": "乐平市",
            "level": 3,
            "regionCode": "360281",
            "childrens": []
          },
          {
            "id": 5220,
            "name": "昌江区",
            "level": 3,
            "regionCode": "360202",
            "childrens": []
          }
        ]
      },
      {
        "id": 1911,
        "name": "萍乡市",
        "level": 2,
        "regionCode": "360300",
        "childrens": [
          {
            "id": 1912,
            "name": "安源区",
            "level": 3,
            "regionCode": "360302",
            "childrens": []
          },
          {
            "id": 1913,
            "name": "湘东区",
            "level": 3,
            "regionCode": "360313",
            "childrens": []
          },
          {
            "id": 1914,
            "name": "莲花县",
            "level": 3,
            "regionCode": "360321",
            "childrens": []
          },
          {
            "id": 1915,
            "name": "上栗县",
            "level": 3,
            "regionCode": "360322",
            "childrens": []
          },
          {
            "id": 1916,
            "name": "芦溪县",
            "level": 3,
            "regionCode": "360323",
            "childrens": []
          }
        ]
      },
      {
        "id": 1922,
        "name": "九江市",
        "level": 2,
        "regionCode": "360400",
        "childrens": [
          {
            "id": 1923,
            "name": "庐山市",
            "level": 3,
            "regionCode": "360483",
            "childrens": []
          },
          {
            "id": 1924,
            "name": "浔阳区",
            "level": 3,
            "regionCode": "360403",
            "childrens": []
          },
          {
            "id": 1926,
            "name": "武宁县",
            "level": 3,
            "regionCode": "360423",
            "childrens": []
          },
          {
            "id": 1927,
            "name": "修水县",
            "level": 3,
            "regionCode": "360424",
            "childrens": []
          },
          {
            "id": 1928,
            "name": "永修县",
            "level": 3,
            "regionCode": "360425",
            "childrens": []
          },
          {
            "id": 1929,
            "name": "德安县",
            "level": 3,
            "regionCode": "360426",
            "childrens": []
          },
          {
            "id": 1931,
            "name": "都昌县",
            "level": 3,
            "regionCode": "360428",
            "childrens": []
          },
          {
            "id": 1932,
            "name": "湖口县",
            "level": 3,
            "regionCode": "360429",
            "childrens": []
          },
          {
            "id": 1933,
            "name": "彭泽县",
            "level": 3,
            "regionCode": "360430",
            "childrens": []
          },
          {
            "id": 1934,
            "name": "瑞昌市",
            "level": 3,
            "regionCode": "360481",
            "childrens": []
          },
          {
            "id": 5221,
            "name": "濂溪区",
            "level": 3,
            "regionCode": "360402",
            "childrens": []
          },
          {
            "id": 5222,
            "name": "柴桑区",
            "level": 3,
            "regionCode": "360404",
            "childrens": []
          },
          {
            "id": 5223,
            "name": "共青城市",
            "level": 3,
            "regionCode": "360482",
            "childrens": []
          }
        ]
      },
      {
        "id": 1940,
        "name": "新余市",
        "level": 2,
        "regionCode": "360500",
        "childrens": [
          {
            "id": 1941,
            "name": "渝水区",
            "level": 3,
            "regionCode": "360502",
            "childrens": []
          },
          {
            "id": 1942,
            "name": "分宜县",
            "level": 3,
            "regionCode": "360521",
            "childrens": []
          }
        ]
      },
      {
        "id": 1948,
        "name": "鹰潭市",
        "level": 2,
        "regionCode": "360600",
        "childrens": [
          {
            "id": 1949,
            "name": "月湖区",
            "level": 3,
            "regionCode": "360602",
            "childrens": []
          },
          {
            "id": 1950,
            "name": "余江区",
            "level": 3,
            "regionCode": "360603",
            "childrens": []
          },
          {
            "id": 1951,
            "name": "贵溪市",
            "level": 3,
            "regionCode": "360681",
            "childrens": []
          }
        ]
      },
      {
        "id": 1957,
        "name": "赣州市",
        "level": 2,
        "regionCode": "360700",
        "childrens": [
          {
            "id": 1958,
            "name": "章贡区",
            "level": 3,
            "regionCode": "360702",
            "childrens": []
          },
          {
            "id": 1959,
            "name": "赣县区",
            "level": 3,
            "regionCode": "360704",
            "childrens": []
          },
          {
            "id": 1960,
            "name": "信丰县",
            "level": 3,
            "regionCode": "360722",
            "childrens": []
          },
          {
            "id": 1961,
            "name": "大余县",
            "level": 3,
            "regionCode": "360723",
            "childrens": []
          },
          {
            "id": 1962,
            "name": "上犹县",
            "level": 3,
            "regionCode": "360724",
            "childrens": []
          },
          {
            "id": 1963,
            "name": "崇义县",
            "level": 3,
            "regionCode": "360725",
            "childrens": []
          },
          {
            "id": 1964,
            "name": "安远县",
            "level": 3,
            "regionCode": "360726",
            "childrens": []
          },
          {
            "id": 1965,
            "name": "龙南市",
            "level": 3,
            "regionCode": "360783",
            "childrens": []
          },
          {
            "id": 1966,
            "name": "定南县",
            "level": 3,
            "regionCode": "360728",
            "childrens": []
          },
          {
            "id": 1967,
            "name": "全南县",
            "level": 3,
            "regionCode": "360729",
            "childrens": []
          },
          {
            "id": 1968,
            "name": "宁都县",
            "level": 3,
            "regionCode": "360730",
            "childrens": []
          },
          {
            "id": 1969,
            "name": "于都县",
            "level": 3,
            "regionCode": "360731",
            "childrens": []
          },
          {
            "id": 1970,
            "name": "兴国县",
            "level": 3,
            "regionCode": "360732",
            "childrens": []
          },
          {
            "id": 1971,
            "name": "会昌县",
            "level": 3,
            "regionCode": "360733",
            "childrens": []
          },
          {
            "id": 1972,
            "name": "寻乌县",
            "level": 3,
            "regionCode": "360734",
            "childrens": []
          },
          {
            "id": 1973,
            "name": "石城县",
            "level": 3,
            "regionCode": "360735",
            "childrens": []
          },
          {
            "id": 1974,
            "name": "瑞金市",
            "level": 3,
            "regionCode": "360781",
            "childrens": []
          },
          {
            "id": 1975,
            "name": "南康区",
            "level": 3,
            "regionCode": "360703",
            "childrens": []
          }
        ]
      },
      {
        "id": 1981,
        "name": "吉安市",
        "level": 2,
        "regionCode": "360800",
        "childrens": [
          {
            "id": 1982,
            "name": "吉州区",
            "level": 3,
            "regionCode": "360802",
            "childrens": []
          },
          {
            "id": 1983,
            "name": "青原区",
            "level": 3,
            "regionCode": "360803",
            "childrens": []
          },
          {
            "id": 1984,
            "name": "吉安县",
            "level": 3,
            "regionCode": "360821",
            "childrens": []
          },
          {
            "id": 1985,
            "name": "吉水县",
            "level": 3,
            "regionCode": "360822",
            "childrens": []
          },
          {
            "id": 1986,
            "name": "峡江县",
            "level": 3,
            "regionCode": "360823",
            "childrens": []
          },
          {
            "id": 1987,
            "name": "新干县",
            "level": 3,
            "regionCode": "360824",
            "childrens": []
          },
          {
            "id": 1988,
            "name": "永丰县",
            "level": 3,
            "regionCode": "360825",
            "childrens": []
          },
          {
            "id": 1989,
            "name": "泰和县",
            "level": 3,
            "regionCode": "360826",
            "childrens": []
          },
          {
            "id": 1990,
            "name": "遂川县",
            "level": 3,
            "regionCode": "360827",
            "childrens": []
          },
          {
            "id": 1991,
            "name": "万安县",
            "level": 3,
            "regionCode": "360828",
            "childrens": []
          },
          {
            "id": 1992,
            "name": "安福县",
            "level": 3,
            "regionCode": "360829",
            "childrens": []
          },
          {
            "id": 1993,
            "name": "永新县",
            "level": 3,
            "regionCode": "360830",
            "childrens": []
          },
          {
            "id": 1994,
            "name": "井冈山市",
            "level": 3,
            "regionCode": "360881",
            "childrens": []
          }
        ]
      },
      {
        "id": 2000,
        "name": "宜春市",
        "level": 2,
        "regionCode": "360900",
        "childrens": [
          {
            "id": 2001,
            "name": "袁州区",
            "level": 3,
            "regionCode": "360902",
            "childrens": []
          },
          {
            "id": 2002,
            "name": "奉新县",
            "level": 3,
            "regionCode": "360921",
            "childrens": []
          },
          {
            "id": 2003,
            "name": "万载县",
            "level": 3,
            "regionCode": "360922",
            "childrens": []
          },
          {
            "id": 2004,
            "name": "上高县",
            "level": 3,
            "regionCode": "360923",
            "childrens": []
          },
          {
            "id": 2005,
            "name": "宜丰县",
            "level": 3,
            "regionCode": "360924",
            "childrens": []
          },
          {
            "id": 2006,
            "name": "靖安县",
            "level": 3,
            "regionCode": "360925",
            "childrens": []
          },
          {
            "id": 2007,
            "name": "铜鼓县",
            "level": 3,
            "regionCode": "360926",
            "childrens": []
          },
          {
            "id": 2008,
            "name": "丰城市",
            "level": 3,
            "regionCode": "360981",
            "childrens": []
          },
          {
            "id": 2009,
            "name": "樟树市",
            "level": 3,
            "regionCode": "360982",
            "childrens": []
          },
          {
            "id": 2010,
            "name": "高安市",
            "level": 3,
            "regionCode": "360983",
            "childrens": []
          }
        ]
      },
      {
        "id": 2016,
        "name": "抚州市",
        "level": 2,
        "regionCode": "361000",
        "childrens": [
          {
            "id": 2017,
            "name": "临川区",
            "level": 3,
            "regionCode": "361002",
            "childrens": []
          },
          {
            "id": 2018,
            "name": "南城县",
            "level": 3,
            "regionCode": "361021",
            "childrens": []
          },
          {
            "id": 2019,
            "name": "黎川县",
            "level": 3,
            "regionCode": "361022",
            "childrens": []
          },
          {
            "id": 2020,
            "name": "南丰县",
            "level": 3,
            "regionCode": "361023",
            "childrens": []
          },
          {
            "id": 2021,
            "name": "崇仁县",
            "level": 3,
            "regionCode": "361024",
            "childrens": []
          },
          {
            "id": 2022,
            "name": "乐安县",
            "level": 3,
            "regionCode": "361025",
            "childrens": []
          },
          {
            "id": 2023,
            "name": "宜黄县",
            "level": 3,
            "regionCode": "361026",
            "childrens": []
          },
          {
            "id": 2024,
            "name": "金溪县",
            "level": 3,
            "regionCode": "361027",
            "childrens": []
          },
          {
            "id": 2025,
            "name": "资溪县",
            "level": 3,
            "regionCode": "361028",
            "childrens": []
          },
          {
            "id": 2026,
            "name": "东乡区",
            "level": 3,
            "regionCode": "361003",
            "childrens": []
          },
          {
            "id": 2027,
            "name": "广昌县",
            "level": 3,
            "regionCode": "361030",
            "childrens": []
          }
        ]
      },
      {
        "id": 2033,
        "name": "上饶市",
        "level": 2,
        "regionCode": "361100",
        "childrens": [
          {
            "id": 2034,
            "name": "信州区",
            "level": 3,
            "regionCode": "361102",
            "childrens": []
          },
          {
            "id": 2036,
            "name": "广丰区",
            "level": 3,
            "regionCode": "361103",
            "childrens": []
          },
          {
            "id": 2037,
            "name": "玉山县",
            "level": 3,
            "regionCode": "361123",
            "childrens": []
          },
          {
            "id": 2038,
            "name": "铅山县",
            "level": 3,
            "regionCode": "361124",
            "childrens": []
          },
          {
            "id": 2039,
            "name": "横峰县",
            "level": 3,
            "regionCode": "361125",
            "childrens": []
          },
          {
            "id": 2040,
            "name": "弋阳县",
            "level": 3,
            "regionCode": "361126",
            "childrens": []
          },
          {
            "id": 2041,
            "name": "余干县",
            "level": 3,
            "regionCode": "361127",
            "childrens": []
          },
          {
            "id": 2042,
            "name": "鄱阳县",
            "level": 3,
            "regionCode": "361128",
            "childrens": []
          },
          {
            "id": 2043,
            "name": "万年县",
            "level": 3,
            "regionCode": "361129",
            "childrens": []
          },
          {
            "id": 2044,
            "name": "婺源县",
            "level": 3,
            "regionCode": "361130",
            "childrens": []
          },
          {
            "id": 2045,
            "name": "德兴市",
            "level": 3,
            "regionCode": "361181",
            "childrens": []
          },
          {
            "id": 5224,
            "name": "广信区",
            "level": 3,
            "regionCode": "361104",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 2051,
    "name": "山东省",
    "level": 1,
    "regionCode": "370000",
    "childrens": [
      {
        "id": 2052,
        "name": "济南市",
        "level": 2,
        "regionCode": "370100",
        "childrens": [
          {
            "id": 2053,
            "name": "历下区",
            "level": 3,
            "regionCode": "370102",
            "childrens": []
          },
          {
            "id": 2054,
            "name": "市中区",
            "level": 3,
            "regionCode": "370103",
            "childrens": []
          },
          {
            "id": 2055,
            "name": "槐荫区",
            "level": 3,
            "regionCode": "370104",
            "childrens": []
          },
          {
            "id": 2056,
            "name": "天桥区",
            "level": 3,
            "regionCode": "370105",
            "childrens": []
          },
          {
            "id": 2057,
            "name": "历城区",
            "level": 3,
            "regionCode": "370112",
            "childrens": []
          },
          {
            "id": 2058,
            "name": "长清区",
            "level": 3,
            "regionCode": "370113",
            "childrens": []
          },
          {
            "id": 2059,
            "name": "平阴县",
            "level": 3,
            "regionCode": "370124",
            "childrens": []
          },
          {
            "id": 2060,
            "name": "济阳区",
            "level": 3,
            "regionCode": "370115",
            "childrens": []
          },
          {
            "id": 2061,
            "name": "商河县",
            "level": 3,
            "regionCode": "370126",
            "childrens": []
          },
          {
            "id": 2062,
            "name": "章丘区",
            "level": 3,
            "regionCode": "370114",
            "childrens": []
          },
          {
            "id": 5225,
            "name": "莱芜区",
            "level": 3,
            "regionCode": "370116",
            "childrens": []
          },
          {
            "id": 5226,
            "name": "钢城区",
            "level": 3,
            "regionCode": "370117",
            "childrens": []
          }
        ]
      },
      {
        "id": 2068,
        "name": "青岛市",
        "level": 2,
        "regionCode": "370200",
        "childrens": [
          {
            "id": 2069,
            "name": "市南区",
            "level": 3,
            "regionCode": "370202",
            "childrens": []
          },
          {
            "id": 2070,
            "name": "市北区",
            "level": 3,
            "regionCode": "370203",
            "childrens": []
          },
          {
            "id": 2072,
            "name": "黄岛区",
            "level": 3,
            "regionCode": "370211",
            "childrens": []
          },
          {
            "id": 2073,
            "name": "崂山区",
            "level": 3,
            "regionCode": "370212",
            "childrens": []
          },
          {
            "id": 2074,
            "name": "李沧区",
            "level": 3,
            "regionCode": "370213",
            "childrens": []
          },
          {
            "id": 2075,
            "name": "城阳区",
            "level": 3,
            "regionCode": "370214",
            "childrens": []
          },
          {
            "id": 2076,
            "name": "胶州市",
            "level": 3,
            "regionCode": "370281",
            "childrens": []
          },
          {
            "id": 2077,
            "name": "即墨区",
            "level": 3,
            "regionCode": "370215",
            "childrens": []
          },
          {
            "id": 2078,
            "name": "平度市",
            "level": 3,
            "regionCode": "370283",
            "childrens": []
          },
          {
            "id": 2080,
            "name": "莱西市",
            "level": 3,
            "regionCode": "370285",
            "childrens": []
          }
        ]
      },
      {
        "id": 2086,
        "name": "淄博市",
        "level": 2,
        "regionCode": "370300",
        "childrens": [
          {
            "id": 2087,
            "name": "淄川区",
            "level": 3,
            "regionCode": "370302",
            "childrens": []
          },
          {
            "id": 2088,
            "name": "张店区",
            "level": 3,
            "regionCode": "370303",
            "childrens": []
          },
          {
            "id": 2089,
            "name": "博山区",
            "level": 3,
            "regionCode": "370304",
            "childrens": []
          },
          {
            "id": 2090,
            "name": "临淄区",
            "level": 3,
            "regionCode": "370305",
            "childrens": []
          },
          {
            "id": 2091,
            "name": "周村区",
            "level": 3,
            "regionCode": "370306",
            "childrens": []
          },
          {
            "id": 2092,
            "name": "桓台县",
            "level": 3,
            "regionCode": "370321",
            "childrens": []
          },
          {
            "id": 2093,
            "name": "高青县",
            "level": 3,
            "regionCode": "370322",
            "childrens": []
          },
          {
            "id": 2094,
            "name": "沂源县",
            "level": 3,
            "regionCode": "370323",
            "childrens": []
          }
        ]
      },
      {
        "id": 2100,
        "name": "枣庄市",
        "level": 2,
        "regionCode": "370400",
        "childrens": [
          {
            "id": 2102,
            "name": "薛城区",
            "level": 3,
            "regionCode": "370403",
            "childrens": []
          },
          {
            "id": 2103,
            "name": "峄城区",
            "level": 3,
            "regionCode": "370404",
            "childrens": []
          },
          {
            "id": 2104,
            "name": "台儿庄区",
            "level": 3,
            "regionCode": "370405",
            "childrens": []
          },
          {
            "id": 2105,
            "name": "山亭区",
            "level": 3,
            "regionCode": "370406",
            "childrens": []
          },
          {
            "id": 2106,
            "name": "滕州市",
            "level": 3,
            "regionCode": "370481",
            "childrens": []
          },
          {
            "id": 5227,
            "name": "市中区",
            "level": 3,
            "regionCode": "370402",
            "childrens": []
          }
        ]
      },
      {
        "id": 2112,
        "name": "东营市",
        "level": 2,
        "regionCode": "370500",
        "childrens": [
          {
            "id": 2113,
            "name": "东营区",
            "level": 3,
            "regionCode": "370502",
            "childrens": []
          },
          {
            "id": 2114,
            "name": "河口区",
            "level": 3,
            "regionCode": "370503",
            "childrens": []
          },
          {
            "id": 2115,
            "name": "垦利区",
            "level": 3,
            "regionCode": "370505",
            "childrens": []
          },
          {
            "id": 2116,
            "name": "利津县",
            "level": 3,
            "regionCode": "370522",
            "childrens": []
          },
          {
            "id": 2117,
            "name": "广饶县",
            "level": 3,
            "regionCode": "370523",
            "childrens": []
          }
        ]
      },
      {
        "id": 2123,
        "name": "烟台市",
        "level": 2,
        "regionCode": "370600",
        "childrens": [
          {
            "id": 2124,
            "name": "芝罘区",
            "level": 3,
            "regionCode": "370602",
            "childrens": []
          },
          {
            "id": 2125,
            "name": "福山区",
            "level": 3,
            "regionCode": "370611",
            "childrens": []
          },
          {
            "id": 2126,
            "name": "牟平区",
            "level": 3,
            "regionCode": "370612",
            "childrens": []
          },
          {
            "id": 2127,
            "name": "莱山区",
            "level": 3,
            "regionCode": "370613",
            "childrens": []
          },
          {
            "id": 2129,
            "name": "龙口市",
            "level": 3,
            "regionCode": "370681",
            "childrens": []
          },
          {
            "id": 2130,
            "name": "莱阳市",
            "level": 3,
            "regionCode": "370682",
            "childrens": []
          },
          {
            "id": 2131,
            "name": "莱州市",
            "level": 3,
            "regionCode": "370683",
            "childrens": []
          },
          {
            "id": 2132,
            "name": "蓬莱区",
            "level": 3,
            "regionCode": "370614",
            "childrens": []
          },
          {
            "id": 2133,
            "name": "招远市",
            "level": 3,
            "regionCode": "370685",
            "childrens": []
          },
          {
            "id": 2134,
            "name": "栖霞市",
            "level": 3,
            "regionCode": "370686",
            "childrens": []
          },
          {
            "id": 2135,
            "name": "海阳市",
            "level": 3,
            "regionCode": "370687",
            "childrens": []
          }
        ]
      },
      {
        "id": 2141,
        "name": "潍坊市",
        "level": 2,
        "regionCode": "370700",
        "childrens": [
          {
            "id": 2142,
            "name": "潍城区",
            "level": 3,
            "regionCode": "370702",
            "childrens": []
          },
          {
            "id": 2143,
            "name": "寒亭区",
            "level": 3,
            "regionCode": "370703",
            "childrens": []
          },
          {
            "id": 2144,
            "name": "坊子区",
            "level": 3,
            "regionCode": "370704",
            "childrens": []
          },
          {
            "id": 2145,
            "name": "奎文区",
            "level": 3,
            "regionCode": "370705",
            "childrens": []
          },
          {
            "id": 2146,
            "name": "临朐县",
            "level": 3,
            "regionCode": "370724",
            "childrens": []
          },
          {
            "id": 2147,
            "name": "昌乐县",
            "level": 3,
            "regionCode": "370725",
            "childrens": []
          },
          {
            "id": 2148,
            "name": "青州市",
            "level": 3,
            "regionCode": "370781",
            "childrens": []
          },
          {
            "id": 2149,
            "name": "诸城市",
            "level": 3,
            "regionCode": "370782",
            "childrens": []
          },
          {
            "id": 2150,
            "name": "寿光市",
            "level": 3,
            "regionCode": "370783",
            "childrens": []
          },
          {
            "id": 2151,
            "name": "安丘市",
            "level": 3,
            "regionCode": "370784",
            "childrens": []
          },
          {
            "id": 2152,
            "name": "高密市",
            "level": 3,
            "regionCode": "370785",
            "childrens": []
          },
          {
            "id": 2153,
            "name": "昌邑市",
            "level": 3,
            "regionCode": "370786",
            "childrens": []
          }
        ]
      },
      {
        "id": 2159,
        "name": "济宁市",
        "level": 2,
        "regionCode": "370800",
        "childrens": [
          {
            "id": 2161,
            "name": "任城区",
            "level": 3,
            "regionCode": "370811",
            "childrens": []
          },
          {
            "id": 2162,
            "name": "鱼台县",
            "level": 3,
            "regionCode": "370827",
            "childrens": []
          },
          {
            "id": 2163,
            "name": "金乡县",
            "level": 3,
            "regionCode": "370828",
            "childrens": []
          },
          {
            "id": 2164,
            "name": "嘉祥县",
            "level": 3,
            "regionCode": "370829",
            "childrens": []
          },
          {
            "id": 2165,
            "name": "汶上县",
            "level": 3,
            "regionCode": "370830",
            "childrens": []
          },
          {
            "id": 2166,
            "name": "泗水县",
            "level": 3,
            "regionCode": "370831",
            "childrens": []
          },
          {
            "id": 2167,
            "name": "梁山县",
            "level": 3,
            "regionCode": "370832",
            "childrens": []
          },
          {
            "id": 2168,
            "name": "曲阜市",
            "level": 3,
            "regionCode": "370881",
            "childrens": []
          },
          {
            "id": 2169,
            "name": "兖州区",
            "level": 3,
            "regionCode": "370812",
            "childrens": []
          },
          {
            "id": 2170,
            "name": "邹城市",
            "level": 3,
            "regionCode": "370883",
            "childrens": []
          },
          {
            "id": 5228,
            "name": "微山县",
            "level": 3,
            "regionCode": "370826",
            "childrens": []
          }
        ]
      },
      {
        "id": 2176,
        "name": "泰安市",
        "level": 2,
        "regionCode": "370900",
        "childrens": [
          {
            "id": 2177,
            "name": "泰山区",
            "level": 3,
            "regionCode": "370902",
            "childrens": []
          },
          {
            "id": 2178,
            "name": "岱岳区",
            "level": 3,
            "regionCode": "370911",
            "childrens": []
          },
          {
            "id": 2179,
            "name": "宁阳县",
            "level": 3,
            "regionCode": "370921",
            "childrens": []
          },
          {
            "id": 2180,
            "name": "东平县",
            "level": 3,
            "regionCode": "370923",
            "childrens": []
          },
          {
            "id": 2181,
            "name": "新泰市",
            "level": 3,
            "regionCode": "370982",
            "childrens": []
          },
          {
            "id": 2182,
            "name": "肥城市",
            "level": 3,
            "regionCode": "370983",
            "childrens": []
          }
        ]
      },
      {
        "id": 2188,
        "name": "威海市",
        "level": 2,
        "regionCode": "371000",
        "childrens": [
          {
            "id": 2189,
            "name": "环翠区",
            "level": 3,
            "regionCode": "371002",
            "childrens": []
          },
          {
            "id": 2190,
            "name": "文登区",
            "level": 3,
            "regionCode": "371003",
            "childrens": []
          },
          {
            "id": 2191,
            "name": "荣成市",
            "level": 3,
            "regionCode": "371082",
            "childrens": []
          },
          {
            "id": 2192,
            "name": "乳山市",
            "level": 3,
            "regionCode": "371083",
            "childrens": []
          }
        ]
      },
      {
        "id": 2198,
        "name": "日照市",
        "level": 2,
        "regionCode": "371100",
        "childrens": [
          {
            "id": 2199,
            "name": "东港区",
            "level": 3,
            "regionCode": "371102",
            "childrens": []
          },
          {
            "id": 2200,
            "name": "岚山区",
            "level": 3,
            "regionCode": "371103",
            "childrens": []
          },
          {
            "id": 2201,
            "name": "五莲县",
            "level": 3,
            "regionCode": "371121",
            "childrens": []
          },
          {
            "id": 2202,
            "name": "莒县",
            "level": 3,
            "regionCode": "371122",
            "childrens": []
          }
        ]
      },
      {
        "id": 2216,
        "name": "临沂市",
        "level": 2,
        "regionCode": "371300",
        "childrens": [
          {
            "id": 2218,
            "name": "兰山区",
            "level": 3,
            "regionCode": "371302",
            "childrens": []
          },
          {
            "id": 2219,
            "name": "罗庄区",
            "level": 3,
            "regionCode": "371311",
            "childrens": []
          },
          {
            "id": 2221,
            "name": "沂南县",
            "level": 3,
            "regionCode": "371321",
            "childrens": []
          },
          {
            "id": 2222,
            "name": "郯城县",
            "level": 3,
            "regionCode": "371322",
            "childrens": []
          },
          {
            "id": 2223,
            "name": "沂水县",
            "level": 3,
            "regionCode": "371323",
            "childrens": []
          },
          {
            "id": 2224,
            "name": "兰陵县",
            "level": 3,
            "regionCode": "371324",
            "childrens": []
          },
          {
            "id": 2225,
            "name": "费县",
            "level": 3,
            "regionCode": "371325",
            "childrens": []
          },
          {
            "id": 2226,
            "name": "平邑县",
            "level": 3,
            "regionCode": "371326",
            "childrens": []
          },
          {
            "id": 2227,
            "name": "莒南县",
            "level": 3,
            "regionCode": "371327",
            "childrens": []
          },
          {
            "id": 2228,
            "name": "蒙阴县",
            "level": 3,
            "regionCode": "371328",
            "childrens": []
          },
          {
            "id": 2229,
            "name": "临沭县",
            "level": 3,
            "regionCode": "371329",
            "childrens": []
          },
          {
            "id": 5229,
            "name": "河东区",
            "level": 3,
            "regionCode": "371312",
            "childrens": []
          }
        ]
      },
      {
        "id": 2235,
        "name": "德州市",
        "level": 2,
        "regionCode": "371400",
        "childrens": [
          {
            "id": 2236,
            "name": "德城区",
            "level": 3,
            "regionCode": "371402",
            "childrens": []
          },
          {
            "id": 2238,
            "name": "宁津县",
            "level": 3,
            "regionCode": "371422",
            "childrens": []
          },
          {
            "id": 2239,
            "name": "庆云县",
            "level": 3,
            "regionCode": "371423",
            "childrens": []
          },
          {
            "id": 2240,
            "name": "临邑县",
            "level": 3,
            "regionCode": "371424",
            "childrens": []
          },
          {
            "id": 2241,
            "name": "齐河县",
            "level": 3,
            "regionCode": "371425",
            "childrens": []
          },
          {
            "id": 2242,
            "name": "平原县",
            "level": 3,
            "regionCode": "371426",
            "childrens": []
          },
          {
            "id": 2243,
            "name": "夏津县",
            "level": 3,
            "regionCode": "371427",
            "childrens": []
          },
          {
            "id": 2244,
            "name": "武城县",
            "level": 3,
            "regionCode": "371428",
            "childrens": []
          },
          {
            "id": 2245,
            "name": "乐陵市",
            "level": 3,
            "regionCode": "371481",
            "childrens": []
          },
          {
            "id": 2246,
            "name": "禹城市",
            "level": 3,
            "regionCode": "371482",
            "childrens": []
          },
          {
            "id": 5230,
            "name": "陵城区",
            "level": 3,
            "regionCode": "371403",
            "childrens": []
          }
        ]
      },
      {
        "id": 2252,
        "name": "聊城市",
        "level": 2,
        "regionCode": "371500",
        "childrens": [
          {
            "id": 2253,
            "name": "东昌府区",
            "level": 3,
            "regionCode": "371502",
            "childrens": []
          },
          {
            "id": 2254,
            "name": "阳谷县",
            "level": 3,
            "regionCode": "371521",
            "childrens": []
          },
          {
            "id": 2255,
            "name": "莘县",
            "level": 3,
            "regionCode": "371522",
            "childrens": []
          },
          {
            "id": 2256,
            "name": "茌平区",
            "level": 3,
            "regionCode": "371503",
            "childrens": []
          },
          {
            "id": 2257,
            "name": "东阿县",
            "level": 3,
            "regionCode": "371524",
            "childrens": []
          },
          {
            "id": 2258,
            "name": "冠县",
            "level": 3,
            "regionCode": "371525",
            "childrens": []
          },
          {
            "id": 2259,
            "name": "高唐县",
            "level": 3,
            "regionCode": "371526",
            "childrens": []
          },
          {
            "id": 2260,
            "name": "临清市",
            "level": 3,
            "regionCode": "371581",
            "childrens": []
          }
        ]
      },
      {
        "id": 2266,
        "name": "滨州市",
        "level": 2,
        "regionCode": "371600",
        "childrens": [
          {
            "id": 2267,
            "name": "滨城区",
            "level": 3,
            "regionCode": "371602",
            "childrens": []
          },
          {
            "id": 2268,
            "name": "惠民县",
            "level": 3,
            "regionCode": "371621",
            "childrens": []
          },
          {
            "id": 2269,
            "name": "阳信县",
            "level": 3,
            "regionCode": "371622",
            "childrens": []
          },
          {
            "id": 2270,
            "name": "无棣县",
            "level": 3,
            "regionCode": "371623",
            "childrens": []
          },
          {
            "id": 2271,
            "name": "沾化区",
            "level": 3,
            "regionCode": "371603",
            "childrens": []
          },
          {
            "id": 2272,
            "name": "博兴县",
            "level": 3,
            "regionCode": "371625",
            "childrens": []
          },
          {
            "id": 2273,
            "name": "邹平市",
            "level": 3,
            "regionCode": "371681",
            "childrens": []
          }
        ]
      },
      {
        "id": 2279,
        "name": "菏泽市",
        "level": 2,
        "regionCode": "371700",
        "childrens": [
          {
            "id": 2280,
            "name": "牡丹区",
            "level": 3,
            "regionCode": "371702",
            "childrens": []
          },
          {
            "id": 2281,
            "name": "曹县",
            "level": 3,
            "regionCode": "371721",
            "childrens": []
          },
          {
            "id": 2282,
            "name": "单县",
            "level": 3,
            "regionCode": "371722",
            "childrens": []
          },
          {
            "id": 2283,
            "name": "成武县",
            "level": 3,
            "regionCode": "371723",
            "childrens": []
          },
          {
            "id": 2284,
            "name": "巨野县",
            "level": 3,
            "regionCode": "371724",
            "childrens": []
          },
          {
            "id": 2285,
            "name": "郓城县",
            "level": 3,
            "regionCode": "371725",
            "childrens": []
          },
          {
            "id": 2286,
            "name": "鄄城县",
            "level": 3,
            "regionCode": "371726",
            "childrens": []
          },
          {
            "id": 2287,
            "name": "定陶区",
            "level": 3,
            "regionCode": "371703",
            "childrens": []
          },
          {
            "id": 2288,
            "name": "东明县",
            "level": 3,
            "regionCode": "371728",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 2294,
    "name": "河南省",
    "level": 1,
    "regionCode": "410000",
    "childrens": [
      {
        "id": 2295,
        "name": "郑州市",
        "level": 2,
        "regionCode": "410100",
        "childrens": [
          {
            "id": 2296,
            "name": "中原区",
            "level": 3,
            "regionCode": "410102",
            "childrens": []
          },
          {
            "id": 2297,
            "name": "二七区",
            "level": 3,
            "regionCode": "410103",
            "childrens": []
          },
          {
            "id": 2298,
            "name": "管城回族区",
            "level": 3,
            "regionCode": "410104",
            "childrens": []
          },
          {
            "id": 2299,
            "name": "金水区",
            "level": 3,
            "regionCode": "410105",
            "childrens": []
          },
          {
            "id": 2300,
            "name": "上街区",
            "level": 3,
            "regionCode": "410106",
            "childrens": []
          },
          {
            "id": 2301,
            "name": "惠济区",
            "level": 3,
            "regionCode": "410108",
            "childrens": []
          },
          {
            "id": 2302,
            "name": "中牟县",
            "level": 3,
            "regionCode": "410122",
            "childrens": []
          },
          {
            "id": 2303,
            "name": "巩义市",
            "level": 3,
            "regionCode": "410181",
            "childrens": []
          },
          {
            "id": 2304,
            "name": "荥阳市",
            "level": 3,
            "regionCode": "410182",
            "childrens": []
          },
          {
            "id": 2305,
            "name": "新密市",
            "level": 3,
            "regionCode": "410183",
            "childrens": []
          },
          {
            "id": 2306,
            "name": "新郑市",
            "level": 3,
            "regionCode": "410184",
            "childrens": []
          },
          {
            "id": 2307,
            "name": "登封市",
            "level": 3,
            "regionCode": "410185",
            "childrens": []
          }
        ]
      },
      {
        "id": 2313,
        "name": "开封市",
        "level": 2,
        "regionCode": "410200",
        "childrens": [
          {
            "id": 2314,
            "name": "龙亭区",
            "level": 3,
            "regionCode": "410202",
            "childrens": []
          },
          {
            "id": 2315,
            "name": "顺河回族区",
            "level": 3,
            "regionCode": "410203",
            "childrens": []
          },
          {
            "id": 2316,
            "name": "鼓楼区",
            "level": 3,
            "regionCode": "410204",
            "childrens": []
          },
          {
            "id": 2317,
            "name": "禹王台区",
            "level": 3,
            "regionCode": "410205",
            "childrens": []
          },
          {
            "id": 2319,
            "name": "杞县",
            "level": 3,
            "regionCode": "410221",
            "childrens": []
          },
          {
            "id": 2320,
            "name": "通许县",
            "level": 3,
            "regionCode": "410222",
            "childrens": []
          },
          {
            "id": 2321,
            "name": "尉氏县",
            "level": 3,
            "regionCode": "410223",
            "childrens": []
          },
          {
            "id": 2323,
            "name": "兰考县",
            "level": 3,
            "regionCode": "410225",
            "childrens": []
          },
          {
            "id": 5231,
            "name": "祥符区",
            "level": 3,
            "regionCode": "410212",
            "childrens": []
          }
        ]
      },
      {
        "id": 2329,
        "name": "洛阳市",
        "level": 2,
        "regionCode": "410300",
        "childrens": [
          {
            "id": 2330,
            "name": "老城区",
            "level": 3,
            "regionCode": "410302",
            "childrens": []
          },
          {
            "id": 2331,
            "name": "西工区",
            "level": 3,
            "regionCode": "410303",
            "childrens": []
          },
          {
            "id": 2333,
            "name": "涧西区",
            "level": 3,
            "regionCode": "410305",
            "childrens": []
          },
          {
            "id": 2335,
            "name": "洛龙区",
            "level": 3,
            "regionCode": "410311",
            "childrens": []
          },
          {
            "id": 2336,
            "name": "孟津区",
            "level": 3,
            "regionCode": "410306",
            "childrens": []
          },
          {
            "id": 2337,
            "name": "新安县",
            "level": 3,
            "regionCode": "410323",
            "childrens": []
          },
          {
            "id": 2338,
            "name": "栾川县",
            "level": 3,
            "regionCode": "410324",
            "childrens": []
          },
          {
            "id": 2339,
            "name": "嵩县",
            "level": 3,
            "regionCode": "410325",
            "childrens": []
          },
          {
            "id": 2340,
            "name": "汝阳县",
            "level": 3,
            "regionCode": "410326",
            "childrens": []
          },
          {
            "id": 2341,
            "name": "宜阳县",
            "level": 3,
            "regionCode": "410327",
            "childrens": []
          },
          {
            "id": 2342,
            "name": "洛宁县",
            "level": 3,
            "regionCode": "410328",
            "childrens": []
          },
          {
            "id": 2343,
            "name": "伊川县",
            "level": 3,
            "regionCode": "410329",
            "childrens": []
          },
          {
            "id": 2344,
            "name": "偃师区",
            "level": 3,
            "regionCode": "410381",
            "childrens": []
          },
          {
            "id": 5232,
            "name": "瀍河回族区",
            "level": 3,
            "regionCode": "410304",
            "childrens": []
          }
        ]
      },
      {
        "id": 2350,
        "name": "平顶山市",
        "level": 2,
        "regionCode": "410400",
        "childrens": [
          {
            "id": 2352,
            "name": "卫东区",
            "level": 3,
            "regionCode": "410403",
            "childrens": []
          },
          {
            "id": 2353,
            "name": "石龙区",
            "level": 3,
            "regionCode": "410404",
            "childrens": []
          },
          {
            "id": 2354,
            "name": "湛河区",
            "level": 3,
            "regionCode": "410411",
            "childrens": []
          },
          {
            "id": 2355,
            "name": "宝丰县",
            "level": 3,
            "regionCode": "410421",
            "childrens": []
          },
          {
            "id": 2356,
            "name": "叶县",
            "level": 3,
            "regionCode": "410422",
            "childrens": []
          },
          {
            "id": 2357,
            "name": "鲁山县",
            "level": 3,
            "regionCode": "410423",
            "childrens": []
          },
          {
            "id": 2358,
            "name": "郏县",
            "level": 3,
            "regionCode": "410425",
            "childrens": []
          },
          {
            "id": 2359,
            "name": "舞钢市",
            "level": 3,
            "regionCode": "410481",
            "childrens": []
          },
          {
            "id": 2360,
            "name": "汝州市",
            "level": 3,
            "regionCode": "410482",
            "childrens": []
          },
          {
            "id": 5233,
            "name": "新华区",
            "level": 3,
            "regionCode": "410402",
            "childrens": []
          }
        ]
      },
      {
        "id": 2366,
        "name": "安阳市",
        "level": 2,
        "regionCode": "410500",
        "childrens": [
          {
            "id": 2367,
            "name": "文峰区",
            "level": 3,
            "regionCode": "410502",
            "childrens": []
          },
          {
            "id": 2368,
            "name": "北关区",
            "level": 3,
            "regionCode": "410503",
            "childrens": []
          },
          {
            "id": 2369,
            "name": "殷都区",
            "level": 3,
            "regionCode": "410505",
            "childrens": []
          },
          {
            "id": 2370,
            "name": "龙安区",
            "level": 3,
            "regionCode": "410506",
            "childrens": []
          },
          {
            "id": 2371,
            "name": "安阳县",
            "level": 3,
            "regionCode": "410522",
            "childrens": []
          },
          {
            "id": 2372,
            "name": "汤阴县",
            "level": 3,
            "regionCode": "410523",
            "childrens": []
          },
          {
            "id": 2373,
            "name": "滑县",
            "level": 3,
            "regionCode": "410526",
            "childrens": []
          },
          {
            "id": 2374,
            "name": "内黄县",
            "level": 3,
            "regionCode": "410527",
            "childrens": []
          },
          {
            "id": 2375,
            "name": "林州市",
            "level": 3,
            "regionCode": "410581",
            "childrens": []
          }
        ]
      },
      {
        "id": 2381,
        "name": "鹤壁市",
        "level": 2,
        "regionCode": "410600",
        "childrens": [
          {
            "id": 2382,
            "name": "鹤山区",
            "level": 3,
            "regionCode": "410602",
            "childrens": []
          },
          {
            "id": 2383,
            "name": "山城区",
            "level": 3,
            "regionCode": "410603",
            "childrens": []
          },
          {
            "id": 2384,
            "name": "淇滨区",
            "level": 3,
            "regionCode": "410611",
            "childrens": []
          },
          {
            "id": 2385,
            "name": "浚县",
            "level": 3,
            "regionCode": "410621",
            "childrens": []
          },
          {
            "id": 2386,
            "name": "淇县",
            "level": 3,
            "regionCode": "410622",
            "childrens": []
          }
        ]
      },
      {
        "id": 2392,
        "name": "新乡市",
        "level": 2,
        "regionCode": "410700",
        "childrens": [
          {
            "id": 2393,
            "name": "红旗区",
            "level": 3,
            "regionCode": "410702",
            "childrens": []
          },
          {
            "id": 2394,
            "name": "卫滨区",
            "level": 3,
            "regionCode": "410703",
            "childrens": []
          },
          {
            "id": 2395,
            "name": "凤泉区",
            "level": 3,
            "regionCode": "410704",
            "childrens": []
          },
          {
            "id": 2396,
            "name": "牧野区",
            "level": 3,
            "regionCode": "410711",
            "childrens": []
          },
          {
            "id": 2397,
            "name": "新乡县",
            "level": 3,
            "regionCode": "410721",
            "childrens": []
          },
          {
            "id": 2398,
            "name": "获嘉县",
            "level": 3,
            "regionCode": "410724",
            "childrens": []
          },
          {
            "id": 2399,
            "name": "原阳县",
            "level": 3,
            "regionCode": "410725",
            "childrens": []
          },
          {
            "id": 2400,
            "name": "延津县",
            "level": 3,
            "regionCode": "410726",
            "childrens": []
          },
          {
            "id": 2401,
            "name": "封丘县",
            "level": 3,
            "regionCode": "410727",
            "childrens": []
          },
          {
            "id": 2402,
            "name": "长垣市",
            "level": 3,
            "regionCode": "410783",
            "childrens": []
          },
          {
            "id": 2403,
            "name": "卫辉市",
            "level": 3,
            "regionCode": "410781",
            "childrens": []
          },
          {
            "id": 2404,
            "name": "辉县市",
            "level": 3,
            "regionCode": "410782",
            "childrens": []
          }
        ]
      },
      {
        "id": 2410,
        "name": "焦作市",
        "level": 2,
        "regionCode": "410800",
        "childrens": [
          {
            "id": 2411,
            "name": "解放区",
            "level": 3,
            "regionCode": "410802",
            "childrens": []
          },
          {
            "id": 2412,
            "name": "中站区",
            "level": 3,
            "regionCode": "410803",
            "childrens": []
          },
          {
            "id": 2413,
            "name": "马村区",
            "level": 3,
            "regionCode": "410804",
            "childrens": []
          },
          {
            "id": 2414,
            "name": "山阳区",
            "level": 3,
            "regionCode": "410811",
            "childrens": []
          },
          {
            "id": 2415,
            "name": "修武县",
            "level": 3,
            "regionCode": "410821",
            "childrens": []
          },
          {
            "id": 2416,
            "name": "博爱县",
            "level": 3,
            "regionCode": "410822",
            "childrens": []
          },
          {
            "id": 2417,
            "name": "武陟县",
            "level": 3,
            "regionCode": "410823",
            "childrens": []
          },
          {
            "id": 2418,
            "name": "温县",
            "level": 3,
            "regionCode": "410825",
            "childrens": []
          },
          {
            "id": 2420,
            "name": "沁阳市",
            "level": 3,
            "regionCode": "410882",
            "childrens": []
          },
          {
            "id": 2421,
            "name": "孟州市",
            "level": 3,
            "regionCode": "410883",
            "childrens": []
          }
        ]
      },
      {
        "id": 2427,
        "name": "濮阳市",
        "level": 2,
        "regionCode": "410900",
        "childrens": [
          {
            "id": 2428,
            "name": "华龙区",
            "level": 3,
            "regionCode": "410902",
            "childrens": []
          },
          {
            "id": 2429,
            "name": "清丰县",
            "level": 3,
            "regionCode": "410922",
            "childrens": []
          },
          {
            "id": 2430,
            "name": "南乐县",
            "level": 3,
            "regionCode": "410923",
            "childrens": []
          },
          {
            "id": 2431,
            "name": "范县",
            "level": 3,
            "regionCode": "410926",
            "childrens": []
          },
          {
            "id": 2432,
            "name": "台前县",
            "level": 3,
            "regionCode": "410927",
            "childrens": []
          },
          {
            "id": 2433,
            "name": "濮阳县",
            "level": 3,
            "regionCode": "410928",
            "childrens": []
          }
        ]
      },
      {
        "id": 2439,
        "name": "许昌市",
        "level": 2,
        "regionCode": "411000",
        "childrens": [
          {
            "id": 2440,
            "name": "魏都区",
            "level": 3,
            "regionCode": "411002",
            "childrens": []
          },
          {
            "id": 2442,
            "name": "鄢陵县",
            "level": 3,
            "regionCode": "411024",
            "childrens": []
          },
          {
            "id": 2443,
            "name": "襄城县",
            "level": 3,
            "regionCode": "411025",
            "childrens": []
          },
          {
            "id": 2444,
            "name": "禹州市",
            "level": 3,
            "regionCode": "411081",
            "childrens": []
          },
          {
            "id": 2445,
            "name": "长葛市",
            "level": 3,
            "regionCode": "411082",
            "childrens": []
          },
          {
            "id": 5234,
            "name": "建安区",
            "level": 3,
            "regionCode": "411003",
            "childrens": []
          }
        ]
      },
      {
        "id": 2451,
        "name": "漯河市",
        "level": 2,
        "regionCode": "411100",
        "childrens": [
          {
            "id": 2452,
            "name": "源汇区",
            "level": 3,
            "regionCode": "411102",
            "childrens": []
          },
          {
            "id": 2453,
            "name": "郾城区",
            "level": 3,
            "regionCode": "411103",
            "childrens": []
          },
          {
            "id": 2454,
            "name": "召陵区",
            "level": 3,
            "regionCode": "411104",
            "childrens": []
          },
          {
            "id": 2455,
            "name": "舞阳县",
            "level": 3,
            "regionCode": "411121",
            "childrens": []
          },
          {
            "id": 5235,
            "name": "临颍县",
            "level": 3,
            "regionCode": "411122",
            "childrens": []
          }
        ]
      },
      {
        "id": 2462,
        "name": "三门峡市",
        "level": 2,
        "regionCode": "411200",
        "childrens": [
          {
            "id": 2463,
            "name": "湖滨区",
            "level": 3,
            "regionCode": "411202",
            "childrens": []
          },
          {
            "id": 2464,
            "name": "渑池县",
            "level": 3,
            "regionCode": "411221",
            "childrens": []
          },
          {
            "id": 2466,
            "name": "卢氏县",
            "level": 3,
            "regionCode": "411224",
            "childrens": []
          },
          {
            "id": 2467,
            "name": "义马市",
            "level": 3,
            "regionCode": "411281",
            "childrens": []
          },
          {
            "id": 2468,
            "name": "灵宝市",
            "level": 3,
            "regionCode": "411282",
            "childrens": []
          },
          {
            "id": 5236,
            "name": "陕州区",
            "level": 3,
            "regionCode": "411203",
            "childrens": []
          }
        ]
      },
      {
        "id": 2474,
        "name": "南阳市",
        "level": 2,
        "regionCode": "411300",
        "childrens": [
          {
            "id": 2475,
            "name": "宛城区",
            "level": 3,
            "regionCode": "411302",
            "childrens": []
          },
          {
            "id": 2476,
            "name": "卧龙区",
            "level": 3,
            "regionCode": "411303",
            "childrens": []
          },
          {
            "id": 2477,
            "name": "南召县",
            "level": 3,
            "regionCode": "411321",
            "childrens": []
          },
          {
            "id": 2478,
            "name": "方城县",
            "level": 3,
            "regionCode": "411322",
            "childrens": []
          },
          {
            "id": 2479,
            "name": "西峡县",
            "level": 3,
            "regionCode": "411323",
            "childrens": []
          },
          {
            "id": 2480,
            "name": "镇平县",
            "level": 3,
            "regionCode": "411324",
            "childrens": []
          },
          {
            "id": 2481,
            "name": "内乡县",
            "level": 3,
            "regionCode": "411325",
            "childrens": []
          },
          {
            "id": 2482,
            "name": "淅川县",
            "level": 3,
            "regionCode": "411326",
            "childrens": []
          },
          {
            "id": 2483,
            "name": "社旗县",
            "level": 3,
            "regionCode": "411327",
            "childrens": []
          },
          {
            "id": 2484,
            "name": "唐河县",
            "level": 3,
            "regionCode": "411328",
            "childrens": []
          },
          {
            "id": 2485,
            "name": "新野县",
            "level": 3,
            "regionCode": "411329",
            "childrens": []
          },
          {
            "id": 2486,
            "name": "桐柏县",
            "level": 3,
            "regionCode": "411330",
            "childrens": []
          },
          {
            "id": 2487,
            "name": "邓州市",
            "level": 3,
            "regionCode": "411381",
            "childrens": []
          }
        ]
      },
      {
        "id": 2493,
        "name": "商丘市",
        "level": 2,
        "regionCode": "411400",
        "childrens": [
          {
            "id": 2494,
            "name": "梁园区",
            "level": 3,
            "regionCode": "411402",
            "childrens": []
          },
          {
            "id": 2495,
            "name": "睢阳区",
            "level": 3,
            "regionCode": "411403",
            "childrens": []
          },
          {
            "id": 2496,
            "name": "民权县",
            "level": 3,
            "regionCode": "411421",
            "childrens": []
          },
          {
            "id": 2497,
            "name": "睢县",
            "level": 3,
            "regionCode": "411422",
            "childrens": []
          },
          {
            "id": 2498,
            "name": "宁陵县",
            "level": 3,
            "regionCode": "411423",
            "childrens": []
          },
          {
            "id": 2499,
            "name": "柘城县",
            "level": 3,
            "regionCode": "411424",
            "childrens": []
          },
          {
            "id": 2500,
            "name": "虞城县",
            "level": 3,
            "regionCode": "411425",
            "childrens": []
          },
          {
            "id": 2501,
            "name": "夏邑县",
            "level": 3,
            "regionCode": "411426",
            "childrens": []
          },
          {
            "id": 2502,
            "name": "永城市",
            "level": 3,
            "regionCode": "411481",
            "childrens": []
          }
        ]
      },
      {
        "id": 2508,
        "name": "信阳市",
        "level": 2,
        "regionCode": "411500",
        "childrens": [
          {
            "id": 2509,
            "name": "浉河区",
            "level": 3,
            "regionCode": "411502",
            "childrens": []
          },
          {
            "id": 2510,
            "name": "平桥区",
            "level": 3,
            "regionCode": "411503",
            "childrens": []
          },
          {
            "id": 2511,
            "name": "罗山县",
            "level": 3,
            "regionCode": "411521",
            "childrens": []
          },
          {
            "id": 2512,
            "name": "光山县",
            "level": 3,
            "regionCode": "411522",
            "childrens": []
          },
          {
            "id": 2513,
            "name": "新县",
            "level": 3,
            "regionCode": "411523",
            "childrens": []
          },
          {
            "id": 2514,
            "name": "商城县",
            "level": 3,
            "regionCode": "411524",
            "childrens": []
          },
          {
            "id": 2515,
            "name": "固始县",
            "level": 3,
            "regionCode": "411525",
            "childrens": []
          },
          {
            "id": 2516,
            "name": "潢川县",
            "level": 3,
            "regionCode": "411526",
            "childrens": []
          },
          {
            "id": 2517,
            "name": "淮滨县",
            "level": 3,
            "regionCode": "411527",
            "childrens": []
          },
          {
            "id": 2518,
            "name": "息县",
            "level": 3,
            "regionCode": "411528",
            "childrens": []
          }
        ]
      },
      {
        "id": 2524,
        "name": "周口市",
        "level": 2,
        "regionCode": "411600",
        "childrens": [
          {
            "id": 2525,
            "name": "川汇区",
            "level": 3,
            "regionCode": "411602",
            "childrens": []
          },
          {
            "id": 2526,
            "name": "扶沟县",
            "level": 3,
            "regionCode": "411621",
            "childrens": []
          },
          {
            "id": 2527,
            "name": "西华县",
            "level": 3,
            "regionCode": "411622",
            "childrens": []
          },
          {
            "id": 2528,
            "name": "商水县",
            "level": 3,
            "regionCode": "411623",
            "childrens": []
          },
          {
            "id": 2529,
            "name": "沈丘县",
            "level": 3,
            "regionCode": "411624",
            "childrens": []
          },
          {
            "id": 2530,
            "name": "郸城县",
            "level": 3,
            "regionCode": "411625",
            "childrens": []
          },
          {
            "id": 2531,
            "name": "淮阳区",
            "level": 3,
            "regionCode": "411603",
            "childrens": []
          },
          {
            "id": 2532,
            "name": "太康县",
            "level": 3,
            "regionCode": "411627",
            "childrens": []
          },
          {
            "id": 2533,
            "name": "鹿邑县",
            "level": 3,
            "regionCode": "411628",
            "childrens": []
          },
          {
            "id": 2534,
            "name": "项城市",
            "level": 3,
            "regionCode": "411681",
            "childrens": []
          }
        ]
      },
      {
        "id": 2540,
        "name": "驻马店市",
        "level": 2,
        "regionCode": "411700",
        "childrens": [
          {
            "id": 2541,
            "name": "驿城区",
            "level": 3,
            "regionCode": "411702",
            "childrens": []
          },
          {
            "id": 2542,
            "name": "西平县",
            "level": 3,
            "regionCode": "411721",
            "childrens": []
          },
          {
            "id": 2543,
            "name": "上蔡县",
            "level": 3,
            "regionCode": "411722",
            "childrens": []
          },
          {
            "id": 2544,
            "name": "平舆县",
            "level": 3,
            "regionCode": "411723",
            "childrens": []
          },
          {
            "id": 2545,
            "name": "正阳县",
            "level": 3,
            "regionCode": "411724",
            "childrens": []
          },
          {
            "id": 2546,
            "name": "确山县",
            "level": 3,
            "regionCode": "411725",
            "childrens": []
          },
          {
            "id": 2547,
            "name": "泌阳县",
            "level": 3,
            "regionCode": "411726",
            "childrens": []
          },
          {
            "id": 2548,
            "name": "汝南县",
            "level": 3,
            "regionCode": "411727",
            "childrens": []
          },
          {
            "id": 2549,
            "name": "遂平县",
            "level": 3,
            "regionCode": "411728",
            "childrens": []
          },
          {
            "id": 2550,
            "name": "新蔡县",
            "level": 3,
            "regionCode": "411729",
            "childrens": []
          }
        ]
      },
      {
        "id": 4964,
        "name": "济源市",
        "level": 2,
        "regionCode": "419001",
        "childrens": []
      }
    ]
  },
  {
    "id": 2556,
    "name": "湖北省",
    "level": 1,
    "regionCode": "420000",
    "childrens": [
      {
        "id": 2557,
        "name": "武汉市",
        "level": 2,
        "regionCode": "420100",
        "childrens": [
          {
            "id": 2558,
            "name": "江岸区",
            "level": 3,
            "regionCode": "420102",
            "childrens": []
          },
          {
            "id": 2559,
            "name": "江汉区",
            "level": 3,
            "regionCode": "420103",
            "childrens": []
          },
          {
            "id": 2560,
            "name": "硚口区",
            "level": 3,
            "regionCode": "420104",
            "childrens": []
          },
          {
            "id": 2561,
            "name": "汉阳区",
            "level": 3,
            "regionCode": "420105",
            "childrens": []
          },
          {
            "id": 2562,
            "name": "武昌区",
            "level": 3,
            "regionCode": "420106",
            "childrens": []
          },
          {
            "id": 2563,
            "name": "青山区",
            "level": 3,
            "regionCode": "420107",
            "childrens": []
          },
          {
            "id": 2564,
            "name": "洪山区",
            "level": 3,
            "regionCode": "420111",
            "childrens": []
          },
          {
            "id": 2565,
            "name": "东西湖区",
            "level": 3,
            "regionCode": "420112",
            "childrens": []
          },
          {
            "id": 2566,
            "name": "汉南区",
            "level": 3,
            "regionCode": "420113",
            "childrens": []
          },
          {
            "id": 2567,
            "name": "蔡甸区",
            "level": 3,
            "regionCode": "420114",
            "childrens": []
          },
          {
            "id": 2568,
            "name": "江夏区",
            "level": 3,
            "regionCode": "420115",
            "childrens": []
          },
          {
            "id": 2569,
            "name": "黄陂区",
            "level": 3,
            "regionCode": "420116",
            "childrens": []
          },
          {
            "id": 5237,
            "name": "新洲区",
            "level": 3,
            "regionCode": "420117",
            "childrens": []
          }
        ]
      },
      {
        "id": 2576,
        "name": "黄石市",
        "level": 2,
        "regionCode": "420200",
        "childrens": [
          {
            "id": 2577,
            "name": "黄石港区",
            "level": 3,
            "regionCode": "420202",
            "childrens": []
          },
          {
            "id": 2578,
            "name": "西塞山区",
            "level": 3,
            "regionCode": "420203",
            "childrens": []
          },
          {
            "id": 2579,
            "name": "下陆区",
            "level": 3,
            "regionCode": "420204",
            "childrens": []
          },
          {
            "id": 2580,
            "name": "铁山区",
            "level": 3,
            "regionCode": "420205",
            "childrens": []
          },
          {
            "id": 2581,
            "name": "阳新县",
            "level": 3,
            "regionCode": "420222",
            "childrens": []
          },
          {
            "id": 2582,
            "name": "大冶市",
            "level": 3,
            "regionCode": "420281",
            "childrens": []
          }
        ]
      },
      {
        "id": 2588,
        "name": "十堰市",
        "level": 2,
        "regionCode": "420300",
        "childrens": [
          {
            "id": 2589,
            "name": "茅箭区",
            "level": 3,
            "regionCode": "420302",
            "childrens": []
          },
          {
            "id": 2590,
            "name": "张湾区",
            "level": 3,
            "regionCode": "420303",
            "childrens": []
          },
          {
            "id": 2592,
            "name": "郧西县",
            "level": 3,
            "regionCode": "420322",
            "childrens": []
          },
          {
            "id": 2593,
            "name": "竹山县",
            "level": 3,
            "regionCode": "420323",
            "childrens": []
          },
          {
            "id": 2594,
            "name": "竹溪县",
            "level": 3,
            "regionCode": "420324",
            "childrens": []
          },
          {
            "id": 2595,
            "name": "房县",
            "level": 3,
            "regionCode": "420325",
            "childrens": []
          },
          {
            "id": 2596,
            "name": "丹江口市",
            "level": 3,
            "regionCode": "420381",
            "childrens": []
          },
          {
            "id": 5238,
            "name": "郧阳区",
            "level": 3,
            "regionCode": "420304",
            "childrens": []
          }
        ]
      },
      {
        "id": 2602,
        "name": "宜昌市",
        "level": 2,
        "regionCode": "420500",
        "childrens": [
          {
            "id": 2603,
            "name": "西陵区",
            "level": 3,
            "regionCode": "420502",
            "childrens": []
          },
          {
            "id": 2604,
            "name": "伍家岗区",
            "level": 3,
            "regionCode": "420503",
            "childrens": []
          },
          {
            "id": 2605,
            "name": "点军区",
            "level": 3,
            "regionCode": "420504",
            "childrens": []
          },
          {
            "id": 2606,
            "name": "猇亭区",
            "level": 3,
            "regionCode": "420505",
            "childrens": []
          },
          {
            "id": 2607,
            "name": "夷陵区",
            "level": 3,
            "regionCode": "420506",
            "childrens": []
          },
          {
            "id": 2608,
            "name": "远安县",
            "level": 3,
            "regionCode": "420525",
            "childrens": []
          },
          {
            "id": 2609,
            "name": "兴山县",
            "level": 3,
            "regionCode": "420526",
            "childrens": []
          },
          {
            "id": 2610,
            "name": "秭归县",
            "level": 3,
            "regionCode": "420527",
            "childrens": []
          },
          {
            "id": 2611,
            "name": "长阳土家族自治县",
            "level": 3,
            "regionCode": "420528",
            "childrens": []
          },
          {
            "id": 2612,
            "name": "五峰土家族自治县",
            "level": 3,
            "regionCode": "420529",
            "childrens": []
          },
          {
            "id": 2613,
            "name": "宜都市",
            "level": 3,
            "regionCode": "420581",
            "childrens": []
          },
          {
            "id": 2614,
            "name": "当阳市",
            "level": 3,
            "regionCode": "420582",
            "childrens": []
          },
          {
            "id": 2615,
            "name": "枝江市",
            "level": 3,
            "regionCode": "420583",
            "childrens": []
          }
        ]
      },
      {
        "id": 2636,
        "name": "鄂州市",
        "level": 2,
        "regionCode": "420700",
        "childrens": [
          {
            "id": 2638,
            "name": "华容区",
            "level": 3,
            "regionCode": "420703",
            "childrens": []
          },
          {
            "id": 2639,
            "name": "鄂城区",
            "level": 3,
            "regionCode": "420704",
            "childrens": []
          },
          {
            "id": 5239,
            "name": "梁子湖区",
            "level": 3,
            "regionCode": "420702",
            "childrens": []
          }
        ]
      },
      {
        "id": 2645,
        "name": "荆门市",
        "level": 2,
        "regionCode": "420800",
        "childrens": [
          {
            "id": 2646,
            "name": "东宝区",
            "level": 3,
            "regionCode": "420802",
            "childrens": []
          },
          {
            "id": 2647,
            "name": "掇刀区",
            "level": 3,
            "regionCode": "420804",
            "childrens": []
          },
          {
            "id": 2648,
            "name": "京山市",
            "level": 3,
            "regionCode": "420882",
            "childrens": []
          },
          {
            "id": 2649,
            "name": "沙洋县",
            "level": 3,
            "regionCode": "420822",
            "childrens": []
          },
          {
            "id": 2650,
            "name": "钟祥市",
            "level": 3,
            "regionCode": "420881",
            "childrens": []
          }
        ]
      },
      {
        "id": 2656,
        "name": "孝感市",
        "level": 2,
        "regionCode": "420900",
        "childrens": [
          {
            "id": 2657,
            "name": "孝南区",
            "level": 3,
            "regionCode": "420902",
            "childrens": []
          },
          {
            "id": 2658,
            "name": "孝昌县",
            "level": 3,
            "regionCode": "420921",
            "childrens": []
          },
          {
            "id": 2659,
            "name": "大悟县",
            "level": 3,
            "regionCode": "420922",
            "childrens": []
          },
          {
            "id": 2660,
            "name": "云梦县",
            "level": 3,
            "regionCode": "420923",
            "childrens": []
          },
          {
            "id": 2661,
            "name": "应城市",
            "level": 3,
            "regionCode": "420981",
            "childrens": []
          },
          {
            "id": 2662,
            "name": "安陆市",
            "level": 3,
            "regionCode": "420982",
            "childrens": []
          },
          {
            "id": 2663,
            "name": "汉川市",
            "level": 3,
            "regionCode": "420984",
            "childrens": []
          }
        ]
      },
      {
        "id": 2669,
        "name": "荆州市",
        "level": 2,
        "regionCode": "421000",
        "childrens": [
          {
            "id": 2670,
            "name": "沙市区",
            "level": 3,
            "regionCode": "421002",
            "childrens": []
          },
          {
            "id": 2671,
            "name": "荆州区",
            "level": 3,
            "regionCode": "421003",
            "childrens": []
          },
          {
            "id": 2672,
            "name": "公安县",
            "level": 3,
            "regionCode": "421022",
            "childrens": []
          },
          {
            "id": 2673,
            "name": "监利市",
            "level": 3,
            "regionCode": "421023",
            "childrens": []
          },
          {
            "id": 2674,
            "name": "江陵县",
            "level": 3,
            "regionCode": "421024",
            "childrens": []
          },
          {
            "id": 2675,
            "name": "石首市",
            "level": 3,
            "regionCode": "421081",
            "childrens": []
          },
          {
            "id": 2676,
            "name": "洪湖市",
            "level": 3,
            "regionCode": "421083",
            "childrens": []
          },
          {
            "id": 2677,
            "name": "松滋市",
            "level": 3,
            "regionCode": "421087",
            "childrens": []
          }
        ]
      },
      {
        "id": 2683,
        "name": "黄冈市",
        "level": 2,
        "regionCode": "421100",
        "childrens": [
          {
            "id": 2684,
            "name": "黄州区",
            "level": 3,
            "regionCode": "421102",
            "childrens": []
          },
          {
            "id": 2685,
            "name": "团风县",
            "level": 3,
            "regionCode": "421121",
            "childrens": []
          },
          {
            "id": 2686,
            "name": "红安县",
            "level": 3,
            "regionCode": "421122",
            "childrens": []
          },
          {
            "id": 2687,
            "name": "罗田县",
            "level": 3,
            "regionCode": "421123",
            "childrens": []
          },
          {
            "id": 2688,
            "name": "英山县",
            "level": 3,
            "regionCode": "421124",
            "childrens": []
          },
          {
            "id": 2689,
            "name": "浠水县",
            "level": 3,
            "regionCode": "421125",
            "childrens": []
          },
          {
            "id": 2690,
            "name": "蕲春县",
            "level": 3,
            "regionCode": "421126",
            "childrens": []
          },
          {
            "id": 2691,
            "name": "黄梅县",
            "level": 3,
            "regionCode": "421127",
            "childrens": []
          },
          {
            "id": 2692,
            "name": "麻城市",
            "level": 3,
            "regionCode": "421181",
            "childrens": []
          },
          {
            "id": 2693,
            "name": "武穴市",
            "level": 3,
            "regionCode": "421182",
            "childrens": []
          }
        ]
      },
      {
        "id": 2699,
        "name": "咸宁市",
        "level": 2,
        "regionCode": "421200",
        "childrens": [
          {
            "id": 2700,
            "name": "咸安区",
            "level": 3,
            "regionCode": "421202",
            "childrens": []
          },
          {
            "id": 2701,
            "name": "嘉鱼县",
            "level": 3,
            "regionCode": "421221",
            "childrens": []
          },
          {
            "id": 2702,
            "name": "通城县",
            "level": 3,
            "regionCode": "421222",
            "childrens": []
          },
          {
            "id": 2703,
            "name": "崇阳县",
            "level": 3,
            "regionCode": "421223",
            "childrens": []
          },
          {
            "id": 2704,
            "name": "通山县",
            "level": 3,
            "regionCode": "421224",
            "childrens": []
          },
          {
            "id": 2705,
            "name": "赤壁市",
            "level": 3,
            "regionCode": "421281",
            "childrens": []
          }
        ]
      },
      {
        "id": 2711,
        "name": "随州市",
        "level": 2,
        "regionCode": "421300",
        "childrens": [
          {
            "id": 2712,
            "name": "曾都区",
            "level": 3,
            "regionCode": "421303",
            "childrens": []
          },
          {
            "id": 2713,
            "name": "广水市",
            "level": 3,
            "regionCode": "421381",
            "childrens": []
          },
          {
            "id": 5240,
            "name": "随县",
            "level": 3,
            "regionCode": "421321",
            "childrens": []
          }
        ]
      },
      {
        "id": 2719,
        "name": "恩施土家族苗族自治州",
        "level": 2,
        "regionCode": "422800",
        "childrens": [
          {
            "id": 2720,
            "name": "恩施市",
            "level": 3,
            "regionCode": "422801",
            "childrens": []
          },
          {
            "id": 2721,
            "name": "利川市",
            "level": 3,
            "regionCode": "422802",
            "childrens": []
          },
          {
            "id": 2722,
            "name": "建始县",
            "level": 3,
            "regionCode": "422822",
            "childrens": []
          },
          {
            "id": 2723,
            "name": "巴东县",
            "level": 3,
            "regionCode": "422823",
            "childrens": []
          },
          {
            "id": 2724,
            "name": "宣恩县",
            "level": 3,
            "regionCode": "422825",
            "childrens": []
          },
          {
            "id": 2725,
            "name": "咸丰县",
            "level": 3,
            "regionCode": "422826",
            "childrens": []
          },
          {
            "id": 2726,
            "name": "来凤县",
            "level": 3,
            "regionCode": "422827",
            "childrens": []
          },
          {
            "id": 2727,
            "name": "鹤峰县",
            "level": 3,
            "regionCode": "422828",
            "childrens": []
          }
        ]
      },
      {
        "id": 4965,
        "name": "襄阳市",
        "level": 2,
        "regionCode": "420600",
        "childrens": [
          {
            "id": 5044,
            "name": "襄城区",
            "level": 3,
            "regionCode": "420602",
            "childrens": []
          },
          {
            "id": 5045,
            "name": "樊城区",
            "level": 3,
            "regionCode": "420606",
            "childrens": []
          },
          {
            "id": 5046,
            "name": "襄州区",
            "level": 3,
            "regionCode": "420607",
            "childrens": []
          },
          {
            "id": 5047,
            "name": "南漳县",
            "level": 3,
            "regionCode": "420624",
            "childrens": []
          },
          {
            "id": 5048,
            "name": "谷城县",
            "level": 3,
            "regionCode": "420625",
            "childrens": []
          },
          {
            "id": 5049,
            "name": "保康县",
            "level": 3,
            "regionCode": "420626",
            "childrens": []
          },
          {
            "id": 5050,
            "name": "老河口市",
            "level": 3,
            "regionCode": "420682",
            "childrens": []
          },
          {
            "id": 5051,
            "name": "枣阳市",
            "level": 3,
            "regionCode": "420683",
            "childrens": []
          },
          {
            "id": 5052,
            "name": "宜城市",
            "level": 3,
            "regionCode": "420684",
            "childrens": []
          }
        ]
      },
      {
        "id": 4966,
        "name": "仙桃市",
        "level": 2,
        "regionCode": "429004",
        "childrens": []
      },
      {
        "id": 4967,
        "name": "潜江市",
        "level": 2,
        "regionCode": "429005",
        "childrens": []
      },
      {
        "id": 4968,
        "name": "天门市",
        "level": 2,
        "regionCode": "429006",
        "childrens": []
      }
    ]
  },
  {
    "id": 2743,
    "name": "湖南省",
    "level": 1,
    "regionCode": "430000",
    "childrens": [
      {
        "id": 2744,
        "name": "长沙市",
        "level": 2,
        "regionCode": "430100",
        "childrens": [
          {
            "id": 2745,
            "name": "芙蓉区",
            "level": 3,
            "regionCode": "430102",
            "childrens": []
          },
          {
            "id": 2746,
            "name": "天心区",
            "level": 3,
            "regionCode": "430103",
            "childrens": []
          },
          {
            "id": 2747,
            "name": "岳麓区",
            "level": 3,
            "regionCode": "430104",
            "childrens": []
          },
          {
            "id": 2748,
            "name": "开福区",
            "level": 3,
            "regionCode": "430105",
            "childrens": []
          },
          {
            "id": 2749,
            "name": "雨花区",
            "level": 3,
            "regionCode": "430111",
            "childrens": []
          },
          {
            "id": 2750,
            "name": "长沙县",
            "level": 3,
            "regionCode": "430121",
            "childrens": []
          },
          {
            "id": 2751,
            "name": "望城区",
            "level": 3,
            "regionCode": "430112",
            "childrens": []
          },
          {
            "id": 2752,
            "name": "宁乡市",
            "level": 3,
            "regionCode": "430182",
            "childrens": []
          },
          {
            "id": 2753,
            "name": "浏阳市",
            "level": 3,
            "regionCode": "430181",
            "childrens": []
          }
        ]
      },
      {
        "id": 2759,
        "name": "株洲市",
        "level": 2,
        "regionCode": "430200",
        "childrens": [
          {
            "id": 2760,
            "name": "荷塘区",
            "level": 3,
            "regionCode": "430202",
            "childrens": []
          },
          {
            "id": 2761,
            "name": "芦淞区",
            "level": 3,
            "regionCode": "430203",
            "childrens": []
          },
          {
            "id": 2762,
            "name": "石峰区",
            "level": 3,
            "regionCode": "430204",
            "childrens": []
          },
          {
            "id": 2763,
            "name": "天元区",
            "level": 3,
            "regionCode": "430211",
            "childrens": []
          },
          {
            "id": 2765,
            "name": "攸县",
            "level": 3,
            "regionCode": "430223",
            "childrens": []
          },
          {
            "id": 2766,
            "name": "茶陵县",
            "level": 3,
            "regionCode": "430224",
            "childrens": []
          },
          {
            "id": 2767,
            "name": "炎陵县",
            "level": 3,
            "regionCode": "430225",
            "childrens": []
          },
          {
            "id": 2768,
            "name": "醴陵市",
            "level": 3,
            "regionCode": "430281",
            "childrens": []
          },
          {
            "id": 5241,
            "name": "渌口区",
            "level": 3,
            "regionCode": "430212",
            "childrens": []
          }
        ]
      },
      {
        "id": 2774,
        "name": "湘潭市",
        "level": 2,
        "regionCode": "430300",
        "childrens": [
          {
            "id": 2775,
            "name": "雨湖区",
            "level": 3,
            "regionCode": "430302",
            "childrens": []
          },
          {
            "id": 2776,
            "name": "岳塘区",
            "level": 3,
            "regionCode": "430304",
            "childrens": []
          },
          {
            "id": 2777,
            "name": "湘潭县",
            "level": 3,
            "regionCode": "430321",
            "childrens": []
          },
          {
            "id": 2778,
            "name": "湘乡市",
            "level": 3,
            "regionCode": "430381",
            "childrens": []
          },
          {
            "id": 2779,
            "name": "韶山市",
            "level": 3,
            "regionCode": "430382",
            "childrens": []
          }
        ]
      },
      {
        "id": 2785,
        "name": "衡阳市",
        "level": 2,
        "regionCode": "430400",
        "childrens": [
          {
            "id": 2786,
            "name": "珠晖区",
            "level": 3,
            "regionCode": "430405",
            "childrens": []
          },
          {
            "id": 2787,
            "name": "雁峰区",
            "level": 3,
            "regionCode": "430406",
            "childrens": []
          },
          {
            "id": 2788,
            "name": "石鼓区",
            "level": 3,
            "regionCode": "430407",
            "childrens": []
          },
          {
            "id": 2789,
            "name": "蒸湘区",
            "level": 3,
            "regionCode": "430408",
            "childrens": []
          },
          {
            "id": 2790,
            "name": "南岳区",
            "level": 3,
            "regionCode": "430412",
            "childrens": []
          },
          {
            "id": 2791,
            "name": "衡阳县",
            "level": 3,
            "regionCode": "430421",
            "childrens": []
          },
          {
            "id": 2792,
            "name": "衡南县",
            "level": 3,
            "regionCode": "430422",
            "childrens": []
          },
          {
            "id": 2793,
            "name": "衡山县",
            "level": 3,
            "regionCode": "430423",
            "childrens": []
          },
          {
            "id": 2794,
            "name": "衡东县",
            "level": 3,
            "regionCode": "430424",
            "childrens": []
          },
          {
            "id": 2795,
            "name": "祁东县",
            "level": 3,
            "regionCode": "430426",
            "childrens": []
          },
          {
            "id": 2796,
            "name": "耒阳市",
            "level": 3,
            "regionCode": "430481",
            "childrens": []
          },
          {
            "id": 2797,
            "name": "常宁市",
            "level": 3,
            "regionCode": "430482",
            "childrens": []
          }
        ]
      },
      {
        "id": 2803,
        "name": "邵阳市",
        "level": 2,
        "regionCode": "430500",
        "childrens": [
          {
            "id": 2804,
            "name": "双清区",
            "level": 3,
            "regionCode": "430502",
            "childrens": []
          },
          {
            "id": 2805,
            "name": "大祥区",
            "level": 3,
            "regionCode": "430503",
            "childrens": []
          },
          {
            "id": 2806,
            "name": "北塔区",
            "level": 3,
            "regionCode": "430511",
            "childrens": []
          },
          {
            "id": 2807,
            "name": "邵东市",
            "level": 3,
            "regionCode": "430582",
            "childrens": []
          },
          {
            "id": 2808,
            "name": "新邵县",
            "level": 3,
            "regionCode": "430522",
            "childrens": []
          },
          {
            "id": 2809,
            "name": "邵阳县",
            "level": 3,
            "regionCode": "430523",
            "childrens": []
          },
          {
            "id": 2810,
            "name": "隆回县",
            "level": 3,
            "regionCode": "430524",
            "childrens": []
          },
          {
            "id": 2811,
            "name": "洞口县",
            "level": 3,
            "regionCode": "430525",
            "childrens": []
          },
          {
            "id": 2812,
            "name": "绥宁县",
            "level": 3,
            "regionCode": "430527",
            "childrens": []
          },
          {
            "id": 2813,
            "name": "新宁县",
            "level": 3,
            "regionCode": "430528",
            "childrens": []
          },
          {
            "id": 2814,
            "name": "城步苗族自治县",
            "level": 3,
            "regionCode": "430529",
            "childrens": []
          },
          {
            "id": 2815,
            "name": "武冈市",
            "level": 3,
            "regionCode": "430581",
            "childrens": []
          }
        ]
      },
      {
        "id": 2821,
        "name": "岳阳市",
        "level": 2,
        "regionCode": "430600",
        "childrens": [
          {
            "id": 2822,
            "name": "岳阳楼区",
            "level": 3,
            "regionCode": "430602",
            "childrens": []
          },
          {
            "id": 2823,
            "name": "云溪区",
            "level": 3,
            "regionCode": "430603",
            "childrens": []
          },
          {
            "id": 2824,
            "name": "君山区",
            "level": 3,
            "regionCode": "430611",
            "childrens": []
          },
          {
            "id": 2825,
            "name": "岳阳县",
            "level": 3,
            "regionCode": "430621",
            "childrens": []
          },
          {
            "id": 2826,
            "name": "华容县",
            "level": 3,
            "regionCode": "430623",
            "childrens": []
          },
          {
            "id": 2827,
            "name": "湘阴县",
            "level": 3,
            "regionCode": "430624",
            "childrens": []
          },
          {
            "id": 2828,
            "name": "平江县",
            "level": 3,
            "regionCode": "430626",
            "childrens": []
          },
          {
            "id": 2830,
            "name": "临湘市",
            "level": 3,
            "regionCode": "430682",
            "childrens": []
          },
          {
            "id": 5242,
            "name": "汨罗市",
            "level": 3,
            "regionCode": "430681",
            "childrens": []
          }
        ]
      },
      {
        "id": 2836,
        "name": "常德市",
        "level": 2,
        "regionCode": "430700",
        "childrens": [
          {
            "id": 2837,
            "name": "武陵区",
            "level": 3,
            "regionCode": "430702",
            "childrens": []
          },
          {
            "id": 2838,
            "name": "鼎城区",
            "level": 3,
            "regionCode": "430703",
            "childrens": []
          },
          {
            "id": 2839,
            "name": "安乡县",
            "level": 3,
            "regionCode": "430721",
            "childrens": []
          },
          {
            "id": 2840,
            "name": "汉寿县",
            "level": 3,
            "regionCode": "430722",
            "childrens": []
          },
          {
            "id": 2841,
            "name": "澧县",
            "level": 3,
            "regionCode": "430723",
            "childrens": []
          },
          {
            "id": 2842,
            "name": "临澧县",
            "level": 3,
            "regionCode": "430724",
            "childrens": []
          },
          {
            "id": 2843,
            "name": "桃源县",
            "level": 3,
            "regionCode": "430725",
            "childrens": []
          },
          {
            "id": 2844,
            "name": "石门县",
            "level": 3,
            "regionCode": "430726",
            "childrens": []
          },
          {
            "id": 2845,
            "name": "津市市",
            "level": 3,
            "regionCode": "430781",
            "childrens": []
          }
        ]
      },
      {
        "id": 2851,
        "name": "张家界市",
        "level": 2,
        "regionCode": "430800",
        "childrens": [
          {
            "id": 2852,
            "name": "永定区",
            "level": 3,
            "regionCode": "430802",
            "childrens": []
          },
          {
            "id": 2853,
            "name": "武陵源区",
            "level": 3,
            "regionCode": "430811",
            "childrens": []
          },
          {
            "id": 2854,
            "name": "慈利县",
            "level": 3,
            "regionCode": "430821",
            "childrens": []
          },
          {
            "id": 2855,
            "name": "桑植县",
            "level": 3,
            "regionCode": "430822",
            "childrens": []
          }
        ]
      },
      {
        "id": 2861,
        "name": "益阳市",
        "level": 2,
        "regionCode": "430900",
        "childrens": [
          {
            "id": 2862,
            "name": "资阳区",
            "level": 3,
            "regionCode": "430902",
            "childrens": []
          },
          {
            "id": 2863,
            "name": "赫山区",
            "level": 3,
            "regionCode": "430903",
            "childrens": []
          },
          {
            "id": 2864,
            "name": "南县",
            "level": 3,
            "regionCode": "430921",
            "childrens": []
          },
          {
            "id": 2865,
            "name": "桃江县",
            "level": 3,
            "regionCode": "430922",
            "childrens": []
          },
          {
            "id": 2866,
            "name": "安化县",
            "level": 3,
            "regionCode": "430923",
            "childrens": []
          },
          {
            "id": 2867,
            "name": "沅江市",
            "level": 3,
            "regionCode": "430981",
            "childrens": []
          }
        ]
      },
      {
        "id": 2873,
        "name": "郴州市",
        "level": 2,
        "regionCode": "431000",
        "childrens": [
          {
            "id": 2874,
            "name": "北湖区",
            "level": 3,
            "regionCode": "431002",
            "childrens": []
          },
          {
            "id": 2875,
            "name": "苏仙区",
            "level": 3,
            "regionCode": "431003",
            "childrens": []
          },
          {
            "id": 2876,
            "name": "桂阳县",
            "level": 3,
            "regionCode": "431021",
            "childrens": []
          },
          {
            "id": 2877,
            "name": "宜章县",
            "level": 3,
            "regionCode": "431022",
            "childrens": []
          },
          {
            "id": 2878,
            "name": "永兴县",
            "level": 3,
            "regionCode": "431023",
            "childrens": []
          },
          {
            "id": 2879,
            "name": "嘉禾县",
            "level": 3,
            "regionCode": "431024",
            "childrens": []
          },
          {
            "id": 2880,
            "name": "临武县",
            "level": 3,
            "regionCode": "431025",
            "childrens": []
          },
          {
            "id": 2881,
            "name": "汝城县",
            "level": 3,
            "regionCode": "431026",
            "childrens": []
          },
          {
            "id": 2882,
            "name": "桂东县",
            "level": 3,
            "regionCode": "431027",
            "childrens": []
          },
          {
            "id": 2883,
            "name": "安仁县",
            "level": 3,
            "regionCode": "431028",
            "childrens": []
          },
          {
            "id": 2884,
            "name": "资兴市",
            "level": 3,
            "regionCode": "431081",
            "childrens": []
          }
        ]
      },
      {
        "id": 2890,
        "name": "永州市",
        "level": 2,
        "regionCode": "431100",
        "childrens": [
          {
            "id": 2891,
            "name": "零陵区",
            "level": 3,
            "regionCode": "431102",
            "childrens": []
          },
          {
            "id": 2892,
            "name": "冷水滩区",
            "level": 3,
            "regionCode": "431103",
            "childrens": []
          },
          {
            "id": 2893,
            "name": "祁阳市",
            "level": 3,
            "regionCode": "431121",
            "childrens": []
          },
          {
            "id": 2894,
            "name": "东安县",
            "level": 3,
            "regionCode": "431122",
            "childrens": []
          },
          {
            "id": 2895,
            "name": "双牌县",
            "level": 3,
            "regionCode": "431123",
            "childrens": []
          },
          {
            "id": 2896,
            "name": "道县",
            "level": 3,
            "regionCode": "431124",
            "childrens": []
          },
          {
            "id": 2897,
            "name": "江永县",
            "level": 3,
            "regionCode": "431125",
            "childrens": []
          },
          {
            "id": 2898,
            "name": "宁远县",
            "level": 3,
            "regionCode": "431126",
            "childrens": []
          },
          {
            "id": 2899,
            "name": "蓝山县",
            "level": 3,
            "regionCode": "431127",
            "childrens": []
          },
          {
            "id": 2900,
            "name": "新田县",
            "level": 3,
            "regionCode": "431128",
            "childrens": []
          },
          {
            "id": 2901,
            "name": "江华瑶族自治县",
            "level": 3,
            "regionCode": "431129",
            "childrens": []
          }
        ]
      },
      {
        "id": 2907,
        "name": "怀化市",
        "level": 2,
        "regionCode": "431200",
        "childrens": [
          {
            "id": 2908,
            "name": "鹤城区",
            "level": 3,
            "regionCode": "431202",
            "childrens": []
          },
          {
            "id": 2909,
            "name": "中方县",
            "level": 3,
            "regionCode": "431221",
            "childrens": []
          },
          {
            "id": 2910,
            "name": "沅陵县",
            "level": 3,
            "regionCode": "431222",
            "childrens": []
          },
          {
            "id": 2911,
            "name": "辰溪县",
            "level": 3,
            "regionCode": "431223",
            "childrens": []
          },
          {
            "id": 2912,
            "name": "溆浦县",
            "level": 3,
            "regionCode": "431224",
            "childrens": []
          },
          {
            "id": 2913,
            "name": "会同县",
            "level": 3,
            "regionCode": "431225",
            "childrens": []
          },
          {
            "id": 2914,
            "name": "麻阳苗族自治县",
            "level": 3,
            "regionCode": "431226",
            "childrens": []
          },
          {
            "id": 2915,
            "name": "新晃侗族自治县",
            "level": 3,
            "regionCode": "431227",
            "childrens": []
          },
          {
            "id": 2916,
            "name": "芷江侗族自治县",
            "level": 3,
            "regionCode": "431228",
            "childrens": []
          },
          {
            "id": 2917,
            "name": "靖州苗族侗族自治县",
            "level": 3,
            "regionCode": "431229",
            "childrens": []
          },
          {
            "id": 2918,
            "name": "通道侗族自治县",
            "level": 3,
            "regionCode": "431230",
            "childrens": []
          },
          {
            "id": 2919,
            "name": "洪江市",
            "level": 3,
            "regionCode": "431281",
            "childrens": []
          }
        ]
      },
      {
        "id": 2925,
        "name": "娄底市",
        "level": 2,
        "regionCode": "431300",
        "childrens": [
          {
            "id": 2926,
            "name": "娄星区",
            "level": 3,
            "regionCode": "431302",
            "childrens": []
          },
          {
            "id": 2927,
            "name": "双峰县",
            "level": 3,
            "regionCode": "431321",
            "childrens": []
          },
          {
            "id": 2928,
            "name": "新化县",
            "level": 3,
            "regionCode": "431322",
            "childrens": []
          },
          {
            "id": 2929,
            "name": "冷水江市",
            "level": 3,
            "regionCode": "431381",
            "childrens": []
          },
          {
            "id": 2930,
            "name": "涟源市",
            "level": 3,
            "regionCode": "431382",
            "childrens": []
          }
        ]
      },
      {
        "id": 2936,
        "name": "湘西土家族苗族自治州",
        "level": 2,
        "regionCode": "433100",
        "childrens": [
          {
            "id": 2937,
            "name": "吉首市",
            "level": 3,
            "regionCode": "433101",
            "childrens": []
          },
          {
            "id": 2938,
            "name": "泸溪县",
            "level": 3,
            "regionCode": "433122",
            "childrens": []
          },
          {
            "id": 2939,
            "name": "凤凰县",
            "level": 3,
            "regionCode": "433123",
            "childrens": []
          },
          {
            "id": 2940,
            "name": "花垣县",
            "level": 3,
            "regionCode": "433124",
            "childrens": []
          },
          {
            "id": 2941,
            "name": "保靖县",
            "level": 3,
            "regionCode": "433125",
            "childrens": []
          },
          {
            "id": 2942,
            "name": "古丈县",
            "level": 3,
            "regionCode": "433126",
            "childrens": []
          },
          {
            "id": 2943,
            "name": "永顺县",
            "level": 3,
            "regionCode": "433127",
            "childrens": []
          },
          {
            "id": 2944,
            "name": "龙山县",
            "level": 3,
            "regionCode": "433130",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 2950,
    "name": "广东省",
    "level": 1,
    "regionCode": "440000",
    "childrens": [
      {
        "id": 2951,
        "name": "广州市",
        "level": 2,
        "regionCode": "440100",
        "childrens": [
          {
            "id": 2952,
            "name": "荔湾区",
            "level": 3,
            "regionCode": "440103",
            "childrens": []
          },
          {
            "id": 2953,
            "name": "越秀区",
            "level": 3,
            "regionCode": "440104",
            "childrens": []
          },
          {
            "id": 2954,
            "name": "海珠区",
            "level": 3,
            "regionCode": "440105",
            "childrens": []
          },
          {
            "id": 2955,
            "name": "天河区",
            "level": 3,
            "regionCode": "440106",
            "childrens": []
          },
          {
            "id": 2956,
            "name": "白云区",
            "level": 3,
            "regionCode": "440111",
            "childrens": []
          },
          {
            "id": 2957,
            "name": "黄埔区",
            "level": 3,
            "regionCode": "440112",
            "childrens": []
          },
          {
            "id": 2958,
            "name": "番禺区",
            "level": 3,
            "regionCode": "440113",
            "childrens": []
          },
          {
            "id": 2959,
            "name": "花都区",
            "level": 3,
            "regionCode": "440114",
            "childrens": []
          },
          {
            "id": 2960,
            "name": "南沙区",
            "level": 3,
            "regionCode": "440115",
            "childrens": []
          },
          {
            "id": 2962,
            "name": "增城区",
            "level": 3,
            "regionCode": "440118",
            "childrens": []
          },
          {
            "id": 2963,
            "name": "从化区",
            "level": 3,
            "regionCode": "440117",
            "childrens": []
          }
        ]
      },
      {
        "id": 2969,
        "name": "韶关市",
        "level": 2,
        "regionCode": "440200",
        "childrens": [
          {
            "id": 2970,
            "name": "武江区",
            "level": 3,
            "regionCode": "440203",
            "childrens": []
          },
          {
            "id": 2971,
            "name": "浈江区",
            "level": 3,
            "regionCode": "440204",
            "childrens": []
          },
          {
            "id": 2972,
            "name": "曲江区",
            "level": 3,
            "regionCode": "440205",
            "childrens": []
          },
          {
            "id": 2973,
            "name": "始兴县",
            "level": 3,
            "regionCode": "440222",
            "childrens": []
          },
          {
            "id": 2974,
            "name": "仁化县",
            "level": 3,
            "regionCode": "440224",
            "childrens": []
          },
          {
            "id": 2975,
            "name": "翁源县",
            "level": 3,
            "regionCode": "440229",
            "childrens": []
          },
          {
            "id": 2976,
            "name": "乳源瑶族自治县",
            "level": 3,
            "regionCode": "440232",
            "childrens": []
          },
          {
            "id": 2977,
            "name": "新丰县",
            "level": 3,
            "regionCode": "440233",
            "childrens": []
          },
          {
            "id": 2978,
            "name": "乐昌市",
            "level": 3,
            "regionCode": "440281",
            "childrens": []
          },
          {
            "id": 2979,
            "name": "南雄市",
            "level": 3,
            "regionCode": "440282",
            "childrens": []
          }
        ]
      },
      {
        "id": 2985,
        "name": "深圳市",
        "level": 2,
        "regionCode": "440300",
        "childrens": [
          {
            "id": 2986,
            "name": "罗湖区",
            "level": 3,
            "regionCode": "440303",
            "childrens": []
          },
          {
            "id": 2987,
            "name": "福田区",
            "level": 3,
            "regionCode": "440304",
            "childrens": []
          },
          {
            "id": 2988,
            "name": "南山区",
            "level": 3,
            "regionCode": "440305",
            "childrens": []
          },
          {
            "id": 2989,
            "name": "宝安区",
            "level": 3,
            "regionCode": "440306",
            "childrens": []
          },
          {
            "id": 2990,
            "name": "龙岗区",
            "level": 3,
            "regionCode": "440307",
            "childrens": []
          },
          {
            "id": 2991,
            "name": "盐田区",
            "level": 3,
            "regionCode": "440308",
            "childrens": []
          },
          {
            "id": 2992,
            "name": "龙华区",
            "level": 3,
            "regionCode": "440309",
            "childrens": []
          },
          {
            "id": 2993,
            "name": "光明区",
            "level": 3,
            "regionCode": "440311",
            "childrens": []
          },
          {
            "id": 2994,
            "name": "坪山区",
            "level": 3,
            "regionCode": "440310",
            "childrens": []
          },
          {
            "id": 2995,
            "name": "大鹏新区",
            "level": 3,
            "regionCode": "440313",
            "childrens": []
          },
          {
            "id": 2996,
            "name": "前海",
            "level": 3,
            "regionCode": "440315",
            "childrens": []
          }
        ]
      },
      {
        "id": 3001,
        "name": "珠海市",
        "level": 2,
        "regionCode": "440400",
        "childrens": [
          {
            "id": 3002,
            "name": "香洲区",
            "level": 3,
            "regionCode": "440402",
            "childrens": []
          },
          {
            "id": 3003,
            "name": "斗门区",
            "level": 3,
            "regionCode": "440403",
            "childrens": []
          },
          {
            "id": 3004,
            "name": "金湾区",
            "level": 3,
            "regionCode": "440404",
            "childrens": []
          }
        ]
      },
      {
        "id": 3010,
        "name": "汕头市",
        "level": 2,
        "regionCode": "440500",
        "childrens": [
          {
            "id": 3011,
            "name": "龙湖区",
            "level": 3,
            "regionCode": "440507",
            "childrens": []
          },
          {
            "id": 3012,
            "name": "金平区",
            "level": 3,
            "regionCode": "440511",
            "childrens": []
          },
          {
            "id": 3013,
            "name": "濠江区",
            "level": 3,
            "regionCode": "440512",
            "childrens": []
          },
          {
            "id": 3014,
            "name": "潮阳区",
            "level": 3,
            "regionCode": "440513",
            "childrens": []
          },
          {
            "id": 3015,
            "name": "潮南区",
            "level": 3,
            "regionCode": "440514",
            "childrens": []
          },
          {
            "id": 3016,
            "name": "澄海区",
            "level": 3,
            "regionCode": "440515",
            "childrens": []
          },
          {
            "id": 3017,
            "name": "南澳县",
            "level": 3,
            "regionCode": "440523",
            "childrens": []
          }
        ]
      },
      {
        "id": 3023,
        "name": "佛山市",
        "level": 2,
        "regionCode": "440600",
        "childrens": [
          {
            "id": 3024,
            "name": "禅城区",
            "level": 3,
            "regionCode": "440604",
            "childrens": []
          },
          {
            "id": 3025,
            "name": "南海区",
            "level": 3,
            "regionCode": "440605",
            "childrens": []
          },
          {
            "id": 3026,
            "name": "顺德区",
            "level": 3,
            "regionCode": "440606",
            "childrens": []
          },
          {
            "id": 3027,
            "name": "三水区",
            "level": 3,
            "regionCode": "440607",
            "childrens": []
          },
          {
            "id": 3028,
            "name": "高明区",
            "level": 3,
            "regionCode": "440608",
            "childrens": []
          }
        ]
      },
      {
        "id": 3034,
        "name": "江门市",
        "level": 2,
        "regionCode": "440700",
        "childrens": [
          {
            "id": 3035,
            "name": "蓬江区",
            "level": 3,
            "regionCode": "440703",
            "childrens": []
          },
          {
            "id": 3036,
            "name": "江海区",
            "level": 3,
            "regionCode": "440704",
            "childrens": []
          },
          {
            "id": 3037,
            "name": "新会区",
            "level": 3,
            "regionCode": "440705",
            "childrens": []
          },
          {
            "id": 3038,
            "name": "台山市",
            "level": 3,
            "regionCode": "440781",
            "childrens": []
          },
          {
            "id": 3039,
            "name": "开平市",
            "level": 3,
            "regionCode": "440783",
            "childrens": []
          },
          {
            "id": 3040,
            "name": "鹤山市",
            "level": 3,
            "regionCode": "440784",
            "childrens": []
          },
          {
            "id": 3041,
            "name": "恩平市",
            "level": 3,
            "regionCode": "440785",
            "childrens": []
          }
        ]
      },
      {
        "id": 3047,
        "name": "湛江市",
        "level": 2,
        "regionCode": "440800",
        "childrens": [
          {
            "id": 3048,
            "name": "赤坎区",
            "level": 3,
            "regionCode": "440802",
            "childrens": []
          },
          {
            "id": 3049,
            "name": "霞山区",
            "level": 3,
            "regionCode": "440803",
            "childrens": []
          },
          {
            "id": 3050,
            "name": "坡头区",
            "level": 3,
            "regionCode": "440804",
            "childrens": []
          },
          {
            "id": 3051,
            "name": "麻章区",
            "level": 3,
            "regionCode": "440811",
            "childrens": []
          },
          {
            "id": 3052,
            "name": "遂溪县",
            "level": 3,
            "regionCode": "440823",
            "childrens": []
          },
          {
            "id": 3053,
            "name": "徐闻县",
            "level": 3,
            "regionCode": "440825",
            "childrens": []
          },
          {
            "id": 3054,
            "name": "廉江市",
            "level": 3,
            "regionCode": "440881",
            "childrens": []
          },
          {
            "id": 3055,
            "name": "雷州市",
            "level": 3,
            "regionCode": "440882",
            "childrens": []
          },
          {
            "id": 3056,
            "name": "吴川市",
            "level": 3,
            "regionCode": "440883",
            "childrens": []
          }
        ]
      },
      {
        "id": 3062,
        "name": "茂名市",
        "level": 2,
        "regionCode": "440900",
        "childrens": [
          {
            "id": 3063,
            "name": "茂南区",
            "level": 3,
            "regionCode": "440902",
            "childrens": []
          },
          {
            "id": 3065,
            "name": "电白区",
            "level": 3,
            "regionCode": "440904",
            "childrens": []
          },
          {
            "id": 3066,
            "name": "高州市",
            "level": 3,
            "regionCode": "440981",
            "childrens": []
          },
          {
            "id": 3067,
            "name": "化州市",
            "level": 3,
            "regionCode": "440982",
            "childrens": []
          },
          {
            "id": 3068,
            "name": "信宜市",
            "level": 3,
            "regionCode": "440983",
            "childrens": []
          }
        ]
      },
      {
        "id": 3074,
        "name": "肇庆市",
        "level": 2,
        "regionCode": "441200",
        "childrens": [
          {
            "id": 3075,
            "name": "端州区",
            "level": 3,
            "regionCode": "441202",
            "childrens": []
          },
          {
            "id": 3076,
            "name": "鼎湖区",
            "level": 3,
            "regionCode": "441203",
            "childrens": []
          },
          {
            "id": 3077,
            "name": "广宁县",
            "level": 3,
            "regionCode": "441223",
            "childrens": []
          },
          {
            "id": 3078,
            "name": "怀集县",
            "level": 3,
            "regionCode": "441224",
            "childrens": []
          },
          {
            "id": 3079,
            "name": "封开县",
            "level": 3,
            "regionCode": "441225",
            "childrens": []
          },
          {
            "id": 3080,
            "name": "德庆县",
            "level": 3,
            "regionCode": "441226",
            "childrens": []
          },
          {
            "id": 3081,
            "name": "高要区",
            "level": 3,
            "regionCode": "441204",
            "childrens": []
          },
          {
            "id": 3082,
            "name": "四会市",
            "level": 3,
            "regionCode": "441284",
            "childrens": []
          }
        ]
      },
      {
        "id": 3088,
        "name": "惠州市",
        "level": 2,
        "regionCode": "441300",
        "childrens": [
          {
            "id": 3089,
            "name": "惠城区",
            "level": 3,
            "regionCode": "441302",
            "childrens": []
          },
          {
            "id": 3090,
            "name": "惠阳区",
            "level": 3,
            "regionCode": "441303",
            "childrens": []
          },
          {
            "id": 3091,
            "name": "博罗县",
            "level": 3,
            "regionCode": "441322",
            "childrens": []
          },
          {
            "id": 3092,
            "name": "惠东县",
            "level": 3,
            "regionCode": "441323",
            "childrens": []
          },
          {
            "id": 3093,
            "name": "龙门县",
            "level": 3,
            "regionCode": "441324",
            "childrens": []
          }
        ]
      },
      {
        "id": 3099,
        "name": "梅州市",
        "level": 2,
        "regionCode": "441400",
        "childrens": [
          {
            "id": 3100,
            "name": "梅江区",
            "level": 3,
            "regionCode": "441402",
            "childrens": []
          },
          {
            "id": 3101,
            "name": "梅县区",
            "level": 3,
            "regionCode": "441403",
            "childrens": []
          },
          {
            "id": 3102,
            "name": "大埔县",
            "level": 3,
            "regionCode": "441422",
            "childrens": []
          },
          {
            "id": 3103,
            "name": "丰顺县",
            "level": 3,
            "regionCode": "441423",
            "childrens": []
          },
          {
            "id": 3104,
            "name": "五华县",
            "level": 3,
            "regionCode": "441424",
            "childrens": []
          },
          {
            "id": 3105,
            "name": "平远县",
            "level": 3,
            "regionCode": "441426",
            "childrens": []
          },
          {
            "id": 3106,
            "name": "蕉岭县",
            "level": 3,
            "regionCode": "441427",
            "childrens": []
          },
          {
            "id": 3107,
            "name": "兴宁市",
            "level": 3,
            "regionCode": "441481",
            "childrens": []
          }
        ]
      },
      {
        "id": 3113,
        "name": "汕尾市",
        "level": 2,
        "regionCode": "441500",
        "childrens": [
          {
            "id": 3115,
            "name": "海丰县",
            "level": 3,
            "regionCode": "441521",
            "childrens": []
          },
          {
            "id": 3116,
            "name": "陆河县",
            "level": 3,
            "regionCode": "441523",
            "childrens": []
          },
          {
            "id": 3117,
            "name": "陆丰市",
            "level": 3,
            "regionCode": "441581",
            "childrens": []
          },
          {
            "id": 5244,
            "name": "城区",
            "level": 3,
            "regionCode": "441502",
            "childrens": []
          }
        ]
      },
      {
        "id": 3123,
        "name": "河源市",
        "level": 2,
        "regionCode": "441600",
        "childrens": [
          {
            "id": 3124,
            "name": "源城区",
            "level": 3,
            "regionCode": "441602",
            "childrens": []
          },
          {
            "id": 3125,
            "name": "紫金县",
            "level": 3,
            "regionCode": "441621",
            "childrens": []
          },
          {
            "id": 3126,
            "name": "龙川县",
            "level": 3,
            "regionCode": "441622",
            "childrens": []
          },
          {
            "id": 3127,
            "name": "连平县",
            "level": 3,
            "regionCode": "441623",
            "childrens": []
          },
          {
            "id": 3128,
            "name": "和平县",
            "level": 3,
            "regionCode": "441624",
            "childrens": []
          },
          {
            "id": 3129,
            "name": "东源县",
            "level": 3,
            "regionCode": "441625",
            "childrens": []
          }
        ]
      },
      {
        "id": 3135,
        "name": "阳江市",
        "level": 2,
        "regionCode": "441700",
        "childrens": [
          {
            "id": 3136,
            "name": "江城区",
            "level": 3,
            "regionCode": "441702",
            "childrens": []
          },
          {
            "id": 3137,
            "name": "阳西县",
            "level": 3,
            "regionCode": "441721",
            "childrens": []
          },
          {
            "id": 3138,
            "name": "阳东区",
            "level": 3,
            "regionCode": "441704",
            "childrens": []
          },
          {
            "id": 3139,
            "name": "阳春市",
            "level": 3,
            "regionCode": "441781",
            "childrens": []
          }
        ]
      },
      {
        "id": 3145,
        "name": "清远市",
        "level": 2,
        "regionCode": "441800",
        "childrens": [
          {
            "id": 3146,
            "name": "清城区",
            "level": 3,
            "regionCode": "441802",
            "childrens": []
          },
          {
            "id": 3147,
            "name": "佛冈县",
            "level": 3,
            "regionCode": "441821",
            "childrens": []
          },
          {
            "id": 3148,
            "name": "阳山县",
            "level": 3,
            "regionCode": "441823",
            "childrens": []
          },
          {
            "id": 3150,
            "name": "连南瑶族自治县",
            "level": 3,
            "regionCode": "441826",
            "childrens": []
          },
          {
            "id": 3151,
            "name": "清新区",
            "level": 3,
            "regionCode": "441803",
            "childrens": []
          },
          {
            "id": 3152,
            "name": "英德市",
            "level": 3,
            "regionCode": "441881",
            "childrens": []
          },
          {
            "id": 3153,
            "name": "连州市",
            "level": 3,
            "regionCode": "441882",
            "childrens": []
          },
          {
            "id": 5245,
            "name": "连山壮族瑶族自治县",
            "level": 3,
            "regionCode": "441825",
            "childrens": []
          }
        ]
      },
      {
        "id": 3159,
        "name": "东莞市",
        "level": 2,
        "regionCode": "441900",
        "childrens": []
      },
      {
        "id": 3165,
        "name": "中山市",
        "level": 2,
        "regionCode": "442000",
        "childrens": []
      },
      {
        "id": 3171,
        "name": "潮州市",
        "level": 2,
        "regionCode": "445100",
        "childrens": [
          {
            "id": 3172,
            "name": "湘桥区",
            "level": 3,
            "regionCode": "445102",
            "childrens": []
          },
          {
            "id": 3173,
            "name": "潮安区",
            "level": 3,
            "regionCode": "445103",
            "childrens": []
          },
          {
            "id": 3174,
            "name": "饶平县",
            "level": 3,
            "regionCode": "445122",
            "childrens": []
          }
        ]
      },
      {
        "id": 3180,
        "name": "揭阳市",
        "level": 2,
        "regionCode": "445200",
        "childrens": [
          {
            "id": 3181,
            "name": "榕城区",
            "level": 3,
            "regionCode": "445202",
            "childrens": []
          },
          {
            "id": 3182,
            "name": "揭东区",
            "level": 3,
            "regionCode": "445203",
            "childrens": []
          },
          {
            "id": 3183,
            "name": "揭西县",
            "level": 3,
            "regionCode": "445222",
            "childrens": []
          },
          {
            "id": 3184,
            "name": "惠来县",
            "level": 3,
            "regionCode": "445224",
            "childrens": []
          },
          {
            "id": 3185,
            "name": "普宁市",
            "level": 3,
            "regionCode": "445281",
            "childrens": []
          }
        ]
      },
      {
        "id": 3191,
        "name": "云浮市",
        "level": 2,
        "regionCode": "445300",
        "childrens": [
          {
            "id": 3192,
            "name": "云城区",
            "level": 3,
            "regionCode": "445302",
            "childrens": []
          },
          {
            "id": 3193,
            "name": "新兴县",
            "level": 3,
            "regionCode": "445321",
            "childrens": []
          },
          {
            "id": 3194,
            "name": "郁南县",
            "level": 3,
            "regionCode": "445322",
            "childrens": []
          },
          {
            "id": 3195,
            "name": "云安区",
            "level": 3,
            "regionCode": "445303",
            "childrens": []
          },
          {
            "id": 3196,
            "name": "罗定市",
            "level": 3,
            "regionCode": "445381",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 3202,
    "name": "广西壮族自治区",
    "level": 1,
    "regionCode": "450000",
    "childrens": [
      {
        "id": 3203,
        "name": "南宁市",
        "level": 2,
        "regionCode": "450100",
        "childrens": [
          {
            "id": 3204,
            "name": "兴宁区",
            "level": 3,
            "regionCode": "450102",
            "childrens": []
          },
          {
            "id": 3205,
            "name": "青秀区",
            "level": 3,
            "regionCode": "450103",
            "childrens": []
          },
          {
            "id": 3206,
            "name": "江南区",
            "level": 3,
            "regionCode": "450105",
            "childrens": []
          },
          {
            "id": 3207,
            "name": "西乡塘区",
            "level": 3,
            "regionCode": "450107",
            "childrens": []
          },
          {
            "id": 3208,
            "name": "良庆区",
            "level": 3,
            "regionCode": "450108",
            "childrens": []
          },
          {
            "id": 3209,
            "name": "邕宁区",
            "level": 3,
            "regionCode": "450109",
            "childrens": []
          },
          {
            "id": 3210,
            "name": "武鸣区",
            "level": 3,
            "regionCode": "450110",
            "childrens": []
          },
          {
            "id": 3211,
            "name": "隆安县",
            "level": 3,
            "regionCode": "450123",
            "childrens": []
          },
          {
            "id": 3212,
            "name": "马山县",
            "level": 3,
            "regionCode": "450124",
            "childrens": []
          },
          {
            "id": 3213,
            "name": "上林县",
            "level": 3,
            "regionCode": "450125",
            "childrens": []
          },
          {
            "id": 3214,
            "name": "宾阳县",
            "level": 3,
            "regionCode": "450126",
            "childrens": []
          },
          {
            "id": 3215,
            "name": "横州市",
            "level": 3,
            "regionCode": "450127",
            "childrens": []
          }
        ]
      },
      {
        "id": 3221,
        "name": "柳州市",
        "level": 2,
        "regionCode": "450200",
        "childrens": [
          {
            "id": 3223,
            "name": "鱼峰区",
            "level": 3,
            "regionCode": "450203",
            "childrens": []
          },
          {
            "id": 3224,
            "name": "柳南区",
            "level": 3,
            "regionCode": "450204",
            "childrens": []
          },
          {
            "id": 3225,
            "name": "柳北区",
            "level": 3,
            "regionCode": "450205",
            "childrens": []
          },
          {
            "id": 3226,
            "name": "柳江区",
            "level": 3,
            "regionCode": "450206",
            "childrens": []
          },
          {
            "id": 3227,
            "name": "柳城县",
            "level": 3,
            "regionCode": "450222",
            "childrens": []
          },
          {
            "id": 3228,
            "name": "鹿寨县",
            "level": 3,
            "regionCode": "450223",
            "childrens": []
          },
          {
            "id": 3229,
            "name": "融安县",
            "level": 3,
            "regionCode": "450224",
            "childrens": []
          },
          {
            "id": 3230,
            "name": "融水苗族自治县",
            "level": 3,
            "regionCode": "450225",
            "childrens": []
          },
          {
            "id": 3231,
            "name": "三江侗族自治县",
            "level": 3,
            "regionCode": "450226",
            "childrens": []
          },
          {
            "id": 5246,
            "name": "城中区",
            "level": 3,
            "regionCode": "450202",
            "childrens": []
          }
        ]
      },
      {
        "id": 3237,
        "name": "桂林市",
        "level": 2,
        "regionCode": "450300",
        "childrens": [
          {
            "id": 3238,
            "name": "秀峰区",
            "level": 3,
            "regionCode": "450302",
            "childrens": []
          },
          {
            "id": 3239,
            "name": "叠彩区",
            "level": 3,
            "regionCode": "450303",
            "childrens": []
          },
          {
            "id": 3240,
            "name": "象山区",
            "level": 3,
            "regionCode": "450304",
            "childrens": []
          },
          {
            "id": 3241,
            "name": "七星区",
            "level": 3,
            "regionCode": "450305",
            "childrens": []
          },
          {
            "id": 3242,
            "name": "雁山区",
            "level": 3,
            "regionCode": "450311",
            "childrens": []
          },
          {
            "id": 3243,
            "name": "阳朔县",
            "level": 3,
            "regionCode": "450321",
            "childrens": []
          },
          {
            "id": 3244,
            "name": "临桂区",
            "level": 3,
            "regionCode": "450312",
            "childrens": []
          },
          {
            "id": 3245,
            "name": "灵川县",
            "level": 3,
            "regionCode": "450323",
            "childrens": []
          },
          {
            "id": 3246,
            "name": "全州县",
            "level": 3,
            "regionCode": "450324",
            "childrens": []
          },
          {
            "id": 3247,
            "name": "兴安县",
            "level": 3,
            "regionCode": "450325",
            "childrens": []
          },
          {
            "id": 3248,
            "name": "永福县",
            "level": 3,
            "regionCode": "450326",
            "childrens": []
          },
          {
            "id": 3249,
            "name": "灌阳县",
            "level": 3,
            "regionCode": "450327",
            "childrens": []
          },
          {
            "id": 3250,
            "name": "龙胜各族自治县",
            "level": 3,
            "regionCode": "450328",
            "childrens": []
          },
          {
            "id": 3251,
            "name": "资源县",
            "level": 3,
            "regionCode": "450329",
            "childrens": []
          },
          {
            "id": 3252,
            "name": "平乐县",
            "level": 3,
            "regionCode": "450330",
            "childrens": []
          },
          {
            "id": 3253,
            "name": "荔浦市",
            "level": 3,
            "regionCode": "450381",
            "childrens": []
          },
          {
            "id": 3254,
            "name": "恭城瑶族自治县",
            "level": 3,
            "regionCode": "450332",
            "childrens": []
          }
        ]
      },
      {
        "id": 3260,
        "name": "梧州市",
        "level": 2,
        "regionCode": "450400",
        "childrens": [
          {
            "id": 3261,
            "name": "万秀区",
            "level": 3,
            "regionCode": "450403",
            "childrens": []
          },
          {
            "id": 3263,
            "name": "长洲区",
            "level": 3,
            "regionCode": "450405",
            "childrens": []
          },
          {
            "id": 3264,
            "name": "苍梧县",
            "level": 3,
            "regionCode": "450421",
            "childrens": []
          },
          {
            "id": 3265,
            "name": "藤县",
            "level": 3,
            "regionCode": "450422",
            "childrens": []
          },
          {
            "id": 3266,
            "name": "蒙山县",
            "level": 3,
            "regionCode": "450423",
            "childrens": []
          },
          {
            "id": 3267,
            "name": "岑溪市",
            "level": 3,
            "regionCode": "450481",
            "childrens": []
          },
          {
            "id": 5247,
            "name": "龙圩区",
            "level": 3,
            "regionCode": "450406",
            "childrens": []
          }
        ]
      },
      {
        "id": 3273,
        "name": "北海市",
        "level": 2,
        "regionCode": "450500",
        "childrens": [
          {
            "id": 3274,
            "name": "海城区",
            "level": 3,
            "regionCode": "450502",
            "childrens": []
          },
          {
            "id": 3275,
            "name": "银海区",
            "level": 3,
            "regionCode": "450503",
            "childrens": []
          },
          {
            "id": 3276,
            "name": "铁山港区",
            "level": 3,
            "regionCode": "450512",
            "childrens": []
          },
          {
            "id": 3277,
            "name": "合浦县",
            "level": 3,
            "regionCode": "450521",
            "childrens": []
          }
        ]
      },
      {
        "id": 3283,
        "name": "防城港市",
        "level": 2,
        "regionCode": "450600",
        "childrens": [
          {
            "id": 3284,
            "name": "港口区",
            "level": 3,
            "regionCode": "450602",
            "childrens": []
          },
          {
            "id": 3285,
            "name": "防城区",
            "level": 3,
            "regionCode": "450603",
            "childrens": []
          },
          {
            "id": 3286,
            "name": "上思县",
            "level": 3,
            "regionCode": "450621",
            "childrens": []
          },
          {
            "id": 3287,
            "name": "东兴市",
            "level": 3,
            "regionCode": "450681",
            "childrens": []
          }
        ]
      },
      {
        "id": 3293,
        "name": "钦州市",
        "level": 2,
        "regionCode": "450700",
        "childrens": [
          {
            "id": 3294,
            "name": "钦南区",
            "level": 3,
            "regionCode": "450702",
            "childrens": []
          },
          {
            "id": 3295,
            "name": "钦北区",
            "level": 3,
            "regionCode": "450703",
            "childrens": []
          },
          {
            "id": 3296,
            "name": "灵山县",
            "level": 3,
            "regionCode": "450721",
            "childrens": []
          },
          {
            "id": 3297,
            "name": "浦北县",
            "level": 3,
            "regionCode": "450722",
            "childrens": []
          }
        ]
      },
      {
        "id": 3303,
        "name": "贵港市",
        "level": 2,
        "regionCode": "450800",
        "childrens": [
          {
            "id": 3304,
            "name": "港北区",
            "level": 3,
            "regionCode": "450802",
            "childrens": []
          },
          {
            "id": 3305,
            "name": "港南区",
            "level": 3,
            "regionCode": "450803",
            "childrens": []
          },
          {
            "id": 3306,
            "name": "覃塘区",
            "level": 3,
            "regionCode": "450804",
            "childrens": []
          },
          {
            "id": 3307,
            "name": "平南县",
            "level": 3,
            "regionCode": "450821",
            "childrens": []
          },
          {
            "id": 3308,
            "name": "桂平市",
            "level": 3,
            "regionCode": "450881",
            "childrens": []
          }
        ]
      },
      {
        "id": 3314,
        "name": "玉林市",
        "level": 2,
        "regionCode": "450900",
        "childrens": [
          {
            "id": 3315,
            "name": "玉州区",
            "level": 3,
            "regionCode": "450902",
            "childrens": []
          },
          {
            "id": 3316,
            "name": "容县",
            "level": 3,
            "regionCode": "450921",
            "childrens": []
          },
          {
            "id": 3317,
            "name": "陆川县",
            "level": 3,
            "regionCode": "450922",
            "childrens": []
          },
          {
            "id": 3318,
            "name": "博白县",
            "level": 3,
            "regionCode": "450923",
            "childrens": []
          },
          {
            "id": 3319,
            "name": "兴业县",
            "level": 3,
            "regionCode": "450924",
            "childrens": []
          },
          {
            "id": 3320,
            "name": "北流市",
            "level": 3,
            "regionCode": "450981",
            "childrens": []
          },
          {
            "id": 5248,
            "name": "福绵区",
            "level": 3,
            "regionCode": "450903",
            "childrens": []
          }
        ]
      },
      {
        "id": 3326,
        "name": "百色市",
        "level": 2,
        "regionCode": "451000",
        "childrens": [
          {
            "id": 3327,
            "name": "右江区",
            "level": 3,
            "regionCode": "451002",
            "childrens": []
          },
          {
            "id": 3328,
            "name": "田阳区",
            "level": 3,
            "regionCode": "451003",
            "childrens": []
          },
          {
            "id": 3329,
            "name": "田东县",
            "level": 3,
            "regionCode": "451022",
            "childrens": []
          },
          {
            "id": 3330,
            "name": "平果市",
            "level": 3,
            "regionCode": "451082",
            "childrens": []
          },
          {
            "id": 3331,
            "name": "德保县",
            "level": 3,
            "regionCode": "451024",
            "childrens": []
          },
          {
            "id": 3332,
            "name": "靖西市",
            "level": 3,
            "regionCode": "451081",
            "childrens": []
          },
          {
            "id": 3333,
            "name": "那坡县",
            "level": 3,
            "regionCode": "451026",
            "childrens": []
          },
          {
            "id": 3334,
            "name": "凌云县",
            "level": 3,
            "regionCode": "451027",
            "childrens": []
          },
          {
            "id": 3335,
            "name": "乐业县",
            "level": 3,
            "regionCode": "451028",
            "childrens": []
          },
          {
            "id": 3336,
            "name": "田林县",
            "level": 3,
            "regionCode": "451029",
            "childrens": []
          },
          {
            "id": 3337,
            "name": "西林县",
            "level": 3,
            "regionCode": "451030",
            "childrens": []
          },
          {
            "id": 3338,
            "name": "隆林各族自治县",
            "level": 3,
            "regionCode": "451031",
            "childrens": []
          }
        ]
      },
      {
        "id": 3344,
        "name": "贺州市",
        "level": 2,
        "regionCode": "451100",
        "childrens": [
          {
            "id": 3345,
            "name": "八步区",
            "level": 3,
            "regionCode": "451102",
            "childrens": []
          },
          {
            "id": 3346,
            "name": "昭平县",
            "level": 3,
            "regionCode": "451121",
            "childrens": []
          },
          {
            "id": 3347,
            "name": "钟山县",
            "level": 3,
            "regionCode": "451122",
            "childrens": []
          },
          {
            "id": 3348,
            "name": "富川瑶族自治县",
            "level": 3,
            "regionCode": "451123",
            "childrens": []
          },
          {
            "id": 5249,
            "name": "平桂区",
            "level": 3,
            "regionCode": "451103",
            "childrens": []
          }
        ]
      },
      {
        "id": 3354,
        "name": "河池市",
        "level": 2,
        "regionCode": "451200",
        "childrens": [
          {
            "id": 3355,
            "name": "金城江区",
            "level": 3,
            "regionCode": "451202",
            "childrens": []
          },
          {
            "id": 3356,
            "name": "南丹县",
            "level": 3,
            "regionCode": "451221",
            "childrens": []
          },
          {
            "id": 3357,
            "name": "天峨县",
            "level": 3,
            "regionCode": "451222",
            "childrens": []
          },
          {
            "id": 3358,
            "name": "凤山县",
            "level": 3,
            "regionCode": "451223",
            "childrens": []
          },
          {
            "id": 3359,
            "name": "东兰县",
            "level": 3,
            "regionCode": "451224",
            "childrens": []
          },
          {
            "id": 3360,
            "name": "罗城仫佬族自治县",
            "level": 3,
            "regionCode": "451225",
            "childrens": []
          },
          {
            "id": 3361,
            "name": "环江毛南族自治县",
            "level": 3,
            "regionCode": "451226",
            "childrens": []
          },
          {
            "id": 3362,
            "name": "巴马瑶族自治县",
            "level": 3,
            "regionCode": "451227",
            "childrens": []
          },
          {
            "id": 3363,
            "name": "都安瑶族自治县",
            "level": 3,
            "regionCode": "451228",
            "childrens": []
          },
          {
            "id": 3364,
            "name": "大化瑶族自治县",
            "level": 3,
            "regionCode": "451229",
            "childrens": []
          },
          {
            "id": 3365,
            "name": "宜州区",
            "level": 3,
            "regionCode": "451203",
            "childrens": []
          }
        ]
      },
      {
        "id": 3371,
        "name": "来宾市",
        "level": 2,
        "regionCode": "451300",
        "childrens": [
          {
            "id": 3372,
            "name": "兴宾区",
            "level": 3,
            "regionCode": "451302",
            "childrens": []
          },
          {
            "id": 3373,
            "name": "忻城县",
            "level": 3,
            "regionCode": "451321",
            "childrens": []
          },
          {
            "id": 3374,
            "name": "象州县",
            "level": 3,
            "regionCode": "451322",
            "childrens": []
          },
          {
            "id": 3375,
            "name": "武宣县",
            "level": 3,
            "regionCode": "451323",
            "childrens": []
          },
          {
            "id": 3376,
            "name": "金秀瑶族自治县",
            "level": 3,
            "regionCode": "451324",
            "childrens": []
          },
          {
            "id": 3377,
            "name": "合山市",
            "level": 3,
            "regionCode": "451381",
            "childrens": []
          }
        ]
      },
      {
        "id": 3383,
        "name": "崇左市",
        "level": 2,
        "regionCode": "451400",
        "childrens": [
          {
            "id": 3384,
            "name": "江州区",
            "level": 3,
            "regionCode": "451402",
            "childrens": []
          },
          {
            "id": 3385,
            "name": "扶绥县",
            "level": 3,
            "regionCode": "451421",
            "childrens": []
          },
          {
            "id": 3386,
            "name": "宁明县",
            "level": 3,
            "regionCode": "451422",
            "childrens": []
          },
          {
            "id": 3387,
            "name": "龙州县",
            "level": 3,
            "regionCode": "451423",
            "childrens": []
          },
          {
            "id": 3388,
            "name": "大新县",
            "level": 3,
            "regionCode": "451424",
            "childrens": []
          },
          {
            "id": 3389,
            "name": "天等县",
            "level": 3,
            "regionCode": "451425",
            "childrens": []
          },
          {
            "id": 3390,
            "name": "凭祥市",
            "level": 3,
            "regionCode": "451481",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 3396,
    "name": "海南省",
    "level": 1,
    "regionCode": "460000",
    "childrens": [
      {
        "id": 3397,
        "name": "海口市",
        "level": 2,
        "regionCode": "460100",
        "childrens": [
          {
            "id": 3398,
            "name": "秀英区",
            "level": 3,
            "regionCode": "460105",
            "childrens": []
          },
          {
            "id": 3399,
            "name": "龙华区",
            "level": 3,
            "regionCode": "460106",
            "childrens": []
          },
          {
            "id": 3400,
            "name": "琼山区",
            "level": 3,
            "regionCode": "460107",
            "childrens": []
          },
          {
            "id": 3401,
            "name": "美兰区",
            "level": 3,
            "regionCode": "460108",
            "childrens": []
          }
        ]
      },
      {
        "id": 3407,
        "name": "三亚市",
        "level": 2,
        "regionCode": "460200",
        "childrens": [
          {
            "id": 5250,
            "name": "海棠区",
            "level": 3,
            "regionCode": "460202",
            "childrens": []
          },
          {
            "id": 5251,
            "name": "吉阳区",
            "level": 3,
            "regionCode": "460203",
            "childrens": []
          },
          {
            "id": 5252,
            "name": "天涯区",
            "level": 3,
            "regionCode": "460204",
            "childrens": []
          },
          {
            "id": 5253,
            "name": "崖州区",
            "level": 3,
            "regionCode": "460205",
            "childrens": []
          }
        ]
      },
      {
        "id": 4970,
        "name": "三沙市",
        "level": 2,
        "regionCode": "460300",
        "childrens": [
          {
            "id": 5053,
            "name": "西沙区",
            "level": 3,
            "regionCode": "460301",
            "childrens": []
          },
          {
            "id": 5054,
            "name": "南沙区",
            "level": 3,
            "regionCode": "460302",
            "childrens": []
          }
        ]
      },
      {
        "id": 4971,
        "name": "儋州市",
        "level": 2,
        "regionCode": "460400",
        "childrens": []
      },
      {
        "id": 4972,
        "name": "五指山市",
        "level": 2,
        "regionCode": "469001",
        "childrens": []
      },
      {
        "id": 4973,
        "name": "琼海市",
        "level": 2,
        "regionCode": "469002",
        "childrens": []
      },
      {
        "id": 4974,
        "name": "文昌市",
        "level": 2,
        "regionCode": "469005",
        "childrens": []
      },
      {
        "id": 4975,
        "name": "万宁市",
        "level": 2,
        "regionCode": "469006",
        "childrens": []
      },
      {
        "id": 4976,
        "name": "东方市",
        "level": 2,
        "regionCode": "469007",
        "childrens": []
      },
      {
        "id": 4977,
        "name": "定安县",
        "level": 2,
        "regionCode": "469021",
        "childrens": []
      },
      {
        "id": 4978,
        "name": "屯昌县",
        "level": 2,
        "regionCode": "469022",
        "childrens": []
      },
      {
        "id": 4979,
        "name": "澄迈县",
        "level": 2,
        "regionCode": "469023",
        "childrens": []
      },
      {
        "id": 4980,
        "name": "临高县",
        "level": 2,
        "regionCode": "469024",
        "childrens": []
      },
      {
        "id": 4981,
        "name": "白沙黎族自治县",
        "level": 2,
        "regionCode": "469025",
        "childrens": []
      },
      {
        "id": 4982,
        "name": "昌江黎族自治县",
        "level": 2,
        "regionCode": "469026",
        "childrens": []
      },
      {
        "id": 4983,
        "name": "乐东黎族自治县",
        "level": 2,
        "regionCode": "469027",
        "childrens": []
      },
      {
        "id": 4984,
        "name": "陵水黎族自治县",
        "level": 2,
        "regionCode": "469028",
        "childrens": []
      },
      {
        "id": 4985,
        "name": "保亭黎族苗族自治县",
        "level": 2,
        "regionCode": "469029",
        "childrens": []
      },
      {
        "id": 4986,
        "name": "琼中黎族苗族自治县",
        "level": 2,
        "regionCode": "469030",
        "childrens": []
      }
    ]
  },
  {
    "id": 3438,
    "name": "重庆市",
    "level": 1,
    "regionCode": "500000",
    "childrens": [
      {
        "id": 4995,
        "name": "石柱土家族自治县",
        "level": 2,
        "regionCode": "500240",
        "childrens": []
      },
      {
        "id": 4996,
        "name": "秀山土家族苗族自治县",
        "level": 2,
        "regionCode": "500241",
        "childrens": []
      },
      {
        "id": 4997,
        "name": "酉阳土家族苗族自治县",
        "level": 2,
        "regionCode": "500242",
        "childrens": []
      },
      {
        "id": 4998,
        "name": "彭水苗族土家族自治县",
        "level": 2,
        "regionCode": "500243",
        "childrens": []
      }
    ]
  },
  {
    "id": 3484,
    "name": "四川省",
    "level": 1,
    "regionCode": "510000",
    "childrens": [
      {
        "id": 3485,
        "name": "成都市",
        "level": 2,
        "regionCode": "510100",
        "childrens": [
          {
            "id": 3486,
            "name": "锦江区",
            "level": 3,
            "regionCode": "510104",
            "childrens": []
          },
          {
            "id": 3487,
            "name": "青羊区",
            "level": 3,
            "regionCode": "510105",
            "childrens": []
          },
          {
            "id": 3488,
            "name": "金牛区",
            "level": 3,
            "regionCode": "510106",
            "childrens": []
          },
          {
            "id": 3489,
            "name": "武侯区",
            "level": 3,
            "regionCode": "510107",
            "childrens": []
          },
          {
            "id": 3490,
            "name": "成华区",
            "level": 3,
            "regionCode": "510108",
            "childrens": []
          },
          {
            "id": 3491,
            "name": "龙泉驿区",
            "level": 3,
            "regionCode": "510112",
            "childrens": []
          },
          {
            "id": 3492,
            "name": "青白江区",
            "level": 3,
            "regionCode": "510113",
            "childrens": []
          },
          {
            "id": 3493,
            "name": "新都区",
            "level": 3,
            "regionCode": "510114",
            "childrens": []
          },
          {
            "id": 3494,
            "name": "温江区",
            "level": 3,
            "regionCode": "510115",
            "childrens": []
          },
          {
            "id": 3495,
            "name": "金堂县",
            "level": 3,
            "regionCode": "510121",
            "childrens": []
          },
          {
            "id": 3496,
            "name": "双流区",
            "level": 3,
            "regionCode": "510116",
            "childrens": []
          },
          {
            "id": 3498,
            "name": "大邑县",
            "level": 3,
            "regionCode": "510129",
            "childrens": []
          },
          {
            "id": 3499,
            "name": "蒲江县",
            "level": 3,
            "regionCode": "510131",
            "childrens": []
          },
          {
            "id": 3500,
            "name": "新津区",
            "level": 3,
            "regionCode": "510118",
            "childrens": []
          },
          {
            "id": 3501,
            "name": "都江堰市",
            "level": 3,
            "regionCode": "510181",
            "childrens": []
          },
          {
            "id": 3502,
            "name": "彭州市",
            "level": 3,
            "regionCode": "510182",
            "childrens": []
          },
          {
            "id": 3503,
            "name": "邛崃市",
            "level": 3,
            "regionCode": "510183",
            "childrens": []
          },
          {
            "id": 3504,
            "name": "崇州市",
            "level": 3,
            "regionCode": "510184",
            "childrens": []
          },
          {
            "id": 5254,
            "name": "郫都区",
            "level": 3,
            "regionCode": "510117",
            "childrens": []
          },
          {
            "id": 5255,
            "name": "简阳市",
            "level": 3,
            "regionCode": "510185",
            "childrens": []
          }
        ]
      },
      {
        "id": 3510,
        "name": "自贡市",
        "level": 2,
        "regionCode": "510300",
        "childrens": [
          {
            "id": 3511,
            "name": "自流井区",
            "level": 3,
            "regionCode": "510302",
            "childrens": []
          },
          {
            "id": 3512,
            "name": "贡井区",
            "level": 3,
            "regionCode": "510303",
            "childrens": []
          },
          {
            "id": 3513,
            "name": "大安区",
            "level": 3,
            "regionCode": "510304",
            "childrens": []
          },
          {
            "id": 3514,
            "name": "沿滩区",
            "level": 3,
            "regionCode": "510311",
            "childrens": []
          },
          {
            "id": 3515,
            "name": "荣县",
            "level": 3,
            "regionCode": "510321",
            "childrens": []
          },
          {
            "id": 3516,
            "name": "富顺县",
            "level": 3,
            "regionCode": "510322",
            "childrens": []
          }
        ]
      },
      {
        "id": 3522,
        "name": "攀枝花市",
        "level": 2,
        "regionCode": "510400",
        "childrens": [
          {
            "id": 3524,
            "name": "西区",
            "level": 3,
            "regionCode": "510403",
            "childrens": []
          },
          {
            "id": 3525,
            "name": "仁和区",
            "level": 3,
            "regionCode": "510411",
            "childrens": []
          },
          {
            "id": 3526,
            "name": "米易县",
            "level": 3,
            "regionCode": "510421",
            "childrens": []
          },
          {
            "id": 3527,
            "name": "盐边县",
            "level": 3,
            "regionCode": "510422",
            "childrens": []
          },
          {
            "id": 5256,
            "name": "东区",
            "level": 3,
            "regionCode": "510402",
            "childrens": []
          }
        ]
      },
      {
        "id": 3533,
        "name": "泸州市",
        "level": 2,
        "regionCode": "510500",
        "childrens": [
          {
            "id": 3534,
            "name": "江阳区",
            "level": 3,
            "regionCode": "510502",
            "childrens": []
          },
          {
            "id": 3535,
            "name": "纳溪区",
            "level": 3,
            "regionCode": "510503",
            "childrens": []
          },
          {
            "id": 3536,
            "name": "龙马潭区",
            "level": 3,
            "regionCode": "510504",
            "childrens": []
          },
          {
            "id": 3537,
            "name": "泸县",
            "level": 3,
            "regionCode": "510521",
            "childrens": []
          },
          {
            "id": 3538,
            "name": "合江县",
            "level": 3,
            "regionCode": "510522",
            "childrens": []
          },
          {
            "id": 3539,
            "name": "叙永县",
            "level": 3,
            "regionCode": "510524",
            "childrens": []
          },
          {
            "id": 3540,
            "name": "古蔺县",
            "level": 3,
            "regionCode": "510525",
            "childrens": []
          }
        ]
      },
      {
        "id": 3546,
        "name": "德阳市",
        "level": 2,
        "regionCode": "510600",
        "childrens": [
          {
            "id": 3547,
            "name": "旌阳区",
            "level": 3,
            "regionCode": "510603",
            "childrens": []
          },
          {
            "id": 3548,
            "name": "中江县",
            "level": 3,
            "regionCode": "510623",
            "childrens": []
          },
          {
            "id": 3549,
            "name": "罗江区",
            "level": 3,
            "regionCode": "510604",
            "childrens": []
          },
          {
            "id": 3550,
            "name": "广汉市",
            "level": 3,
            "regionCode": "510681",
            "childrens": []
          },
          {
            "id": 3551,
            "name": "什邡市",
            "level": 3,
            "regionCode": "510682",
            "childrens": []
          },
          {
            "id": 3552,
            "name": "绵竹市",
            "level": 3,
            "regionCode": "510683",
            "childrens": []
          }
        ]
      },
      {
        "id": 3558,
        "name": "绵阳市",
        "level": 2,
        "regionCode": "510700",
        "childrens": [
          {
            "id": 3559,
            "name": "涪城区",
            "level": 3,
            "regionCode": "510703",
            "childrens": []
          },
          {
            "id": 3560,
            "name": "游仙区",
            "level": 3,
            "regionCode": "510704",
            "childrens": []
          },
          {
            "id": 3561,
            "name": "三台县",
            "level": 3,
            "regionCode": "510722",
            "childrens": []
          },
          {
            "id": 3562,
            "name": "盐亭县",
            "level": 3,
            "regionCode": "510723",
            "childrens": []
          },
          {
            "id": 3564,
            "name": "梓潼县",
            "level": 3,
            "regionCode": "510725",
            "childrens": []
          },
          {
            "id": 3565,
            "name": "北川羌族自治县",
            "level": 3,
            "regionCode": "510726",
            "childrens": []
          },
          {
            "id": 3566,
            "name": "平武县",
            "level": 3,
            "regionCode": "510727",
            "childrens": []
          },
          {
            "id": 3567,
            "name": "江油市",
            "level": 3,
            "regionCode": "510781",
            "childrens": []
          },
          {
            "id": 5257,
            "name": "安州区",
            "level": 3,
            "regionCode": "510705",
            "childrens": []
          }
        ]
      },
      {
        "id": 3573,
        "name": "广元市",
        "level": 2,
        "regionCode": "510800",
        "childrens": [
          {
            "id": 3575,
            "name": "昭化区",
            "level": 3,
            "regionCode": "510811",
            "childrens": []
          },
          {
            "id": 3576,
            "name": "朝天区",
            "level": 3,
            "regionCode": "510812",
            "childrens": []
          },
          {
            "id": 3577,
            "name": "旺苍县",
            "level": 3,
            "regionCode": "510821",
            "childrens": []
          },
          {
            "id": 3578,
            "name": "青川县",
            "level": 3,
            "regionCode": "510822",
            "childrens": []
          },
          {
            "id": 3579,
            "name": "剑阁县",
            "level": 3,
            "regionCode": "510823",
            "childrens": []
          },
          {
            "id": 3580,
            "name": "苍溪县",
            "level": 3,
            "regionCode": "510824",
            "childrens": []
          },
          {
            "id": 5258,
            "name": "利州区",
            "level": 3,
            "regionCode": "510802",
            "childrens": []
          }
        ]
      },
      {
        "id": 3586,
        "name": "遂宁市",
        "level": 2,
        "regionCode": "510900",
        "childrens": [
          {
            "id": 3587,
            "name": "船山区",
            "level": 3,
            "regionCode": "510903",
            "childrens": []
          },
          {
            "id": 3588,
            "name": "安居区",
            "level": 3,
            "regionCode": "510904",
            "childrens": []
          },
          {
            "id": 3589,
            "name": "蓬溪县",
            "level": 3,
            "regionCode": "510921",
            "childrens": []
          },
          {
            "id": 3590,
            "name": "射洪市",
            "level": 3,
            "regionCode": "510981",
            "childrens": []
          },
          {
            "id": 3591,
            "name": "大英县",
            "level": 3,
            "regionCode": "510923",
            "childrens": []
          }
        ]
      },
      {
        "id": 3597,
        "name": "内江市",
        "level": 2,
        "regionCode": "511000",
        "childrens": [
          {
            "id": 3599,
            "name": "东兴区",
            "level": 3,
            "regionCode": "511011",
            "childrens": []
          },
          {
            "id": 3600,
            "name": "威远县",
            "level": 3,
            "regionCode": "511024",
            "childrens": []
          },
          {
            "id": 3601,
            "name": "资中县",
            "level": 3,
            "regionCode": "511025",
            "childrens": []
          },
          {
            "id": 3602,
            "name": "隆昌市",
            "level": 3,
            "regionCode": "511083",
            "childrens": []
          },
          {
            "id": 5259,
            "name": "市中区",
            "level": 3,
            "regionCode": "511002",
            "childrens": []
          }
        ]
      },
      {
        "id": 3608,
        "name": "乐山市",
        "level": 2,
        "regionCode": "511100",
        "childrens": [
          {
            "id": 3610,
            "name": "沙湾区",
            "level": 3,
            "regionCode": "511111",
            "childrens": []
          },
          {
            "id": 3611,
            "name": "五通桥区",
            "level": 3,
            "regionCode": "511112",
            "childrens": []
          },
          {
            "id": 3612,
            "name": "金口河区",
            "level": 3,
            "regionCode": "511113",
            "childrens": []
          },
          {
            "id": 3613,
            "name": "犍为县",
            "level": 3,
            "regionCode": "511123",
            "childrens": []
          },
          {
            "id": 3614,
            "name": "井研县",
            "level": 3,
            "regionCode": "511124",
            "childrens": []
          },
          {
            "id": 3615,
            "name": "夹江县",
            "level": 3,
            "regionCode": "511126",
            "childrens": []
          },
          {
            "id": 3616,
            "name": "沐川县",
            "level": 3,
            "regionCode": "511129",
            "childrens": []
          },
          {
            "id": 3617,
            "name": "峨边彝族自治县",
            "level": 3,
            "regionCode": "511132",
            "childrens": []
          },
          {
            "id": 3618,
            "name": "马边彝族自治县",
            "level": 3,
            "regionCode": "511133",
            "childrens": []
          },
          {
            "id": 3619,
            "name": "峨眉山市",
            "level": 3,
            "regionCode": "511181",
            "childrens": []
          },
          {
            "id": 5260,
            "name": "市中区",
            "level": 3,
            "regionCode": "511102",
            "childrens": []
          }
        ]
      },
      {
        "id": 3625,
        "name": "南充市",
        "level": 2,
        "regionCode": "511300",
        "childrens": [
          {
            "id": 3626,
            "name": "顺庆区",
            "level": 3,
            "regionCode": "511302",
            "childrens": []
          },
          {
            "id": 3627,
            "name": "高坪区",
            "level": 3,
            "regionCode": "511303",
            "childrens": []
          },
          {
            "id": 3628,
            "name": "嘉陵区",
            "level": 3,
            "regionCode": "511304",
            "childrens": []
          },
          {
            "id": 3629,
            "name": "南部县",
            "level": 3,
            "regionCode": "511321",
            "childrens": []
          },
          {
            "id": 3630,
            "name": "营山县",
            "level": 3,
            "regionCode": "511322",
            "childrens": []
          },
          {
            "id": 3631,
            "name": "蓬安县",
            "level": 3,
            "regionCode": "511323",
            "childrens": []
          },
          {
            "id": 3632,
            "name": "仪陇县",
            "level": 3,
            "regionCode": "511324",
            "childrens": []
          },
          {
            "id": 3633,
            "name": "西充县",
            "level": 3,
            "regionCode": "511325",
            "childrens": []
          },
          {
            "id": 3634,
            "name": "阆中市",
            "level": 3,
            "regionCode": "511381",
            "childrens": []
          }
        ]
      },
      {
        "id": 3640,
        "name": "眉山市",
        "level": 2,
        "regionCode": "511400",
        "childrens": [
          {
            "id": 3641,
            "name": "东坡区",
            "level": 3,
            "regionCode": "511402",
            "childrens": []
          },
          {
            "id": 3642,
            "name": "仁寿县",
            "level": 3,
            "regionCode": "511421",
            "childrens": []
          },
          {
            "id": 3643,
            "name": "彭山区",
            "level": 3,
            "regionCode": "511403",
            "childrens": []
          },
          {
            "id": 3644,
            "name": "洪雅县",
            "level": 3,
            "regionCode": "511423",
            "childrens": []
          },
          {
            "id": 3645,
            "name": "丹棱县",
            "level": 3,
            "regionCode": "511424",
            "childrens": []
          },
          {
            "id": 3646,
            "name": "青神县",
            "level": 3,
            "regionCode": "511425",
            "childrens": []
          }
        ]
      },
      {
        "id": 3652,
        "name": "宜宾市",
        "level": 2,
        "regionCode": "511500",
        "childrens": [
          {
            "id": 3653,
            "name": "翠屏区",
            "level": 3,
            "regionCode": "511502",
            "childrens": []
          },
          {
            "id": 3655,
            "name": "南溪区",
            "level": 3,
            "regionCode": "511503",
            "childrens": []
          },
          {
            "id": 3656,
            "name": "江安县",
            "level": 3,
            "regionCode": "511523",
            "childrens": []
          },
          {
            "id": 3657,
            "name": "长宁县",
            "level": 3,
            "regionCode": "511524",
            "childrens": []
          },
          {
            "id": 3658,
            "name": "高县",
            "level": 3,
            "regionCode": "511525",
            "childrens": []
          },
          {
            "id": 3659,
            "name": "珙县",
            "level": 3,
            "regionCode": "511526",
            "childrens": []
          },
          {
            "id": 3660,
            "name": "筠连县",
            "level": 3,
            "regionCode": "511527",
            "childrens": []
          },
          {
            "id": 3661,
            "name": "兴文县",
            "level": 3,
            "regionCode": "511528",
            "childrens": []
          },
          {
            "id": 3662,
            "name": "屏山县",
            "level": 3,
            "regionCode": "511529",
            "childrens": []
          },
          {
            "id": 5261,
            "name": "叙州区",
            "level": 3,
            "regionCode": "511504",
            "childrens": []
          }
        ]
      },
      {
        "id": 3668,
        "name": "广安市",
        "level": 2,
        "regionCode": "511600",
        "childrens": [
          {
            "id": 3669,
            "name": "广安区",
            "level": 3,
            "regionCode": "511602",
            "childrens": []
          },
          {
            "id": 3670,
            "name": "岳池县",
            "level": 3,
            "regionCode": "511621",
            "childrens": []
          },
          {
            "id": 3671,
            "name": "武胜县",
            "level": 3,
            "regionCode": "511622",
            "childrens": []
          },
          {
            "id": 3672,
            "name": "邻水县",
            "level": 3,
            "regionCode": "511623",
            "childrens": []
          },
          {
            "id": 3673,
            "name": "华蓥市",
            "level": 3,
            "regionCode": "511681",
            "childrens": []
          },
          {
            "id": 5262,
            "name": "前锋区",
            "level": 3,
            "regionCode": "511603",
            "childrens": []
          }
        ]
      },
      {
        "id": 3679,
        "name": "达州市",
        "level": 2,
        "regionCode": "511700",
        "childrens": [
          {
            "id": 3680,
            "name": "通川区",
            "level": 3,
            "regionCode": "511702",
            "childrens": []
          },
          {
            "id": 3682,
            "name": "宣汉县",
            "level": 3,
            "regionCode": "511722",
            "childrens": []
          },
          {
            "id": 3683,
            "name": "开江县",
            "level": 3,
            "regionCode": "511723",
            "childrens": []
          },
          {
            "id": 3684,
            "name": "大竹县",
            "level": 3,
            "regionCode": "511724",
            "childrens": []
          },
          {
            "id": 3685,
            "name": "渠县",
            "level": 3,
            "regionCode": "511725",
            "childrens": []
          },
          {
            "id": 3686,
            "name": "万源市",
            "level": 3,
            "regionCode": "511781",
            "childrens": []
          },
          {
            "id": 5263,
            "name": "达川区",
            "level": 3,
            "regionCode": "511703",
            "childrens": []
          }
        ]
      },
      {
        "id": 3692,
        "name": "雅安市",
        "level": 2,
        "regionCode": "511800",
        "childrens": [
          {
            "id": 3693,
            "name": "雨城区",
            "level": 3,
            "regionCode": "511802",
            "childrens": []
          },
          {
            "id": 3694,
            "name": "名山区",
            "level": 3,
            "regionCode": "511803",
            "childrens": []
          },
          {
            "id": 3695,
            "name": "荥经县",
            "level": 3,
            "regionCode": "511822",
            "childrens": []
          },
          {
            "id": 3696,
            "name": "汉源县",
            "level": 3,
            "regionCode": "511823",
            "childrens": []
          },
          {
            "id": 3697,
            "name": "石棉县",
            "level": 3,
            "regionCode": "511824",
            "childrens": []
          },
          {
            "id": 3698,
            "name": "天全县",
            "level": 3,
            "regionCode": "511825",
            "childrens": []
          },
          {
            "id": 3699,
            "name": "芦山县",
            "level": 3,
            "regionCode": "511826",
            "childrens": []
          },
          {
            "id": 3700,
            "name": "宝兴县",
            "level": 3,
            "regionCode": "511827",
            "childrens": []
          }
        ]
      },
      {
        "id": 3706,
        "name": "巴中市",
        "level": 2,
        "regionCode": "511900",
        "childrens": [
          {
            "id": 3707,
            "name": "巴州区",
            "level": 3,
            "regionCode": "511902",
            "childrens": []
          },
          {
            "id": 3708,
            "name": "通江县",
            "level": 3,
            "regionCode": "511921",
            "childrens": []
          },
          {
            "id": 3709,
            "name": "南江县",
            "level": 3,
            "regionCode": "511922",
            "childrens": []
          },
          {
            "id": 3710,
            "name": "平昌县",
            "level": 3,
            "regionCode": "511923",
            "childrens": []
          },
          {
            "id": 5264,
            "name": "恩阳区",
            "level": 3,
            "regionCode": "511903",
            "childrens": []
          }
        ]
      },
      {
        "id": 3716,
        "name": "资阳市",
        "level": 2,
        "regionCode": "512000",
        "childrens": [
          {
            "id": 3717,
            "name": "雁江区",
            "level": 3,
            "regionCode": "512002",
            "childrens": []
          },
          {
            "id": 3718,
            "name": "安岳县",
            "level": 3,
            "regionCode": "512021",
            "childrens": []
          },
          {
            "id": 3719,
            "name": "乐至县",
            "level": 3,
            "regionCode": "512022",
            "childrens": []
          }
        ]
      },
      {
        "id": 3726,
        "name": "阿坝藏族羌族自治州",
        "level": 2,
        "regionCode": "513200",
        "childrens": [
          {
            "id": 3727,
            "name": "汶川县",
            "level": 3,
            "regionCode": "513221",
            "childrens": []
          },
          {
            "id": 3728,
            "name": "理县",
            "level": 3,
            "regionCode": "513222",
            "childrens": []
          },
          {
            "id": 3729,
            "name": "茂县",
            "level": 3,
            "regionCode": "513223",
            "childrens": []
          },
          {
            "id": 3730,
            "name": "松潘县",
            "level": 3,
            "regionCode": "513224",
            "childrens": []
          },
          {
            "id": 3731,
            "name": "九寨沟县",
            "level": 3,
            "regionCode": "513225",
            "childrens": []
          },
          {
            "id": 3732,
            "name": "金川县",
            "level": 3,
            "regionCode": "513226",
            "childrens": []
          },
          {
            "id": 3733,
            "name": "小金县",
            "level": 3,
            "regionCode": "513227",
            "childrens": []
          },
          {
            "id": 3734,
            "name": "黑水县",
            "level": 3,
            "regionCode": "513228",
            "childrens": []
          },
          {
            "id": 3735,
            "name": "马尔康市",
            "level": 3,
            "regionCode": "513201",
            "childrens": []
          },
          {
            "id": 3736,
            "name": "壤塘县",
            "level": 3,
            "regionCode": "513230",
            "childrens": []
          },
          {
            "id": 3737,
            "name": "阿坝县",
            "level": 3,
            "regionCode": "513231",
            "childrens": []
          },
          {
            "id": 3738,
            "name": "若尔盖县",
            "level": 3,
            "regionCode": "513232",
            "childrens": []
          },
          {
            "id": 3739,
            "name": "红原县",
            "level": 3,
            "regionCode": "513233",
            "childrens": []
          }
        ]
      },
      {
        "id": 3745,
        "name": "甘孜藏族自治州",
        "level": 2,
        "regionCode": "513300",
        "childrens": [
          {
            "id": 3746,
            "name": "康定市",
            "level": 3,
            "regionCode": "513301",
            "childrens": []
          },
          {
            "id": 3747,
            "name": "泸定县",
            "level": 3,
            "regionCode": "513322",
            "childrens": []
          },
          {
            "id": 3748,
            "name": "丹巴县",
            "level": 3,
            "regionCode": "513323",
            "childrens": []
          },
          {
            "id": 3749,
            "name": "九龙县",
            "level": 3,
            "regionCode": "513324",
            "childrens": []
          },
          {
            "id": 3750,
            "name": "雅江县",
            "level": 3,
            "regionCode": "513325",
            "childrens": []
          },
          {
            "id": 3751,
            "name": "道孚县",
            "level": 3,
            "regionCode": "513326",
            "childrens": []
          },
          {
            "id": 3752,
            "name": "炉霍县",
            "level": 3,
            "regionCode": "513327",
            "childrens": []
          },
          {
            "id": 3754,
            "name": "新龙县",
            "level": 3,
            "regionCode": "513329",
            "childrens": []
          },
          {
            "id": 3755,
            "name": "德格县",
            "level": 3,
            "regionCode": "513330",
            "childrens": []
          },
          {
            "id": 3756,
            "name": "白玉县",
            "level": 3,
            "regionCode": "513331",
            "childrens": []
          },
          {
            "id": 3757,
            "name": "石渠县",
            "level": 3,
            "regionCode": "513332",
            "childrens": []
          },
          {
            "id": 3759,
            "name": "理塘县",
            "level": 3,
            "regionCode": "513334",
            "childrens": []
          },
          {
            "id": 3760,
            "name": "巴塘县",
            "level": 3,
            "regionCode": "513335",
            "childrens": []
          },
          {
            "id": 3761,
            "name": "乡城县",
            "level": 3,
            "regionCode": "513336",
            "childrens": []
          },
          {
            "id": 3762,
            "name": "稻城县",
            "level": 3,
            "regionCode": "513337",
            "childrens": []
          },
          {
            "id": 3763,
            "name": "得荣县",
            "level": 3,
            "regionCode": "513338",
            "childrens": []
          },
          {
            "id": 5265,
            "name": "甘孜县",
            "level": 3,
            "regionCode": "513328",
            "childrens": []
          },
          {
            "id": 5266,
            "name": "色达县",
            "level": 3,
            "regionCode": "513333",
            "childrens": []
          }
        ]
      },
      {
        "id": 3769,
        "name": "凉山彝族自治州",
        "level": 2,
        "regionCode": "513400",
        "childrens": [
          {
            "id": 3770,
            "name": "西昌市",
            "level": 3,
            "regionCode": "513401",
            "childrens": []
          },
          {
            "id": 3771,
            "name": "木里藏族自治县",
            "level": 3,
            "regionCode": "513422",
            "childrens": []
          },
          {
            "id": 3772,
            "name": "盐源县",
            "level": 3,
            "regionCode": "513423",
            "childrens": []
          },
          {
            "id": 3773,
            "name": "德昌县",
            "level": 3,
            "regionCode": "513424",
            "childrens": []
          },
          {
            "id": 3774,
            "name": "会理市",
            "level": 3,
            "regionCode": "513425",
            "childrens": []
          },
          {
            "id": 3775,
            "name": "会东县",
            "level": 3,
            "regionCode": "513426",
            "childrens": []
          },
          {
            "id": 3776,
            "name": "宁南县",
            "level": 3,
            "regionCode": "513427",
            "childrens": []
          },
          {
            "id": 3777,
            "name": "普格县",
            "level": 3,
            "regionCode": "513428",
            "childrens": []
          },
          {
            "id": 3778,
            "name": "布拖县",
            "level": 3,
            "regionCode": "513429",
            "childrens": []
          },
          {
            "id": 3779,
            "name": "金阳县",
            "level": 3,
            "regionCode": "513430",
            "childrens": []
          },
          {
            "id": 3780,
            "name": "昭觉县",
            "level": 3,
            "regionCode": "513431",
            "childrens": []
          },
          {
            "id": 3781,
            "name": "喜德县",
            "level": 3,
            "regionCode": "513432",
            "childrens": []
          },
          {
            "id": 3782,
            "name": "冕宁县",
            "level": 3,
            "regionCode": "513433",
            "childrens": []
          },
          {
            "id": 3783,
            "name": "越西县",
            "level": 3,
            "regionCode": "513434",
            "childrens": []
          },
          {
            "id": 3784,
            "name": "甘洛县",
            "level": 3,
            "regionCode": "513435",
            "childrens": []
          },
          {
            "id": 3785,
            "name": "美姑县",
            "level": 3,
            "regionCode": "513436",
            "childrens": []
          },
          {
            "id": 3786,
            "name": "雷波县",
            "level": 3,
            "regionCode": "513437",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 3792,
    "name": "贵州省",
    "level": 1,
    "regionCode": "520000",
    "childrens": [
      {
        "id": 3793,
        "name": "贵阳市",
        "level": 2,
        "regionCode": "520100",
        "childrens": [
          {
            "id": 3794,
            "name": "南明区",
            "level": 3,
            "regionCode": "520102",
            "childrens": []
          },
          {
            "id": 3795,
            "name": "云岩区",
            "level": 3,
            "regionCode": "520103",
            "childrens": []
          },
          {
            "id": 3796,
            "name": "花溪区",
            "level": 3,
            "regionCode": "520111",
            "childrens": []
          },
          {
            "id": 3797,
            "name": "乌当区",
            "level": 3,
            "regionCode": "520112",
            "childrens": []
          },
          {
            "id": 3800,
            "name": "开阳县",
            "level": 3,
            "regionCode": "520121",
            "childrens": []
          },
          {
            "id": 3801,
            "name": "息烽县",
            "level": 3,
            "regionCode": "520122",
            "childrens": []
          },
          {
            "id": 3802,
            "name": "修文县",
            "level": 3,
            "regionCode": "520123",
            "childrens": []
          },
          {
            "id": 3803,
            "name": "清镇市",
            "level": 3,
            "regionCode": "520181",
            "childrens": []
          },
          {
            "id": 5267,
            "name": "白云区",
            "level": 3,
            "regionCode": "520113",
            "childrens": []
          },
          {
            "id": 5268,
            "name": "观山湖区",
            "level": 3,
            "regionCode": "520115",
            "childrens": []
          }
        ]
      },
      {
        "id": 3809,
        "name": "六盘水市",
        "level": 2,
        "regionCode": "520200",
        "childrens": [
          {
            "id": 3810,
            "name": "钟山区",
            "level": 3,
            "regionCode": "520201",
            "childrens": []
          },
          {
            "id": 3811,
            "name": "六枝特区",
            "level": 3,
            "regionCode": "520203",
            "childrens": []
          },
          {
            "id": 3812,
            "name": "水城区",
            "level": 3,
            "regionCode": "520221",
            "childrens": []
          },
          {
            "id": 5269,
            "name": "盘州市",
            "level": 3,
            "regionCode": "520281",
            "childrens": []
          }
        ]
      },
      {
        "id": 3819,
        "name": "遵义市",
        "level": 2,
        "regionCode": "520300",
        "childrens": [
          {
            "id": 3820,
            "name": "红花岗区",
            "level": 3,
            "regionCode": "520302",
            "childrens": []
          },
          {
            "id": 3821,
            "name": "汇川区",
            "level": 3,
            "regionCode": "520303",
            "childrens": []
          },
          {
            "id": 3823,
            "name": "桐梓县",
            "level": 3,
            "regionCode": "520322",
            "childrens": []
          },
          {
            "id": 3824,
            "name": "绥阳县",
            "level": 3,
            "regionCode": "520323",
            "childrens": []
          },
          {
            "id": 3825,
            "name": "正安县",
            "level": 3,
            "regionCode": "520324",
            "childrens": []
          },
          {
            "id": 3826,
            "name": "道真仡佬族苗族自治县",
            "level": 3,
            "regionCode": "520325",
            "childrens": []
          },
          {
            "id": 3827,
            "name": "务川仡佬族苗族自治县",
            "level": 3,
            "regionCode": "520326",
            "childrens": []
          },
          {
            "id": 3828,
            "name": "凤冈县",
            "level": 3,
            "regionCode": "520327",
            "childrens": []
          },
          {
            "id": 3829,
            "name": "湄潭县",
            "level": 3,
            "regionCode": "520328",
            "childrens": []
          },
          {
            "id": 3830,
            "name": "余庆县",
            "level": 3,
            "regionCode": "520329",
            "childrens": []
          },
          {
            "id": 3831,
            "name": "习水县",
            "level": 3,
            "regionCode": "520330",
            "childrens": []
          },
          {
            "id": 3832,
            "name": "赤水市",
            "level": 3,
            "regionCode": "520381",
            "childrens": []
          },
          {
            "id": 3833,
            "name": "仁怀市",
            "level": 3,
            "regionCode": "520382",
            "childrens": []
          },
          {
            "id": 5270,
            "name": "播州区",
            "level": 3,
            "regionCode": "520304",
            "childrens": []
          }
        ]
      },
      {
        "id": 3839,
        "name": "安顺市",
        "level": 2,
        "regionCode": "520400",
        "childrens": [
          {
            "id": 3840,
            "name": "西秀区",
            "level": 3,
            "regionCode": "520402",
            "childrens": []
          },
          {
            "id": 3841,
            "name": "平坝区",
            "level": 3,
            "regionCode": "520403",
            "childrens": []
          },
          {
            "id": 3842,
            "name": "普定县",
            "level": 3,
            "regionCode": "520422",
            "childrens": []
          },
          {
            "id": 3843,
            "name": "镇宁布依族苗族自治县",
            "level": 3,
            "regionCode": "520423",
            "childrens": []
          },
          {
            "id": 3844,
            "name": "关岭布依族苗族自治县",
            "level": 3,
            "regionCode": "520424",
            "childrens": []
          },
          {
            "id": 3845,
            "name": "紫云苗族布依族自治县",
            "level": 3,
            "regionCode": "520425",
            "childrens": []
          }
        ]
      },
      {
        "id": 3851,
        "name": "铜仁市",
        "level": 2,
        "regionCode": "520600",
        "childrens": [
          {
            "id": 3853,
            "name": "江口县",
            "level": 3,
            "regionCode": "520621",
            "childrens": []
          },
          {
            "id": 3854,
            "name": "玉屏侗族自治县",
            "level": 3,
            "regionCode": "520622",
            "childrens": []
          },
          {
            "id": 3855,
            "name": "石阡县",
            "level": 3,
            "regionCode": "520623",
            "childrens": []
          },
          {
            "id": 3856,
            "name": "思南县",
            "level": 3,
            "regionCode": "520624",
            "childrens": []
          },
          {
            "id": 3857,
            "name": "印江土家族苗族自治县",
            "level": 3,
            "regionCode": "520625",
            "childrens": []
          },
          {
            "id": 3858,
            "name": "德江县",
            "level": 3,
            "regionCode": "520626",
            "childrens": []
          },
          {
            "id": 3859,
            "name": "沿河土家族自治县",
            "level": 3,
            "regionCode": "520627",
            "childrens": []
          },
          {
            "id": 3860,
            "name": "松桃苗族自治县",
            "level": 3,
            "regionCode": "520628",
            "childrens": []
          },
          {
            "id": 3861,
            "name": "万山区",
            "level": 3,
            "regionCode": "520603",
            "childrens": []
          },
          {
            "id": 5272,
            "name": "碧江区",
            "level": 3,
            "regionCode": "520602",
            "childrens": []
          }
        ]
      },
      {
        "id": 3867,
        "name": "黔西南布依族苗族自治州",
        "level": 2,
        "regionCode": "522300",
        "childrens": [
          {
            "id": 3868,
            "name": "兴义市",
            "level": 3,
            "regionCode": "522301",
            "childrens": []
          },
          {
            "id": 3869,
            "name": "兴仁市",
            "level": 3,
            "regionCode": "522302",
            "childrens": []
          },
          {
            "id": 3870,
            "name": "普安县",
            "level": 3,
            "regionCode": "522323",
            "childrens": []
          },
          {
            "id": 3871,
            "name": "晴隆县",
            "level": 3,
            "regionCode": "522324",
            "childrens": []
          },
          {
            "id": 3872,
            "name": "贞丰县",
            "level": 3,
            "regionCode": "522325",
            "childrens": []
          },
          {
            "id": 3873,
            "name": "望谟县",
            "level": 3,
            "regionCode": "522326",
            "childrens": []
          },
          {
            "id": 3874,
            "name": "册亨县",
            "level": 3,
            "regionCode": "522327",
            "childrens": []
          },
          {
            "id": 3875,
            "name": "安龙县",
            "level": 3,
            "regionCode": "522328",
            "childrens": []
          }
        ]
      },
      {
        "id": 3881,
        "name": "毕节市",
        "level": 2,
        "regionCode": "520500",
        "childrens": [
          {
            "id": 3883,
            "name": "大方县",
            "level": 3,
            "regionCode": "520521",
            "childrens": []
          },
          {
            "id": 3884,
            "name": "黔西市",
            "level": 3,
            "regionCode": "520522",
            "childrens": []
          },
          {
            "id": 3885,
            "name": "金沙县",
            "level": 3,
            "regionCode": "520523",
            "childrens": []
          },
          {
            "id": 3886,
            "name": "织金县",
            "level": 3,
            "regionCode": "520524",
            "childrens": []
          },
          {
            "id": 3887,
            "name": "纳雍县",
            "level": 3,
            "regionCode": "520525",
            "childrens": []
          },
          {
            "id": 3888,
            "name": "威宁彝族回族苗族自治县",
            "level": 3,
            "regionCode": "520526",
            "childrens": []
          },
          {
            "id": 3889,
            "name": "赫章县",
            "level": 3,
            "regionCode": "520527",
            "childrens": []
          },
          {
            "id": 5271,
            "name": "七星关区",
            "level": 3,
            "regionCode": "520502",
            "childrens": []
          }
        ]
      },
      {
        "id": 3895,
        "name": "黔东南苗族侗族自治州",
        "level": 2,
        "regionCode": "522600",
        "childrens": [
          {
            "id": 3896,
            "name": "凯里市",
            "level": 3,
            "regionCode": "522601",
            "childrens": []
          },
          {
            "id": 3897,
            "name": "黄平县",
            "level": 3,
            "regionCode": "522622",
            "childrens": []
          },
          {
            "id": 3898,
            "name": "施秉县",
            "level": 3,
            "regionCode": "522623",
            "childrens": []
          },
          {
            "id": 3899,
            "name": "三穗县",
            "level": 3,
            "regionCode": "522624",
            "childrens": []
          },
          {
            "id": 3900,
            "name": "镇远县",
            "level": 3,
            "regionCode": "522625",
            "childrens": []
          },
          {
            "id": 3901,
            "name": "岑巩县",
            "level": 3,
            "regionCode": "522626",
            "childrens": []
          },
          {
            "id": 3902,
            "name": "天柱县",
            "level": 3,
            "regionCode": "522627",
            "childrens": []
          },
          {
            "id": 3903,
            "name": "锦屏县",
            "level": 3,
            "regionCode": "522628",
            "childrens": []
          },
          {
            "id": 3904,
            "name": "剑河县",
            "level": 3,
            "regionCode": "522629",
            "childrens": []
          },
          {
            "id": 3905,
            "name": "台江县",
            "level": 3,
            "regionCode": "522630",
            "childrens": []
          },
          {
            "id": 3906,
            "name": "黎平县",
            "level": 3,
            "regionCode": "522631",
            "childrens": []
          },
          {
            "id": 3907,
            "name": "榕江县",
            "level": 3,
            "regionCode": "522632",
            "childrens": []
          },
          {
            "id": 3908,
            "name": "从江县",
            "level": 3,
            "regionCode": "522633",
            "childrens": []
          },
          {
            "id": 3909,
            "name": "雷山县",
            "level": 3,
            "regionCode": "522634",
            "childrens": []
          },
          {
            "id": 3910,
            "name": "麻江县",
            "level": 3,
            "regionCode": "522635",
            "childrens": []
          },
          {
            "id": 3911,
            "name": "丹寨县",
            "level": 3,
            "regionCode": "522636",
            "childrens": []
          }
        ]
      },
      {
        "id": 3917,
        "name": "黔南布依族苗族自治州",
        "level": 2,
        "regionCode": "522700",
        "childrens": [
          {
            "id": 3918,
            "name": "都匀市",
            "level": 3,
            "regionCode": "522701",
            "childrens": []
          },
          {
            "id": 3919,
            "name": "福泉市",
            "level": 3,
            "regionCode": "522702",
            "childrens": []
          },
          {
            "id": 3920,
            "name": "荔波县",
            "level": 3,
            "regionCode": "522722",
            "childrens": []
          },
          {
            "id": 3921,
            "name": "贵定县",
            "level": 3,
            "regionCode": "522723",
            "childrens": []
          },
          {
            "id": 3922,
            "name": "瓮安县",
            "level": 3,
            "regionCode": "522725",
            "childrens": []
          },
          {
            "id": 3923,
            "name": "独山县",
            "level": 3,
            "regionCode": "522726",
            "childrens": []
          },
          {
            "id": 3924,
            "name": "平塘县",
            "level": 3,
            "regionCode": "522727",
            "childrens": []
          },
          {
            "id": 3925,
            "name": "罗甸县",
            "level": 3,
            "regionCode": "522728",
            "childrens": []
          },
          {
            "id": 3926,
            "name": "长顺县",
            "level": 3,
            "regionCode": "522729",
            "childrens": []
          },
          {
            "id": 3927,
            "name": "龙里县",
            "level": 3,
            "regionCode": "522730",
            "childrens": []
          },
          {
            "id": 3928,
            "name": "惠水县",
            "level": 3,
            "regionCode": "522731",
            "childrens": []
          },
          {
            "id": 3929,
            "name": "三都水族自治县",
            "level": 3,
            "regionCode": "522732",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 3935,
    "name": "云南省",
    "level": 1,
    "regionCode": "530000",
    "childrens": [
      {
        "id": 3936,
        "name": "昆明市",
        "level": 2,
        "regionCode": "530100",
        "childrens": [
          {
            "id": 3937,
            "name": "五华区",
            "level": 3,
            "regionCode": "530102",
            "childrens": []
          },
          {
            "id": 3938,
            "name": "盘龙区",
            "level": 3,
            "regionCode": "530103",
            "childrens": []
          },
          {
            "id": 3939,
            "name": "官渡区",
            "level": 3,
            "regionCode": "530111",
            "childrens": []
          },
          {
            "id": 3940,
            "name": "西山区",
            "level": 3,
            "regionCode": "530112",
            "childrens": []
          },
          {
            "id": 3941,
            "name": "东川区",
            "level": 3,
            "regionCode": "530113",
            "childrens": []
          },
          {
            "id": 3942,
            "name": "呈贡区",
            "level": 3,
            "regionCode": "530114",
            "childrens": []
          },
          {
            "id": 3943,
            "name": "晋宁区",
            "level": 3,
            "regionCode": "530115",
            "childrens": []
          },
          {
            "id": 3944,
            "name": "富民县",
            "level": 3,
            "regionCode": "530124",
            "childrens": []
          },
          {
            "id": 3945,
            "name": "宜良县",
            "level": 3,
            "regionCode": "530125",
            "childrens": []
          },
          {
            "id": 3946,
            "name": "石林彝族自治县",
            "level": 3,
            "regionCode": "530126",
            "childrens": []
          },
          {
            "id": 3947,
            "name": "嵩明县",
            "level": 3,
            "regionCode": "530127",
            "childrens": []
          },
          {
            "id": 3948,
            "name": "禄劝彝族苗族自治县",
            "level": 3,
            "regionCode": "530128",
            "childrens": []
          },
          {
            "id": 3949,
            "name": "寻甸回族彝族自治县",
            "level": 3,
            "regionCode": "530129",
            "childrens": []
          },
          {
            "id": 3950,
            "name": "安宁市",
            "level": 3,
            "regionCode": "530181",
            "childrens": []
          }
        ]
      },
      {
        "id": 3956,
        "name": "曲靖市",
        "level": 2,
        "regionCode": "530300",
        "childrens": [
          {
            "id": 3957,
            "name": "麒麟区",
            "level": 3,
            "regionCode": "530302",
            "childrens": []
          },
          {
            "id": 3958,
            "name": "马龙区",
            "level": 3,
            "regionCode": "530304",
            "childrens": []
          },
          {
            "id": 3959,
            "name": "陆良县",
            "level": 3,
            "regionCode": "530322",
            "childrens": []
          },
          {
            "id": 3960,
            "name": "师宗县",
            "level": 3,
            "regionCode": "530323",
            "childrens": []
          },
          {
            "id": 3961,
            "name": "罗平县",
            "level": 3,
            "regionCode": "530324",
            "childrens": []
          },
          {
            "id": 3962,
            "name": "富源县",
            "level": 3,
            "regionCode": "530325",
            "childrens": []
          },
          {
            "id": 3963,
            "name": "会泽县",
            "level": 3,
            "regionCode": "530326",
            "childrens": []
          },
          {
            "id": 3964,
            "name": "沾益区",
            "level": 3,
            "regionCode": "530303",
            "childrens": []
          },
          {
            "id": 3965,
            "name": "宣威市",
            "level": 3,
            "regionCode": "530381",
            "childrens": []
          }
        ]
      },
      {
        "id": 3971,
        "name": "玉溪市",
        "level": 2,
        "regionCode": "530400",
        "childrens": [
          {
            "id": 3972,
            "name": "红塔区",
            "level": 3,
            "regionCode": "530402",
            "childrens": []
          },
          {
            "id": 3973,
            "name": "江川区",
            "level": 3,
            "regionCode": "530403",
            "childrens": []
          },
          {
            "id": 3974,
            "name": "澄江市",
            "level": 3,
            "regionCode": "530481",
            "childrens": []
          },
          {
            "id": 3975,
            "name": "通海县",
            "level": 3,
            "regionCode": "530423",
            "childrens": []
          },
          {
            "id": 3976,
            "name": "华宁县",
            "level": 3,
            "regionCode": "530424",
            "childrens": []
          },
          {
            "id": 3977,
            "name": "易门县",
            "level": 3,
            "regionCode": "530425",
            "childrens": []
          },
          {
            "id": 3978,
            "name": "峨山彝族自治县",
            "level": 3,
            "regionCode": "530426",
            "childrens": []
          },
          {
            "id": 3979,
            "name": "新平彝族傣族自治县",
            "level": 3,
            "regionCode": "530427",
            "childrens": []
          },
          {
            "id": 3980,
            "name": "元江哈尼族彝族傣族自治县",
            "level": 3,
            "regionCode": "530428",
            "childrens": []
          }
        ]
      },
      {
        "id": 3986,
        "name": "保山市",
        "level": 2,
        "regionCode": "530500",
        "childrens": [
          {
            "id": 3987,
            "name": "隆阳区",
            "level": 3,
            "regionCode": "530502",
            "childrens": []
          },
          {
            "id": 3988,
            "name": "施甸县",
            "level": 3,
            "regionCode": "530521",
            "childrens": []
          },
          {
            "id": 3989,
            "name": "腾冲市",
            "level": 3,
            "regionCode": "530581",
            "childrens": []
          },
          {
            "id": 3990,
            "name": "龙陵县",
            "level": 3,
            "regionCode": "530523",
            "childrens": []
          },
          {
            "id": 3991,
            "name": "昌宁县",
            "level": 3,
            "regionCode": "530524",
            "childrens": []
          }
        ]
      },
      {
        "id": 3997,
        "name": "昭通市",
        "level": 2,
        "regionCode": "530600",
        "childrens": [
          {
            "id": 3998,
            "name": "昭阳区",
            "level": 3,
            "regionCode": "530602",
            "childrens": []
          },
          {
            "id": 3999,
            "name": "鲁甸县",
            "level": 3,
            "regionCode": "530621",
            "childrens": []
          },
          {
            "id": 4000,
            "name": "巧家县",
            "level": 3,
            "regionCode": "530622",
            "childrens": []
          },
          {
            "id": 4001,
            "name": "盐津县",
            "level": 3,
            "regionCode": "530623",
            "childrens": []
          },
          {
            "id": 4002,
            "name": "大关县",
            "level": 3,
            "regionCode": "530624",
            "childrens": []
          },
          {
            "id": 4003,
            "name": "永善县",
            "level": 3,
            "regionCode": "530625",
            "childrens": []
          },
          {
            "id": 4004,
            "name": "绥江县",
            "level": 3,
            "regionCode": "530626",
            "childrens": []
          },
          {
            "id": 4005,
            "name": "镇雄县",
            "level": 3,
            "regionCode": "530627",
            "childrens": []
          },
          {
            "id": 4006,
            "name": "彝良县",
            "level": 3,
            "regionCode": "530628",
            "childrens": []
          },
          {
            "id": 4007,
            "name": "威信县",
            "level": 3,
            "regionCode": "530629",
            "childrens": []
          },
          {
            "id": 4008,
            "name": "水富市",
            "level": 3,
            "regionCode": "530681",
            "childrens": []
          }
        ]
      },
      {
        "id": 4014,
        "name": "丽江市",
        "level": 2,
        "regionCode": "530700",
        "childrens": [
          {
            "id": 4015,
            "name": "古城区",
            "level": 3,
            "regionCode": "530702",
            "childrens": []
          },
          {
            "id": 4016,
            "name": "玉龙纳西族自治县",
            "level": 3,
            "regionCode": "530721",
            "childrens": []
          },
          {
            "id": 4017,
            "name": "永胜县",
            "level": 3,
            "regionCode": "530722",
            "childrens": []
          },
          {
            "id": 4018,
            "name": "华坪县",
            "level": 3,
            "regionCode": "530723",
            "childrens": []
          },
          {
            "id": 4019,
            "name": "宁蒗彝族自治县",
            "level": 3,
            "regionCode": "530724",
            "childrens": []
          }
        ]
      },
      {
        "id": 4041,
        "name": "临沧市",
        "level": 2,
        "regionCode": "530900",
        "childrens": [
          {
            "id": 4042,
            "name": "临翔区",
            "level": 3,
            "regionCode": "530902",
            "childrens": []
          },
          {
            "id": 4043,
            "name": "凤庆县",
            "level": 3,
            "regionCode": "530921",
            "childrens": []
          },
          {
            "id": 4044,
            "name": "云县",
            "level": 3,
            "regionCode": "530922",
            "childrens": []
          },
          {
            "id": 4045,
            "name": "永德县",
            "level": 3,
            "regionCode": "530923",
            "childrens": []
          },
          {
            "id": 4046,
            "name": "镇康县",
            "level": 3,
            "regionCode": "530924",
            "childrens": []
          },
          {
            "id": 4047,
            "name": "双江拉祜族佤族布朗族傣族自治县",
            "level": 3,
            "regionCode": "530925",
            "childrens": []
          },
          {
            "id": 4048,
            "name": "耿马傣族佤族自治县",
            "level": 3,
            "regionCode": "530926",
            "childrens": []
          },
          {
            "id": 4049,
            "name": "沧源佤族自治县",
            "level": 3,
            "regionCode": "530927",
            "childrens": []
          }
        ]
      },
      {
        "id": 4055,
        "name": "楚雄彝族自治州",
        "level": 2,
        "regionCode": "532300",
        "childrens": [
          {
            "id": 4056,
            "name": "楚雄市",
            "level": 3,
            "regionCode": "532301",
            "childrens": []
          },
          {
            "id": 4057,
            "name": "双柏县",
            "level": 3,
            "regionCode": "532322",
            "childrens": []
          },
          {
            "id": 4058,
            "name": "牟定县",
            "level": 3,
            "regionCode": "532323",
            "childrens": []
          },
          {
            "id": 4059,
            "name": "南华县",
            "level": 3,
            "regionCode": "532324",
            "childrens": []
          },
          {
            "id": 4060,
            "name": "姚安县",
            "level": 3,
            "regionCode": "532325",
            "childrens": []
          },
          {
            "id": 4061,
            "name": "大姚县",
            "level": 3,
            "regionCode": "532326",
            "childrens": []
          },
          {
            "id": 4062,
            "name": "永仁县",
            "level": 3,
            "regionCode": "532327",
            "childrens": []
          },
          {
            "id": 4063,
            "name": "元谋县",
            "level": 3,
            "regionCode": "532328",
            "childrens": []
          },
          {
            "id": 4064,
            "name": "武定县",
            "level": 3,
            "regionCode": "532329",
            "childrens": []
          },
          {
            "id": 4065,
            "name": "禄丰市",
            "level": 3,
            "regionCode": "532331",
            "childrens": []
          }
        ]
      },
      {
        "id": 4071,
        "name": "红河哈尼族彝族自治州",
        "level": 2,
        "regionCode": "532500",
        "childrens": [
          {
            "id": 4072,
            "name": "个旧市",
            "level": 3,
            "regionCode": "532501",
            "childrens": []
          },
          {
            "id": 4073,
            "name": "开远市",
            "level": 3,
            "regionCode": "532502",
            "childrens": []
          },
          {
            "id": 4074,
            "name": "蒙自市",
            "level": 3,
            "regionCode": "532503",
            "childrens": []
          },
          {
            "id": 4075,
            "name": "屏边苗族自治县",
            "level": 3,
            "regionCode": "532523",
            "childrens": []
          },
          {
            "id": 4076,
            "name": "建水县",
            "level": 3,
            "regionCode": "532524",
            "childrens": []
          },
          {
            "id": 4077,
            "name": "石屏县",
            "level": 3,
            "regionCode": "532525",
            "childrens": []
          },
          {
            "id": 4078,
            "name": "弥勒市",
            "level": 3,
            "regionCode": "532504",
            "childrens": []
          },
          {
            "id": 4079,
            "name": "泸西县",
            "level": 3,
            "regionCode": "532527",
            "childrens": []
          },
          {
            "id": 4080,
            "name": "元阳县",
            "level": 3,
            "regionCode": "532528",
            "childrens": []
          },
          {
            "id": 4081,
            "name": "红河县",
            "level": 3,
            "regionCode": "532529",
            "childrens": []
          },
          {
            "id": 4083,
            "name": "绿春县",
            "level": 3,
            "regionCode": "532531",
            "childrens": []
          },
          {
            "id": 5273,
            "name": "金平苗族瑶族傣族自治县",
            "level": 3,
            "regionCode": "532530",
            "childrens": []
          },
          {
            "id": 5274,
            "name": "河口瑶族自治县",
            "level": 3,
            "regionCode": "532532",
            "childrens": []
          }
        ]
      },
      {
        "id": 4090,
        "name": "文山壮族苗族自治州",
        "level": 2,
        "regionCode": "532600",
        "childrens": [
          {
            "id": 4091,
            "name": "文山市",
            "level": 3,
            "regionCode": "532601",
            "childrens": []
          },
          {
            "id": 4092,
            "name": "砚山县",
            "level": 3,
            "regionCode": "532622",
            "childrens": []
          },
          {
            "id": 4093,
            "name": "西畴县",
            "level": 3,
            "regionCode": "532623",
            "childrens": []
          },
          {
            "id": 4094,
            "name": "麻栗坡县",
            "level": 3,
            "regionCode": "532624",
            "childrens": []
          },
          {
            "id": 4095,
            "name": "马关县",
            "level": 3,
            "regionCode": "532625",
            "childrens": []
          },
          {
            "id": 4096,
            "name": "丘北县",
            "level": 3,
            "regionCode": "532626",
            "childrens": []
          },
          {
            "id": 4097,
            "name": "广南县",
            "level": 3,
            "regionCode": "532627",
            "childrens": []
          },
          {
            "id": 4098,
            "name": "富宁县",
            "level": 3,
            "regionCode": "532628",
            "childrens": []
          }
        ]
      },
      {
        "id": 4104,
        "name": "西双版纳傣族自治州",
        "level": 2,
        "regionCode": "532800",
        "childrens": [
          {
            "id": 4105,
            "name": "景洪市",
            "level": 3,
            "regionCode": "532801",
            "childrens": []
          },
          {
            "id": 4106,
            "name": "勐海县",
            "level": 3,
            "regionCode": "532822",
            "childrens": []
          },
          {
            "id": 4107,
            "name": "勐腊县",
            "level": 3,
            "regionCode": "532823",
            "childrens": []
          }
        ]
      },
      {
        "id": 4113,
        "name": "大理白族自治州",
        "level": 2,
        "regionCode": "532900",
        "childrens": [
          {
            "id": 4114,
            "name": "大理市",
            "level": 3,
            "regionCode": "532901",
            "childrens": []
          },
          {
            "id": 4115,
            "name": "漾濞彝族自治县",
            "level": 3,
            "regionCode": "532922",
            "childrens": []
          },
          {
            "id": 4116,
            "name": "祥云县",
            "level": 3,
            "regionCode": "532923",
            "childrens": []
          },
          {
            "id": 4117,
            "name": "宾川县",
            "level": 3,
            "regionCode": "532924",
            "childrens": []
          },
          {
            "id": 4118,
            "name": "弥渡县",
            "level": 3,
            "regionCode": "532925",
            "childrens": []
          },
          {
            "id": 4119,
            "name": "南涧彝族自治县",
            "level": 3,
            "regionCode": "532926",
            "childrens": []
          },
          {
            "id": 4120,
            "name": "巍山彝族回族自治县",
            "level": 3,
            "regionCode": "532927",
            "childrens": []
          },
          {
            "id": 4121,
            "name": "永平县",
            "level": 3,
            "regionCode": "532928",
            "childrens": []
          },
          {
            "id": 4122,
            "name": "云龙县",
            "level": 3,
            "regionCode": "532929",
            "childrens": []
          },
          {
            "id": 4123,
            "name": "洱源县",
            "level": 3,
            "regionCode": "532930",
            "childrens": []
          },
          {
            "id": 4124,
            "name": "剑川县",
            "level": 3,
            "regionCode": "532931",
            "childrens": []
          },
          {
            "id": 4125,
            "name": "鹤庆县",
            "level": 3,
            "regionCode": "532932",
            "childrens": []
          }
        ]
      },
      {
        "id": 4131,
        "name": "德宏傣族景颇族自治州",
        "level": 2,
        "regionCode": "533100",
        "childrens": [
          {
            "id": 4132,
            "name": "瑞丽市",
            "level": 3,
            "regionCode": "533102",
            "childrens": []
          },
          {
            "id": 4134,
            "name": "梁河县",
            "level": 3,
            "regionCode": "533122",
            "childrens": []
          },
          {
            "id": 4135,
            "name": "盈江县",
            "level": 3,
            "regionCode": "533123",
            "childrens": []
          },
          {
            "id": 4136,
            "name": "陇川县",
            "level": 3,
            "regionCode": "533124",
            "childrens": []
          },
          {
            "id": 5275,
            "name": "芒市",
            "level": 3,
            "regionCode": "533103",
            "childrens": []
          }
        ]
      },
      {
        "id": 4142,
        "name": "怒江傈僳族自治州",
        "level": 2,
        "regionCode": "533300",
        "childrens": [
          {
            "id": 4143,
            "name": "泸水市",
            "level": 3,
            "regionCode": "533301",
            "childrens": []
          },
          {
            "id": 4144,
            "name": "福贡县",
            "level": 3,
            "regionCode": "533323",
            "childrens": []
          },
          {
            "id": 4145,
            "name": "贡山独龙族怒族自治县",
            "level": 3,
            "regionCode": "533324",
            "childrens": []
          },
          {
            "id": 4146,
            "name": "兰坪白族普米族自治县",
            "level": 3,
            "regionCode": "533325",
            "childrens": []
          }
        ]
      },
      {
        "id": 4152,
        "name": "迪庆藏族自治州",
        "level": 2,
        "regionCode": "533400",
        "childrens": [
          {
            "id": 4153,
            "name": "香格里拉市",
            "level": 3,
            "regionCode": "533401",
            "childrens": []
          },
          {
            "id": 4154,
            "name": "德钦县",
            "level": 3,
            "regionCode": "533422",
            "childrens": []
          },
          {
            "id": 4155,
            "name": "维西傈僳族自治县",
            "level": 3,
            "regionCode": "533423",
            "childrens": []
          }
        ]
      },
      {
        "id": 4999,
        "name": "普洱市",
        "level": 2,
        "regionCode": "530800",
        "childrens": [
          {
            "id": 5055,
            "name": "思茅区",
            "level": 3,
            "regionCode": "530802",
            "childrens": []
          },
          {
            "id": 5056,
            "name": "宁洱哈尼族彝族自治县",
            "level": 3,
            "regionCode": "530821",
            "childrens": []
          },
          {
            "id": 5057,
            "name": "墨江哈尼族自治县",
            "level": 3,
            "regionCode": "530822",
            "childrens": []
          },
          {
            "id": 5058,
            "name": "景东彝族自治县",
            "level": 3,
            "regionCode": "530823",
            "childrens": []
          },
          {
            "id": 5059,
            "name": "景谷傣族彝族自治县",
            "level": 3,
            "regionCode": "530824",
            "childrens": []
          },
          {
            "id": 5060,
            "name": "镇沅彝族哈尼族拉祜族自治县",
            "level": 3,
            "regionCode": "530825",
            "childrens": []
          },
          {
            "id": 5061,
            "name": "江城哈尼族彝族自治县",
            "level": 3,
            "regionCode": "530826",
            "childrens": []
          },
          {
            "id": 5062,
            "name": "孟连傣族拉祜族佤族自治县",
            "level": 3,
            "regionCode": "530827",
            "childrens": []
          },
          {
            "id": 5063,
            "name": "澜沧拉祜族自治县",
            "level": 3,
            "regionCode": "530828",
            "childrens": []
          },
          {
            "id": 5064,
            "name": "西盟佤族自治县",
            "level": 3,
            "regionCode": "530829",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 4161,
    "name": "西藏自治区",
    "level": 1,
    "regionCode": "540000",
    "childrens": [
      {
        "id": 4162,
        "name": "拉萨市",
        "level": 2,
        "regionCode": "540100",
        "childrens": [
          {
            "id": 4163,
            "name": "城关区",
            "level": 3,
            "regionCode": "540102",
            "childrens": []
          },
          {
            "id": 4164,
            "name": "林周县",
            "level": 3,
            "regionCode": "540121",
            "childrens": []
          },
          {
            "id": 4165,
            "name": "当雄县",
            "level": 3,
            "regionCode": "540122",
            "childrens": []
          },
          {
            "id": 4166,
            "name": "尼木县",
            "level": 3,
            "regionCode": "540123",
            "childrens": []
          },
          {
            "id": 4167,
            "name": "曲水县",
            "level": 3,
            "regionCode": "540124",
            "childrens": []
          },
          {
            "id": 4168,
            "name": "堆龙德庆区",
            "level": 3,
            "regionCode": "540103",
            "childrens": []
          },
          {
            "id": 4169,
            "name": "达孜区",
            "level": 3,
            "regionCode": "540104",
            "childrens": []
          },
          {
            "id": 4170,
            "name": "墨竹工卡县",
            "level": 3,
            "regionCode": "540127",
            "childrens": []
          }
        ]
      },
      {
        "id": 4251,
        "name": "阿里地区",
        "level": 2,
        "regionCode": "542500",
        "childrens": [
          {
            "id": 4252,
            "name": "普兰县",
            "level": 3,
            "regionCode": "542521",
            "childrens": []
          },
          {
            "id": 4253,
            "name": "札达县",
            "level": 3,
            "regionCode": "542522",
            "childrens": []
          },
          {
            "id": 4254,
            "name": "噶尔县",
            "level": 3,
            "regionCode": "542523",
            "childrens": []
          },
          {
            "id": 4255,
            "name": "日土县",
            "level": 3,
            "regionCode": "542524",
            "childrens": []
          },
          {
            "id": 4256,
            "name": "革吉县",
            "level": 3,
            "regionCode": "542525",
            "childrens": []
          },
          {
            "id": 4257,
            "name": "改则县",
            "level": 3,
            "regionCode": "542526",
            "childrens": []
          },
          {
            "id": 4258,
            "name": "措勤县",
            "level": 3,
            "regionCode": "542527",
            "childrens": []
          }
        ]
      },
      {
        "id": 5000,
        "name": "日喀则市",
        "level": 2,
        "regionCode": "540200",
        "childrens": [
          {
            "id": 5065,
            "name": "桑珠孜区",
            "level": 3,
            "regionCode": "540202",
            "childrens": []
          },
          {
            "id": 5066,
            "name": "南木林县",
            "level": 3,
            "regionCode": "540221",
            "childrens": []
          },
          {
            "id": 5067,
            "name": "江孜县",
            "level": 3,
            "regionCode": "540222",
            "childrens": []
          },
          {
            "id": 5068,
            "name": "定日县",
            "level": 3,
            "regionCode": "540223",
            "childrens": []
          },
          {
            "id": 5069,
            "name": "萨迦县",
            "level": 3,
            "regionCode": "540224",
            "childrens": []
          },
          {
            "id": 5070,
            "name": "拉孜县",
            "level": 3,
            "regionCode": "540225",
            "childrens": []
          },
          {
            "id": 5071,
            "name": "昂仁县",
            "level": 3,
            "regionCode": "540226",
            "childrens": []
          },
          {
            "id": 5072,
            "name": "谢通门县",
            "level": 3,
            "regionCode": "540227",
            "childrens": []
          },
          {
            "id": 5073,
            "name": "白朗县",
            "level": 3,
            "regionCode": "540228",
            "childrens": []
          },
          {
            "id": 5074,
            "name": "仁布县",
            "level": 3,
            "regionCode": "540229",
            "childrens": []
          },
          {
            "id": 5075,
            "name": "康马县",
            "level": 3,
            "regionCode": "540230",
            "childrens": []
          },
          {
            "id": 5076,
            "name": "定结县",
            "level": 3,
            "regionCode": "540231",
            "childrens": []
          },
          {
            "id": 5077,
            "name": "仲巴县",
            "level": 3,
            "regionCode": "540232",
            "childrens": []
          },
          {
            "id": 5078,
            "name": "亚东县",
            "level": 3,
            "regionCode": "540233",
            "childrens": []
          },
          {
            "id": 5079,
            "name": "吉隆县",
            "level": 3,
            "regionCode": "540234",
            "childrens": []
          },
          {
            "id": 5080,
            "name": "聂拉木县",
            "level": 3,
            "regionCode": "540235",
            "childrens": []
          },
          {
            "id": 5081,
            "name": "萨嘎县",
            "level": 3,
            "regionCode": "540236",
            "childrens": []
          },
          {
            "id": 5082,
            "name": "岗巴县",
            "level": 3,
            "regionCode": "540237",
            "childrens": []
          }
        ]
      },
      {
        "id": 5001,
        "name": "昌都市",
        "level": 2,
        "regionCode": "540300",
        "childrens": [
          {
            "id": 5083,
            "name": "卡若区",
            "level": 3,
            "regionCode": "540302",
            "childrens": []
          },
          {
            "id": 5084,
            "name": "江达县",
            "level": 3,
            "regionCode": "540321",
            "childrens": []
          },
          {
            "id": 5085,
            "name": "贡觉县",
            "level": 3,
            "regionCode": "540322",
            "childrens": []
          },
          {
            "id": 5086,
            "name": "类乌齐县",
            "level": 3,
            "regionCode": "540323",
            "childrens": []
          },
          {
            "id": 5087,
            "name": "丁青县",
            "level": 3,
            "regionCode": "540324",
            "childrens": []
          },
          {
            "id": 5088,
            "name": "察雅县",
            "level": 3,
            "regionCode": "540325",
            "childrens": []
          },
          {
            "id": 5089,
            "name": "八宿县",
            "level": 3,
            "regionCode": "540326",
            "childrens": []
          },
          {
            "id": 5090,
            "name": "左贡县",
            "level": 3,
            "regionCode": "540327",
            "childrens": []
          },
          {
            "id": 5091,
            "name": "芒康县",
            "level": 3,
            "regionCode": "540328",
            "childrens": []
          },
          {
            "id": 5092,
            "name": "洛隆县",
            "level": 3,
            "regionCode": "540329",
            "childrens": []
          },
          {
            "id": 5093,
            "name": "边坝县",
            "level": 3,
            "regionCode": "540330",
            "childrens": []
          }
        ]
      },
      {
        "id": 5002,
        "name": "林芝市",
        "level": 2,
        "regionCode": "540400",
        "childrens": [
          {
            "id": 5094,
            "name": "巴宜区",
            "level": 3,
            "regionCode": "540402",
            "childrens": []
          },
          {
            "id": 5095,
            "name": "工布江达县",
            "level": 3,
            "regionCode": "540421",
            "childrens": []
          },
          {
            "id": 5096,
            "name": "米林县",
            "level": 3,
            "regionCode": "540422",
            "childrens": []
          },
          {
            "id": 5097,
            "name": "墨脱县",
            "level": 3,
            "regionCode": "540423",
            "childrens": []
          },
          {
            "id": 5098,
            "name": "波密县",
            "level": 3,
            "regionCode": "540424",
            "childrens": []
          },
          {
            "id": 5099,
            "name": "察隅县",
            "level": 3,
            "regionCode": "540425",
            "childrens": []
          },
          {
            "id": 5100,
            "name": "朗县",
            "level": 3,
            "regionCode": "540426",
            "childrens": []
          }
        ]
      },
      {
        "id": 5003,
        "name": "山南市",
        "level": 2,
        "regionCode": "540500",
        "childrens": [
          {
            "id": 5101,
            "name": "乃东区",
            "level": 3,
            "regionCode": "540502",
            "childrens": []
          },
          {
            "id": 5102,
            "name": "扎囊县",
            "level": 3,
            "regionCode": "540521",
            "childrens": []
          },
          {
            "id": 5103,
            "name": "贡嘎县",
            "level": 3,
            "regionCode": "540522",
            "childrens": []
          },
          {
            "id": 5104,
            "name": "桑日县",
            "level": 3,
            "regionCode": "540523",
            "childrens": []
          },
          {
            "id": 5105,
            "name": "琼结县",
            "level": 3,
            "regionCode": "540524",
            "childrens": []
          },
          {
            "id": 5106,
            "name": "曲松县",
            "level": 3,
            "regionCode": "540525",
            "childrens": []
          },
          {
            "id": 5107,
            "name": "措美县",
            "level": 3,
            "regionCode": "540526",
            "childrens": []
          },
          {
            "id": 5108,
            "name": "洛扎县",
            "level": 3,
            "regionCode": "540527",
            "childrens": []
          },
          {
            "id": 5109,
            "name": "加查县",
            "level": 3,
            "regionCode": "540528",
            "childrens": []
          },
          {
            "id": 5110,
            "name": "隆子县",
            "level": 3,
            "regionCode": "540529",
            "childrens": []
          },
          {
            "id": 5111,
            "name": "错那县",
            "level": 3,
            "regionCode": "540530",
            "childrens": []
          },
          {
            "id": 5112,
            "name": "浪卡子县",
            "level": 3,
            "regionCode": "540531",
            "childrens": []
          }
        ]
      },
      {
        "id": 5004,
        "name": "那曲市",
        "level": 2,
        "regionCode": "540600",
        "childrens": [
          {
            "id": 5113,
            "name": "色尼区",
            "level": 3,
            "regionCode": "540602",
            "childrens": []
          },
          {
            "id": 5114,
            "name": "嘉黎县",
            "level": 3,
            "regionCode": "540621",
            "childrens": []
          },
          {
            "id": 5115,
            "name": "比如县",
            "level": 3,
            "regionCode": "540622",
            "childrens": []
          },
          {
            "id": 5116,
            "name": "聂荣县",
            "level": 3,
            "regionCode": "540623",
            "childrens": []
          },
          {
            "id": 5117,
            "name": "安多县",
            "level": 3,
            "regionCode": "540624",
            "childrens": []
          },
          {
            "id": 5118,
            "name": "申扎县",
            "level": 3,
            "regionCode": "540625",
            "childrens": []
          },
          {
            "id": 5119,
            "name": "索县",
            "level": 3,
            "regionCode": "540626",
            "childrens": []
          },
          {
            "id": 5120,
            "name": "班戈县",
            "level": 3,
            "regionCode": "540627",
            "childrens": []
          },
          {
            "id": 5121,
            "name": "巴青县",
            "level": 3,
            "regionCode": "540628",
            "childrens": []
          },
          {
            "id": 5122,
            "name": "尼玛县",
            "level": 3,
            "regionCode": "540629",
            "childrens": []
          },
          {
            "id": 5123,
            "name": "双湖县",
            "level": 3,
            "regionCode": "540630",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 4277,
    "name": "陕西省",
    "level": 1,
    "regionCode": "610000",
    "childrens": [
      {
        "id": 4278,
        "name": "西安市",
        "level": 2,
        "regionCode": "610100",
        "childrens": [
          {
            "id": 4280,
            "name": "碑林区",
            "level": 3,
            "regionCode": "610103",
            "childrens": []
          },
          {
            "id": 4281,
            "name": "莲湖区",
            "level": 3,
            "regionCode": "610104",
            "childrens": []
          },
          {
            "id": 4282,
            "name": "灞桥区",
            "level": 3,
            "regionCode": "610111",
            "childrens": []
          },
          {
            "id": 4283,
            "name": "未央区",
            "level": 3,
            "regionCode": "610112",
            "childrens": []
          },
          {
            "id": 4284,
            "name": "雁塔区",
            "level": 3,
            "regionCode": "610113",
            "childrens": []
          },
          {
            "id": 4285,
            "name": "阎良区",
            "level": 3,
            "regionCode": "610114",
            "childrens": []
          },
          {
            "id": 4286,
            "name": "临潼区",
            "level": 3,
            "regionCode": "610115",
            "childrens": []
          },
          {
            "id": 4288,
            "name": "蓝田县",
            "level": 3,
            "regionCode": "610122",
            "childrens": []
          },
          {
            "id": 4289,
            "name": "周至县",
            "level": 3,
            "regionCode": "610124",
            "childrens": []
          },
          {
            "id": 4291,
            "name": "高陵区",
            "level": 3,
            "regionCode": "610117",
            "childrens": []
          },
          {
            "id": 5276,
            "name": "新城区",
            "level": 3,
            "regionCode": "610102",
            "childrens": []
          },
          {
            "id": 5277,
            "name": "长安区",
            "level": 3,
            "regionCode": "610116",
            "childrens": []
          },
          {
            "id": 5278,
            "name": "鄠邑区",
            "level": 3,
            "regionCode": "610118",
            "childrens": []
          }
        ]
      },
      {
        "id": 4297,
        "name": "铜川市",
        "level": 2,
        "regionCode": "610200",
        "childrens": [
          {
            "id": 4298,
            "name": "王益区",
            "level": 3,
            "regionCode": "610202",
            "childrens": []
          },
          {
            "id": 4299,
            "name": "印台区",
            "level": 3,
            "regionCode": "610203",
            "childrens": []
          },
          {
            "id": 4300,
            "name": "耀州区",
            "level": 3,
            "regionCode": "610204",
            "childrens": []
          },
          {
            "id": 4301,
            "name": "宜君县",
            "level": 3,
            "regionCode": "610222",
            "childrens": []
          }
        ]
      },
      {
        "id": 4307,
        "name": "宝鸡市",
        "level": 2,
        "regionCode": "610300",
        "childrens": [
          {
            "id": 4308,
            "name": "渭滨区",
            "level": 3,
            "regionCode": "610302",
            "childrens": []
          },
          {
            "id": 4309,
            "name": "金台区",
            "level": 3,
            "regionCode": "610303",
            "childrens": []
          },
          {
            "id": 4310,
            "name": "陈仓区",
            "level": 3,
            "regionCode": "610304",
            "childrens": []
          },
          {
            "id": 4311,
            "name": "凤翔区",
            "level": 3,
            "regionCode": "610322",
            "childrens": []
          },
          {
            "id": 4312,
            "name": "岐山县",
            "level": 3,
            "regionCode": "610323",
            "childrens": []
          },
          {
            "id": 4313,
            "name": "扶风县",
            "level": 3,
            "regionCode": "610324",
            "childrens": []
          },
          {
            "id": 4314,
            "name": "眉县",
            "level": 3,
            "regionCode": "610326",
            "childrens": []
          },
          {
            "id": 4315,
            "name": "陇县",
            "level": 3,
            "regionCode": "610327",
            "childrens": []
          },
          {
            "id": 4316,
            "name": "千阳县",
            "level": 3,
            "regionCode": "610328",
            "childrens": []
          },
          {
            "id": 4317,
            "name": "麟游县",
            "level": 3,
            "regionCode": "610329",
            "childrens": []
          },
          {
            "id": 4318,
            "name": "凤县",
            "level": 3,
            "regionCode": "610330",
            "childrens": []
          },
          {
            "id": 4319,
            "name": "太白县",
            "level": 3,
            "regionCode": "610331",
            "childrens": []
          }
        ]
      },
      {
        "id": 4325,
        "name": "咸阳市",
        "level": 2,
        "regionCode": "610400",
        "childrens": [
          {
            "id": 4326,
            "name": "秦都区",
            "level": 3,
            "regionCode": "610402",
            "childrens": []
          },
          {
            "id": 4328,
            "name": "渭城区",
            "level": 3,
            "regionCode": "610404",
            "childrens": []
          },
          {
            "id": 4329,
            "name": "三原县",
            "level": 3,
            "regionCode": "610422",
            "childrens": []
          },
          {
            "id": 4330,
            "name": "泾阳县",
            "level": 3,
            "regionCode": "610423",
            "childrens": []
          },
          {
            "id": 4331,
            "name": "乾县",
            "level": 3,
            "regionCode": "610424",
            "childrens": []
          },
          {
            "id": 4332,
            "name": "礼泉县",
            "level": 3,
            "regionCode": "610425",
            "childrens": []
          },
          {
            "id": 4333,
            "name": "永寿县",
            "level": 3,
            "regionCode": "610426",
            "childrens": []
          },
          {
            "id": 4335,
            "name": "长武县",
            "level": 3,
            "regionCode": "610428",
            "childrens": []
          },
          {
            "id": 4336,
            "name": "旬邑县",
            "level": 3,
            "regionCode": "610429",
            "childrens": []
          },
          {
            "id": 4337,
            "name": "淳化县",
            "level": 3,
            "regionCode": "610430",
            "childrens": []
          },
          {
            "id": 4338,
            "name": "武功县",
            "level": 3,
            "regionCode": "610431",
            "childrens": []
          },
          {
            "id": 4339,
            "name": "兴平市",
            "level": 3,
            "regionCode": "610481",
            "childrens": []
          },
          {
            "id": 5279,
            "name": "杨陵区",
            "level": 3,
            "regionCode": "610403",
            "childrens": []
          },
          {
            "id": 5280,
            "name": "彬州市",
            "level": 3,
            "regionCode": "610482",
            "childrens": []
          }
        ]
      },
      {
        "id": 4345,
        "name": "渭南市",
        "level": 2,
        "regionCode": "610500",
        "childrens": [
          {
            "id": 4346,
            "name": "临渭区",
            "level": 3,
            "regionCode": "610502",
            "childrens": []
          },
          {
            "id": 4348,
            "name": "潼关县",
            "level": 3,
            "regionCode": "610522",
            "childrens": []
          },
          {
            "id": 4349,
            "name": "大荔县",
            "level": 3,
            "regionCode": "610523",
            "childrens": []
          },
          {
            "id": 4350,
            "name": "合阳县",
            "level": 3,
            "regionCode": "610524",
            "childrens": []
          },
          {
            "id": 4351,
            "name": "澄城县",
            "level": 3,
            "regionCode": "610525",
            "childrens": []
          },
          {
            "id": 4352,
            "name": "蒲城县",
            "level": 3,
            "regionCode": "610526",
            "childrens": []
          },
          {
            "id": 4353,
            "name": "白水县",
            "level": 3,
            "regionCode": "610527",
            "childrens": []
          },
          {
            "id": 4354,
            "name": "富平县",
            "level": 3,
            "regionCode": "610528",
            "childrens": []
          },
          {
            "id": 4355,
            "name": "韩城市",
            "level": 3,
            "regionCode": "610581",
            "childrens": []
          },
          {
            "id": 4356,
            "name": "华阴市",
            "level": 3,
            "regionCode": "610582",
            "childrens": []
          },
          {
            "id": 5281,
            "name": "华州区",
            "level": 3,
            "regionCode": "610503",
            "childrens": []
          }
        ]
      },
      {
        "id": 4362,
        "name": "延安市",
        "level": 2,
        "regionCode": "610600",
        "childrens": [
          {
            "id": 4363,
            "name": "宝塔区",
            "level": 3,
            "regionCode": "610602",
            "childrens": []
          },
          {
            "id": 4364,
            "name": "延长县",
            "level": 3,
            "regionCode": "610621",
            "childrens": []
          },
          {
            "id": 4365,
            "name": "延川县",
            "level": 3,
            "regionCode": "610622",
            "childrens": []
          },
          {
            "id": 4366,
            "name": "子长市",
            "level": 3,
            "regionCode": "610681",
            "childrens": []
          },
          {
            "id": 4367,
            "name": "安塞区",
            "level": 3,
            "regionCode": "610603",
            "childrens": []
          },
          {
            "id": 4368,
            "name": "志丹县",
            "level": 3,
            "regionCode": "610625",
            "childrens": []
          },
          {
            "id": 4369,
            "name": "吴起县",
            "level": 3,
            "regionCode": "610626",
            "childrens": []
          },
          {
            "id": 4370,
            "name": "甘泉县",
            "level": 3,
            "regionCode": "610627",
            "childrens": []
          },
          {
            "id": 4371,
            "name": "富县",
            "level": 3,
            "regionCode": "610628",
            "childrens": []
          },
          {
            "id": 4372,
            "name": "洛川县",
            "level": 3,
            "regionCode": "610629",
            "childrens": []
          },
          {
            "id": 4373,
            "name": "宜川县",
            "level": 3,
            "regionCode": "610630",
            "childrens": []
          },
          {
            "id": 4374,
            "name": "黄龙县",
            "level": 3,
            "regionCode": "610631",
            "childrens": []
          },
          {
            "id": 4375,
            "name": "黄陵县",
            "level": 3,
            "regionCode": "610632",
            "childrens": []
          }
        ]
      },
      {
        "id": 4381,
        "name": "汉中市",
        "level": 2,
        "regionCode": "610700",
        "childrens": [
          {
            "id": 4382,
            "name": "汉台区",
            "level": 3,
            "regionCode": "610702",
            "childrens": []
          },
          {
            "id": 4383,
            "name": "南郑区",
            "level": 3,
            "regionCode": "610703",
            "childrens": []
          },
          {
            "id": 4384,
            "name": "城固县",
            "level": 3,
            "regionCode": "610722",
            "childrens": []
          },
          {
            "id": 4385,
            "name": "洋县",
            "level": 3,
            "regionCode": "610723",
            "childrens": []
          },
          {
            "id": 4386,
            "name": "西乡县",
            "level": 3,
            "regionCode": "610724",
            "childrens": []
          },
          {
            "id": 4387,
            "name": "勉县",
            "level": 3,
            "regionCode": "610725",
            "childrens": []
          },
          {
            "id": 4388,
            "name": "宁强县",
            "level": 3,
            "regionCode": "610726",
            "childrens": []
          },
          {
            "id": 4389,
            "name": "略阳县",
            "level": 3,
            "regionCode": "610727",
            "childrens": []
          },
          {
            "id": 4390,
            "name": "镇巴县",
            "level": 3,
            "regionCode": "610728",
            "childrens": []
          },
          {
            "id": 4391,
            "name": "留坝县",
            "level": 3,
            "regionCode": "610729",
            "childrens": []
          },
          {
            "id": 4392,
            "name": "佛坪县",
            "level": 3,
            "regionCode": "610730",
            "childrens": []
          }
        ]
      },
      {
        "id": 4398,
        "name": "榆林市",
        "level": 2,
        "regionCode": "610800",
        "childrens": [
          {
            "id": 4399,
            "name": "榆阳区",
            "level": 3,
            "regionCode": "610802",
            "childrens": []
          },
          {
            "id": 4400,
            "name": "神木市",
            "level": 3,
            "regionCode": "610881",
            "childrens": []
          },
          {
            "id": 4401,
            "name": "府谷县",
            "level": 3,
            "regionCode": "610822",
            "childrens": []
          },
          {
            "id": 4402,
            "name": "横山区",
            "level": 3,
            "regionCode": "610803",
            "childrens": []
          },
          {
            "id": 4403,
            "name": "靖边县",
            "level": 3,
            "regionCode": "610824",
            "childrens": []
          },
          {
            "id": 4404,
            "name": "定边县",
            "level": 3,
            "regionCode": "610825",
            "childrens": []
          },
          {
            "id": 4405,
            "name": "绥德县",
            "level": 3,
            "regionCode": "610826",
            "childrens": []
          },
          {
            "id": 4406,
            "name": "米脂县",
            "level": 3,
            "regionCode": "610827",
            "childrens": []
          },
          {
            "id": 4407,
            "name": "佳县",
            "level": 3,
            "regionCode": "610828",
            "childrens": []
          },
          {
            "id": 4408,
            "name": "吴堡县",
            "level": 3,
            "regionCode": "610829",
            "childrens": []
          },
          {
            "id": 4409,
            "name": "清涧县",
            "level": 3,
            "regionCode": "610830",
            "childrens": []
          },
          {
            "id": 4410,
            "name": "子洲县",
            "level": 3,
            "regionCode": "610831",
            "childrens": []
          }
        ]
      },
      {
        "id": 4416,
        "name": "安康市",
        "level": 2,
        "regionCode": "610900",
        "childrens": [
          {
            "id": 4417,
            "name": "汉滨区",
            "level": 3,
            "regionCode": "610902",
            "childrens": []
          },
          {
            "id": 4418,
            "name": "汉阴县",
            "level": 3,
            "regionCode": "610921",
            "childrens": []
          },
          {
            "id": 4419,
            "name": "石泉县",
            "level": 3,
            "regionCode": "610922",
            "childrens": []
          },
          {
            "id": 4420,
            "name": "宁陕县",
            "level": 3,
            "regionCode": "610923",
            "childrens": []
          },
          {
            "id": 4421,
            "name": "紫阳县",
            "level": 3,
            "regionCode": "610924",
            "childrens": []
          },
          {
            "id": 4422,
            "name": "岚皋县",
            "level": 3,
            "regionCode": "610925",
            "childrens": []
          },
          {
            "id": 4423,
            "name": "平利县",
            "level": 3,
            "regionCode": "610926",
            "childrens": []
          },
          {
            "id": 4424,
            "name": "镇坪县",
            "level": 3,
            "regionCode": "610927",
            "childrens": []
          },
          {
            "id": 4425,
            "name": "旬阳市",
            "level": 3,
            "regionCode": "610928",
            "childrens": []
          },
          {
            "id": 4426,
            "name": "白河县",
            "level": 3,
            "regionCode": "610929",
            "childrens": []
          }
        ]
      },
      {
        "id": 4432,
        "name": "商洛市",
        "level": 2,
        "regionCode": "611000",
        "childrens": [
          {
            "id": 4433,
            "name": "商州区",
            "level": 3,
            "regionCode": "611002",
            "childrens": []
          },
          {
            "id": 4434,
            "name": "洛南县",
            "level": 3,
            "regionCode": "611021",
            "childrens": []
          },
          {
            "id": 4435,
            "name": "丹凤县",
            "level": 3,
            "regionCode": "611022",
            "childrens": []
          },
          {
            "id": 4436,
            "name": "商南县",
            "level": 3,
            "regionCode": "611023",
            "childrens": []
          },
          {
            "id": 4437,
            "name": "山阳县",
            "level": 3,
            "regionCode": "611024",
            "childrens": []
          },
          {
            "id": 4438,
            "name": "镇安县",
            "level": 3,
            "regionCode": "611025",
            "childrens": []
          },
          {
            "id": 4439,
            "name": "柞水县",
            "level": 3,
            "regionCode": "611026",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 4445,
    "name": "甘肃省",
    "level": 1,
    "regionCode": "620000",
    "childrens": [
      {
        "id": 4446,
        "name": "兰州市",
        "level": 2,
        "regionCode": "620100",
        "childrens": [
          {
            "id": 4448,
            "name": "七里河区",
            "level": 3,
            "regionCode": "620103",
            "childrens": []
          },
          {
            "id": 4450,
            "name": "安宁区",
            "level": 3,
            "regionCode": "620105",
            "childrens": []
          },
          {
            "id": 4451,
            "name": "红古区",
            "level": 3,
            "regionCode": "620111",
            "childrens": []
          },
          {
            "id": 4452,
            "name": "永登县",
            "level": 3,
            "regionCode": "620121",
            "childrens": []
          },
          {
            "id": 4453,
            "name": "皋兰县",
            "level": 3,
            "regionCode": "620122",
            "childrens": []
          },
          {
            "id": 4454,
            "name": "榆中县",
            "level": 3,
            "regionCode": "620123",
            "childrens": []
          },
          {
            "id": 5282,
            "name": "城关区",
            "level": 3,
            "regionCode": "620102",
            "childrens": []
          },
          {
            "id": 5283,
            "name": "西固区",
            "level": 3,
            "regionCode": "620104",
            "childrens": []
          }
        ]
      },
      {
        "id": 4460,
        "name": "嘉峪关市",
        "level": 2,
        "regionCode": "620200",
        "childrens": []
      },
      {
        "id": 4467,
        "name": "金昌市",
        "level": 2,
        "regionCode": "620300",
        "childrens": [
          {
            "id": 4468,
            "name": "金川区",
            "level": 3,
            "regionCode": "620302",
            "childrens": []
          },
          {
            "id": 4469,
            "name": "永昌县",
            "level": 3,
            "regionCode": "620321",
            "childrens": []
          }
        ]
      },
      {
        "id": 4475,
        "name": "白银市",
        "level": 2,
        "regionCode": "620400",
        "childrens": [
          {
            "id": 4476,
            "name": "白银区",
            "level": 3,
            "regionCode": "620402",
            "childrens": []
          },
          {
            "id": 4477,
            "name": "平川区",
            "level": 3,
            "regionCode": "620403",
            "childrens": []
          },
          {
            "id": 4478,
            "name": "靖远县",
            "level": 3,
            "regionCode": "620421",
            "childrens": []
          },
          {
            "id": 4479,
            "name": "会宁县",
            "level": 3,
            "regionCode": "620422",
            "childrens": []
          },
          {
            "id": 4480,
            "name": "景泰县",
            "level": 3,
            "regionCode": "620423",
            "childrens": []
          }
        ]
      },
      {
        "id": 4486,
        "name": "天水市",
        "level": 2,
        "regionCode": "620500",
        "childrens": [
          {
            "id": 4487,
            "name": "秦州区",
            "level": 3,
            "regionCode": "620502",
            "childrens": []
          },
          {
            "id": 4488,
            "name": "麦积区",
            "level": 3,
            "regionCode": "620503",
            "childrens": []
          },
          {
            "id": 4489,
            "name": "清水县",
            "level": 3,
            "regionCode": "620521",
            "childrens": []
          },
          {
            "id": 4490,
            "name": "秦安县",
            "level": 3,
            "regionCode": "620522",
            "childrens": []
          },
          {
            "id": 4491,
            "name": "甘谷县",
            "level": 3,
            "regionCode": "620523",
            "childrens": []
          },
          {
            "id": 4492,
            "name": "武山县",
            "level": 3,
            "regionCode": "620524",
            "childrens": []
          },
          {
            "id": 5284,
            "name": "张家川回族自治县",
            "level": 3,
            "regionCode": "620525",
            "childrens": []
          }
        ]
      },
      {
        "id": 4499,
        "name": "武威市",
        "level": 2,
        "regionCode": "620600",
        "childrens": [
          {
            "id": 4500,
            "name": "凉州区",
            "level": 3,
            "regionCode": "620602",
            "childrens": []
          },
          {
            "id": 4501,
            "name": "民勤县",
            "level": 3,
            "regionCode": "620621",
            "childrens": []
          },
          {
            "id": 4502,
            "name": "古浪县",
            "level": 3,
            "regionCode": "620622",
            "childrens": []
          },
          {
            "id": 4503,
            "name": "天祝藏族自治县",
            "level": 3,
            "regionCode": "620623",
            "childrens": []
          }
        ]
      },
      {
        "id": 4509,
        "name": "张掖市",
        "level": 2,
        "regionCode": "620700",
        "childrens": [
          {
            "id": 4510,
            "name": "甘州区",
            "level": 3,
            "regionCode": "620702",
            "childrens": []
          },
          {
            "id": 4511,
            "name": "肃南裕固族自治县",
            "level": 3,
            "regionCode": "620721",
            "childrens": []
          },
          {
            "id": 4512,
            "name": "民乐县",
            "level": 3,
            "regionCode": "620722",
            "childrens": []
          },
          {
            "id": 4513,
            "name": "临泽县",
            "level": 3,
            "regionCode": "620723",
            "childrens": []
          },
          {
            "id": 4514,
            "name": "高台县",
            "level": 3,
            "regionCode": "620724",
            "childrens": []
          },
          {
            "id": 4515,
            "name": "山丹县",
            "level": 3,
            "regionCode": "620725",
            "childrens": []
          }
        ]
      },
      {
        "id": 4521,
        "name": "平凉市",
        "level": 2,
        "regionCode": "620800",
        "childrens": [
          {
            "id": 4522,
            "name": "崆峒区",
            "level": 3,
            "regionCode": "620802",
            "childrens": []
          },
          {
            "id": 4523,
            "name": "泾川县",
            "level": 3,
            "regionCode": "620821",
            "childrens": []
          },
          {
            "id": 4524,
            "name": "灵台县",
            "level": 3,
            "regionCode": "620822",
            "childrens": []
          },
          {
            "id": 4525,
            "name": "崇信县",
            "level": 3,
            "regionCode": "620823",
            "childrens": []
          },
          {
            "id": 4526,
            "name": "华亭市",
            "level": 3,
            "regionCode": "620881",
            "childrens": []
          },
          {
            "id": 4527,
            "name": "庄浪县",
            "level": 3,
            "regionCode": "620825",
            "childrens": []
          },
          {
            "id": 4528,
            "name": "静宁县",
            "level": 3,
            "regionCode": "620826",
            "childrens": []
          }
        ]
      },
      {
        "id": 4534,
        "name": "酒泉市",
        "level": 2,
        "regionCode": "620900",
        "childrens": [
          {
            "id": 4535,
            "name": "肃州区",
            "level": 3,
            "regionCode": "620902",
            "childrens": []
          },
          {
            "id": 4536,
            "name": "金塔县",
            "level": 3,
            "regionCode": "620921",
            "childrens": []
          },
          {
            "id": 4537,
            "name": "瓜州县",
            "level": 3,
            "regionCode": "620922",
            "childrens": []
          },
          {
            "id": 4538,
            "name": "肃北蒙古族自治县",
            "level": 3,
            "regionCode": "620923",
            "childrens": []
          },
          {
            "id": 4539,
            "name": "阿克塞哈萨克族自治县",
            "level": 3,
            "regionCode": "620924",
            "childrens": []
          },
          {
            "id": 4540,
            "name": "玉门市",
            "level": 3,
            "regionCode": "620981",
            "childrens": []
          },
          {
            "id": 4541,
            "name": "敦煌市",
            "level": 3,
            "regionCode": "620982",
            "childrens": []
          }
        ]
      },
      {
        "id": 4547,
        "name": "庆阳市",
        "level": 2,
        "regionCode": "621000",
        "childrens": [
          {
            "id": 4548,
            "name": "西峰区",
            "level": 3,
            "regionCode": "621002",
            "childrens": []
          },
          {
            "id": 4549,
            "name": "庆城县",
            "level": 3,
            "regionCode": "621021",
            "childrens": []
          },
          {
            "id": 4550,
            "name": "环县",
            "level": 3,
            "regionCode": "621022",
            "childrens": []
          },
          {
            "id": 4551,
            "name": "华池县",
            "level": 3,
            "regionCode": "621023",
            "childrens": []
          },
          {
            "id": 4552,
            "name": "合水县",
            "level": 3,
            "regionCode": "621024",
            "childrens": []
          },
          {
            "id": 4553,
            "name": "正宁县",
            "level": 3,
            "regionCode": "621025",
            "childrens": []
          },
          {
            "id": 4554,
            "name": "宁县",
            "level": 3,
            "regionCode": "621026",
            "childrens": []
          },
          {
            "id": 4555,
            "name": "镇原县",
            "level": 3,
            "regionCode": "621027",
            "childrens": []
          }
        ]
      },
      {
        "id": 4561,
        "name": "定西市",
        "level": 2,
        "regionCode": "621100",
        "childrens": [
          {
            "id": 4562,
            "name": "安定区",
            "level": 3,
            "regionCode": "621102",
            "childrens": []
          },
          {
            "id": 4563,
            "name": "通渭县",
            "level": 3,
            "regionCode": "621121",
            "childrens": []
          },
          {
            "id": 4564,
            "name": "陇西县",
            "level": 3,
            "regionCode": "621122",
            "childrens": []
          },
          {
            "id": 4565,
            "name": "渭源县",
            "level": 3,
            "regionCode": "621123",
            "childrens": []
          },
          {
            "id": 4566,
            "name": "临洮县",
            "level": 3,
            "regionCode": "621124",
            "childrens": []
          },
          {
            "id": 4567,
            "name": "漳县",
            "level": 3,
            "regionCode": "621125",
            "childrens": []
          },
          {
            "id": 4568,
            "name": "岷县",
            "level": 3,
            "regionCode": "621126",
            "childrens": []
          }
        ]
      },
      {
        "id": 4574,
        "name": "陇南市",
        "level": 2,
        "regionCode": "621200",
        "childrens": [
          {
            "id": 4575,
            "name": "武都区",
            "level": 3,
            "regionCode": "621202",
            "childrens": []
          },
          {
            "id": 4576,
            "name": "成县",
            "level": 3,
            "regionCode": "621221",
            "childrens": []
          },
          {
            "id": 4577,
            "name": "文县",
            "level": 3,
            "regionCode": "621222",
            "childrens": []
          },
          {
            "id": 4578,
            "name": "宕昌县",
            "level": 3,
            "regionCode": "621223",
            "childrens": []
          },
          {
            "id": 4579,
            "name": "康县",
            "level": 3,
            "regionCode": "621224",
            "childrens": []
          },
          {
            "id": 4580,
            "name": "西和县",
            "level": 3,
            "regionCode": "621225",
            "childrens": []
          },
          {
            "id": 4581,
            "name": "礼县",
            "level": 3,
            "regionCode": "621226",
            "childrens": []
          },
          {
            "id": 4582,
            "name": "徽县",
            "level": 3,
            "regionCode": "621227",
            "childrens": []
          },
          {
            "id": 4583,
            "name": "两当县",
            "level": 3,
            "regionCode": "621228",
            "childrens": []
          }
        ]
      },
      {
        "id": 4589,
        "name": "临夏回族自治州",
        "level": 2,
        "regionCode": "622900",
        "childrens": [
          {
            "id": 4590,
            "name": "临夏市",
            "level": 3,
            "regionCode": "622901",
            "childrens": []
          },
          {
            "id": 4592,
            "name": "康乐县",
            "level": 3,
            "regionCode": "622922",
            "childrens": []
          },
          {
            "id": 4593,
            "name": "永靖县",
            "level": 3,
            "regionCode": "622923",
            "childrens": []
          },
          {
            "id": 4594,
            "name": "广河县",
            "level": 3,
            "regionCode": "622924",
            "childrens": []
          },
          {
            "id": 4595,
            "name": "和政县",
            "level": 3,
            "regionCode": "622925",
            "childrens": []
          },
          {
            "id": 4596,
            "name": "东乡族自治县",
            "level": 3,
            "regionCode": "622926",
            "childrens": []
          },
          {
            "id": 4597,
            "name": "积石山保安族东乡族撒拉族自治县",
            "level": 3,
            "regionCode": "622927",
            "childrens": []
          }
        ]
      },
      {
        "id": 4603,
        "name": "甘南藏族自治州",
        "level": 2,
        "regionCode": "623000",
        "childrens": [
          {
            "id": 4604,
            "name": "合作市",
            "level": 3,
            "regionCode": "623001",
            "childrens": []
          },
          {
            "id": 4605,
            "name": "临潭县",
            "level": 3,
            "regionCode": "623021",
            "childrens": []
          },
          {
            "id": 4606,
            "name": "卓尼县",
            "level": 3,
            "regionCode": "623022",
            "childrens": []
          },
          {
            "id": 4607,
            "name": "舟曲县",
            "level": 3,
            "regionCode": "623023",
            "childrens": []
          },
          {
            "id": 4608,
            "name": "迭部县",
            "level": 3,
            "regionCode": "623024",
            "childrens": []
          },
          {
            "id": 4609,
            "name": "玛曲县",
            "level": 3,
            "regionCode": "623025",
            "childrens": []
          },
          {
            "id": 4610,
            "name": "碌曲县",
            "level": 3,
            "regionCode": "623026",
            "childrens": []
          },
          {
            "id": 4611,
            "name": "夏河县",
            "level": 3,
            "regionCode": "623027",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 4617,
    "name": "青海省",
    "level": 1,
    "regionCode": "630000",
    "childrens": [
      {
        "id": 4618,
        "name": "西宁市",
        "level": 2,
        "regionCode": "630100",
        "childrens": [
          {
            "id": 4619,
            "name": "城东区",
            "level": 3,
            "regionCode": "630102",
            "childrens": []
          },
          {
            "id": 4620,
            "name": "城中区",
            "level": 3,
            "regionCode": "630103",
            "childrens": []
          },
          {
            "id": 4621,
            "name": "城西区",
            "level": 3,
            "regionCode": "630104",
            "childrens": []
          },
          {
            "id": 4622,
            "name": "城北区",
            "level": 3,
            "regionCode": "630105",
            "childrens": []
          },
          {
            "id": 4624,
            "name": "湟中区",
            "level": 3,
            "regionCode": "630106",
            "childrens": []
          },
          {
            "id": 4625,
            "name": "湟源县",
            "level": 3,
            "regionCode": "630123",
            "childrens": []
          },
          {
            "id": 5285,
            "name": "大通回族土族自治县",
            "level": 3,
            "regionCode": "630121",
            "childrens": []
          }
        ]
      },
      {
        "id": 4631,
        "name": "海东市",
        "level": 2,
        "regionCode": "630200",
        "childrens": [
          {
            "id": 4632,
            "name": "平安区",
            "level": 3,
            "regionCode": "630203",
            "childrens": []
          },
          {
            "id": 4633,
            "name": "民和回族土族自治县",
            "level": 3,
            "regionCode": "630222",
            "childrens": []
          },
          {
            "id": 4634,
            "name": "乐都区",
            "level": 3,
            "regionCode": "630202",
            "childrens": []
          },
          {
            "id": 4635,
            "name": "互助土族自治县",
            "level": 3,
            "regionCode": "630223",
            "childrens": []
          },
          {
            "id": 4636,
            "name": "化隆回族自治县",
            "level": 3,
            "regionCode": "630224",
            "childrens": []
          },
          {
            "id": 4637,
            "name": "循化撒拉族自治县",
            "level": 3,
            "regionCode": "630225",
            "childrens": []
          }
        ]
      },
      {
        "id": 4643,
        "name": "海北藏族自治州",
        "level": 2,
        "regionCode": "632200",
        "childrens": [
          {
            "id": 4644,
            "name": "门源回族自治县",
            "level": 3,
            "regionCode": "632221",
            "childrens": []
          },
          {
            "id": 4645,
            "name": "祁连县",
            "level": 3,
            "regionCode": "632222",
            "childrens": []
          },
          {
            "id": 4646,
            "name": "海晏县",
            "level": 3,
            "regionCode": "632223",
            "childrens": []
          },
          {
            "id": 4647,
            "name": "刚察县",
            "level": 3,
            "regionCode": "632224",
            "childrens": []
          }
        ]
      },
      {
        "id": 4653,
        "name": "黄南藏族自治州",
        "level": 2,
        "regionCode": "632300",
        "childrens": [
          {
            "id": 4654,
            "name": "同仁市",
            "level": 3,
            "regionCode": "632301",
            "childrens": []
          },
          {
            "id": 4655,
            "name": "尖扎县",
            "level": 3,
            "regionCode": "632322",
            "childrens": []
          },
          {
            "id": 4656,
            "name": "泽库县",
            "level": 3,
            "regionCode": "632323",
            "childrens": []
          },
          {
            "id": 4657,
            "name": "河南蒙古族自治县",
            "level": 3,
            "regionCode": "632324",
            "childrens": []
          }
        ]
      },
      {
        "id": 4663,
        "name": "海南藏族自治州",
        "level": 2,
        "regionCode": "632500",
        "childrens": [
          {
            "id": 4664,
            "name": "共和县",
            "level": 3,
            "regionCode": "632521",
            "childrens": []
          },
          {
            "id": 4665,
            "name": "同德县",
            "level": 3,
            "regionCode": "632522",
            "childrens": []
          },
          {
            "id": 4666,
            "name": "贵德县",
            "level": 3,
            "regionCode": "632523",
            "childrens": []
          },
          {
            "id": 4667,
            "name": "兴海县",
            "level": 3,
            "regionCode": "632524",
            "childrens": []
          },
          {
            "id": 4668,
            "name": "贵南县",
            "level": 3,
            "regionCode": "632525",
            "childrens": []
          }
        ]
      },
      {
        "id": 4674,
        "name": "果洛藏族自治州",
        "level": 2,
        "regionCode": "632600",
        "childrens": [
          {
            "id": 4675,
            "name": "玛沁县",
            "level": 3,
            "regionCode": "632621",
            "childrens": []
          },
          {
            "id": 4676,
            "name": "班玛县",
            "level": 3,
            "regionCode": "632622",
            "childrens": []
          },
          {
            "id": 4677,
            "name": "甘德县",
            "level": 3,
            "regionCode": "632623",
            "childrens": []
          },
          {
            "id": 4678,
            "name": "达日县",
            "level": 3,
            "regionCode": "632624",
            "childrens": []
          },
          {
            "id": 4679,
            "name": "久治县",
            "level": 3,
            "regionCode": "632625",
            "childrens": []
          },
          {
            "id": 4680,
            "name": "玛多县",
            "level": 3,
            "regionCode": "632626",
            "childrens": []
          }
        ]
      },
      {
        "id": 4686,
        "name": "玉树藏族自治州",
        "level": 2,
        "regionCode": "632700",
        "childrens": [
          {
            "id": 4687,
            "name": "玉树市",
            "level": 3,
            "regionCode": "632701",
            "childrens": []
          },
          {
            "id": 4688,
            "name": "杂多县",
            "level": 3,
            "regionCode": "632722",
            "childrens": []
          },
          {
            "id": 4689,
            "name": "称多县",
            "level": 3,
            "regionCode": "632723",
            "childrens": []
          },
          {
            "id": 4690,
            "name": "治多县",
            "level": 3,
            "regionCode": "632724",
            "childrens": []
          },
          {
            "id": 4691,
            "name": "囊谦县",
            "level": 3,
            "regionCode": "632725",
            "childrens": []
          },
          {
            "id": 4692,
            "name": "曲麻莱县",
            "level": 3,
            "regionCode": "632726",
            "childrens": []
          }
        ]
      },
      {
        "id": 4698,
        "name": "海西蒙古族藏族自治州",
        "level": 2,
        "regionCode": "632800",
        "childrens": [
          {
            "id": 4699,
            "name": "格尔木市",
            "level": 3,
            "regionCode": "632801",
            "childrens": []
          },
          {
            "id": 4700,
            "name": "德令哈市",
            "level": 3,
            "regionCode": "632802",
            "childrens": []
          },
          {
            "id": 4701,
            "name": "乌兰县",
            "level": 3,
            "regionCode": "632821",
            "childrens": []
          },
          {
            "id": 4702,
            "name": "都兰县",
            "level": 3,
            "regionCode": "632822",
            "childrens": []
          },
          {
            "id": 4703,
            "name": "天峻县",
            "level": 3,
            "regionCode": "632823",
            "childrens": []
          },
          {
            "id": 5286,
            "name": "茫崖市",
            "level": 3,
            "regionCode": "632803",
            "childrens": []
          },
          {
            "id": 5287,
            "name": "海西蒙古族藏族自治州直辖",
            "level": 3,
            "regionCode": "632825",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 4709,
    "name": "宁夏",
    "level": 1,
    "regionCode": "640000",
    "childrens": [
      {
        "id": 4710,
        "name": "银川市",
        "level": 2,
        "regionCode": "640100",
        "childrens": [
          {
            "id": 4711,
            "name": "兴庆区",
            "level": 3,
            "regionCode": "640104",
            "childrens": []
          },
          {
            "id": 4712,
            "name": "西夏区",
            "level": 3,
            "regionCode": "640105",
            "childrens": []
          },
          {
            "id": 4713,
            "name": "金凤区",
            "level": 3,
            "regionCode": "640106",
            "childrens": []
          },
          {
            "id": 4714,
            "name": "永宁县",
            "level": 3,
            "regionCode": "640121",
            "childrens": []
          },
          {
            "id": 4715,
            "name": "贺兰县",
            "level": 3,
            "regionCode": "640122",
            "childrens": []
          },
          {
            "id": 4716,
            "name": "灵武市",
            "level": 3,
            "regionCode": "640181",
            "childrens": []
          }
        ]
      },
      {
        "id": 4722,
        "name": "石嘴山市",
        "level": 2,
        "regionCode": "640200",
        "childrens": [
          {
            "id": 4723,
            "name": "大武口区",
            "level": 3,
            "regionCode": "640202",
            "childrens": []
          },
          {
            "id": 4724,
            "name": "惠农区",
            "level": 3,
            "regionCode": "640205",
            "childrens": []
          },
          {
            "id": 4725,
            "name": "平罗县",
            "level": 3,
            "regionCode": "640221",
            "childrens": []
          }
        ]
      },
      {
        "id": 4731,
        "name": "吴忠市",
        "level": 2,
        "regionCode": "640300",
        "childrens": [
          {
            "id": 4732,
            "name": "利通区",
            "level": 3,
            "regionCode": "640302",
            "childrens": []
          },
          {
            "id": 4733,
            "name": "盐池县",
            "level": 3,
            "regionCode": "640323",
            "childrens": []
          },
          {
            "id": 4734,
            "name": "同心县",
            "level": 3,
            "regionCode": "640324",
            "childrens": []
          },
          {
            "id": 4735,
            "name": "青铜峡市",
            "level": 3,
            "regionCode": "640381",
            "childrens": []
          },
          {
            "id": 5288,
            "name": "红寺堡区",
            "level": 3,
            "regionCode": "640303",
            "childrens": []
          }
        ]
      },
      {
        "id": 4741,
        "name": "固原市",
        "level": 2,
        "regionCode": "640400",
        "childrens": [
          {
            "id": 4742,
            "name": "原州区",
            "level": 3,
            "regionCode": "640402",
            "childrens": []
          },
          {
            "id": 4743,
            "name": "西吉县",
            "level": 3,
            "regionCode": "640422",
            "childrens": []
          },
          {
            "id": 4744,
            "name": "隆德县",
            "level": 3,
            "regionCode": "640423",
            "childrens": []
          },
          {
            "id": 4745,
            "name": "泾源县",
            "level": 3,
            "regionCode": "640424",
            "childrens": []
          },
          {
            "id": 4746,
            "name": "彭阳县",
            "level": 3,
            "regionCode": "640425",
            "childrens": []
          }
        ]
      },
      {
        "id": 4752,
        "name": "中卫市",
        "level": 2,
        "regionCode": "640500",
        "childrens": [
          {
            "id": 4753,
            "name": "沙坡头区",
            "level": 3,
            "regionCode": "640502",
            "childrens": []
          },
          {
            "id": 4754,
            "name": "中宁县",
            "level": 3,
            "regionCode": "640521",
            "childrens": []
          },
          {
            "id": 4755,
            "name": "海原县",
            "level": 3,
            "regionCode": "640522",
            "childrens": []
          }
        ]
      }
    ]
  },
  {
    "id": 4761,
    "name": "新疆维吾尔自治区",
    "level": 1,
    "regionCode": "650000",
    "childrens": [
      {
        "id": 4762,
        "name": "乌鲁木齐市",
        "level": 2,
        "regionCode": "650100",
        "childrens": [
          {
            "id": 4763,
            "name": "天山区",
            "level": 3,
            "regionCode": "650102",
            "childrens": []
          },
          {
            "id": 4764,
            "name": "沙依巴克区",
            "level": 3,
            "regionCode": "650103",
            "childrens": []
          },
          {
            "id": 4766,
            "name": "水磨沟区",
            "level": 3,
            "regionCode": "650105",
            "childrens": []
          },
          {
            "id": 4767,
            "name": "头屯河区",
            "level": 3,
            "regionCode": "650106",
            "childrens": []
          },
          {
            "id": 4768,
            "name": "达坂城区",
            "level": 3,
            "regionCode": "650107",
            "childrens": []
          },
          {
            "id": 4770,
            "name": "乌鲁木齐县",
            "level": 3,
            "regionCode": "650121",
            "childrens": []
          },
          {
            "id": 5289,
            "name": "新市区",
            "level": 3,
            "regionCode": "650104",
            "childrens": []
          },
          {
            "id": 5290,
            "name": "米东区",
            "level": 3,
            "regionCode": "650109",
            "childrens": []
          }
        ]
      },
      {
        "id": 4776,
        "name": "克拉玛依市",
        "level": 2,
        "regionCode": "650200",
        "childrens": [
          {
            "id": 4777,
            "name": "独山子区",
            "level": 3,
            "regionCode": "650202",
            "childrens": []
          },
          {
            "id": 4778,
            "name": "克拉玛依区",
            "level": 3,
            "regionCode": "650203",
            "childrens": []
          },
          {
            "id": 4779,
            "name": "白碱滩区",
            "level": 3,
            "regionCode": "650204",
            "childrens": []
          },
          {
            "id": 4780,
            "name": "乌尔禾区",
            "level": 3,
            "regionCode": "650205",
            "childrens": []
          }
        ]
      },
      {
        "id": 4804,
        "name": "昌吉回族自治州",
        "level": 2,
        "regionCode": "652300",
        "childrens": [
          {
            "id": 4805,
            "name": "昌吉市",
            "level": 3,
            "regionCode": "652301",
            "childrens": []
          },
          {
            "id": 4806,
            "name": "阜康市",
            "level": 3,
            "regionCode": "652302",
            "childrens": []
          },
          {
            "id": 4808,
            "name": "呼图壁县",
            "level": 3,
            "regionCode": "652323",
            "childrens": []
          },
          {
            "id": 4809,
            "name": "玛纳斯县",
            "level": 3,
            "regionCode": "652324",
            "childrens": []
          },
          {
            "id": 4810,
            "name": "奇台县",
            "level": 3,
            "regionCode": "652325",
            "childrens": []
          },
          {
            "id": 4811,
            "name": "吉木萨尔县",
            "level": 3,
            "regionCode": "652327",
            "childrens": []
          },
          {
            "id": 4812,
            "name": "木垒哈萨克自治县",
            "level": 3,
            "regionCode": "652328",
            "childrens": []
          }
        ]
      },
      {
        "id": 4818,
        "name": "博尔塔拉蒙古自治州",
        "level": 2,
        "regionCode": "652700",
        "childrens": [
          {
            "id": 4819,
            "name": "博乐市",
            "level": 3,
            "regionCode": "652701",
            "childrens": []
          },
          {
            "id": 4820,
            "name": "精河县",
            "level": 3,
            "regionCode": "652722",
            "childrens": []
          },
          {
            "id": 4821,
            "name": "温泉县",
            "level": 3,
            "regionCode": "652723",
            "childrens": []
          },
          {
            "id": 5291,
            "name": "阿拉山口市",
            "level": 3,
            "regionCode": "652702",
            "childrens": []
          }
        ]
      },
      {
        "id": 4827,
        "name": "巴音郭楞蒙古自治州",
        "level": 2,
        "regionCode": "652800",
        "childrens": [
          {
            "id": 4828,
            "name": "库尔勒市",
            "level": 3,
            "regionCode": "652801",
            "childrens": []
          },
          {
            "id": 4829,
            "name": "轮台县",
            "level": 3,
            "regionCode": "652822",
            "childrens": []
          },
          {
            "id": 4830,
            "name": "尉犁县",
            "level": 3,
            "regionCode": "652823",
            "childrens": []
          },
          {
            "id": 4831,
            "name": "若羌县",
            "level": 3,
            "regionCode": "652824",
            "childrens": []
          },
          {
            "id": 4832,
            "name": "且末县",
            "level": 3,
            "regionCode": "652825",
            "childrens": []
          },
          {
            "id": 4833,
            "name": "焉耆回族自治县",
            "level": 3,
            "regionCode": "652826",
            "childrens": []
          },
          {
            "id": 4834,
            "name": "和静县",
            "level": 3,
            "regionCode": "652827",
            "childrens": []
          },
          {
            "id": 4835,
            "name": "和硕县",
            "level": 3,
            "regionCode": "652828",
            "childrens": []
          },
          {
            "id": 4836,
            "name": "博湖县",
            "level": 3,
            "regionCode": "652829",
            "childrens": []
          }
        ]
      },
      {
        "id": 4842,
        "name": "阿克苏地区",
        "level": 2,
        "regionCode": "652900",
        "childrens": [
          {
            "id": 4843,
            "name": "阿克苏市",
            "level": 3,
            "regionCode": "652901",
            "childrens": []
          },
          {
            "id": 4844,
            "name": "温宿县",
            "level": 3,
            "regionCode": "652922",
            "childrens": []
          },
          {
            "id": 4845,
            "name": "库车市",
            "level": 3,
            "regionCode": "652902",
            "childrens": []
          },
          {
            "id": 4846,
            "name": "沙雅县",
            "level": 3,
            "regionCode": "652924",
            "childrens": []
          },
          {
            "id": 4847,
            "name": "新和县",
            "level": 3,
            "regionCode": "652925",
            "childrens": []
          },
          {
            "id": 4848,
            "name": "拜城县",
            "level": 3,
            "regionCode": "652926",
            "childrens": []
          },
          {
            "id": 4849,
            "name": "乌什县",
            "level": 3,
            "regionCode": "652927",
            "childrens": []
          },
          {
            "id": 4850,
            "name": "阿瓦提县",
            "level": 3,
            "regionCode": "652928",
            "childrens": []
          },
          {
            "id": 4851,
            "name": "柯坪县",
            "level": 3,
            "regionCode": "652929",
            "childrens": []
          }
        ]
      },
      {
        "id": 4867,
        "name": "喀什地区",
        "level": 2,
        "regionCode": "653100",
        "childrens": [
          {
            "id": 4868,
            "name": "喀什市",
            "level": 3,
            "regionCode": "653101",
            "childrens": []
          },
          {
            "id": 4869,
            "name": "疏附县",
            "level": 3,
            "regionCode": "653121",
            "childrens": []
          },
          {
            "id": 4870,
            "name": "疏勒县",
            "level": 3,
            "regionCode": "653122",
            "childrens": []
          },
          {
            "id": 4871,
            "name": "英吉沙县",
            "level": 3,
            "regionCode": "653123",
            "childrens": []
          },
          {
            "id": 4872,
            "name": "泽普县",
            "level": 3,
            "regionCode": "653124",
            "childrens": []
          },
          {
            "id": 4873,
            "name": "莎车县",
            "level": 3,
            "regionCode": "653125",
            "childrens": []
          },
          {
            "id": 4874,
            "name": "叶城县",
            "level": 3,
            "regionCode": "653126",
            "childrens": []
          },
          {
            "id": 4875,
            "name": "麦盖提县",
            "level": 3,
            "regionCode": "653127",
            "childrens": []
          },
          {
            "id": 4876,
            "name": "岳普湖县",
            "level": 3,
            "regionCode": "653128",
            "childrens": []
          },
          {
            "id": 4877,
            "name": "伽师县",
            "level": 3,
            "regionCode": "653129",
            "childrens": []
          },
          {
            "id": 4878,
            "name": "巴楚县",
            "level": 3,
            "regionCode": "653130",
            "childrens": []
          },
          {
            "id": 4879,
            "name": "塔什库尔干塔吉克自治县",
            "level": 3,
            "regionCode": "653131",
            "childrens": []
          }
        ]
      },
      {
        "id": 4885,
        "name": "和田地区",
        "level": 2,
        "regionCode": "653200",
        "childrens": [
          {
            "id": 4886,
            "name": "和田市",
            "level": 3,
            "regionCode": "653201",
            "childrens": []
          },
          {
            "id": 4888,
            "name": "墨玉县",
            "level": 3,
            "regionCode": "653222",
            "childrens": []
          },
          {
            "id": 4889,
            "name": "皮山县",
            "level": 3,
            "regionCode": "653223",
            "childrens": []
          },
          {
            "id": 4890,
            "name": "洛浦县",
            "level": 3,
            "regionCode": "653224",
            "childrens": []
          },
          {
            "id": 4891,
            "name": "策勒县",
            "level": 3,
            "regionCode": "653225",
            "childrens": []
          },
          {
            "id": 4892,
            "name": "于田县",
            "level": 3,
            "regionCode": "653226",
            "childrens": []
          },
          {
            "id": 4893,
            "name": "民丰县",
            "level": 3,
            "regionCode": "653227",
            "childrens": []
          }
        ]
      },
      {
        "id": 4899,
        "name": "伊犁哈萨克自治州",
        "level": 2,
        "regionCode": "654000",
        "childrens": [
          {
            "id": 4900,
            "name": "伊宁市",
            "level": 3,
            "regionCode": "654002",
            "childrens": []
          },
          {
            "id": 4901,
            "name": "奎屯市",
            "level": 3,
            "regionCode": "654003",
            "childrens": []
          },
          {
            "id": 4903,
            "name": "察布查尔锡伯自治县",
            "level": 3,
            "regionCode": "654022",
            "childrens": []
          },
          {
            "id": 4904,
            "name": "霍城县",
            "level": 3,
            "regionCode": "654023",
            "childrens": []
          },
          {
            "id": 4905,
            "name": "巩留县",
            "level": 3,
            "regionCode": "654024",
            "childrens": []
          },
          {
            "id": 4906,
            "name": "新源县",
            "level": 3,
            "regionCode": "654025",
            "childrens": []
          },
          {
            "id": 4907,
            "name": "昭苏县",
            "level": 3,
            "regionCode": "654026",
            "childrens": []
          },
          {
            "id": 4908,
            "name": "特克斯县",
            "level": 3,
            "regionCode": "654027",
            "childrens": []
          },
          {
            "id": 4909,
            "name": "尼勒克县",
            "level": 3,
            "regionCode": "654028",
            "childrens": []
          },
          {
            "id": 5292,
            "name": "霍尔果斯市",
            "level": 3,
            "regionCode": "654004",
            "childrens": []
          }
        ]
      },
      {
        "id": 4915,
        "name": "塔城地区",
        "level": 2,
        "regionCode": "654200",
        "childrens": [
          {
            "id": 4916,
            "name": "塔城市",
            "level": 3,
            "regionCode": "654201",
            "childrens": []
          },
          {
            "id": 4917,
            "name": "乌苏市",
            "level": 3,
            "regionCode": "654202",
            "childrens": []
          },
          {
            "id": 4918,
            "name": "额敏县",
            "level": 3,
            "regionCode": "654221",
            "childrens": []
          },
          {
            "id": 4919,
            "name": "沙湾市",
            "level": 3,
            "regionCode": "654223",
            "childrens": []
          },
          {
            "id": 4920,
            "name": "托里县",
            "level": 3,
            "regionCode": "654224",
            "childrens": []
          },
          {
            "id": 4921,
            "name": "裕民县",
            "level": 3,
            "regionCode": "654225",
            "childrens": []
          },
          {
            "id": 4922,
            "name": "和布克赛尔蒙古自治县",
            "level": 3,
            "regionCode": "654226",
            "childrens": []
          }
        ]
      },
      {
        "id": 4928,
        "name": "阿勒泰地区",
        "level": 2,
        "regionCode": "654300",
        "childrens": [
          {
            "id": 4929,
            "name": "阿勒泰市",
            "level": 3,
            "regionCode": "654301",
            "childrens": []
          },
          {
            "id": 4930,
            "name": "布尔津县",
            "level": 3,
            "regionCode": "654321",
            "childrens": []
          },
          {
            "id": 4931,
            "name": "富蕴县",
            "level": 3,
            "regionCode": "654322",
            "childrens": []
          },
          {
            "id": 4932,
            "name": "福海县",
            "level": 3,
            "regionCode": "654323",
            "childrens": []
          },
          {
            "id": 4933,
            "name": "哈巴河县",
            "level": 3,
            "regionCode": "654324",
            "childrens": []
          },
          {
            "id": 4934,
            "name": "青河县",
            "level": 3,
            "regionCode": "654325",
            "childrens": []
          },
          {
            "id": 4935,
            "name": "吉木乃县",
            "level": 3,
            "regionCode": "654326",
            "childrens": []
          }
        ]
      },
      {
        "id": 5005,
        "name": "吐鲁番市",
        "level": 2,
        "regionCode": "650400",
        "childrens": [
          {
            "id": 5124,
            "name": "高昌区",
            "level": 3,
            "regionCode": "650402",
            "childrens": []
          },
          {
            "id": 5125,
            "name": "鄯善县",
            "level": 3,
            "regionCode": "650421",
            "childrens": []
          },
          {
            "id": 5126,
            "name": "托克逊县",
            "level": 3,
            "regionCode": "650422",
            "childrens": []
          }
        ]
      },
      {
        "id": 5006,
        "name": "哈密市",
        "level": 2,
        "regionCode": "650500",
        "childrens": [
          {
            "id": 5127,
            "name": "伊州区",
            "level": 3,
            "regionCode": "650502",
            "childrens": []
          },
          {
            "id": 5128,
            "name": "巴里坤哈萨克自治县",
            "level": 3,
            "regionCode": "650521",
            "childrens": []
          },
          {
            "id": 5129,
            "name": "伊吾县",
            "level": 3,
            "regionCode": "650522",
            "childrens": []
          }
        ]
      },
      {
        "id": 5007,
        "name": "克孜勒苏柯尔克孜自治州",
        "level": 2,
        "regionCode": "653000",
        "childrens": [
          {
            "id": 5130,
            "name": "阿图什市",
            "level": 3,
            "regionCode": "653001",
            "childrens": []
          },
          {
            "id": 5131,
            "name": "阿克陶县",
            "level": 3,
            "regionCode": "653022",
            "childrens": []
          },
          {
            "id": 5132,
            "name": "阿合奇县",
            "level": 3,
            "regionCode": "653023",
            "childrens": []
          },
          {
            "id": 5133,
            "name": "乌恰县",
            "level": 3,
            "regionCode": "653024",
            "childrens": []
          }
        ]
      },
      {
        "id": 5008,
        "name": "石河子市",
        "level": 2,
        "regionCode": "659001",
        "childrens": []
      },
      {
        "id": 5009,
        "name": "阿拉尔市",
        "level": 2,
        "regionCode": "659002",
        "childrens": []
      },
      {
        "id": 5010,
        "name": "图木舒克市",
        "level": 2,
        "regionCode": "659003",
        "childrens": []
      },
      {
        "id": 5011,
        "name": "五家渠市",
        "level": 2,
        "regionCode": "659004",
        "childrens": []
      },
      {
        "id": 5012,
        "name": "北屯市",
        "level": 2,
        "regionCode": "659005",
        "childrens": []
      },
      {
        "id": 5013,
        "name": "铁门关市",
        "level": 2,
        "regionCode": "659006",
        "childrens": []
      },
      {
        "id": 5014,
        "name": "双河市",
        "level": 2,
        "regionCode": "659007",
        "childrens": []
      },
      {
        "id": 5015,
        "name": "可克达拉市",
        "level": 2,
        "regionCode": "659008",
        "childrens": []
      },
      {
        "id": 5016,
        "name": "昆玉市",
        "level": 2,
        "regionCode": "659009",
        "childrens": []
      },
      {
        "id": 5017,
        "name": "胡杨河市",
        "level": 2,
        "regionCode": "659010",
        "childrens": []
      }
    ]
  },
  {
    "id": 4951,
    "name": "台湾省",
    "level": 1,
    "regionCode": "710000",
    "childrens": []
  },
  {
    "id": 4952,
    "name": "香港",
    "level": 1,
    "regionCode": "810000",
    "childrens": []
  },
  {
    "id": 4953,
    "name": "澳门",
    "level": 1,
    "regionCode": "820000",
    "childrens": []
  }
]