











import { Component, Vue, Prop, Watch, Inject, Provide, Emit } from 'vue-property-decorator';

interface Vdata {
    name: string; // 组件名称
}


@Component({
    components: {

    },
})
export default class ContainerHeaderTitle extends Vue {

    // data
    private data: Vdata = {
        name: 'container-header-title',
    }

    // props
    @Prop({default: '事件推送'}) readonly headerTitle: string | undefined;
    @Prop({default: () => ({})}) readonly customStyle: any;
    
}
