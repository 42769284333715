













































































































































































































































































































import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";
import axios from "axios";
// import { State } from 'vuex-class';
import * as regionData from "./regionData";

interface Vdata {
  name: string; // 组件名称
}

interface adCodeVosItem {
  adCode: string;
  type: number;
}

// 定义 筛选项 参数 类型
type FilterDataType = {
  beginEpochMillis?: number | string; // 开始时间戳
  endEpochMillis?: number | string; // 结束时间戳
  id?: number | string; // 事件id
  infoType: number | string; // 信息类型
  page: number; // 页码
  pageSize: number; // 每页条数
  platIds: Array<string | number | null | undefined>; // 输入框关键词
  wd?: string; // 输入框关键词
  wdType?: number | string; // 查询方式 0用户id 1用户名 2关键词
  imgId?: string; // 图片ID
  imgType?: number | string; // 图片搜索类型 0 1
  sortCode?: number; //排序 0 时间升序 -1时间降序
  timeOptions?: number;
  adCodeVos?: Array<adCodeVosItem>;
};

interface Vdata {
  name: string; // 组件名称
}

// 定义 筛选项 参数 类型
// type FilterDataType = {
//   beginEpochMillis?: number | string; // 开始时间戳
//   endEpochMillis?: number | string; // 结束时间戳
//   id?: number | string; // 事件id
//   infoType: number | string; // 信息类型
//   page: number; // 页码
//   pageSize: number; // 每页条数
//   platIds: Array<string | number | null | undefined>; // 输入框关键词
//   wd?: string; // 输入框关键词
//   wdType?: number | string; // 查询方式 0用户id 1用户名 2关键词
//   imgId?: string; // 图片ID
//   imgType?: number; // 图片搜索类型 0 1
// };

// 定义 平台项
interface PlatformItem {
  key: number;
  name: string;
}
interface RegionOptionsItem {
  id: number,
  name: string,
  level: number,
  regionCode: string,
  childrens?: Array<RegionOptionsItem> | []; //
}
type PropertyName = "childrens";

interface adCodeVosItem {
  adCode: string;
  type: number;
}

@Component({
  components: {},
})
export default class ContainerFilter extends Vue {
  // @State(state => state.userInfo.provinceId)
  // private provinceId;
  private provinceId = (this.$store.state.userInfo.provinceId + Array(6).join('0')).slice(0,6);
  // data
  private data: Vdata = {
    name: "container-filter",
  };
  // data 平台列表 复选框
  private plats: Array<PlatformItem> = [
    // { key: 2, name: '抖音' }, { key: 3, name: '快手' },
  ];
  
  private regionType = {
    value: [0,1,2],
    label: '全部地域',
    options: [
      {label: '全部地域', value: -1, checked: true},
      {label: '发布地域', value: 0, checked: false},
      {label: '作者地域', value: 1, checked: false},
      {label: '涉及地域', value: 2, checked: false}
    ]
  };
  private regionList = {
    value: [""],
    options: this.deleteProperty(regionData.region, 'childrens')
  };
  // private regionOptions: Array<RegionOptionsItem> = [];
  // private region = {
  //   label: '全部地域',
  //   value: [],
  //   resVal: [],
  //   resData:{
  //     all:null,
  //     publish:null,
  //     author:null,
  //     involve:null,
  //   },
  //   regionOptions: [
  //     {
  //       regionCode: 'all',
  //       name: '全部地域'
  //     },
  //     {
  //       regionCode: 'publish',
  //       name: '发布地域',
  //       childrens: regionData.region
  //     },
  //     {
  //       regionCode: 'author',
  //       name: '作者地域',
  //       childrens: regionData.region
  //     },
  //     {
  //       regionCode: 'involve',
  //       name: '涉及地域',
  //       childrens: regionData.region
  //     },
  //   ],
  // };
  // private regionAll = {
  //   label: '全部地域',
  //   options: [
  //     {
  //       regionCode: '-1',
  //       name: '全部地域'
  //     }
  //   ],
  //   value: '',
  // };
  // private regionPublish = {
  //   label: '发布地域',
  //   options: [{
  //       regionCode: '',
  //       name: '发布地域',
  //       childrens: regionData.region
  //     }
  //   ],
  //   value: '',
  // };
  // private regionAuthor = {
  //   label: '作者地域',
  //   options: [{
  //       regionCode: '',
  //       name: '作者地域',
  //       childrens: regionData.region
  //     }
  //   ],
  //   value: '',
  // };
  // private regionInvolve = {
  //   label: '涉及地域',
  //   options: [{
  //       regionCode: '',
  //       name: '涉及地域',
  //       childrens: regionData.region
  //     }
  //   ],
  //   value: '',
  // };
  private adCodeVos: Array<adCodeVosItem> = [
    {
      "adCode": this.provinceId,
      "type": 0
    },
    {
      "adCode": this.provinceId,
      "type": 1
    },
    {
      "adCode": this.provinceId,
      "type": 2
    }
  ];
  // data checkedPlats
  private checkedPlats: Array<number | string | undefined | null> = [];
  // data isCheckPlatsAll 选择全部平台
  private isCheckPlatsAll: boolean = true;
  // data 推送类别 图片10 视频20
  private pushType: number | string = 20;
  // data 起止时间 当天1 近一周2 近一月3 自定义4 （默认近一周）
  private timeType: number | string = 0;
  // data 自定义时间
  private customTime: Array<number | string> = [
    new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
    new Date().getTime(),
  ];
  // data 内容搜索 下拉菜单
  private selectList: Array<object> = [
    { value: 0, name: "用户ID" },
    { value: 1, name: "用户名" },
    { value: 2, name: "关键词" },
  ];
  private selectValue: number | string = 0; // 默认展示 用户ID
  private selectKeyWord: string = ""; // 输入的关键词
  // data 搜索方式
  private selectWaysList: Array<object> = [
    { value: 1, name: "以文搜图" },
    { value: 2, name: "以图搜图" },
  ];
  private selectWaysValue: number | string = 1; // 默认展示 以文搜图
  private searchWaysType: number | string = 0; // 默认 人脸0 其他1
  // data 内容搜索 以图搜图
  private imageSearchValue: string = "";
  private showImageUploadModal: boolean = false; // 是否显示以图搜图的弹窗
  // REQUEST FILTER DATA
  private filterData: FilterDataType = {
    beginEpochMillis: 0,
    endEpochMillis: 0,
    id: 0,
    infoType: 0,
    page: 1,
    pageSize: 20,
    platIds: [],
    wd: "",
    wdType: 0,
    // 视图搜索 参数
    imgId: "",
    imgType: 0, // 0, 1
    // sortCode: 0,
    timeOptions: 0,
  };

  // props
  @Prop({ default: "事件推送" }) readonly pageType: string | undefined; // 事件推送 视图搜索
  @Prop({ default: 0, type: Number }) readonly eventId:
    | string
    | number
    | undefined; // 左侧事件ID
  @Prop({ default: true, type: Boolean }) readonly showTotalNum:
    | boolean
    | undefined; // 图片 image 、视频 video

  // Emit
  @Emit("getField") // 传递参数出去
  getField(params: FilterDataType): FilterDataType {
    return params;
  }
  @Prop({ default: 0, type: Number }) readonly totalNum: number | undefined; // 图片 image 、视频 video

  // Watch
  @Watch("eventId", { immediate: false, deep: true })
  onEventIdChange(newVal: number, oldVal: number | null | undefined): void {
    // 初始化 格式化参数 AND REQUEST
    this.formatRequestAttr();
  }

  // mounted
  private mounted(): void {
    this.requestPlatformList();
  }
  
  private deleteProperty(arr:any, propertyName:PropertyName): any {
    for (const item of arr) {
      if (item[propertyName] && item[propertyName].length < 1) {
        delete item[propertyName]
      }
      if (item[propertyName] && item[propertyName].length > 1) {
        this.deleteProperty(item[propertyName], propertyName)
      }
    }
    return arr;
  }
  
  // private handleRegionChange(value: any, type:any) {
  //   if(type == -1) {
  //     this.adCodeVos = [];
  //     this.regionPublish.label = '发布地域'
  //     this.regionPublish.value = ''
  //     this.regionAuthor.label = '作者地域'
  //     this.regionAuthor.value = ''
  //     this.regionInvolve.label = '涉及地域'
  //     this.regionInvolve.value = ''
  //     return;
  //   }
  //   this.regionAll.value = ''
  //   let pathLabel = this.getCascaderPath(value[value.length - 1]).map((item: { name: any; }) => item.name).join("/"); 
  //   if(type == 0){
  //     this.regionPublish.label = value.length !== 1 || value[0] !== "" ? pathLabel : '发布地域'
  //     this.regionPublish.value = value
  //   }
  //   if(type == 1){
  //     this.regionAuthor.label = value.length !== 1 || value[0] !== "" ? pathLabel : '作者地域'
  //     this.regionAuthor.value = value
  //   }
  //   if(type == 2){
  //     this.regionInvolve.label = value.length !== 1 || value[0] !== "" ? pathLabel : '涉及地域'
  //     this.regionInvolve.value = value
  //   }
    
  //   this.adCodeVos.forEach((item,index) => {
  //     item.type == type ? this.adCodeVos.splice(index, 1) : ''
  //   })
  //   if(value[value.length-1] !== ""){
  //     this.adCodeVos.push({adCode: value[value.length-1], type: type})
  //   }
    
  //   this.formatRequestAttr();
  // }
  
  // private changeRegion(val){
    // debugger;
    // let adCodeVos = this.adCodeVos;
    // let result = {}
    // if(val.length > 0){
    //   val.forEach(item => {
    //     this.region.resData[[item[0]]] = item;
    //     result = Object.assign(result, { [item[0]]: item })
    //   })
    //   var resArr = []
    //   for(let res in this.region.resData){
    //     if(this.region.resData[res]){
    //       resArr.push(this.region.resData[res])
    //     }
    //   }
    //   this.region.value = resArr
      
      // if(this.region.resData['all']){
      //   debugger;
      //   this.region.resData.publish = null;
      //   this.region.resData.author = null;
      //   this.region.resData.involve = null;
      //   this.region.value = [['all']]
      // }else{
    // }
    /**************************************** *****/
      // let node = this.$refs.regionRef.getCheckedNodes()
      // this.region.value = [node[node.length-1].path]
      // console.log(val,this.region.value,this.region.resVal,node)
      // debugger;
      //   this.region.resVal.push(node[node.length-1].path)
      //   for(let i = 0; i < this.region.resVal.length; i++){
      //     if(this.region.resVal[i][0] == node[node.length-1].path[0]){
      //       this.region.resVal[i] = node[node.length-1].path
      //       break;
      //     }
      //   }
        // this.region.value = this.region.resVal
        // console.log(this.region.resVal)
      // }
  // }
  
  private regionTypeChange(value:any){
    if (value.label == '全部地域') {
      this.regionType.options.forEach(item => item.checked=false);
      this.regionType.options[0].checked = true;
      this.regionType.value = [0,1,2];
    } else {
      if (value.checked) { // 取消
        value.checked = false
        if (this.regionType.options.filter(item => item.checked).length < 1) {
          this.regionType.options[0].checked = true
        }
      } else { // 勾选
        value.checked = true
        if (this.regionType.options.filter(item => item.checked).length == this.regionType.options.length-1 && !this.regionType.options[0].checked) {
          this.regionType.options.forEach(item => item.checked=false);
          this.regionType.options[0].checked = true
        } else {
          this.regionType.options[0].checked = false
        }
      }
      this.regionType.value = this.regionType.options[0].checked ? [0,1,2] :
        this.regionType.options.filter(item => item.checked && item.value!=-1).map(item => item.value);
    }
    this.regionChange();
  };
  private regionChange(){
    const regionCode = this.regionList.value[this.regionList.value.length-1];
    if(regionCode == "") {
      this.adCodeVos = [];
    }else{
      let arr = this.regionType.value.map(item => {
        return {adCode: this.regionList.value[this.regionList.value.length-1], type: item}
      });
      this.adCodeVos = arr;
    }
    
    this.formatRequestAttr();
  }
  
  // 获取级联路径 val 最后一级的regionCode
  private getCascaderPath(val:any, arr = regionData.region, path:Array<RegionOptionsItem> = []) {
    for (const item of arr) {
      if (item.regionCode == val) {
        path.push(item);
        return path;
      }
      if (item.childrens==undefined || item.childrens.length<1) continue;
      path.push(item);
      const temp:any = this.getCascaderPath(val, item.childrens, path);
      if (temp.length > 0) return temp;
      path.pop();
    }
    return [];
  };

  // methods 获取平台列表 REQUEST
  private requestPlatformList(): void {
    const url: string = this.$store.state.common.uviewServer;
    // console.log(179, `${url}data/optional/platform`);
    this.plats = []; // 清空
    axios.get(`${url}data/optional/platform`).then((res: any) => {
      if (res.data.code.substr(0, 1) === "R") {
        this.plats = res.data.data ? res.data.data : [];
        this.checkedPlats = this.plats.map((v) => v.key);
        this.formatRequestAttr(); // 格式化
      }
    });
  }

  // methods 选中平台复选框
  private handleCheckedPlatsChange(): void {
    // 判断是否全选
    if (this.checkedPlats.length === this.plats.length) {
      this.isCheckPlatsAll = true;
    } else {
      this.isCheckPlatsAll = false;
    }
    this.formatRequestAttr(); // 格式化
  }

  // methods 点击全选 全选（全不选）所有平台
  private handleCheckedPlatsAllChange(): void {
    if (this.isCheckPlatsAll) {
      this.checkedPlats = this.plats.map((v) => v.key);
    } else {
      this.checkedPlats = [];
    }
    this.formatRequestAttr(); // 格式化
  }

  // methods 修改时间 change 交互
  private dateTimeChange(label: number): void {
    this.filterData.timeOptions = label;
    this.changeTime();
    this.formatRequestAttr();
  }

  // methods 时间筛选 自定义时间 当天 近一周 近一月 自定义
  private changeTime(): void {
    let currentTimsatmp = new Date().getTime();
    let weekAgoTimsatmp = new Date(
      currentTimsatmp - 1000 * 60 * 60 * 24 * 7
    ).getTime(); //一周前时间戳
    let monthAgoTimsatmp = new Date(
      currentTimsatmp - 1000 * 60 * 60 * 24 * 31
    ).getTime(); //一月前时间戳
    let startDayTimsatmp = new Date(new Date().setHours(0, 0, 0, 0)).getTime(); //凌晨的时间戳
    // let formatStr = "yyyy-MM-dd hh:mm:ss";
    switch (this.timeType) {
      case 0: // 当天
        this.filterData.beginEpochMillis = startDayTimsatmp;
        this.filterData.endEpochMillis = new Date().getTime();
        break;
      case 1: // 近一周
        this.filterData.beginEpochMillis = weekAgoTimsatmp;
        this.filterData.endEpochMillis = new Date().getTime();
        break;
      case 2: // 近一月
        this.filterData.beginEpochMillis = monthAgoTimsatmp;
        this.filterData.endEpochMillis = new Date().getTime();
        break;
      case 3: // 自定义 默认近一周
        // this.customTime = [weekAgoTimsatmp, new Date().getTime()];
        if (this.customTime.length > 0) {
          this.filterData.beginEpochMillis = this.customTime[0];
          this.filterData.endEpochMillis = this.customTime[1];
        }
        break;
    }
  }

  private timeSort(this: any): void {
    this.filterData.sortCode = this.filterData.sortCode
      ? 0
      : this.filterData.sortCode < 0
      ? 0
      : -1;
    this.formatRequestAttr();
  }

  private relationSort(this: any): void {
    this.filterData.sortCode = null;
    this.formatRequestAttr();
  }

  // methods 格式化参数 并请求列表
  private formatRequestAttr(): void {
    // 事件推送 & 视图搜索
    this.changeTime(); // 获取时间
    this.filterData.id = this.eventId;
    this.filterData.infoType = this.pushType;
    this.filterData.platIds = this.checkedPlats;
    this.filterData.wdType = this.selectValue;

    if (this.pageType === "事件推送") {
      this.filterData.wd = this.selectKeyWord;
    }
    
    this.filterData.adCodeVos = this.regionList.value[this.regionList.value.length-1] == "" ? [] : this.adCodeVos;

    // 视图搜索
    this.filterData.imgType = this.searchWaysType;
    // console.log(290,this.filterData)
    this.getField(this.filterData); // 传递筛选参数出去
  }

  // methods 点击显示 以图搜图 弹窗
  private showImageUploadModalFn(): void {
    this.showImageUploadModal = true;
  }

  // methods 上传图片 changeFileUpload
  private changeFileUpload(file: any, fileList: Array<object>): void {
    console.log(file, fileList);
    // 检测图片是否超过 4M
    if (file.size / 1024 / 1024 > 4) {
      this.$message({ message: "图片尺寸超过4M，请重新上传！", type: "error" });
      return;
    }
    let formData: FormData = new FormData();
    formData.append("file", file.raw);
    // // console.log(formData);
    let server: string = this.$store.state.common.uviewServer; // 获取服务
    // REQUEST
    axios({
      method: "post",
      url: `${server}data/upload/temporary-file`,
      data: formData,
    }).then((res: any) => {
      if (res.data.data) {
        // obs文件ID
        this.filterData.imgId = res.data.data;
        this.imageSearchValue = file.name; // 图片名称
        this.showImageUploadModal = false;
        this.$message({ message: "上传成功", type: "success" });
        // REQUEST LIST
        this.formatRequestAttr();
      } else {
        this.$message({ message: res.data.msg, type: "error" });
      }
    });
  }

  // 点击搜索
  private search(): void {
    this.formatRequestAttr(); // 格式化参数 然后请求列表
  }

  // 修改 搜索方式
  private changeSearchWays(): void {
    // 以文搜图的时候 必须清空 imgId
    if (this.selectWaysValue === 1) {
      this.filterData.imgId = "";
      this.imageSearchValue = "";
    }
  }
}
