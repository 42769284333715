// 初始化 获取列表项 详情
export function initGeDetailByListItem(this: any, it: any): void {
  console.log(it);
  // return
  let url = `${this.$store.state.common.uviewServer}data/details/${it.esPropInfo.docId}/${it.esPropInfo.index}`;
  console.log(url);
  this.axios.get(url).then((res: any) => {
    console.log(res);
    if (res.data.code.substr(0, 1) === 'R') {
      this.infoItemDetailData.collection = res.data.collection;
      this.infoItemDetailData.info = res.data.info;
      this.infoItemDetailData = { collection: res.data.data.collection, ...res.data.data.info, playVideo: false, typeId: ['VIDEO'] };
      console.log(this.infoItemDetailData);
    }
  })
}

// 删除该条信息
export function deleteCurrentItemInfo(this: any): void {
  let url = `${this.$store.state.common.uviewServer}data/info`;
  this.axios.delete(url, {
    data: {
      docId: this.infoItemDetailData.esPropInfo.docId,
      index: this.infoItemDetailData.esPropInfo.index
    }
  }).then((res: any) => {
    // console.log(res);
    if (res.data.code.substr(0, 1) === 'R') {
      // 关闭信息详情弹窗 刷新列表
      this.data.detailModal = false;
      this.handleCurrentChange(1);
      // let t = setTimeout(() => {
      //   // clearTimeout(t);
      // },1000)
      this.$message({ type: 'success', message: '删除成功', customClass: 'cancel-collect-tips' }); return;
    } else {
      this.$message({ type: 'error', message: res.data.msg, customClass: 'cancel-collect-tips' }); return;
    }
  })
}

// 新增文件夹
export function addCollectFolder(this: any, infoId: string | number, folderName: string): void {
  let url = `${this.$store.state.common.uviewServer}collection/folder`;
  this.axios.post(url, { name: folderName }).then((res: any) => {
    if (res.data.code.substr(0, 1) === 'R') {
      // 直接绑定新的文件夹
      const { id } = res.data.data;
      moveInfoToFolder.bind(this)(infoId, id);
      // this.collectInfo.type = 'move';
      // 再次获取文件夹列表
      getCollectFolder.bind(this)();
      this.collectInfo.addCollectContent = "";
      //this.$message({ type: 'success', message: '新增成功' }); return;
    }
  })
}

// 获取文件夹列表
export function getCollectFolder(this: any): void {
  let url = `${this.$store.state.common.uviewServer}collection/folder`;
  this.collectInfo.selectOptions = [];
  this.axios.get(url).then((res: any) => {
    // console.log(res);
    if (res.data.code.substr(0, 1) === 'R') {
      this.collectInfo.selectOptions = res.data.data;
    }
  })
}


// 移动信息 到当前的文件夹 (新增收藏)
export function moveInfoToFolder(this: any, infoId: string | number, folderId: string | number): void {
  let url = `${this.$store.state.common.uviewServer}collection/info`;
  this.axios.post(url, { folderId: folderId, docId: infoId, infoType: this.infoItemDetailData.typeId.includes('VIDEO') ? 1 : 0 }).then((res: any) => {
    // console.log(res);
    if (res.data.code.substr(0, 1) === 'R') {
      this.collectInfo.type = 'move';
      this.infoItemDetailData.collection = true;
      // 关闭信息详情弹窗 刷新列表
      this.collectInfo.dialogCollectVisible = false;
      this.collectInfo.selectMoveCollectValue = ''; // 置空
      this.handleCurrentChange(1);
      this.$message({ type: 'success', message: '收藏成功', customClass: 'cancel-collect-tips' }); return;
    } else {
      this.$message({ type: 'error', message: res.data.msg, customClass: 'cancel-collect-tips' }); return;
    }
  })
}


// 删除该信息的收藏信息
export function deleteInfoCollectFolder(this: any, infoId: string | number): void {
  let url = `${this.$store.state.common.uviewServer}collection/doc/${infoId}`;
  this.axios.delete(url).then((res: any) => {
    if (res.data.code.substr(0, 1) === 'R') {
      this.infoItemDetailData.collection = false;
      // 关闭信息详情弹窗 刷新列表
      //this.data.detailModal = false;
      this.handleCurrentChange(1);
      this.$message({ type: 'success', message: '取消收藏成功', customClass: 'cancel-collect-tips' }); return;
    }
  })
}


// 下载文件
export function downloadFile(this: any): void {
  const typeId = this.infoItemDetailData.typeId[0];
  if (typeId === "VIDEO") { // 视频
    window.open(`https://kedun-ai-bj4-video.obs.cn-north-4.myhuaweicloud.com/${this.infoItemDetailData.bid}`)
  } else { // 图片  目前没有图片数据 TODO
    /* (fetch as any)(`https://kedun-ai-bj4-keyframes.obs.cn-north-4.myhuaweicloud.com/${this.infoItemDetailData.bid}`, {
      method: 'get',
      mode: 'cors',
      responseType: 'blob'
    }).then((res: any) => {
      return res.blob();
    }).then((response: any) => {
      if (response) {
        let blob = response.data;
        let a = document.createElement("a");
        let url = window.URL.createObjectURL(blob);
        let name = response.headers.filename;
        let filename = `template${new Date().getTime()}` + ".mp4";
        a.href = url;
        a.download = filename;
        // 兼容Firefox
        document.body.appendChild(a);
        a.click();
        // 兼容Firefox
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    }).catch((error: any) => {
      console.log(error);
      this.$message({ type: 'error', message: '导出失败', customClass: 'cancel-collect-tips' }); return;
    }) */;
    let a = document.createElement("a");
    a.href = `https://kedun-ai-bj4-keyframes.obs.cn-north-4.myhuaweicloud.com/${this.infoItemDetailData.bid}`;
    a.download = "";
    // 兼容Firefox
    document.body.appendChild(a);
    a.click();
    // 兼容Firefox
    document.body.removeChild(a);
    window.URL.revokeObjectURL(a.href);
  }
}
