<template>
    <div>
        <!--加载中-->
        <div class="common-div" v-if="status=='loading'">
            <img src="./loading.gif" alt="" class="img-gif">
            <p>数据加载中......</p>
        </div>

        <!--暂无数据-->
        <div class="common-div" v-if="status=='nodata'">
            <img src="./nodata.png" alt="" class="img-png">
            <p>未查询到相关数据</p>
        </div>

        <!--加载错误-->
        <div class="common-div" v-if="status=='error'">
            <img src="./error.png" alt="" class="img-png">
            <p>系统繁忙，请五分钟后再次访问。</p>
        </div>
    </div>
</template>
<script>
    // import { mapState } from 'vuex'
    export default {
        props: ['status'],
        computed: {
            // ...mapState(['loadingView', 'errorView', 'nodataView'])
        },
        data(){
            return {
                loadingView: false,
                errorView: false,
                nodataView: false,
            }
        },
        methods:{
            loading(){
                this.loadingView = true;
                this.errorView = false;
                this.nodataView = false;
            },
            error(){
                this.loadingView = false;
                this.errorView = true;
                this.nodataView = false;
            },
            nodata(){
                this.loadingView = false;
                this.errorView = false;
                this.nodataView = true;
            },
            sucess(){
                this.loadingView = false;
                this.errorView = false;
                this.nodataView = false;
            }
        }
    }
</script>
<style lang="stylus" scoped>
    .common-div
        color:#ccc;font-sziz:16px;display:flex;margin-top:150px;flex-direction:column;width:100%;height:auto;align-items:center;margin-bottom:50px;
        .img-gif
            width:60px;height:auto;margin-bottom:28px;
        .img-png
             width:80px;height:auto;margin-bottom:25px;
</style>
